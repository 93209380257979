import React, { useState, useEffect } from "react";
import { Button, Table, CardFooter, Modal, Row, Col, CardBody, Label } from "reactstrap";
import { AvField, AvForm, AvGroup, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation";
import "./style.scss";
import * as moment from "moment";

const BudgetAnalysis = ({ propData, isOpenModel, handleCheckbox, submitCallback }) => {
    // Modal open state
    let [modal, setModal] = useState(false);
    let [data, setData] = useState([]);
    let [update, setUpdate] = useState(moment());
    let [heavyPercentage, setHeavyPercentage] = useState(0);
    let [defferedPercentage, setDefferedPercentage] = useState(0);
    let [upgradesPercentage, setUpgradesPercentage] = useState(0);
    let [valuePercentage, setValuePercentage] = useState(0);
    let [softCostsPercentage, setSoftCostsPercentage] = useState(0);
    let [heavyAmount, setHeavyAmount] = useState(0);
    let [defferedAmount, setDefferedAmount] = useState(0);
    let [upgradesAmount, setUpgradesAmount] = useState(0);
    let [valueAmount, setValueAmount] = useState(0);
    let [softCostsAmount, setSoftCostsAmount] = useState(0);
    let [customErrorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setData(propData);
        setModal(isOpenModel);
        calculate_checkbox_values();
    }, [propData]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

    }, [update]);

    let calculate_checkbox_values = () => {

        if (propData.selected_class.length === 1) {
            setHeavyPercentage(propData.selected_class.find(o => o.startsWith('heavy')) ? Math.round(100) : 0);
            setHeavyAmount(propData.selected_class.find(o => o.startsWith('heavy')) ? (propData.cost_amount) : 0);

            setDefferedPercentage(propData.selected_class.find(o => o.startsWith('deferred')) ? Math.round(100) : 0);
            setDefferedAmount(propData.selected_class.find(o => o.startsWith('deferred')) ? (propData.cost_amount) : 0);

            setUpgradesPercentage(propData.selected_class.find(o => o.startsWith('upgrades')) ? Math.round(100) : 0);
            setUpgradesAmount(propData.selected_class.find(o => o.startsWith('upgrades')) ? (propData.cost_amount) : 0);

            setSoftCostsPercentage(propData.selected_class.find(o => o.startsWith('soft')) ? Math.round(100) : 0);
            setSoftCostsAmount(propData.selected_class.find(o => o.startsWith('soft')) ? (propData.cost_amount) : 0);

            setValuePercentage(propData.selected_class.find(o => o.startsWith('value')) ? Math.round(100) : 0);
            setValueAmount(propData.selected_class.find(o => o.startsWith('value')) ? (propData.cost_amount) : 0);
        }
        else if (propData.selected_class.length === 2 || propData.selected_class.length === 4 || propData.selected_class.length === 5) {
            setHeavyPercentage(propData.selected_class.find(o => o.startsWith('heavy')) ? Math.round(100 / propData.selected_class.length) : 0);
            setHeavyAmount(propData.selected_class.find(o => o.startsWith('heavy')) ? (propData.cost_amount / propData.selected_class.length) : 0);

            setDefferedPercentage(propData.selected_class.find(o => o.startsWith('deferred')) ? Math.round(100 / propData.selected_class.length) : 0);
            setDefferedAmount(propData.selected_class.find(o => o.startsWith('deferred')) ? (propData.cost_amount / propData.selected_class.length) : 0);

            setUpgradesPercentage(propData.selected_class.find(o => o.startsWith('upgrades')) ? Math.round(100 / propData.selected_class.length) : 0);
            setUpgradesAmount(propData.selected_class.find(o => o.startsWith('upgrades')) ? (propData.cost_amount / propData.selected_class.length) : 0);

            setValuePercentage(propData.selected_class.find(o => o.startsWith('value')) ? Math.round(100 / propData.selected_class.length) : 0);
            setValueAmount(propData.selected_class.find(o => o.startsWith('value')) ? (propData.cost_amount / propData.selected_class.length) : 0);

            setSoftCostsPercentage(propData.selected_class.find(o => o.startsWith('soft')) ? Math.round(100 / propData.selected_class.length) : 0);
            setSoftCostsAmount(propData.selected_class.find(o => o.startsWith('soft')) ? (propData.cost_amount / propData.selected_class.length) : 0);
        } else if (propData.selected_class.length === 3) {
            let count = 0;
            let percent_total = 0;
            let amount_total = 0;
            setHeavyPercentage(0);
            setHeavyAmount(0);
            setDefferedPercentage(0);
            setDefferedAmount(0);
            setUpgradesPercentage(0);
            setUpgradesAmount(0);
            setValuePercentage(0);
            setValueAmount(0);
            setSoftCostsPercentage(0);
            setSoftCostsAmount(0);
            heavyPercentage = 0;
            heavyAmount = 0;
            defferedPercentage = 0;
            defferedAmount = 0;
            upgradesPercentage = 0;
            upgradesAmount = 0;
            valuePercentage = 0;
            valueAmount = 0;
            softCostsPercentage = 0;
            softCostsAmount = 0;
            propData.selected_class.forEach(rec => {
                count = count + 1;
                if (count <= 2) {

                    percent_total = percent_total + Math.round(100 / propData.selected_class.length);
                    amount_total = amount_total + ((propData.cost_amount * Math.round(100 / propData.selected_class.length)) / 100);

                    let percent = Math.round(100 / propData.selected_class.length);
                    let amount = parseFloat((propData.cost_amount * Math.round(100 / propData.selected_class.length)) / 100).toFixed(2);

                    heavyPercentage = rec.startsWith('heavy') ? percent : heavyPercentage;
                    setHeavyPercentage(heavyPercentage);
                    heavyAmount = rec.startsWith('heavy') ? amount : heavyAmount;
                    setHeavyAmount(parseFloat(heavyAmount).toFixed(2));

                    defferedPercentage = rec.startsWith('deferred') ? percent : defferedPercentage;
                    setDefferedPercentage(defferedPercentage);
                    defferedAmount = rec.startsWith('deferred') ? amount : defferedAmount;
                    setDefferedAmount(parseFloat(defferedAmount).toFixed(2));

                    upgradesPercentage = rec.startsWith('upgrades') ? percent : upgradesPercentage;
                    setUpgradesPercentage(upgradesPercentage);
                    upgradesAmount = rec.startsWith('upgrades') ? amount : upgradesAmount;
                    setUpgradesAmount(parseFloat(upgradesAmount).toFixed(2));

                    valuePercentage = rec.startsWith('value') ? percent : valuePercentage;
                    setValuePercentage(valuePercentage);
                    valueAmount = rec.startsWith('value') ? amount : valueAmount;
                    setValueAmount(parseFloat(valueAmount).toFixed(2));

                    softCostsPercentage = rec.startsWith('soft') ? percent : softCostsPercentage;
                    setSoftCostsPercentage(softCostsPercentage);
                    softCostsAmount = rec.startsWith('soft') ? amount : softCostsAmount;
                    setSoftCostsAmount(parseFloat(softCostsAmount).toFixed(2));
                }
                else if (count === 3) {
                    percent_total = 100 - percent_total;
                    amount_total = propData.cost_amount - amount_total;
                    amount_total = parseFloat(amount_total).toFixed(2);
                    setHeavyPercentage(rec.startsWith('heavy') ? percent_total : heavyPercentage);
                    setHeavyAmount(rec.startsWith('heavy') ? amount_total : heavyAmount);

                    setDefferedPercentage(rec.startsWith('deferred') ? percent_total : defferedPercentage);
                    setDefferedAmount(rec.startsWith('deferred') ? amount_total : defferedAmount);

                    setUpgradesPercentage(rec.startsWith('upgrades') ? percent_total : upgradesPercentage);
                    setUpgradesAmount(rec.startsWith('upgrades') ? amount_total : upgradesAmount);

                    setValuePercentage(rec.startsWith('value') ? percent_total : valuePercentage);
                    setValueAmount(rec.startsWith('value') ? amount_total : valueAmount);

                    setSoftCostsPercentage(rec.startsWith('soft') ? percent_total : softCostsPercentage);
                    setSoftCostsAmount(rec.startsWith('soft') ? amount_total : softCostsAmount);
                }
            });

        }
        setUpdate(moment());
    };

    let handleTextboxInput = (event) => {
        event.preventDefault();

        let name = event.target.name;
        let value = event.target.value;
        if (value === 100) {
            heavyPercentage = name.includes('heavy') ? Math.round(value) : heavyPercentage;
            setHeavyPercentage(Math.round(heavyPercentage));

            defferedPercentage = name.startsWith('deferred') ? Math.round(value) : defferedPercentage;
            setDefferedPercentage(Math.round(defferedPercentage));

            upgradesPercentage = name.startsWith('upgrades') ? Math.round(value) : upgradesPercentage;
            setUpgradesPercentage(Math.round(upgradesPercentage));

            valuePercentage = name.startsWith('value') ? Math.round(value) : valuePercentage;
            setValuePercentage(Math.round(valuePercentage));

            softCostsPercentage = name.startsWith('soft') ? Math.round(value) : softCostsPercentage;
            setSoftCostsPercentage(Math.round(softCostsPercentage));

            heavyAmount = name.includes('heavy') ? (100) : heavyAmount;
            setHeavyAmount(parseFloat(heavyAmount).toFixed(2));

            defferedAmount = name.startsWith('deferred') ? (100) : defferedAmount;
            setDefferedAmount(parseFloat(defferedAmount).toFixed(2));

            upgradesAmount = name.startsWith('upgrades') ? (100) : upgradesAmount;
            setUpgradesAmount(parseFloat(upgradesAmount).toFixed(2));

            valueAmount = name.startsWith('value') ? (100) : valueAmount;
            setValueAmount(parseFloat(valueAmount).toFixed(2));

            softCostsAmount = name.startsWith('soft') ? (100) : softCostsAmount;
            setSoftCostsAmount(parseFloat(softCostsAmount).toFixed(2));
        }
        else if (!(value > 100 || value < 0)) {
            heavyPercentage = name.includes('heavy') ? Math.round(value) : heavyPercentage;
            setHeavyPercentage(Math.round(heavyPercentage));

            defferedPercentage = name.startsWith('deferred') ? Math.round(value) : defferedPercentage;
            setDefferedPercentage(Math.round(defferedPercentage));

            upgradesPercentage = name.startsWith('upgrades') ? Math.round(value) : upgradesPercentage;
            setUpgradesPercentage(Math.round(upgradesPercentage));

            valuePercentage = name.startsWith('value') ? Math.round(value) : valuePercentage;
            setValuePercentage(Math.round(valuePercentage));

            softCostsPercentage = name.startsWith('soft') ? Math.round(value) : softCostsPercentage;
            setSoftCostsPercentage(Math.round(softCostsPercentage));

            heavyAmount = name.includes('heavy') ? ((data.cost_amount * Math.round(value)) / 100) : heavyAmount;
            setHeavyAmount(parseFloat(heavyAmount).toFixed(2));

            defferedAmount = name.startsWith('deferred') ? ((data.cost_amount * Math.round(value)) / 100) : defferedAmount;
            setDefferedAmount(parseFloat(defferedAmount).toFixed(2));

            upgradesAmount = name.startsWith('upgrades') ? ((data.cost_amount * Math.round(value)) / 100) : upgradesAmount;
            setUpgradesAmount(parseFloat(upgradesAmount).toFixed(2));

            valueAmount = name.startsWith('value') ? ((data.cost_amount * Math.round(value)) / 100) : valueAmount;
            setValueAmount(parseFloat(valueAmount).toFixed(2));

            softCostsAmount = name.startsWith('soft') ? ((data.cost_amount * Math.round(value)) / 100) : softCostsAmount;
            setSoftCostsAmount(parseFloat(softCostsAmount).toFixed(2));

        }
        if ((Math.round(heavyPercentage) + Math.round(defferedPercentage) + Math.round(upgradesPercentage) + Math.round(valuePercentage) + Math.round(softCostsPercentage)) === 100) {
            setErrorMessage("");
        } else {
            setErrorMessage("Total percentage must be 100!");
        }
        setUpdate(moment());

    }

    let give_budget_class_name = function (class_name) {
        let budget_class_name;
        if (class_name.startsWith("heavy")) {
            budget_class_name = "split_amount_heavy_repairs";
        }
        if (class_name.startsWith("deferred")) {
            budget_class_name = "split_amount_deferred_maintenance";
        }
        if (class_name.startsWith("upgrades")) {
            budget_class_name = "split_amount_upgrades";
        }
        if (class_name.startsWith("value")) {
            budget_class_name = "split_amount_reconfiguration_value_add";
        }
        if (class_name.startsWith("soft")) {
            budget_class_name = "split_amount_soft_costs";
        }
        return budget_class_name;
    }

    let handleBudgetFinalData = (e) => {
        let listClassAmount = [];
        if ((Math.round(heavyPercentage) + Math.round(defferedPercentage) + Math.round(upgradesPercentage) + Math.round(valuePercentage) + Math.round(softCostsPercentage)) === 100) {
            setErrorMessage("");
            listClassAmount.push(heavyPercentage > 0 ? { budget_class_name: give_budget_class_name("heavy"), cost_amount: Math.round(heavyPercentage) } : { budget_class_name: give_budget_class_name("heavy"), cost_amount: 0 });
            listClassAmount.push(defferedPercentage > 0 ? { budget_class_name: give_budget_class_name("deferred"), cost_amount: Math.round(defferedPercentage) } : { budget_class_name: give_budget_class_name("deferred"), cost_amount: 0 });
            listClassAmount.push(upgradesPercentage > 0 ? { budget_class_name: give_budget_class_name("upgrades"), cost_amount: Math.round(upgradesPercentage) } : { budget_class_name: give_budget_class_name("upgrades"), cost_amount: 0 });
            listClassAmount.push(valuePercentage > 0 ? { budget_class_name: give_budget_class_name("value"), cost_amount: Math.round(valuePercentage) } : { budget_class_name: give_budget_class_name("value"), cost_amount: 0 });
            listClassAmount.push(softCostsPercentage > 0 ? { budget_class_name: give_budget_class_name("soft"), cost_amount: Math.round(softCostsPercentage) } : { budget_class_name: give_budget_class_name("soft"), cost_amount: 0 });

            data = { budget_extraction_id: data.budget_extraction_id, budget_classes_name: listClassAmount };

            let tempSelectedList = [];
            tempSelectedList.push(heavyPercentage > 0 ? "heavyRepairsBudget_" + data.budget_extraction_id : null);
            tempSelectedList.push(defferedPercentage > 0 ? "deferredMaintenanceBudget_" + data.budget_extraction_id : null);
            tempSelectedList.push(upgradesPercentage > 0 ? "upgradesBudget_" + data.budget_extraction_id : null);
            tempSelectedList.push(valuePercentage > 0 ? "valueAddBudget_" + data.budget_extraction_id : null);
            tempSelectedList.push(softCostsPercentage > 0 ? "softCostsBudget_" + data.budget_extraction_id : null);
            let final_list = [];
            final_list.push(tempSelectedList.filter(x => x !== null));
            handleCheckbox(data, final_list[0]);
            setModal(false);
            submitCallback({ submitted: true })
        } else {
            setErrorMessage("Total percentage must be 100!");
        }

    }
    const toggle = () => setModal(!modal);

    let onCheckBoxChange = (e, row) => {
        if (e.target.checked) {
            data.selected_class.push(e.target.name.split("_")[0]);
            calculate_checkbox_values();
        } else {
            let index = data.selected_class.findIndex(x => x.startsWith(e.target.name.split("_")[0]));
            data.selected_class.splice(index, 1);
            calculate_checkbox_values();
            if (e.target.name.split("_")[0] === "heavy") {
                setHeavyAmount(0);
                setHeavyPercentage(0);
            }
            if (e.target.name.split("_")[0] === "deferred") {
                setDefferedAmount(0);
                setDefferedPercentage(0);
            }
            if (e.target.name.split("_")[0] === "upgrades") {
                setUpgradesAmount(0);
                setUpgradesPercentage(0);
            }
            if (e.target.name.split("_")[0] === "value") {
                setValueAmount(0);
                setValuePercentage(0);
            }
            if (e.target.name.split("_")[0] === "soft") {
                setSoftCostsAmount(0);
                setSoftCostsPercentage(0);
            }
        }
        setData(data);
        setUpdate(moment());
    }

    const subjectPropertyTable = (
        <>
            <Table striped className="analysis-table">
                <tbody>
                    <tr>
                        <td><b>Line Item Name</b></td>
                        <td>:</td>
                        <td>{data.line_item_name}</td>
                    </tr>
                    <tr>
                        <td><b>Description</b></td>
                        <td>:</td>
                        <td>{data.description}</td>
                    </tr>
                    <tr>
                        <td><b>Category</b></td>
                        <td>:</td>
                        <td>{data.category && data.category.length > 1 ? data.category.join(", ") : data.category}</td>
                    </tr>
                </tbody>
            </Table>
            <div className="p-12">
                <AvForm>
                    <CardBody className="budget-section" style={{ maxHeight: "300px" }}>
                        <Row>
                            <Col>
                                <>
                                    <table className="subject-street table table-striped text-left">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th><b>Budget Class</b></th>
                                                <th><b>Percentage ( % )</b></th>
                                                <th><b>Amount ( $ )</b></th>
                                            </tr>
                                            <tr height="50%" align="left">
                                                <td >
                                                    <AvCheckboxGroup className="custom-checkbox" name="heavy_repairs_checkbox" >
                                                        <AvCheckbox onChange={(e) => onCheckBoxChange(e, data.budget_extraction_id)} checked={(data && data.selected_class && data.selected_class.find(o => o.startsWith('heavy'))) ? true : false} className="custom-checkbox" align="center" />
                                                    </AvCheckboxGroup>
                                                </td>
                                                <td><b>Heavy Repairs</b></td>
                                                <td>
                                                    <AvGroup>
                                                        <AvField
                                                            name="heavy_repairs_percentage"
                                                            disabled={!(data.selected_class && data.selected_class.find(o => o.startsWith('heavy'))) ? true : false}
                                                            onChange={(event) => handleTextboxInput(event)}
                                                            className="form-control-alternative form-control"
                                                            placeholder="Enter Percentage"
                                                            type="number"
                                                            required
                                                            errorMessage=" "
                                                            value={heavyPercentage}
                                                            validate={{
                                                                max: {
                                                                    value: 100,
                                                                    errorMessage: "Invalid input."
                                                                },
                                                                min: {
                                                                    value: 0,
                                                                    errorMessage: "Invalid input."
                                                                }
                                                            }}
                                                        />
                                                    </AvGroup>
                                                </td>
                                                <td>
                                                    <AvGroup>
                                                        <AvField
                                                            name="heavy_repairs_amount"
                                                            disabled={true}
                                                            className="form-control-alternative form-control"
                                                            placeholder="0"
                                                            classheight="50px"
                                                            type="text"
                                                            value={heavyAmount}
                                                        />
                                                    </AvGroup>
                                                </td>
                                            </tr>
                                            <tr height="50%" align="left">
                                                <td >
                                                    <AvCheckboxGroup className="custom-checkbox" name="deferred_maintenance_checkbox" >
                                                        <AvCheckbox onChange={(e) => onCheckBoxChange(e, data.budget_extraction_id)} checked={(data && data.selected_class && data.selected_class.find(o => o.startsWith('deferred'))) ? true : false} className="custom-checkbox" align="left" />
                                                    </AvCheckboxGroup>
                                                </td>
                                                <td><b>Deferred Maintenance</b></td>
                                                <td>
                                                    <AvGroup>
                                                        <AvField
                                                            name="deferred_maintenance_percentage"
                                                            maxLength="2"
                                                            disabled={!(data.selected_class && data.selected_class.find(o => o.startsWith('deferred'))) ? true : false}
                                                            onChange={(event) => handleTextboxInput(event)}
                                                            className="form-control-alternative form-control"
                                                            placeholder="Enter Percentage"
                                                            type="number"
                                                            required
                                                            value={defferedPercentage}
                                                            errorMessage=" "
                                                            validate={{
                                                                max: {
                                                                    value: 100,
                                                                    errorMessage: "Invalid input."
                                                                },
                                                                min: {
                                                                    value: 0,
                                                                    errorMessage: "Invalid input."
                                                                }
                                                            }}
                                                        />
                                                    </AvGroup>
                                                </td>
                                                <td>
                                                    <AvGroup>
                                                        <AvField
                                                            name="deferred_maintenance_amount"
                                                            disabled={true}
                                                            className="form-control-alternative form-control"
                                                            placeholder="0"
                                                            classheight="50px"
                                                            type="text"
                                                            value={defferedAmount}
                                                        />
                                                    </AvGroup>
                                                </td>
                                            </tr>
                                            <tr height="50%" align="left">
                                                <td >
                                                    <AvCheckboxGroup className="custom-checkbox" name="upgrades_checkbox" >
                                                        <AvCheckbox onChange={(e) => onCheckBoxChange(e, data.budget_extraction_id)} checked={(data && data.selected_class && data.selected_class.find(o => o.startsWith('upgrades'))) ? true : false} className="custom-checkbox" align="left" />
                                                    </AvCheckboxGroup>
                                                </td>
                                                <td><b>Upgrades</b></td>
                                                <td>
                                                    <AvGroup>
                                                        <AvField
                                                            name="upgrades_percentage"
                                                            disabled={!(data.selected_class && data.selected_class.find(o => o.startsWith('upgrades'))) ? true : false}
                                                            onChange={(event) => handleTextboxInput(event)}
                                                            maxLength="2"
                                                            className="form-control-alternative form-control"
                                                            placeholder="Enter Percentage"
                                                            type="number"
                                                            required
                                                            errorMessage=" "
                                                            value={upgradesPercentage}
                                                            validate={{
                                                                max: {
                                                                    value: 100,
                                                                    errorMessage: "Invalid input."
                                                                },
                                                                min: {
                                                                    value: 0,
                                                                    errorMessage: "Invalid input."
                                                                }
                                                            }}
                                                        />
                                                    </AvGroup>
                                                </td>
                                                <td>
                                                    <AvGroup>
                                                        <AvField
                                                            name="upgrades_amount"
                                                            disabled={true}
                                                            className="form-control-alternative form-control"
                                                            placeholder="0"
                                                            classheight="50px"
                                                            type="text"
                                                            value={upgradesAmount}
                                                        />
                                                    </AvGroup>
                                                </td>
                                            </tr>
                                            <tr height="50%" align="left">
                                                <td >
                                                    <AvCheckboxGroup className="custom-checkbox" name="value_add_checkbox" >
                                                        <AvCheckbox onChange={(e) => onCheckBoxChange(e, data.budget_extraction_id)} checked={(data && data.selected_class && data.selected_class.find(o => o.startsWith('value'))) ? true : false} className="custom-checkbox" align="center" />
                                                    </AvCheckboxGroup>
                                                </td>
                                                <td><b>Value Add / Reconfigurations</b></td>
                                                <td>
                                                    <AvGroup>
                                                        <AvField
                                                            name="value_add_percentage"
                                                            maxLength="2"
                                                            disabled={!(data.selected_class && data.selected_class.find(o => o.startsWith('value'))) ? true : false}
                                                            onChange={(event) => handleTextboxInput(event)}
                                                            className="form-control-alternative form-control"
                                                            placeholder="Enter Percentage"
                                                            type="number"
                                                            required
                                                            errorMessage=" "
                                                            value={valuePercentage}
                                                            validate={{
                                                                max: {
                                                                    value: 100,
                                                                    errorMessage: "Invalid input."
                                                                },
                                                                min: {
                                                                    value: 0,
                                                                    errorMessage: "Invalid input."
                                                                }
                                                            }}
                                                        />
                                                    </AvGroup>
                                                </td>
                                                <td>
                                                    <AvGroup>
                                                        <AvField
                                                            name="value_add_amount"
                                                            disabled={true}
                                                            className="form-control-alternative form-control"
                                                            placeholder="0"
                                                            classheight="50px"
                                                            type="text"
                                                            value={valueAmount}
                                                        />
                                                    </AvGroup>
                                                </td>
                                            </tr>
                                            <tr height="50%" align="left">
                                                <td >
                                                    <AvCheckboxGroup className="custom-checkbox" name="soft_costs_checkbox" >
                                                        <AvCheckbox onChange={(e) => onCheckBoxChange(e, data.budget_extraction_id)} checked={(data && data.selected_class && data.selected_class.find(o => o.startsWith('soft'))) ? true : false} className="custom-checkbox" align="center" />
                                                    </AvCheckboxGroup>
                                                </td>
                                                <td><b>Soft Costs</b></td>
                                                <td>
                                                    <AvGroup>
                                                        <AvField
                                                            name="soft_costs_percentage"
                                                            maxLength="2"
                                                            disabled={!(data.selected_class && data.selected_class.find(o => o.startsWith('soft'))) ? true : false}
                                                            onChange={(event) => handleTextboxInput(event)}
                                                            className="form-control-alternative form-control"
                                                            placeholder="Enter Percentage"
                                                            type="number"
                                                            required
                                                            errorMessage=" "
                                                            value={softCostsPercentage}
                                                            validate={{
                                                                max: {
                                                                    value: 100,
                                                                    errorMessage: "Invalid input."
                                                                },
                                                                min: {
                                                                    value: 0,
                                                                    errorMessage: "Invalid input."
                                                                }
                                                            }}
                                                        />
                                                    </AvGroup>
                                                </td>
                                                <td>
                                                    <AvGroup>
                                                        <AvField
                                                            name="soft_costs_amount"
                                                            disabled={true}
                                                            onChange={(event) => handleTextboxInput(event)}
                                                            className="form-control-alternative form-control"
                                                            placeholder="0"
                                                            classheight="50px"
                                                            type="text"
                                                            value={softCostsAmount}
                                                        />
                                                    </AvGroup>
                                                </td>
                                            </tr>
                                        </thead>
                                    </table>
                                    {customErrorMessage &&
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td colSpan="4"><Label className="text-red">*{customErrorMessage}</Label></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    }
                                </>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col className="text-right">
                                <Button color="info" outline onClick={(e) => handleBudgetFinalData(e)}>
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </CardFooter>
                </AvForm>
            </div>
        </>
    );

    return (
        <Modal backdrop="static" keyboard={false} className="modal-dialog-centered neighborhood-land-user" style={{ maxWidth: "800px" }} isOpen={modal} toggle={() => toggle()}>
            <div className="modal-header modal-header-colored">
                <h2 className="modal-title text-left w-100" id="budgetAllocateModalLabel">
                    Allocate Budget Item
                </h2>
                <h2 className="text-right text-white">${data.cost_amount}</h2>
            </div>
            <div>
                {subjectPropertyTable}
            </div>
        </Modal>
    );
};
export default BudgetAnalysis;