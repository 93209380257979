import React, { useEffect } from "react";
import { useState } from "react";
import { sendExceptionEmail } from "components/Common/Helpers";
import { toast } from "react-toastify";
import { PutData } from "services/Api.service";
import { confirmAlert } from "react-confirm-alert";
import RenderActionButton from "../Common/ActionsButtons/RenderActionButton";
import { useDispatch } from "react-redux";
import { showSpinner } from "ShowHideSpinner.reducer";

const MarkReferralFeeAsPaidButton = ({ propRowData, propButtonOptions, propCb }) => {

    let [requestData, setRequestData] = useState(propRowData);

    const dispatch = useDispatch();

    useEffect(() => {
        setRequestData(propRowData)
    }, [propRowData]); // eslint-disable-line react-hooks/exhaustive-deps

    let handleActionClick = async (e, row) => {
        confirmAlert({
            title: '',
            message: 'Are you sure you want to mark the selected referral fee as paid?',
            closeOnClickOutside: false,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        await handleSubmit(row);
                    },
                    className: "btn btn-primary btn-sm"
                }, {
                    label: 'Close',
                    onClick: () => { },
                    className: "btn btn-secondary btn-sm"
                }
            ]
        });
    };

    let handleSubmit = async () => {
        try {
            let params = {};
            if (requestData?.referral_fee_ids) {
                params.referral_fee_ids = requestData?.referral_fee_ids
            } else {
                params = {
                    company_token: requestData?.company_token,
                    month: requestData?.month,
                    payment_status: requestData?.payment_status,
                }
            }

            dispatch(showSpinner(true));
            const response = await PutData(`company/mark-referral-fee-as-paid`, params);
            toast["success"](response.message);

            if (propCb) {
                propCb();
            }
            dispatch(showSpinner(false));
        } catch (error) {
            toast["error"](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error)
            dispatch(showSpinner(false));
        }
    };

    return (
        <>
            <RenderActionButton propButtonTitle="Mark As Paid" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />
        </>
    );
};

export default MarkReferralFeeAsPaidButton;