import { loggedInUser } from "../../../components/Common/Helpers";
import socketIOClient from "socket.io-client";

const userData = loggedInUser();
const socket = socketIOClient(process.env.REACT_APP_SOCKET_ENDPOINT);

const eventBus = {
    on(event, callback) {
        document.addEventListener(event, (callback), true);
    },
    dispatch(event, data) {
        if (['report_order_status_updated', 'user_status_updated', 'inspection_order_status_updated', 'discrepancy_task_status_updated', 'invoice_status_updated', 'update_supplemental_status'].includes(event)) {
            socket.emit(event, data, (response) => { });
            document.dispatchEvent(new CustomEvent("on_" + event, { detail: data }));
        } else {
            document.dispatchEvent(new CustomEvent(event, { detail: data }));
        }
    },
    remove(event, callback) {
        document.removeEventListener(event, callback, true);
    },
};

// Report order status updated event
const reportOrderStatusChangeEventName = userData.is_RV_user === true ? `on_report_order_status_updated` : `on_report_order_status_updated_${userData.company_id}`;

// Invoice status updated event
const invoiceStatusChangeEventName = userData.is_RV_user === true ? `on_invoice_status_updated` : `on_invoice_status_updated_${userData.company_id}`;

// User status updated event\
const userStatusChangeEventName = userData.is_RV_user === true ? `on_user_status_updated` : `on_user_status_updated_${userData.company_id}`;

// User status updated event\
const inspectionOrderStatusChangeEventName = userData.is_RV_user === true ? `on_inspection_order_status_updated` : `on_inspection_order_status_updated_${userData.company_id}`;

// Discrepancy task status updated event\
const discrepancyTaskStatusChangeEventName = userData.is_RV_user === true ? `on_discrepancy_task_status_updated` : `on_discrepancy_task_status_updated_${userData.company_id}`;

const socketEventArr = [
    { listen: reportOrderStatusChangeEventName, dispatch: 'on_report_order_status_updated' },
    { listen: invoiceStatusChangeEventName, dispatch: 'on_invoice_status_updated' },
    { listen: userStatusChangeEventName, dispatch: 'on_user_status_updated' },
    { listen: inspectionOrderStatusChangeEventName, dispatch: 'on_inspection_order_status_updated' },
    { listen: discrepancyTaskStatusChangeEventName, dispatch: 'on_discrepancy_task_status_updated' },
    { listen: 'get_status', dispatch: 'on_report_order_status_updated' },
    { listen: 'on_update_supplemental_status', dispatch: 'on_update_supplemental_status' },
    { listen: 'budget_extraction_order_updated', dispatch: 'budget_extraction_order_updated' }, // Budget extractions event for valuation
    { listen: 'budget_extraction_standalone_updated', dispatch: 'budget_extraction_standalone_updated' }, // Budget extractions event for standalone
];

for (const event of socketEventArr) {
    socket.on(event.listen, (response) => { eventBus.dispatch(event.dispatch, response); });
}

export default eventBus;