import React, { useState, useEffect } from "react";
import { Button, Row, Col, CardFooter, CardBody, UncontrolledTooltip } from "reactstrap";
import "./style.scss";
import { loggedInUser } from "components/Common/Helpers.js"
import Spinner from "components/Common/Spinner.js";
import { AvForm } from "availity-reactstrap-validation";
import { PostData } from "services/PostData";
import { toast } from 'react-toastify';
import ReactStreetview from 'react-streetview';
import * as moment from "moment";
import { getBuildingApexQualityRating } from "components/Common/Helpers";


const userData = loggedInUser();

const streetViewStyle = {
    width: '100%',
    height: '520px',
    backgroundColor: '#eeeeee'
}
const SubjectStreet = ({ propData, onHandleSubjectStreetResponse }) => {    
    const [isProcess, setProcess] = useState(false);
    let [conditionRatings, setConditionRatings] = useState([]);
    let [qualityRatings, setQualityRatings] = useState([]);
    let [update, setUpdate] = useState(moment());
    let [customError, setCustomError] = useState("");
    let [buildingQualityIds, setBuildingQualityIds] = useState([]);
    let [conditionRatingsIds, setConditionRatingsIds] = useState([]);
    let [selectedDominant, setSelectedDominant] = useState(0);
    let [selectedConditionDominant, setSelectedConditionDominant] = useState(0);
    let [isStreetView, setIsStreetView] = useState(false);
    let [cancelBuildingQuality, setCancelBuildingQuality] = useState([]);
    let [cancelBuildingCondition, setCancelBuildingCondition] = useState([]);
    let [streetViewDate, setStreetViewDate] = useState("");
    let [isStreetViewAvailable, setIsStreetViewAvailable] = useState(true);
    let streetViewPanoramaOptions = {
        position: propData.subject_property.latitude && propData.subject_property.longitude ? { lat: parseFloat(propData.subject_property.latitude), lng: parseFloat(propData.subject_property.longitude) } : "",
        pov: { heading: 100, pitch: 0 },
        zoom: 1,
        imageDateControl: true
    };

    useEffect(() => {        
        let streetViewService = new propData.google.maps.StreetViewService();
        let berkeley = new propData.google.maps.LatLng(parseFloat(propData.subject_property.latitude), parseFloat(propData.subject_property.longitude));
        streetViewService.getPanoramaByLocation(berkeley, 50, function (data, status) {
            if (status === propData.google.maps.StreetViewStatus.OK) {
                let dateFormat = moment(data.imageDate).format("MMM YYYY");
                setStreetViewDate(dateFormat);
            } else {
                setIsStreetViewAvailable(false);
            }
        });

        setConditionRatings(propData.building_condition_ratings);
        setQualityRatings(propData.building_quality_ratings)
        if (propData && propData.current_report_subject_q_ratings && propData.current_report_subject_q_ratings.length > 0) {
            let tempBuildingQualityIds = [...buildingQualityIds, ...propData.current_report_subject_q_ratings];
            for (let i = 0; i < propData.current_report_subject_q_ratings.length; i++) {
                if (propData.current_report_subject_q_ratings[i].is_dominant === "1") {
                    setSelectedDominant(propData.current_report_subject_q_ratings[i].building_q_rating_id);
                }
            } 
            setBuildingQualityIds(tempBuildingQualityIds);
        }

        if (propData && propData.current_report_subject_c_ratings && propData.current_report_subject_c_ratings.length > 0) {
            let tempConditionRatingsIds = [...conditionRatingsIds, ...propData.current_report_subject_c_ratings];
            for (let i = 0; i < propData.current_report_subject_c_ratings.length; i++) {
                if (propData.current_report_subject_c_ratings[i].is_dominant === "1") {
                    setSelectedConditionDominant(propData.current_report_subject_c_ratings[i].building_c_rating_id);
                }
            }
            setConditionRatingsIds(tempConditionRatingsIds);
        }
        if (propData && parseInt(propData.street_view_unavailable) === 1) {
            setIsStreetView(true);
        }

    }, [propData]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

    }, [update]);

    let onChangeCheckBox = (e) => {
        setIsStreetView(e.target.checked);
        propData.street_view_unavailable = e.target.checked === true ? 1 : 0;
    }
    let handleBuildingQualityCheckbox = (e, id) => {
        let index = buildingQualityIds.findIndex(x => x.building_q_rating_id === id);
        if (e.target.checked) {
            propData.current_report_subject_q_ratings.forEach(element => {
                if (element.building_q_rating_id === parseInt(e.target.value)) {
                    cancelBuildingQuality.splice(index, 1);
                    setCancelBuildingQuality(cancelBuildingQuality);
                }
            });

            let buildingQualityIdsTmp = [...buildingQualityIds, { building_q_rating_id: parseInt(e.target.value), is_dominant: "0" }];
            setBuildingQualityIds(buildingQualityIdsTmp);
            setUpdate(moment());
        } else {
            if (selectedDominant === parseInt(e.target.value)) {
                setSelectedDominant("0");
            }

            propData.current_report_subject_q_ratings.forEach(element => {
                if (element.building_q_rating_id === parseInt(e.target.value)) {
                    let cancelBuildingQualityTemp = [...cancelBuildingQuality, e.target.value];
                    setCancelBuildingQuality(cancelBuildingQualityTemp);
                }
            });

            if (index !== -1) {
                buildingQualityIds.splice(index, 1);
                setBuildingQualityIds(buildingQualityIds);
            }
            setUpdate(moment());

        }
    }        

    let handleDominant = (e, i, id, type) => {

        if (type === "quality") {
            setSelectedDominant(id);

            for (let j = 0; j < buildingQualityIds.length; j++) {
                if (buildingQualityIds[j].building_q_rating_id === id) {
                    let buildingQualityObj = buildingQualityIds[j];
                    buildingQualityObj.is_dominant = "1";
                    buildingQualityIds[j] = buildingQualityObj;
                    setBuildingQualityIds(buildingQualityIds);                    
                    // propData.buildingQualityIds = getBuildingApexQualityGrades(buildingQualityObj.building_q_rating_id);
                }
                if (buildingQualityIds[j].building_q_rating_id !== id) {
                    let buildingQualityObj = buildingQualityIds[j];
                    buildingQualityObj.is_dominant = "0";
                    buildingQualityIds[j] = buildingQualityObj;
                    setBuildingQualityIds(buildingQualityIds);
                }
            }

        } else if (type === "condition") {

            setSelectedConditionDominant(id);
            for (let j = 0; j < conditionRatingsIds.length; j++) {
                if (conditionRatingsIds[j].building_c_rating_id === id) {
                    let conditionRatingsObj = conditionRatingsIds[j];                    
                    conditionRatingsObj.is_dominant = "1";
                    conditionRatingsIds[j] = conditionRatingsObj;
                    setConditionRatingsIds(conditionRatingsIds);                    
                    propData.conditionRatingsIds = getBuildingApexQualityRating(conditionRatingsObj.building_c_rating_id);

                }
                if (conditionRatingsIds[j].building_c_rating_id !== id) {
                    let conditionRatingsObj = conditionRatingsIds[j];
                    conditionRatingsObj.is_dominant = "0";
                    conditionRatingsIds[j] = conditionRatingsObj;
                    setConditionRatingsIds(conditionRatingsIds);                    
                }
            }

        }
    }


    let handleConditionRatingsCheckbox = (e, id) => {

        let index = conditionRatingsIds.findIndex(x => x.building_c_rating_id === id);
        if (e.target.checked) {
            propData.current_report_subject_c_ratings.forEach(element => {
                if (element.building_c_rating_id === parseInt(e.target.value)) {
                    cancelBuildingCondition.splice(index, 1);
                    setCancelBuildingQuality(cancelBuildingCondition);
                }
            });

            let conditionRatingsIdsTemp = [...conditionRatingsIds, { building_c_rating_id: parseInt(e.target.value), is_dominant: "0" }];
            setConditionRatingsIds(conditionRatingsIdsTemp);
        } else {
            if (selectedConditionDominant === parseInt(e.target.value)) {
                setSelectedConditionDominant("0");
            }
            propData.current_report_subject_c_ratings.forEach(element => {
                if (element.building_c_rating_id === parseInt(e.target.value)) {
                    let cancelBuildingConditionTemp = [...cancelBuildingCondition, e.target.value];
                    setCancelBuildingCondition(cancelBuildingConditionTemp);
                }
            });
            if (index !== -1) {
                conditionRatingsIds.splice(index, 1);
                setConditionRatingsIds(conditionRatingsIds);
            }
            setUpdate(moment());

        }
    }

    let validateQuality = () => {
        let isQualityError = true;
        for (let i = 0; i < buildingQualityIds.length; i++) {
            if (buildingQualityIds[i].is_dominant === "1") {
                isQualityError = false;
            }
        }
        return isQualityError;
    }
    let validateCondition = () => {
        let isConditionError = true;
        for (let i = 0; i < conditionRatingsIds.length; i++) {
            if (conditionRatingsIds[i].is_dominant === "1") {
                isConditionError = false;
            }
        }
        return isConditionError;
    }

    // Handle Step Two
    let handleQualitySubjectStreetForm = (e) => {

        let isQualityError = false;
        let isConditionError = false;
        if (!isStreetView) {

            if (buildingQualityIds.length === 0 || conditionRatingsIds.length === 0) {
                isQualityError = validateQuality();
                isConditionError = validateCondition();
            } else if (buildingQualityIds.length === 0) {
                isQualityError = true;
            } else if (conditionRatingsIds.length === 0) {
                isConditionError = true;
            } else if (buildingQualityIds.length > 0 && conditionRatingsIds.length > 0) {
                isQualityError = validateQuality();
                isConditionError = validateCondition();
            } else if (buildingQualityIds.length > 0) {
                isQualityError = validateQuality();
            } else if (conditionRatingsIds.length > 0) {
                isConditionError = validateCondition();
            } else {
                isQualityError = false;
                isConditionError = false;
            }

            if ((isQualityError && isConditionError)) {
                setCustomError("Please select the dominant quality and condition categories in order to proceed.");
                return false;
            } else if (isQualityError) {
                setCustomError("Please select the dominant quality category in order to proceed.");
                return false;
            } else if (isConditionError) {
                setCustomError("Please select the dominant condition category in order to proceed.");
                return false;
            }
            setCustomError("");
        }

        let data = {
            report_order_id: propData.id,
            condition_ratings: conditionRatingsIds,
            building_quality: buildingQualityIds,
            user_id: userData.user_id,
            street_view: isStreetView ? "1" : "0",
            cancel_building_quality: cancelBuildingQuality,
            cancel_building_condition: cancelBuildingCondition

        }        

        setProcess(true);
        PostData("orders/save_quality_on_subject_street", data).then(result => {
            toast["success"](result.data.message);
            setProcess(false);
            onHandleSubjectStreetResponse(3, 'Subject Property Condition');
            isQualityError = false;
            isConditionError = false;
        }).catch(errors => {
            setProcess(false);
            isQualityError = false;
            isConditionError = false;
            toast["error"](errors.message);
        });
    }

    return (
        <>
            <Spinner isShow={isProcess} />
            <AvForm onValidSubmit={(e) => handleQualitySubjectStreetForm(e)}>
                <CardBody>
                    <Row>
                        <Col className="col-md-6 text-left">
                            <p>Please use the street view to <span className="">evaluate the quality and condition of the buildings</span> on the subject property’s street</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-md-3 text-left">
                            <div>
                                <table className="quality table table-striped text-center">
                                    <thead >
                                        <tr >
                                            <td width="10%"></td>
                                            <td width="50%">Building Quality</td>
                                            <td width="40%">Dominant?</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {qualityRatings && qualityRatings.map(function (data, i) {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <div className="custom-control custom-checkbox">
                                                            <input
                                                                id={"quality_ratings" + i}
                                                                name="building_quality_ratings"
                                                                className="custom-control-input"
                                                                type="checkbox"
                                                                value={data.id}
                                                                onChange={e => handleBuildingQualityCheckbox(e, data.id)}
                                                                checked={(buildingQualityIds && buildingQualityIds.find(o => o.building_q_rating_id === data.id)) ? true : false}

                                                            />
                                                            <label className="custom-control-label" htmlFor={"quality_ratings" + i}></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span id={"tooltip_quality_" + data.id}>{data.building_quality_rating}</span>
                                                        <UncontrolledTooltip delay={0} placement="auto" target={"tooltip_quality_" + data.id} >{data.description}</UncontrolledTooltip>
                                                    </td>
                                                    <td>

                                                        {(buildingQualityIds && buildingQualityIds.find(o => o.building_q_rating_id === data.id)) &&
                                                            <div className="quality_street-use-radio-btn inline">
                                                                <input type="radio" className="btn-check" name="quality_dominant" id={"quality" + i} value={selectedDominant} onChange={e => handleDominant(e, i, data.id, "quality")} />
                                                                <label className={selectedDominant === data.id ? "yes-label" : "no-label"} htmlFor={"quality" + i}>{selectedDominant === data.id ? "Yes" : "No"}</label>
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                        <Col className="col-md-3 text-left">
                            <div>
                                <table className="land-uses table table-striped text-center">
                                    <thead >
                                        <tr >
                                            <td width="10%" ></td>
                                            <td width="50%">Building Condition</td>
                                            <td width="40%">Dominant?</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {conditionRatings && conditionRatings.map(function (data, i) {


                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <div className="custom-control custom-checkbox">
                                                            <input
                                                                id={"condition_ratings" + i}
                                                                name="land_use"
                                                                className="custom-control-input"
                                                                type="checkbox"
                                                                value={data.id}
                                                                onChange={e => handleConditionRatingsCheckbox(e, data.id)}
                                                                checked={(conditionRatingsIds && conditionRatingsIds.find(o => o.building_c_rating_id === data.id)) ? true : false}


                                                            />
                                                            <label className="custom-control-label" htmlFor={"condition_ratings" + i}></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span id={"tooltip_" + data.id}>{data.building_condition_rating}</span>
                                                        <UncontrolledTooltip delay={0} placement="auto" target={"tooltip_" + data.id} >{data.descriptions}</UncontrolledTooltip>
                                                    </td>
                                                    <td>

                                                        {(conditionRatingsIds && conditionRatingsIds.find(o => o.building_c_rating_id === data.id)) &&
                                                            <div className="quality_street-use-radio-btn inline">
                                                                <input type="radio" className="btn-check" name="condition_dominant" id={"condition" + i} value={selectedConditionDominant} onChange={e => handleDominant(e, i, data.id, "condition")} />
                                                                <label className={selectedConditionDominant === data.id ? "yes-label" : "no-label"} htmlFor={"condition" + i}>{selectedConditionDominant === data.id ? "Yes" : "No"}</label>
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                        <Col className="col-md-6">
                            <div className="street-view-image">

                                {isStreetViewAvailable ?
                                    <>
                                        <div style={streetViewStyle}>
                                            <ReactStreetview
                                                apiKey={userData.google_geocoding_api_key}
                                                streetViewPanoramaOptions={streetViewPanoramaOptions}
                                            />
                                            <span className="street_view_date">Image Capture: {streetViewDate}</span>
                                        </div>
                                    </>
                                    : <>
                                        <img src={require("../../../assets/img/no-image.png").default} alt="No google img" />
                                    </>
                                }
                            </div>

                        </Col>
                    </Row>
                </CardBody>

                <CardFooter>
                    <Row>
                        <Col className="text-left">
                            <div className="custom-control custom-checkbox text-left street_view">
                                <input
                                    id="street_view"
                                    name="street_view"
                                    className="custom-control-input"
                                    type="checkbox"
                                    onChange={(e) => onChangeCheckBox(e)}
                                    checked={parseInt(propData.street_view_unavailable) === 1 ? true : false}
                                />
                                <label className="custom-control-label" htmlFor="street_view">Street View is unavailable</label>
                            </div>
                            <div className="form-error">{customError}</div>
                        </Col>

                        <Col className="text-right">
                            <Button color="danger" outline onClick={() => onHandleSubjectStreetResponse("cancel")} >
                                Cancel Assignment
                            </Button>
                            <Button color="default" outline onClick={() => onHandleSubjectStreetResponse(1, 'Neighborhood Land Uses')}>
                                Back
                            </Button>
                            <Button color="info">
                                <span className="btn-inner--text">Next</span>
                            </Button>
                        </Col>
                    </Row>

                </CardFooter>
            </AvForm>
        </>
    );
};
export default SubjectStreet;