import React, { useState, useEffect } from "react";
import { Button, Modal, FormGroup, UncontrolledTooltip, Col, Row, Label } from "reactstrap";
import './style.scss';
import { PostData } from "../../../services/PostData";
import { loggedInUser } from "components/Common/Helpers.js"
import Spinner from "components/Common/Spinner.js";
import { toast } from 'react-toastify';
import { AvForm, AvGroup, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import eventBus from "./EventBus";

const ClientResponse = ({ propReportRow }) => {

    const userData = loggedInUser();
    let [isProcess, setProcess] = useState(false);
    let [isOpenClientResponseModal, setOpenClientResponseModal] = useState(false);
    let [fields, setFields] = useState({});

    useEffect(() => {

    }, [propReportRow]);// eslint-disable-line react-hooks/exhaustive-deps

    let handleToggleModal = (state) => {
        state = !state;
        setOpenClientResponseModal(state);
    };

    let handleOrderStatus = (e, type, data) => {
        setOpenClientResponseModal(true);
    }
    let handleComps = (e) => {
        fields[e.target.name] = e.target.value;
        setFields(fields);
    }

    let handleClientResponseModal = (e) => {
        e.preventDefault();
        if (propReportRow.id) {
            let data = {
                id: propReportRow.id,
                question_comment: fields.question_comment,
                comps: fields.comps,
                pdf_url: propReportRow.report_pdf_url,
                user_id: userData.user_id
            }

            setProcess(true);
            PostData("orders/dispute_client_response", data).then(result => {
                setProcess(false);
                toast["success"](result.data.message);
                eventBus.dispatch("report_order_status_updated", { client_id: propReportRow.company_id });
                setOpenClientResponseModal(false);

            }).catch(errors => {
                setProcess(false);
                toast["error"]("Something went wrong.");
            });
        } else {
            setProcess(false);
            toast["error"]("Something went wrong.");
        }
    };

    return (
        <>
            <>
                <Button id={"reopen_files_" + propReportRow.id} color="success" size="sm" type="button" onClick={e => handleOrderStatus(e, 'reopen_files', propReportRow)}>
                    <i className="fa fa-reply" aria-hidden="true"></i>
                </Button>
                <UncontrolledTooltip delay={0} placement="auto" target={"reopen_files_" + propReportRow.id} >Client Response </UncontrolledTooltip>
            </>

            {/* styles.clients */}

            {/* Reopen Files Modal */}
            <Modal className="client-response-popup modal-dialog-centered" style={{ maxWidth: "600px" }} isOpen={isOpenClientResponseModal} toggle={() => handleToggleModal(isOpenClientResponseModal)}>
                <div className="modal-header">
                    <h2 className="modal-title w-100 text-center" id="reassignModalLabel">
                        Client Response
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenClientResponseModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body text-left">
                    <p className="font-weight-400">If the dispute is found to be valid, Please visit the val detail page to revise and resend.  If the dispute is found to be invalid, please select reasons here, and type text to the client: </p>
                    <Spinner isShow={isProcess} />
                    <AvForm onValidSubmit={(e) => handleClientResponseModal(e)} >
                        <Row>
                            <Col>
                                <AvRadioGroup name="comps" label="" required onChange={(e) => handleComps(e)}>
                                    <AvRadio customInput label="Comps are not valid" value="Comps are not valid" />
                                    <AvRadio customInput label="Comps are valid but do not effect the opinions of value" value="Comps are valid but do not effect the opinions of value" />
                                </AvRadioGroup>

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <AvGroup>
                                    <Label>Reason / Request <span className="required">*</span></Label>
                                    <AvField maxLength="2000" type="textarea" onChange={(e) => handleComps(e)} cols="100" name="question_comment" id="question_comment" placeholder="Type here..." required />
                                </AvGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12" className="text-right mt-2">
                                <FormGroup>
                                    <Button size="sm" color="danger" outline onClick={() => handleToggleModal(isOpenClientResponseModal)}>
                                        Close
                                    </Button>
                                    <Button size="sm" color="primary" >
                                        Submit
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </AvForm>
                </div>
            </Modal>
        </>
    );
};

export default ClientResponse;