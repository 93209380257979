import React, { useState, useEffect } from "react";
import "./style.scss";
import { GoogleApiWrapper } from 'google-maps-react';
import { loggedInUser } from "components/Common/Helpers.js"
import CompList from "../Common/CompLinks/CompList";
import { useSelector } from 'react-redux';
const userData = loggedInUser();
const mapStyles = { width: '95%', height: '90%' };
const CompDiscrepancy = ({ propData }) => {


    let [showingInfoWindow, setShowingInfoWindow] = useState(false);
    let [activeMarker, setActiveMarker] = useState({});
    let [selectedPlace, setSelectedPlace] = useState({});
    let [subjectProperty, setSubjectProperty] = useState({});
    const taskData = useSelector((state) => state.taskIdsList.data);

    useEffect(() => {

        if (Object.keys(propData).length > 0) {
            setSubjectProperty(propData.subject_property);
        }
    }, [propData]);// eslint-disable-line react-hooks/exhaustive-deps

    // Mapbox
    let onMarkerClick = (props, marker, e) => {
        setSelectedPlace(props);
        setActiveMarker(marker);
        setShowingInfoWindow(true)
    }

    //  Map box close
    let onClose = props => {
        if (showingInfoWindow) {
            setActiveMarker(null);
            setShowingInfoWindow(false)
        }
    };
    let propDataForCompList = {
        propertyImgs: propData.subject_property.image_urls,
        image_urls: propData.subject_property.image_urls,
        public_remarks: propData.subject_property.public_remarks,
        address: subjectProperty.address,
        city: subjectProperty.city,
        state: subjectProperty.state,
        postal_code: subjectProperty.postal_code,
        customTabListStyle: { padding: "6px 26px" },
        contextMenu: true,
        google: propData.google,
        zoom: 20,
        mapStyles,
        latitude: subjectProperty.longitude,
        longitude: subjectProperty.longitude,
        onMarkerClick: onMarkerClick,
        markerName: subjectProperty.address + ", " + subjectProperty.city + ", " + subjectProperty.state + ", " + subjectProperty.postal_code,
        infoWindowMarker: activeMarker,
        infoWindowVisible: showingInfoWindow,
        infoWindowOnClose: onClose,
        selectedPlace,
        openModel: subjectProperty && subjectProperty.latitude && subjectProperty.longitude ? true : false,
        isFromSubjectPropertyCondition: propData?.isFromSubjectPropertyCondition || false,
        taskData,
        orderData: {
            id: propData.id,
            property_id: propData.property_id,
            order_status: propData.order_status
        }
    }
    return (
        <>
            <div className="others-column-body discrepancy-comp">
                <CompList props={propDataForCompList} />
            </div>
        </>
    );
};
export default GoogleApiWrapper({
    apiKey: userData.google_geocoding_api_key
})(CompDiscrepancy);