import React, { useEffect } from "react";

const IndexComponent = () => {
	useEffect(() => {
		const handleStorageChange = (event) => {
			if (event.key === 'refreshTabs') {
				// Refresh the page when the "refreshTabs" value changes
				window.location.reload();
			}
		};
		window.addEventListener('storage', handleStorageChange);

		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, []);

	return (<></>);
};

export default IndexComponent;