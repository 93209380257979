import React from "react";
import { Button, Col, Label, Modal, Row } from "reactstrap";
import { useState } from "react";

import * as moment from "moment";
import { useEffect } from "react";

import RenderActionButton from "../RenderActionButton";
import { loggedInUser } from "components/Common/Helpers";
import { PostData } from "services/PostData";
import { toast } from "react-toastify";
import { sendExceptionEmail } from "components/Common/Helpers";
import { confirmAlert } from "react-confirm-alert";
import eventBus from "../../EventBus";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import ReportOrderOriginalSource from "../../ReportOrderOriginalSource";
import PaymentForm from "../../PaymentForm";

const ChangeReportTypeClientButton = ({ propRowData, propButtonOptions }) => {
    const userData = loggedInUser();
    const [isProcess, setProcess] = useState(false);
    const [isOpenChangeReportTypeModal, setOpenChangeReportTypeModal] = useState(false);
    const [fields, setFields] = useState({
        submission_type: '',
    });
    const [reportTypes, setReportTypes] = useState([]);
    const [update, setUpdate] = useState(moment());
    const [amtVariance, setAmtVariance] = useState(0);
    const [isOpenPaymentSourceModal, setIsOpenPaymentSourceModal] = useState({ current: false });
    const [clientId, setClientId] = useState();
    const [selectedPaymentSource, setSelectedPaymentSource] = useState(null);
    const [paymentError, setPaymentError] = useState(null);
    const [defaultSourceChecked, setDefaultSourceChecked] = useState(false);
    const [conditionRowID, setConditionRowID] = useState(0);
    const [isCompletedOrder, setCompletedOrder] = useState(false);


    useEffect(() => { }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleActionClick = (e, row) => {
        e.preventDefault();
        setProcess(true)
        getReportTypesAndOpenModal();
        setClientId(propRowData.company_id);
        setSelectedPaymentSource(null);
        setDefaultSourceChecked(false);
        setPaymentError(null);
        setOpenChangeReportTypeModal(true);
    };

    const handleToggleModal = (state) => {
        if (!isProcess) {
            state = !state;
            setOpenChangeReportTypeModal(state);
            setConditionRowID(0);
            setFields({
                submission_type: '',
                new_report_type_id: null,
                variance: 0,
            });
            setPaymentError(null);
            setUpdate(moment());
        }
    };

    const getReportTypesAndOpenModal = async () => {
        try {
            setProcess(true)
            const result = await PostData("list/report_types_by_company_id", { company_id: propRowData.company_id });
            const responseJson = result.data;
            setReportTypes(responseJson.data);
            setOpenChangeReportTypeModal(true);
            setProcess(false);
        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error)
            setProcess(false);
        }

    };

    const onReportTypeChange = async (new_report_type_id) => {
        try {
            setProcess(true);
            setConditionRowID(new_report_type_id);
            const result = await PostData("orders/get_pricing_details", { report_order_id: propRowData.report_order_id, report_type_id: new_report_type_id });
            let responseJson = result.data;
            setCompletedOrder(responseJson.pricing_data.is_completed)
            propRowData.base_fee = parseFloat(propRowData.base_fee);
            responseJson.pricing_data.report_price = parseFloat(responseJson.pricing_data.report_price);

            let variance = Math.abs(responseJson.pricing_data.report_price - propRowData.base_fee);

            variance = variance < 0.50 ? 0.50 : variance;
            variance = parseFloat(variance).toFixed(2);

            setAmtVariance(variance);
            if (propRowData.base_fee === responseJson.pricing_data.report_price) {
                setFields(prevState => ({
                    ...prevState,
                    new_report_type_id: new_report_type_id, variance: 0, submission_type: 'identical'
                }));
            } else if (propRowData.base_fee < responseJson.pricing_data.report_price) {
                setFields(prevState => ({
                    ...prevState,
                    new_report_type_id: new_report_type_id, variance: variance, submission_type: 'expensive'
                }));
            } else if (propRowData.base_fee > responseJson.pricing_data.report_price) {
                setFields(prevState => ({
                    ...prevState,
                    new_report_type_id: new_report_type_id, variance: variance, submission_type: 'refund'
                }));
            }
            setProcess(false);
        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error)
            setProcess(false);
        }
    };

    const handleSubmit = (e) => {
        if (fields.submission_type === 'expensive' && !selectedPaymentSource) {
            toast["error"]("Please select the payment method!");
        } else {
            confirmAlert({
                title: '',
                message: "Are you sure you want to change the report type ?",
                closeOnClickOutside: false,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => changeReportType(),
                        className: "btn btn-primary btn-sm"
                    }, {
                        label: 'Close',
                        onClick: () => { },
                        className: "btn btn-outline-danger btn-sm"
                    }
                ]
            });
        }
    };

    const changeReportType = async () => {
        try {
            let params = { ...fields };
            params.report_order_id = propRowData.report_order_id;
            params.user_id = userData.user_id;
            params.company_id = propRowData.company_id;

            if (params.submission_type === 'expensive') {
                params.payment_source = selectedPaymentSource.payment_source;
            }

            setProcess(true)
            const result = await PostData("orders/change_report_type", params);
            let responseJson = result.data;

            if (responseJson.status !== 200) {
                if (responseJson.err_code === 'PAYMENT_ERROR' && selectedPaymentSource?.isOriginalSource === true) {
                    setPaymentError(responseJson.message);
                    setSelectedPaymentSource(null);
                } else {
                    toast["error"](responseJson.message);
                }
            } else {
                eventBus.dispatch("report_order_status_updated", { client_id: propRowData.company_id });
                handleToggleModal(isOpenChangeReportTypeModal);
                toast["success"](responseJson.message);
            }
            setProcess(false);
        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error)
            setProcess(false);
        }

    };

    const openPaymentMethodSelector = () => {
        setSelectedPaymentSource(null);
        setIsOpenPaymentSourceModal({ current: true });
        setUpdate(moment());
    };

    const handlePaymentSourceCb = (result) => {
        if (result) {
            setSelectedPaymentSource(result);
            setUpdate(moment());
        }
    };

    const onReportOrderOriginalSourceCB = (result) => {
        setSelectedPaymentSource(result);
        setDefaultSourceChecked(true);
        setUpdate(moment());
    };


    return (
        <>
            <RenderActionButton propButtonTitle="Change Report Type" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />

            <Modal className="modal-dialog-centered change-report-type" style={{ maxWidth: "65%" }} isOpen={isOpenChangeReportTypeModal} toggle={() => handleToggleModal(isOpenChangeReportTypeModal)}>
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title text-center w-100">
                        Change Report Type
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenChangeReportTypeModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="text-left">
                        <AvForm onValidSubmit={(e) => handleSubmit(e)} >
                            <Row>
                                <Col>
                                    <div className="table-responsive">
                                        <table className="table conditions-table table-striped table-bordered table-condensed mb-3 mt-3">
                                            <thead>
                                                <tr>
                                                    <th>Report</th>
                                                    <th>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {reportTypes.filter(element => element.report_type.id !== propRowData.report_type_id).map(function (reportType, i) {
                                                    return (
                                                        <tr key={reportType.report_type.id} className={conditionRowID === reportType.report_type.id ? "selected" : ""} onClick={(e) => onReportTypeChange(parseInt(reportType.report_type.id))}>
                                                            <td className="text-center">{reportType.report_type.nickname}</td>
                                                            <td className="text-justify">{reportType.report_type.public_description}</td>
                                                        </tr >
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                                <Col xs="12" sm="12" md="12" lg="12">

                                    {fields.submission_type === 'expensive' ?
                                        <>
                                            <label >
                                                Additional charges of <b>${amtVariance}</b> are required for the selected report type.
                                            </label>
                                            <br />
                                            {!paymentError && <ReportOrderOriginalSource reportOrderId={propRowData.report_order_id} onReportOrderOriginalSourceCB={onReportOrderOriginalSourceCB} />}
                                            {paymentError &&
                                                <Row>
                                                    <span className="col-md-12 text-danger font-weight-bold">
                                                        The payment was failed because of following reason. <br />
                                                        <ul><li>{paymentError}</li></ul>
                                                    </span >
                                                </Row>
                                            }
                                            {(selectedPaymentSource && selectedPaymentSource?.isOriginalSource === false) &&
                                                <Label><span className="text-success mr-2"><i className="ni ni-check-bold"></i> {selectedPaymentSource.selectionText}</span><i className="fa fa-pencil-alt text-danger" onClick={() => openPaymentMethodSelector()} aria-hidden="true"></i></Label>
                                            }

                                            {(!selectedPaymentSource && defaultSourceChecked) &&
                                                <Button size="sm" color="warning" outline onClick={() => openPaymentMethodSelector()}> <i className="fas fa-credit-card"></i> Select Payment Method </Button>
                                            }
                                        </>
                                        : ''
                                    }

                                    {fields.submission_type === 'refund' && !isCompletedOrder ?
                                        <>
                                            <label >
                                                <b>${amtVariance}</b> will be refunded in your account soon.
                                            </label>
                                        </>
                                        : ''
                                    }
                                </Col>
                                <Col xs="12" sm="12" md="12" lg="12">
                                    <div className="text-right">
                                        <AvGroup>
                                            {isProcess ?
                                                <Button type="button" color="warning" size="sm">
                                                    <div className="fa fa-spinner fa-spin"></div> Processing...
                                                </Button>
                                                :
                                                <>
                                                    <Button size="sm" color="danger" outline onClick={() => handleToggleModal(isOpenChangeReportTypeModal)}>
                                                        Cancel
                                                    </Button>

                                                    {(fields.submission_type === 'identical' || fields.submission_type === 'refund' || (fields.submission_type === 'expensive' && selectedPaymentSource)) && fields.new_report_type_id ?
                                                        <Button size="sm" color="primary">
                                                            Submit
                                                        </Button>
                                                        : ''}
                                                </>}
                                        </AvGroup>
                                    </div>
                                </Col>
                            </Row>
                        </AvForm>
                    </div>
                </div>
            </Modal >

            <PaymentForm onPay={isOpenPaymentSourceModal}
                paymentDetails={{
                    total_amount: amtVariance,
                    cc_surcharge: null,
                    paymentFor: 'Report Type Change'
                }}
                onPaymentSourceCb={handlePaymentSourceCb} clientId={clientId} />
        </>
    );
};

export default ChangeReportTypeClientButton;