import React, { useState, useEffect } from "react";
import "./style.scss";
import RenderBootstrapTable from "components/Common/RenderBootstrapTable.js";
import { Row, Col, Modal, Button, UncontrolledTooltip } from "reactstrap";
import * as moment from "moment";
import { PostData } from "../../../services/Api.service";
import Spinner from "components/Common/Spinner.js";
import { toast } from 'react-toastify';
import { rowColorCondition, loggedInUser, checkRoles, truncate, displayUserName, sendExceptionEmail } from "components/Common/Helpers.js"
import MessageWithConversationModal from "./MessageWithConversationModal";
import ChatModal from "./ChatModal";
import AddEditConversationModal from "./AddEditConversationModal";
import AutoLoginDecrypt from "components/Common/AutoLoginDecrypt";

const userData = loggedInUser();
const phpUrl = process.env.REACT_APP_PHP_ENDPOINT;
const ConversationModal = ({ standaloneType, propData, onOpenConversationModal, onUpdateConversation, propertyId, onUpdateMessage }) => { // cTab 

    let [isProcess, setProcess] = useState(false);
    let [update, setUpdate] = useState(moment());
    let [selectedRow, setSelectedRow] = useState([]);
    let [conversationRow, setConversationRow] = useState([]);
    let [totalRecordSize, setTotalRecordSize] = useState(0);
    let [currentPage, setCurrentPage] = useState(1);
    let [recordsPerPage, setRecordsPerPage] = useState(25);
    let [tableData, setTableData] = useState([]);
    let [isOpenConversationModal, setIsOpenConversationModal] = useState(false);
    let isOpenAddEditConversationModal = React.useRef(false);
    let isOpenMessageWithConversationModal = React.useRef(false);
    let isOpenChatModal = React.useRef(false);
    let trClassName = rowColorCondition;

    useEffect(() => {
        if (onOpenConversationModal.current) {
            handleConversation();
        };
    }, [currentPage, recordsPerPage, onOpenConversationModal.current]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

    // Handle Error Modal
    let handleModal = (state) => {
        state = !state;
        setIsOpenConversationModal(state);
        setCurrentPage(1);
        onOpenConversationModal.current = false;
    };

    // Get Conversation Data
    let handleConversation = async () => {
        try {
            let currentOffset = (currentPage - 1) * recordsPerPage;
            let data = {
                standalone_type: standaloneType,
                offset: currentOffset,
                limit: recordsPerPage,
            };

            if (standaloneType === '1') { // cTab === 'awaiting_inspection'
                data.inspection_order_id = propData;
            } else {
                data.report_order_id = propData;
            }
            let conversationData = [];
            setProcess(true);
            let result = await PostData("conversation/conversation-list", data);
            let responseJson = result.data.response.rows;
            Object.keys(responseJson).forEach(index => {
                let conversationObj = {};
                conversationObj.id = responseJson[index].id;
                conversationObj.created_by = responseJson[index].created_by;
                if (standaloneType === '1') { // cTab === 'awaiting_inspection'
                    conversationObj.inspection_order_id = responseJson[index].inspection_order_id;
                } else {
                    conversationObj.report_order_id = responseJson[index].report_order_id;
                }
                conversationObj.total_unread_message = responseJson[index].total_unread_message;
                conversationObj.by = displayUserName(responseJson[index]['user'], userData.is_RV_user);
                if (responseJson[index]['report_order_message_conversation_topic']) {
                    conversationObj.topic = responseJson[index]['report_order_message_conversation_topic'].topic_name;
                    if (responseJson[index]['report_order_message_conversation_subtopic']) {
                        conversationObj.recipients = responseJson[index]['report_order_message_conversation_subtopic'].recipients.split(',');
                        conversationObj.topic = responseJson[index]['report_order_message_conversation_topic'].topic_name + ' : ' + responseJson[index]['report_order_message_conversation_subtopic'].subtopic_name;
                    }
                    conversationObj.topic_id = responseJson[index].topic_id;
                    conversationObj.subtopic_id = responseJson[index].subtopic_id;
                } else {
                    conversationObj.topic = "General";
                }
                conversationObj.issue_detail = responseJson[index].issue_detail;
                conversationObj.property_address = result.data.response.property_address;
                conversationObj.internal_issue = responseJson[index].internal_issue;
                conversationObj.issue_resolved = responseJson[index].issue_resolved;
                conversationObj.created_datetime = responseJson[index].created_datetime ? moment(responseJson[index].created_datetime).format('MMM D') : '-';
                conversationObj.last_update = responseJson[index].last_update ? moment(responseJson[index].last_update).format('MMM D hh:mm a') : '-';
                conversationData.push(conversationObj);
            });
            setTableData(conversationData);
            setTotalRecordSize(responseJson.length);
            setIsOpenConversationModal(true);
            // onUpdateConversation();
            setProcess(false);
        } catch (error) {
            setProcess(false);
            toast['error'](error.errors && error.errors.length ? error.errors[0].message : error.message)
            await sendExceptionEmail(error);
        }
    };

    let onPageChangeEvent = (currentPageTemp, recordsPerPageTemp) => {
        setCurrentPage(currentPageTemp > 0 ? currentPageTemp : 1);
        setRecordsPerPage(recordsPerPageTemp);
    };

    let paginationTotalText = (start, to, total) => {
        return (
            <p>From {start} to {to}, totals is {total}</p>
        );
    };

    // Handle Add/Edit Conversation popup
    let handleAddEditConversationModal = (e, row) => {
        e.preventDefault();
        isOpenAddEditConversationModal.current = true;
        if (row) {
            setSelectedRow(row);
        } else {
            setSelectedRow([]);
        }
        setUpdate(moment());
    };

    // Handle Message with Conversation popup
    let handleMessageWithConversationModal = (e, row) => {
        e.preventDefault();
        isOpenMessageWithConversationModal.current = true;
        if (row) {
            setSelectedRow(row);
        } else {
            setSelectedRow([]);
        }
        setUpdate(moment());
    };

    // Handle Chat
    let handleChat = (e, row) => {
        e.preventDefault();
        isOpenChatModal.current = true;
        conversationRow = {
            id: row.id,
            property_address: row.property_address,
            topic_subtopic: row.topic,
            recipients: row.recipients,
            issue_resolved: row.issue_resolved,
            created_by: row.created_by
        }
        if (row.report_order_id) {
            conversationRow.report_order_id = row.report_order_id;
        } else {
            conversationRow.inspection_order_id = row.inspection_order_id;
        }
        setConversationRow(conversationRow);
        setUpdate(moment());
    };

    let historyActionBtn = (cell, row) => {
        return (
            <>
                {(row.topic_id) && (row.created_by === userData.user_id || checkRoles(['ADMINISTRATOR', 'SALES_REP', 'ACCOUNT_REP', 'PROCESSOR'])) &&
                    <Button type="button" id={"update_conversation"} color="primary" size="sm" onClick={e => handleAddEditConversationModal(e, row)}>
                        <i className="fa fa-pencil-alt" aria-hidden="true"></i>
                    </Button>
                }
            </>
        )
    }

    let formatIssueDetail = (cell, row) => {
        const detail_id = `detail_${row.id}`;
        return (
            <div>
                <span id={detail_id}>{truncate(row.issue_detail, 15)}</span>
                <UncontrolledTooltip
                    delay={0}
                    placement="auto"
                    target={detail_id}
                >
                    {row.issue_detail}
                </UncontrolledTooltip>
            </div>

        );
    }

    let handleChatButton = (cell, row) => {
        return (
            <>
                {(row.total_unread_message > 0) ?
                    <>
                        <span className="notification_span">
                            <i id={"message_" + row.id} className="fa fa-2x fa-comment-dots cursor-pointer" style={{ color: '#338BA8' }} aria-hidden="true" onClick={e => handleChat(e, row)}></i>
                            <div className="notification">{row.total_unread_message}</div>
                        </span>
                    </>
                    :
                    <>
                        <i id={"message_" + row.id} className="fa fa-2x fa-comment-dots cursor-pointer" style={{ color: '#999997' }} aria-hidden="true" onClick={e => handleChat(e, row)}></i>
                    </>
                }
            </>
        );
    }

    let handleOpenThread = (row, fields) => {
        onUpdateConversation();
        isOpenChatModal.current = true;
        conversationRow = {
            id: row.id,
            property_address: row.property_address,
            issue_resolved: "0",
            topic_subtopic: (row.topic_id) ? fields.selected_topic + ':' + fields.selected_subtopic : "General",
            created_by: row.created_by
        }
        if (row.report_order_id) {
            conversationRow.report_order_id = row.report_order_id;
        } else {
            conversationRow.inspection_order_id = row.inspection_order_id;
        }
        setConversationRow(conversationRow);
        setUpdate(moment());
    };

    const tableParams = {
        currentPage,
        recordsPerPage,
        onPageChangeEvent,
        paginationTotalText,
        tableData,
        totalRecordSize,
        trClassName,
        columns: [
            { title: 'By', dataField: 'by', width: "15%" },
            { title: 'Date', dataField: 'created_datetime', isKey: true, width: "15%" },
            { title: 'Topic', dataField: 'topic', width: "15%" },
            { title: 'Detail', dataFormat: formatIssueDetail, width: "15%" },
            { title: 'Last', dataField: 'last_update', width: "15%" },
            { title: 'Message', dataFormat: handleChatButton, width: "15%" },
            { title: 'Edit', dataFormat: historyActionBtn, width: "10%" },
        ],
    };

    return (
        <>
            <Spinner isShow={isProcess} />
            <Modal className="modal-dialog-centered" style={{ maxWidth: "60%" }} isOpen={onOpenConversationModal.current} toggle={() => handleModal(isOpenConversationModal)}>
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title text-center text-white w-100">
                        <b>
                            {tableData.length && (
                                userData.is_RV_user ?
                                    <AutoLoginDecrypt key={propertyId} data={{ label: tableData[0].property_address, url: `${phpUrl}valuation/deals/details/${propertyId}` }} />
                                    : tableData[0].property_address)
                            }
                        </b>
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleModal(isOpenConversationModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col className="col-md-12 text-right">
                            <Button id={"new_thread"} color="success" size="sm" type="button" onClick={e => handleMessageWithConversationModal(e)}>
                                New Conversation
                            </Button>
                            <UncontrolledTooltip delay={0} placement="auto" target={"new_thread"} >New Conversation</UncontrolledTooltip>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <RenderBootstrapTable tableParams={tableParams} />
                        </Col>
                    </Row>
                </div>
            </Modal>
            {isOpenMessageWithConversationModal.current &&
                <MessageWithConversationModal propHandleModal={isOpenMessageWithConversationModal} reportInspectionId={propData} standaloneType={standaloneType}
                    currentRow={(selectedRow.length > 0) ? {
                        topic_id: selectedRow.topic_id,
                        id: selectedRow.id,
                        sub_topic_id: selectedRow.sub_topic_id,
                        issue_detail: selectedRow.issue_detail
                    } : selectedRow}
                    onUpdateConversation={handleOpenThread} />
            }
            {isOpenAddEditConversationModal.current &&
                <AddEditConversationModal propHandleModal={isOpenAddEditConversationModal} reportInspectionId={propData} standaloneType={standaloneType}
                    currentRow={(selectedRow.length > 0) ? {
                        topic_id: selectedRow.topic_id,
                        id: selectedRow.id,
                        sub_topic_id: selectedRow.sub_topic_id,
                        issue_detail: selectedRow.issue_detail
                    } : selectedRow}
                    onUpdateConversation={handleConversation} />
            }
            {isOpenChatModal.current &&
                <ChatModal propertyId={propertyId} standaloneType={standaloneType} conversationData={conversationRow} propHandleModal={isOpenChatModal} onCloseChat={handleConversation} onUpdateMessage={onUpdateMessage} />
            }
        </>
    );
};

export default ConversationModal;