import React from "react";
import { Button, Col, Modal, Row } from "reactstrap";
import { useState } from "react";
import { PostData } from "services/PostData";
import { toast } from "react-toastify";
import eventBus from "../../EventBus";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import * as moment from "moment";
import { useEffect } from "react";
import { sendExceptionEmail } from "components/Common/Helpers";
import RenderActionButton from "../RenderActionButton";
import { loggedInUser } from "components/Common/Helpers";

const ReportOrderCancelButton = ({ propRowData, propButtonOptions }) => {

    const userData = loggedInUser();
    const [isOpenCancelReportOrderModal, setIsOpenCancelReportOrderModal] = useState(false);
    const [cancellationReason, setCancellationReason] = useState("");
    const [update, setUpdate] = useState(moment());
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => { }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

    let handleActionClick = (e, row) => {
        e.preventDefault();
        setIsOpenCancelReportOrderModal(true);
    };

    let handleToggleModal = (state) => {
        if (!isProcessing) {
            state = !state;
            setIsOpenCancelReportOrderModal(state);
            setCancellationReason("");
            setUpdate(moment());
        }
    };

    let handleCancelOrderProcess = async () => {
        try {
            setIsProcessing(true)
            const result = await PostData("orders/cancel_report_order", { report_order_id: propRowData.report_order_id, user_id: userData.user_id, cancellation_reason: cancellationReason })
            let responseJson = result.data;

            setIsProcessing(false)
            if (responseJson.status !== 200) {
                toast["error"](responseJson.message);
            } else {
                toast["success"](responseJson.message);
                setIsOpenCancelReportOrderModal(false);
            }

            eventBus.dispatch("report_order_status_updated", { client_id: propRowData.company_id });
        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error)
            setIsProcessing(false)
        };
    }

    return (
        <>
            <RenderActionButton propButtonTitle="Cancel" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />

            {/* Cancel report order Modal */}
            <Modal className="modal-dialog-centered" style={{ maxWidth: "500px" }} isOpen={isOpenCancelReportOrderModal} toggle={() => handleToggleModal(isOpenCancelReportOrderModal)}>
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title text-center w-100">
                        Cancel Report Order
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenCancelReportOrderModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <AvForm onValidSubmit={(e) => handleCancelOrderProcess()} >
                        <AvGroup className="text-left">
                            <AvField
                                name="cancellation_reason"
                                onChange={(e) => setCancellationReason(e.target.value)}
                                className="form-control"
                                placeholder="Enter Reason"
                                type="textarea"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "This field is required."
                                    },
                                    maxLength: {
                                        value: 255,
                                        errorMessage: 'Reason must be less than 255 character'
                                    }
                                }}
                            />
                        </AvGroup>
                        <Row>
                            <Col lg="12" className="text-right">
                                {isProcessing ?
                                    <Button type="button" color="warning" size="sm">
                                        <div className="fa fa-spinner fa-spin"></div> Processing...
                                    </Button>
                                    :
                                    <>
                                        <Button size="sm" color="danger" outline onClick={() => handleToggleModal(isOpenCancelReportOrderModal)} >
                                            Cancel
                                        </Button>
                                        <Button size="sm" color="primary" >
                                            Submit
                                        </Button>
                                    </>
                                }
                            </Col>
                        </Row>
                    </AvForm>
                </div>
            </Modal>
        </>
    );
};

export default ReportOrderCancelButton;