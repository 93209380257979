import axios from 'axios';
import { loggedInUser } from "components/Common/Helpers.js";
import { PostData as OMSPostData } from './PostData';
let BaseUrl = process.env.REACT_APP_ORDER_INTAKE_ENDPOINT;
const userData = loggedInUser();


let generateClientToken = async (clientId, version) => {
    let OCT = localStorage.getItem('OCT');
    if (OCT) {
        OCT = JSON.parse(OCT);
        if (OCT.client === clientId) {
            return OCT.token;
        }
    }

    try {
        return GetData(`company/${clientId}/create-token-for-other-client`, {}, null, version)
            .then(response => {

                const token = response.data.token;

                OCT = {
                    client: clientId,
                    token: token
                };

                localStorage.setItem("OCT", JSON.stringify(OCT));

                return token;
            });
    } catch (error) {
        console.error("error In generateClientToken", error);
    }
};

let companyDetails = async (clientId) => {
    try {
        return OMSPostData(`company/details`, { id: clientId })
            .then(response => {
                const token = response.data.data.token;
                return token;
            });
    } catch (error) {
        console.error("error In companyDetails", error);
    }
};

// Get request
export async function GetData(api, data, clientId = null, version = "v1") {
    let clientTokenForHeader = userData.client_token;
    const replacingClientId = clientId ? clientId : userData.company_id;

    api = api.replace(/{client_id}/g, replacingClientId);

    if (api.search("{company_token}") > -1) {
        let replacingClientToken = await companyDetails(replacingClientId)
        api = api.replace(/{company_token}/g, replacingClientToken);
    }

    if (data.company_token && data.company_token === "{company_token}") {
        let replacingClientToken = await companyDetails(replacingClientId)
        data.company_token = replacingClientToken;
    }

    if (clientId && clientId !== userData.company_id) {
        clientTokenForHeader = await generateClientToken(clientId, version);
    }

    let params = new URLSearchParams();

    for (let key in data) {
        if (data[key]) {
            let value = data[key];

            if (typeof value === 'string') {
                value = value.replace(/{client_id}/g, replacingClientId);
            }

            params.set(key, value);
        }
    }
    return new Promise((resolve, reject) => {
        axios({
            url: BaseUrl + version + "/" + api + "?" + params,
            method: 'get',
            data: data,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${clientTokenForHeader}`
            }
        }).then(response => {
            resolve(response.data);
        }).catch(errors => {
            let errorData = errors;
            if (errors && errors.response && errors.response.data) {
                errorData = errors.response.data;
            } else if (errors && errors.response) {
                errorData = errors.response;
            }

            reject(errorData);
        })
    });
}

// Post request
export async function PostData(api, data, clientId = null, version = "v1") {

    let clientTokenForHeader = userData.client_token;
    const replacingClientId = clientId ? clientId : userData.company_id;

    if (data.company_id && data.company_id === '{client_id}') {
        data.company_id = replacingClientId;
    }

    api = api.replace(/{client_id}/g, replacingClientId);

    if (api.search("{company_token}") > -1) {
        let replacingClientToken = await companyDetails(replacingClientId)
        api = api.replace(/{company_token}/g, replacingClientToken);
    }

    if (data.company_token && data.company_token === "{company_token}") {
        let replacingClientToken = await companyDetails(replacingClientId)
        data.company_token = replacingClientToken;
    }

    if (clientId && clientId !== userData.company_id) {
        clientTokenForHeader = await generateClientToken(clientId, version);
    }

    return new Promise((resolve, reject) => {
        axios({
            url: BaseUrl + version + "/" + api,
            method: 'post',
            data: data,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${clientTokenForHeader}`
            }
        }).then(response => {
            resolve(response.data);
        }).catch(errors => {
            let errorData = errors;
            if (errors && errors.response && errors.response.data) {
                errorData = errors.response.data;
            } else if (errors && errors.response) {
                errorData = errors.response;
            }

            reject(errorData);
        })
    });
}

// PUT request
export async function PutData(api, data, clientId = null, version = "v1") {

    let clientTokenForHeader = userData.client_token;
    const replacingClientId = clientId ? clientId : userData.company_id;

    if (data.company_id && data.company_id === '{client_id}') {
        data.company_id = replacingClientId;
    }

    api = api.replace(/{client_id}/g, replacingClientId);

    if (api.search("{company_token}") > -1) {
        let replacingClientToken = await companyDetails(replacingClientId)
        api = api.replace(/{company_token}/g, replacingClientToken);
    }

    if (data.company_token && data.company_token === "{company_token}") {
        let replacingClientToken = await companyDetails(replacingClientId)
        data.company_token = replacingClientToken;
    }

    if (clientId && clientId !== userData.company_id) {
        clientTokenForHeader = await generateClientToken(clientId, version);
    }

    return new Promise((resolve, reject) => {
        axios({
            url: BaseUrl + version + "/" + api,
            method: 'put',
            data: data,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${clientTokenForHeader}`
            }
        }).then(response => {
            resolve(response.data);
        }).catch(errors => {
            let errorData = errors;
            if (errors && errors.response && errors.response.data) {
                errorData = errors.response.data;
            } else if (errors && errors.response) {
                errorData = errors.response;
            }

            reject(errorData);
        })
    });
}

// DELETE request
export async function DeleteData(api, data, clientId = null, version = "v1") {
    let clientTokenForHeader = userData.client_token;
    const replacingClientId = clientId ? clientId : userData.company_id;

    api = api.replace(/{client_id}/g, replacingClientId);

    if (api.search("{company_token}") > -1) {
        let replacingClientToken = await companyDetails(replacingClientId)
        api = api.replace(/{company_token}/g, replacingClientToken);
    }

    if (data.company_token && data.company_token === "{company_token}") {
        let replacingClientToken = await companyDetails(replacingClientId)
        data.company_token = replacingClientToken;
    }

    if (clientId && clientId !== userData.company_id) {
        clientTokenForHeader = await generateClientToken(clientId, version);
    }

    return new Promise((resolve, reject) => {
        axios({
            url: BaseUrl + version + "/" + api,
            method: 'delete',
            data: data,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${clientTokenForHeader}`
            }
        }).then(response => {
            resolve(response.data);
        }).catch(errors => {
            let errorData = errors;
            if (errors && errors.response && errors.response.data) {
                errorData = errors.response.data;
            } else if (errors && errors.response) {
                errorData = errors.response;
            }

            reject(errorData);
        })
    });
}