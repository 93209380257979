
import { loggedInUser } from "components/Common/Helpers";
import { PostData } from "./PostData";

const phpUrl = process.env.REACT_APP_PHP_ENDPOINT;

export async function loginAndRedirectToPHP(data) {
    try {

        let uData = loggedInUser();
        if (!uData) {
            throw Error("Unauthorized!")
        }

        const result = await PostData("auth/encrypt", { id: uData.user_id })
        let responseJson = result.data;
        if (responseJson.status === 200) {
            window.open(phpUrl + "login?token=" + responseJson.data.string + "&url=" + data.url + "&id=" + uData.user_id, "_blank");
        }
    } catch (error) {
        throw error
    }
}