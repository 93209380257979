import React, { useState } from "react";
import { Button, Row, Col, FormGroup, Modal } from "reactstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { showSpinner } from './../../../../ShowHideSpinner.reducer'

import { PutData as OrderIntakePut } from "services/OrderIntakeData";
import { sendExceptionEmail } from "components/Common/Helpers";
import RenderActionButton from "./RenderActionButton";

const ReactivateReportOrderIntakeButton = ({ propRowData, propButtonOptions }) => {

    const dispatch = useDispatch();
    const [isOpenInspectionNewOrderModal, setIsOpenInspectionNewOrderModal] = useState(false);

    let handleActionClick = (e, row) => {
        e.preventDefault();
        setIsOpenInspectionNewOrderModal(true)
    };

    let handleReactivateReportIntakeProcess = async () => {
        try {
            dispatch(showSpinner(true));
            const response = await OrderIntakePut("order/" + propRowData.intake_token + "/reactivate", {}, propRowData.company_id)
            toast["success"](response.message);
            dispatch(showSpinner(false));
        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error);
            dispatch(showSpinner(false));
        }
    };

    return (
        <>
            <RenderActionButton propButtonTitle="Reactivate" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />

            <Modal className="modal-dialog-centered modal-md" isOpen={isOpenInspectionNewOrderModal} toggle={() => setIsOpenInspectionNewOrderModal(!isOpenInspectionNewOrderModal)}>
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title text-center w-100">
                        Reactivate Order
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => setIsOpenInspectionNewOrderModal(!isOpenInspectionNewOrderModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body text-left">
                    <Row>
                        <Col sm="12">
                            You are reactivating this order for payment again. <br /><br /> Please note that any files you previously uploaded to this order need to be uploaded again, as we have deleted those files when this order was previously dismissed from the active payment status.
                        </Col>
                        <Col sm="12">
                            <br />
                            Thank you for your diligence and understanding!
                        </Col>
                    </Row>
                    <div className="text-right mt-2">
                        <FormGroup>
                            <Button size="sm" color="danger" outline onClick={() => setIsOpenInspectionNewOrderModal(!isOpenInspectionNewOrderModal)}>
                                Close
                            </Button>
                            <Button size="sm" color="primary" onClick={handleReactivateReportIntakeProcess}>
                                OK, Reactivate
                            </Button>
                        </FormGroup>
                    </div>

                </div>
            </Modal >
        </>
    );
};

export default ReactivateReportOrderIntakeButton;