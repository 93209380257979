import React, { useEffect, useState } from "react";
import { Button, Col, Label, Modal, Row, Table, UncontrolledTooltip } from "reactstrap";
import { toast } from "react-toastify";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import { PostData } from "services/PostData";
import { useHistory } from "react-router-dom";
import { GoogleApiWrapper } from 'google-maps-react';
import { useDispatch } from "react-redux";
import { showSpinner } from './../../../../../ShowHideSpinner.reducer'

import eventBus from "../../EventBus";
import * as moment from "moment";
import "./PropertyCompDiscrepancyButton.scss";

import { loggedInUser, sendExceptionEmail } from "components/Common/Helpers";
import PriceFormatter from "components/Common/PriceFormatter";
import NumberFormatter from "components/Common/NumberFormatter";
import CompList from "../../CompLinks/CompList";

const mapStyles = {
    width: '100%',
    height: '400px'
};
const userData = loggedInUser();

const PropertyCompDiscrepancyButton = ({ propRowData, propButtonOptions, google }) => {
    const dispatch = useDispatch();
    let history = useHistory();
    let [isDiscrepancyCompModal, setDiscrepancyCompModal] = useState(false);
    let [propertyCompData, setPropertyCompData] = useState([]);
    let [activeMarker, setActiveMarker] = useState({});
    let [showingInfoWindow, setShowingInfoWindow] = useState(false);
    let [fields, setFields] = useState({});
    let [selectedPlace, setSelectedPlace] = useState({});

    let [golfCourse, setGolfCourse] = useState(0);
    let [waterFront, setSWaterFront] = useState(0);
    let [pool, setPool] = useState("0");

    const current_date = new Date();
    let year = current_date.getFullYear();

    const [update, setUpdate] = useState(moment());

    useEffect(() => {
        document.addEventListener("keydown", _handleKeyDown);

        // returned function will be called on component unmount 
        return () => {
            document.removeEventListener("keydown", _handleKeyDown);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => { }, [update]);

    let _handleKeyDown = (e) => {
        switch (e.keyCode) {
            case 37:
                document.querySelector('.control-prev').click();
                break;
            case 39:
                document.querySelector('.control-next').click();
                break;
            default:
                break;
        }
    };

    // Accept data reconciliations
    let handleActionClick = async (e) => {
        e.preventDefault();

        try {
            if (propRowData.task_id && propRowData.action_type && propRowData.property_comp_id) {

                let data = {
                    user_id: userData.user_id,
                    order_status: 2,
                    id: propRowData.task_id,
                    property_comp_id: propRowData.property_comp_id,
                    action_type: propRowData.action_type,
                    company_id: userData.company_id,
                };

                dispatch(showSpinner(true));
                setPropertyCompData([]);
                setFields({});
                let result = await PostData("orders/update-reconciliation-data", data)
                let responseJson = result.data;
                if (responseJson.status === 200) {

                    propertyCompData = responseJson.data[0];
                    propertyCompData.task_id = propRowData.task_id;
                    propertyCompData.is_baths_discrepancies = false;
                    propertyCompData.is_beds_discrepancies = false;
                    propertyCompData.is_year_built_discrepancies = false;
                    propertyCompData.is_lot_size_discrepancies = false;
                    propertyCompData.is_main_sqft_discrepancies = false;
                    propertyCompData.is_finished_basement_discrepancies = false;
                    propertyCompData.is_total_sqft_discrepancies = false;
                    propertyCompData.property_comp_id = propRowData.property_comp_id;

                    // Waterfront anf Golf Course
                    // setGolfCourse(propertyCompData.key_flags_golf_course);
                    // setSWaterFront(propertyCompData.key_flags_water_front);

                    // Above Grade Sqft discrepancies
                    let above_grade_mls = propertyCompData.living_area_square_feet ? propertyCompData.living_area_square_feet : 0;
                    let above_grade_pr = propertyCompData.county_pr_LivingArea ? propertyCompData.county_pr_LivingArea : 0;
                    let above_grade_final = (above_grade_mls !== 0 && above_grade_pr !== 0) ? Math.abs(above_grade_mls - above_grade_pr) : 0;

                    let min_above_grade = Math.min(above_grade_mls * 0.10, above_grade_pr * 0.10, 250);

                    if (above_grade_final > min_above_grade) {
                        propertyCompData.is_main_sqft_discrepancies = true;
                    }

                    // Below Grade Sqft discrepancies
                    let below_grade_mls = propertyCompData.county_pr_BelowGradeFinishedArea ? propertyCompData.county_pr_BelowGradeFinishedArea : 0;
                    let below_grade_pr = propertyCompData.county_pr_BelowGradeFinishedArea ? propertyCompData.county_pr_BelowGradeFinishedArea : 0;
                    let below_grade_final = (below_grade_mls !== 0 && below_grade_pr !== 0) ? Math.abs(below_grade_mls - below_grade_pr) : 0;

                    let min_below = Math.min(below_grade_mls * 0.10, below_grade_pr * 0.10, 250);
                    if (below_grade_final > min_below) {
                        propertyCompData.is_finished_basement_discrepancies = true;
                    }

                    // Total Grade Sqft discrepancies

                    let total_sqft_mls = above_grade_mls + below_grade_mls;
                    let total_sqft_pr = above_grade_pr + below_grade_pr;
                    let total_sqft_final = (total_sqft_mls !== 0 && total_sqft_pr !== 0) ? Math.abs(total_sqft_mls - total_sqft_pr) : 0;

                    let min_total = Math.min(total_sqft_mls * 0.10, total_sqft_pr * 0.10, 250);
                    if (total_sqft_final > min_total) {
                        propertyCompData.is_total_sqft_discrepancies = true;
                    }

                    // Lot discrepancies
                    let lot_mls = propertyCompData.lot_size_square_feet ? propertyCompData.lot_size_square_feet : 0;
                    let lot_pr = propertyCompData.pr_LotSizeSquareFeet ? propertyCompData.pr_LotSizeSquareFeet : 0;
                    let lot_final = (lot_mls !== 0 && lot_pr !== 0) ? Math.abs(lot_mls - lot_pr) : 0;

                    let min_l_val = Math.min(propertyCompData.pr_LotSizeSquareFeet, propertyCompData.lot_size_square_feet) * 0.10;
                    if (lot_final >= min_l_val) {
                        propertyCompData.is_lot_size_discrepancies = true;
                    }

                    // Year built discrepancies
                    let year_mls = propertyCompData.year_built ? propertyCompData.year_built : 0;
                    let year_pr = propertyCompData.pr_YearBuilt ? propertyCompData.pr_YearBuilt : 0;
                    let year_final = (year_mls !== 0 && year_pr !== 0) ? Math.abs(year_mls - year_pr) : 0;

                    let curr_yr = moment().format("YYYY");
                    if ((curr_yr - Math.max(propertyCompData.pr_YearBuilt, propertyCompData.original_year_built)) <= 10) {
                        if (year_final > 2) {
                            propertyCompData.is_year_built_discrepancies = true;
                        }
                    } else {
                        if (year_final >= 6) {
                            propertyCompData.is_year_built_discrepancies = true;
                        }
                    }

                    // Beds discrepancies
                    let beds_mls = propertyCompData.bedrooms_total ? propertyCompData.bedrooms_total : 0;
                    let beds_pr = propertyCompData.pr_BedroomsTotal ? propertyCompData.pr_BedroomsTotal : 0;
                    let beds_final = (beds_mls !== 0 && beds_pr !== 0) ? Math.abs(beds_mls - beds_pr) : 0;

                    if (beds_final > 0) {
                        propertyCompData.is_beds_discrepancies = true;
                    }

                    // baths discrepancies
                    let baths_mls = propertyCompData.bathrooms_total ? propertyCompData.bathrooms_total : 0;
                    let baths_pr = propertyCompData.pr_BathroomsTotalInteger ? propertyCompData.pr_BathroomsTotalInteger : 0;
                    let baths_final = (baths_mls !== 0 && baths_pr !== 0) ? Math.abs(baths_mls - baths_pr) : 0;

                    if (baths_final > 0) {
                        propertyCompData.is_baths_discrepancies = true;
                    }

                    propertyCompData.task_status = propRowData.task_status;
                    setPropertyCompData(propertyCompData);
                    setDiscrepancyCompModal(true);
                    dispatch(showSpinner(false));
                    setFields({});
                    setUpdate(moment());
                }
            }
        } catch (error) {
            dispatch(showSpinner(false));
            await sendExceptionEmail(error);
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message)
            history.push("/admin/dashboard");
        }
    }

    let propertyImgs = propertyCompData.image_urls;

    // Handle Discrepancy Comp Modal 
    let handleDiscrepancyCompModalToggle = (state) => {
        state = !state;
        setDiscrepancyCompModal(state);

        setSWaterFront(0);
        setPool(0);
        setGolfCourse(0);

        if (propRowData.action_type === 'accept') {
            eventBus.dispatch("discrepancy_task_status_updated", {});
        }
    }

    // Mapbox
    let onMarkerClick = (props, marker, e) => {
        setSelectedPlace(props);
        setActiveMarker(marker);
        setShowingInfoWindow(true);
    }

    //  Map box close
    let onClose = props => {
        if (showingInfoWindow) {
            setShowingInfoWindow(false);
            setActiveMarker(null);
        }
    };

    // Populate property data in form
    let populatePropertyForm = (e) => {
        // e.preventDefault();
        let fields = {};
        let poolTemp = pool;
        let waterFrontTemp = waterFront;
        let golfCourseTemp = golfCourse;

        if (e.target.value === "raw_mls") {

            fields.above_grade_sqft = propertyCompData.living_area_square_feet ? propertyCompData.living_area_square_feet : 0;
            fields.below_grade_sqft = propertyCompData.finished_basement ? propertyCompData.finished_basement : 0;
            fields.total_sqft = (propertyCompData.living_area_square_feet) + (propertyCompData.finished_basement);
            fields.beds = propertyCompData.bedrooms_total ? propertyCompData.bedrooms_total : 0.00;
            fields.baths = propertyCompData.bathrooms_total ? propertyCompData.bathrooms_total : 0.00;
            fields.year_built = propertyCompData.year_built;
            fields.stories = propertyCompData.stories_total ? propertyCompData.stories_total : 0;
            fields.lot_size = propertyCompData.lot_size_square_feet;
            fields.property_type = propertyCompData.property_type_normalized ? propertyCompData.property_type_normalized : "";
            fields.garage = propertyCompData.garage ? propertyCompData.garage : 0;
            poolTemp = propertyCompData.pool === 1 ? "1" : "0";
            waterFrontTemp = propertyCompData.key_flags_water_front ? propertyCompData.key_flags_water_front : 0;
            golfCourseTemp = propertyCompData.key_flags_golf_course ? propertyCompData.key_flags_golf_course : 0;
        } else {
            fields.above_grade_sqft = propertyCompData.pr_LivingArea ? propertyCompData.pr_LivingArea : 0;
            fields.below_grade_sqft = propertyCompData.pr_BelowGradeFinishedArea ? propertyCompData.pr_BelowGradeFinishedArea : 0;
            fields.total_sqft = (propertyCompData.pr_LivingArea) + (propertyCompData.pr_BelowGradeFinishedArea);
            fields.beds = propertyCompData.pr_BedroomsTotal ? propertyCompData.pr_BedroomsTotal : 0.00;
            fields.baths = propertyCompData.pr_BathroomsTotalInteger ? (propertyCompData.pr_BathroomsTotalInteger).toFixed(2) : 0.00;
            fields.year_built = propertyCompData.pr_YearBuilt;
            fields.stories = 0;
            fields.lot_size = propertyCompData.pr_LotSizeSquareFeet;
            fields.property_type = propertyCompData.property_type_normalized ? propertyCompData.property_type_normalized : "";
            fields.garage = propertyCompData.pr_GarageSpaces ? propertyCompData.pr_GarageSpaces : "0";
            poolTemp = "1";
            waterFrontTemp = 0;
            golfCourseTemp = 0;
        }

        setFields(fields);
        setPool(poolTemp);
        setSWaterFront(waterFrontTemp);
        setGolfCourse(golfCourseTemp);
        setUpdate(moment());
    }

    // Handle Input Data
    let handleInput = (e) => {
        e.preventDefault();

        let fieldsTemp = fields;
        let { value, name } = e.target;
        let tempValue = {};
        if (name === 'property_type') {

            if (propertyCompData.property_types) {
                propertyCompData.property_types.map((obj, key) => {
                    if (obj.property_type_normalized === value) {
                        tempValue = obj;
                    }
                    return tempValue
                })
            }

            fieldsTemp['apex_prop_type'] = Object.keys(tempValue).length > 0 ? tempValue.apex_prop_type : "";
            fieldsTemp[name] = Object.keys(tempValue).length > 0 ? tempValue.property_type_normalized : "";
        } else {
            fieldsTemp[name] = value;
        }
        setFields(fieldsTemp)
        setUpdate(moment());
    }

    // Save and update property data
    let handlePropertySubmit = async (e) => {
        e.preventDefault();
        try {
            let data = fields;
            data.user_id = userData.user_id;
            data.company_id = userData.company_id;
            data.property_comp_id = propertyCompData.property_comp_id;
            data.apex_prop_type = propertyCompData.apex_prop_type;
            data.reconciliation_type = 2;
            data.key_flags_golf_course = (golfCourse !== null ? parseInt(golfCourse) : golfCourse);
            data.key_flags_water_front = (waterFront !== null ? parseInt(waterFront) : waterFront);
            data.pool = pool ? pool : "0";

            if (data.property_type) {
                propertyCompData.property_types.map((obj, key) => {
                    if (obj.property_type_normalized === data.property_type) {
                        data.apex_prop_type = obj.apex_prop_type;
                    }
                    return data;
                })
            }

            dispatch(showSpinner(true));
            let result = await PostData("orders/save_update_discrepancy_reconciliation_values", data)
            let responseJson = result.data;
            if (responseJson.status === 200) {
                dispatch(showSpinner(false));
                setDiscrepancyCompModal(false);
                toast['success'](responseJson.message);
                eventBus.dispatch("discrepancy_task_status_updated", {});
            } else {
                toast['error'](responseJson.message);
                dispatch(showSpinner(false));
            }

        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message)
            await sendExceptionEmail(error)
            dispatch(showSpinner(false));
            history.push("/admin/dashboard");
        }
    }

    // Updated task to stump
    let updateStumpTask = async (e, taskId) => {
        e.preventDefault();
        try {
            if (taskId) {
                let data = {
                    id: taskId,
                    status: 6
                }
                dispatch(showSpinner(true));
                let result = await PostData("orders/update_task_stumped_status", data)
                let responseJson = result.data;

                if (responseJson.status === 200) {
                    dispatch(showSpinner(false));
                    setDiscrepancyCompModal(false);
                    toast['success'](responseJson.message);
                    eventBus.dispatch("discrepancy_task_status_updated", {});
                } else {
                    toast['error'](responseJson.message);
                    dispatch(showSpinner(false));
                }
            }
        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message)
            await sendExceptionEmail(error)
            dispatch(showSpinner(false));
            history.push("/admin/dashboard");
        }
    }

    let handleWaterFrontChange = (checked) => {
        setSWaterFront(checked && checked === true ? "1" : "0");
    };

    let handleGolfCourseChange = (checked) => {
        setGolfCourse(checked && checked === true ? "1" : "0");
    };

    let handlePoolChange = (checked) => {
        setPool(checked && checked === true ? "1" : "0");
    };

    let handleLotSizeError = (e) => {
        if (fields.property_type === "Condo") {
            return 0;
        } else {
            return 500;
        }
    }
    let propDataForCompList = {
        propertyImgs,
        image_urls: propertyCompData.image_urls,
        public_remarks: propertyCompData.public_remarks,
        address: propertyCompData.address,
        city: propertyCompData.city,
        state: propertyCompData.state,
        postal_code: propertyCompData.postal_code,
        customTabListStyle: { padding: "6px 26px" },
        contextMenu: true,
        google,
        zoom: 20,
        mapStyles,
        latitude: propertyCompData.latitude,
        longitude: propertyCompData.longitude,
        onMarkerClick: onMarkerClick,
        markerName: propertyCompData.address + ", " + propertyCompData.city + ", " + propertyCompData.state + ", " + propertyCompData.postal_code,
        infoWindowMarker: activeMarker,
        infoWindowVisible: showingInfoWindow,
        infoWindowOnClose: onClose,
        selectedPlace,
        openModel: isDiscrepancyCompModal
    }
    return (
        <>
            <Button
                color={propButtonOptions?.color ? propButtonOptions.color : "accept"}
                outline={propButtonOptions?.outline ? propButtonOptions.outline : true}
                block={propButtonOptions?.block ? propButtonOptions.block : false}
                size={propButtonOptions?.size ? propButtonOptions.size : 'sm'}
                type="button"
                id={"discrepancy_task_" + propRowData.task_id}
                onClick={e => handleActionClick(e)}
            >
                {propRowData.action_type === 'accept' ?
                    <><i className="fa fa-check" aria-hidden="true"></i>{propButtonOptions?.label ? ` ${propButtonOptions.label}` : null}</> :
                    <><i className="fa fa-eye" aria-hidden="true"></i>{propButtonOptions?.label ? ` ${propButtonOptions.label}` : null}</>
                }
            </Button>
            <UncontrolledTooltip delay={0} placement="top" target={"discrepancy_task_" + propRowData.task_id} >View</UncontrolledTooltip>

            <Modal className="modal-dialog-centered discrepancy-property-model modal-xxl" isOpen={isDiscrepancyCompModal} toggle={() => handleDiscrepancyCompModalToggle("isDiscrepancyCompModal")}>
                <div className="modal-header modal-header-colored">
                    <h4 className="modal-title w-100" id="exampleModalLabel">
                        <Row className="mb-2">
                            <Col>
                                {propertyCompData.address + ", " + propertyCompData.city + ", " + propertyCompData.state + ", " + propertyCompData.postal_code}
                            </Col>
                            <Col className="text-right">
                                Comp ID: {propertyCompData ? propertyCompData.comp_id : "####"}, Discrepancy Task ID: {propertyCompData ? propertyCompData.task_id : "####"}
                            </Col>
                        </Row>
                    </h4>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleDiscrepancyCompModalToggle("isDiscrepancyCompModal")}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body text-center">

                    <div className="row">
                        <div className="col-md-3">
                            <div className="discrepancy-column-body">
                                <Table className="text-left property-comp-table" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col" width="45%">&nbsp;</th>
                                            <th scope="col">
                                                <div className="custom-control custom-control-alternative custom-radio">
                                                    <input
                                                        className="custom-control-input"
                                                        value="raw_mls"
                                                        id="customRadio1"
                                                        name="custom-radio-1"
                                                        type="radio"
                                                        onChange={(e) => populatePropertyForm(e)}
                                                    />
                                                    <label className="custom-control-label" htmlFor="customRadio1">
                                                        Raw MLS
                                                    </label>
                                                </div>
                                            </th>

                                            <th scope="col" width="20%">
                                                <div className="custom-control custom-control-alternative custom-radio">
                                                    <input
                                                        className="custom-control-input"
                                                        value="public_record"
                                                        id="customRadio2"
                                                        name="custom-radio-1"
                                                        type="radio"
                                                        onChange={(e) => populatePropertyForm(e)}
                                                    />
                                                    <label className="custom-control-label" htmlFor="customRadio2">
                                                        Public Records
                                                    </label>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><Label for="example">Closed Price</Label></td>
                                            <td className="text-right"><PriceFormatter price={propertyCompData.close_price ? propertyCompData.close_price : 0} /></td>
                                            <td className="text-right"><PriceFormatter price={propertyCompData.pr_LastSalePrice ? propertyCompData.pr_LastSalePrice : 0} /></td>
                                        </tr>
                                        <tr className={propertyCompData.is_main_sqft_discrepancies === true ? "difference" : null}>
                                            <td><Label for="example">Main Sqft</Label></td>
                                            <td className="text-right"><NumberFormatter number={propertyCompData.living_area_square_feet ? (propertyCompData.living_area_square_feet - propertyCompData.basement_square_feet) : 0} /></td>
                                            <td className="text-right"><NumberFormatter number={propertyCompData.county_pr_LivingArea ? propertyCompData.county_pr_LivingArea : 0} /></td>
                                        </tr>
                                        <tr className={propertyCompData.is_finished_basement_discrepancies === true ? "difference" : null}>
                                            <td><Label for="example">Finished Basement</Label></td>
                                            <td className="text-right"><NumberFormatter number={propertyCompData.basement_square_feet ? propertyCompData.basement_square_feet : 0} /></td>
                                            <td className="text-right"><NumberFormatter number={propertyCompData.county_pr_BelowGradeFinishedArea ? propertyCompData.county_pr_BelowGradeFinishedArea : 0} /></td>
                                        </tr>
                                        <tr className={propertyCompData.is_total_sqft_discrepancies === true ? "difference" : null}>
                                            <td><Label for="example">Total Sqft</Label></td>
                                            <td className="text-right"><NumberFormatter number={propertyCompData.living_area_square_feet} /></td>
                                            <td className="text-right"><NumberFormatter number={(parseInt(propertyCompData.pr_LivingArea) + parseInt(propertyCompData.pr_BelowGradeFinishedArea)) ? (parseInt(propertyCompData.pr_LivingArea) + parseInt(propertyCompData.pr_BelowGradeFinishedArea)) : 0} /></td>
                                        </tr>
                                        <tr className={propertyCompData.is_beds_discrepancies === true ? "difference" : null}>
                                            <td><Label for="example">Beds</Label></td>
                                            <td className="text-right">{propertyCompData.bedrooms_total ? propertyCompData.bedrooms_total : 0}</td>
                                            <td className="text-right">{propertyCompData.pr_BedroomsTotal ? propertyCompData.pr_BedroomsTotal : 0}</td>
                                        </tr>
                                        <tr className={propertyCompData.is_baths_discrepancies === true ? "difference" : null}>
                                            <td><Label for="example">Bath Rooms</Label></td>
                                            <td className="text-right">{propertyCompData.bathrooms_total ? propertyCompData.bathrooms_total : 0}</td>
                                            <td className="text-right">{propertyCompData.pr_BathroomsTotalInteger ? (propertyCompData.pr_BathroomsTotalInteger).toFixed(2) : 0.00}</td>
                                        </tr>
                                        <tr className={propertyCompData.is_year_built_discrepancies === true ? "difference" : null}>
                                            <td><Label for="example">Year Built</Label></td>
                                            <td className="text-right">{propertyCompData.year_built ? propertyCompData.year_built : 0}</td>
                                            <td className="text-right">{propertyCompData.pr_YearBuilt ? propertyCompData.pr_YearBuilt : 0}</td>
                                        </tr>
                                        <tr>
                                            <td><Label for="example">Stories</Label></td>
                                            <td className="text-right">{propertyCompData.stories_total ? propertyCompData.stories_total : 0}</td>
                                            <td className="text-right">0</td>
                                        </tr>
                                        <tr className={propertyCompData.is_lot_size_discrepancies === true ? "difference" : null}>
                                            <td><Label for="example">Lot Size (sqft)</Label></td>
                                            <td className="text-right">{propertyCompData.lot_size_square_feet}</td>
                                            <td className="text-right">{propertyCompData.pr_LotSizeSquareFeet}</td>
                                        </tr>
                                        <tr>
                                            <td><Label for="example">Garage</Label></td>
                                            <td className="text-right">{propertyCompData.garage ? propertyCompData.garage : 0}</td>
                                            <td className="text-right">{propertyCompData.pr_GarageSpaces ? propertyCompData.pr_GarageSpaces : 0}</td>
                                        </tr>
                                        <tr>
                                            <td><Label for="example">Property Type</Label></td>
                                            <td className="text-right">{propertyCompData.orig_property_type_normalized ? propertyCompData.orig_property_type_normalized : ""}</td>
                                            <td className="text-right">{propertyCompData.orig_property_type_normalized ? propertyCompData.orig_property_type_normalized : ""}</td>
                                        </tr>
                                        <tr>
                                            <td><Label for="example">Pool</Label></td>
                                            <td className="text-right">
                                                <Col className="custom-checkbox-container inline m-1">
                                                    <label className="checkbox-div">
                                                        <input type="checkbox"
                                                            checked={propertyCompData.pool && parseInt(propertyCompData.pool) === 1 ? true : false}
                                                            onChange={() => { }}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </Col>
                                            </td>
                                            <td className="text-right">
                                                <Col className="custom-checkbox-container inline m-1">
                                                    <label className="checkbox-div pr-0 mr--3">
                                                        <input type="checkbox" checked={true} onChange={() => { }} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </Col>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Label for="example">Waterfront</Label></td>
                                            <td className="text-right">
                                                <Col className="custom-checkbox-container inline m-1">
                                                    <label className="checkbox-div">
                                                        <input type="checkbox"
                                                            checked={propertyCompData.key_flags_water_front && parseInt(propertyCompData.key_flags_water_front) === 1 ? true : false}
                                                            onChange={() => { }}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </Col>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td><Label for="example">Golf Course</Label></td>
                                            <td className="text-right">
                                                <Col className="custom-checkbox-container inline m-1">
                                                    <label className="checkbox-div">
                                                        <input type="checkbox"
                                                            checked={propertyCompData.key_flags_golf_course && parseInt(propertyCompData.key_flags_golf_course) === 1 ? true : false}
                                                            onChange={() => { }}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </Col>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <CompList props={propDataForCompList} />
                        </div>
                        <div className="col-md-3 ">
                            <div className="comp-column-body">
                                <AvForm onValidSubmit={(e) => handlePropertySubmit(e)} >
                                    <Row className="mb-2">
                                        <Col md="6">
                                            <h2>&nbsp;</h2>
                                        </Col>
                                        <Col md="6">
                                            <h2>My Values</h2>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <Label for="example">Above Grade</Label>
                                        </Col>
                                        <Col md="6">
                                            <AvGroup>
                                                <AvField
                                                    name="above_grade_sqft"
                                                    onChange={(event) => handleInput(event)}
                                                    className="form-control-alternative form-control"
                                                    value={fields.above_grade_sqft}
                                                    placeholder=""
                                                    type="text"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "This field is required."
                                                        },
                                                        number: {
                                                            value: true,
                                                            errorMessage: "Enter only number value."
                                                        },
                                                        min: {
                                                            value: 450,
                                                            errorMessage: "This value should be between 450 and 15000."
                                                        },
                                                        max: {
                                                            value: 15000,
                                                            errorMessage: "This value should be between 450 and 15000."
                                                        }
                                                    }}
                                                />
                                                {/*  */}
                                            </AvGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <Label for="example">Below Grade</Label>
                                        </Col>
                                        <Col md="6">
                                            <AvGroup>
                                                <AvField
                                                    name="below_grade_sqft"
                                                    onChange={(event) => handleInput(event)}
                                                    className="form-control-alternative form-control"
                                                    value={fields.below_grade_sqft}
                                                    placeholder=""
                                                    type="text"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "This field is required."
                                                        },
                                                        number: {
                                                            value: true,
                                                            errorMessage: "Enter only number value."
                                                        }
                                                    }}
                                                />

                                            </AvGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <Label for="example">Total Sqft</Label>
                                        </Col>
                                        <Col md="6">
                                            <AvGroup>
                                                <AvField
                                                    name="total_sqft"
                                                    onChange={(event) => handleInput(event)}
                                                    className="form-control-alternative form-control disabled-input"
                                                    value={(Number(fields.above_grade_sqft) + Number(fields.below_grade_sqft)) >= 0 ? (Number(fields.above_grade_sqft) + Number(fields.below_grade_sqft)).toFixed(0) : ""}
                                                    placeholder=""
                                                    type="text"
                                                    disabled
                                                />

                                            </AvGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <Label for="example">Beds</Label>
                                        </Col>
                                        <Col md="6">
                                            <AvGroup>
                                                <AvField
                                                    name="beds"
                                                    onChange={(event) => handleInput(event)}
                                                    className="form-control-alternative form-control"
                                                    value={fields.beds}
                                                    placeholder=""
                                                    type="text"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "This field is required."
                                                        },
                                                        number: {
                                                            value: true,
                                                            errorMessage: "Enter only number value."
                                                        },
                                                        pattern: {
                                                            value: '^[0-9\b]+$',
                                                            errorMessage: 'Enter only number value, decimal value not allowed.'
                                                        },
                                                        min: {
                                                            value: 0,
                                                            errorMessage: `This value is not valid.`
                                                        },
                                                        max: {
                                                            value: 16,
                                                            errorMessage: `This value should be less than or equal to 16.`
                                                        }
                                                    }}
                                                />

                                            </AvGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <Label for="example">Baths</Label>
                                        </Col>
                                        <Col md="6">
                                            <AvGroup>
                                                <AvField
                                                    name="baths"
                                                    onChange={(event) => handleInput(event)}
                                                    className="form-control-alternative form-control"
                                                    value={fields.baths}
                                                    placeholder=""
                                                    type="text"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "This field is required."
                                                        },
                                                        number: {
                                                            value: true,
                                                            errorMessage: "Enter only number value."
                                                        },
                                                        min: {
                                                            value: 1,
                                                            errorMessage: `This value should be between 1 and 16.`
                                                        },
                                                        max: {
                                                            value: 16,
                                                            errorMessage: `This value should be between 1 and 16.`
                                                        }
                                                    }}
                                                />

                                            </AvGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <Label for="example">Year Built</Label>
                                        </Col>
                                        <Col md="6">
                                            <AvGroup>
                                                <AvField
                                                    name="year_built"
                                                    onChange={(event) => handleInput(event)}
                                                    className="form-control-alternative form-control"
                                                    value={fields.year_built}
                                                    placeholder=""
                                                    type="text"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "This field is required."
                                                        },
                                                        number: {
                                                            value: true,
                                                            errorMessage: "Enter only number value."
                                                        },
                                                        pattern: {
                                                            value: '^[0-9\b]+$',
                                                            errorMessage: 'Enter only number value, decimal value not allowed.'
                                                        },
                                                        min: {
                                                            value: 1701,
                                                            errorMessage: `This value should be between 1701 and ${year}.`
                                                        },
                                                        max: {
                                                            value: year,
                                                            errorMessage: `This value should be between 1701 and ${year}.`
                                                        }
                                                    }}
                                                />

                                            </AvGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <Label for="example">Stories</Label>
                                        </Col>
                                        <Col md="6">
                                            <AvGroup>
                                                <AvField
                                                    name="stories"
                                                    onChange={(event) => handleInput(event)}
                                                    className="form-control-alternative form-control"
                                                    value={fields.stories}
                                                    placeholder=""
                                                    type="text"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "This field is required."
                                                        },
                                                        number: {
                                                            value: true,
                                                            errorMessage: "Enter only number value."
                                                        },
                                                        min: {
                                                            value: 1,
                                                            errorMessage: `This value should be between 1 and 4.`
                                                        },
                                                        max: {
                                                            value: 4,
                                                            errorMessage: `This value should be between 1 and 4.`
                                                        }
                                                    }}
                                                />

                                            </AvGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <Label for="example">Lot Size</Label>
                                        </Col>
                                        <Col md="6">
                                            <AvGroup>
                                                <AvField
                                                    name="lot_size"
                                                    onChange={(event) => handleInput(event)}
                                                    className="form-control-alternative form-control"
                                                    value={fields.lot_size}
                                                    placeholder=""
                                                    type="text"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "This field is required."
                                                        },
                                                        number: {
                                                            value: true,
                                                            errorMessage: "Enter only number value."
                                                        },
                                                        min: {
                                                            value: handleLotSizeError(),
                                                            errorMessage: `This value should be between ${handleLotSizeError()} and 5000000.`
                                                        },
                                                        max: {
                                                            value: 5000000,
                                                            errorMessage: `This value should be between ${handleLotSizeError()} and 500000.`
                                                        }
                                                    }}
                                                />

                                            </AvGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6"><Label for="example">Garage</Label></Col>
                                        <Col md="6">
                                            <AvGroup>
                                                <AvField name="garage" onChange={(event) => handleInput(event)} className="form-control-alternative form-control disabled-input" value={fields.garage ? fields.garage : 0} placeholder="" type="text" validate={{
                                                    required: { value: true, errorMessage: "This field is required." }, number: { value: true, errorMessage: "Enter only number value." },
                                                    min: { value: 0, errorMessage: "This value should be between 0 and 10." },
                                                    max: { value: 10, errorMessage: "This value should be between 0 and 10." }
                                                }} />
                                            </AvGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <Label for="example">Property Type</Label>
                                        </Col>
                                        <Col md="6">
                                            <AvGroup>
                                                <AvField
                                                    name="property_type"
                                                    className="form-control-alternative form-control"
                                                    placeholder=""
                                                    value={fields.property_type}
                                                    type="select"
                                                    onChange={(event) => handleInput(event)}
                                                >
                                                    <option>Select Property Type</option>
                                                    {propertyCompData.property_types ? propertyCompData.property_types.map((e, key) => {
                                                        return <option key={key} value={e.property_type_normalized}>{e.property_type_normalized}</option>
                                                    }) : null}

                                                </AvField>

                                            </AvGroup>
                                        </Col>
                                    </Row>
                                    <Row className="mb-1">
                                        <Col md="6">
                                            <Label for="example">Pool</Label>
                                        </Col>
                                        <Col md="4" className="custom-checkbox-container inline m-1">
                                            <label className="checkbox-div">
                                                <input type="checkbox"
                                                    name="pool"
                                                    id="pool"
                                                    checked={pool && parseInt(pool) === 1 ? true : false}
                                                    onChange={(e) => handlePoolChange(e.target.checked)}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row className="mb-1">
                                        <Col md="6">
                                            <Label for="example">Waterfront</Label>
                                        </Col>
                                        <Col md="4" className="custom-checkbox-container inline m-1">
                                            <label className="checkbox-div">
                                                <input type="checkbox"
                                                    name="waterfront_checkbox"
                                                    id="waterfront_checkbox"
                                                    checked={waterFront && parseInt(waterFront) === 1 ? true : false}
                                                    onChange={(e) => handleWaterFrontChange(e.target.checked)}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </Col>
                                    </Row>

                                    <Row className="mb-2">
                                        <Col md="6">
                                            <Label for="example">Golf Course</Label>
                                        </Col>
                                        <Col md="4" className="custom-checkbox-container inline m-1">
                                            <label className="checkbox-div">
                                                <input type="checkbox"
                                                    name="golf_course_checkbox"
                                                    id="golf_course_checkbox"
                                                    checked={golfCourse && parseInt(golfCourse) === 1 ? true : false}
                                                    onChange={(e) => handleGolfCourseChange(e.target.checked)}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" className="text-right mt-2">
                                            <Button size="sm" color="danger" outline type="button" onClick={(e) => updateStumpTask(e, propertyCompData.task_id)}>
                                                Stumped
                                            </Button>
                                            <Button size="sm" color="primary">
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                </AvForm>
                            </div>

                        </div>
                    </div>

                </div>
                <div className="modal-footer"></div>
            </Modal>
        </>
    );
};

export default GoogleApiWrapper({
    apiKey: userData.google_geocoding_api_key
})(PropertyCompDiscrepancyButton);