import React, { useState, useEffect } from "react";
import { Modal, Row, Col, CardBody, FormGroup, Button } from "reactstrap";
import { AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import UsersByRole from "../UsersByRole";
import { sendExceptionEmail } from "components/Common/Helpers";
import * as moment from "moment";
import { loggedInUser } from "components/Common/Helpers";
import { checkRoles } from "components/Common/Helpers";
import { toast } from "react-toastify";
import { sleep } from "components/Common/Helpers";

const AssignAnalystModal = ({ analystAssignModal, selectedOrders, onSubmitUserAssignForm }) => {
    const [isResumeModal, setOpenResumeModal] = useState(false);
    const [fields, setFields] = useState({});
    const [update, setUpdate] = useState(moment());
    const [customErrors, setCustomErrors] = useState([]);
    const userData = loggedInUser();
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        if (analystAssignModal.current) {
            setOpenResumeModal(true);
            setIsProcessing(false)
        }
    }, [analystAssignModal.current]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { }, [update]);

    // Handle modal
    const handleToggleModal = (state) => {
        if (!isProcessing) {
            state = !state;
            setOpenResumeModal(state);
            analystAssignModal.current = false;
        }
    };

    // Handle input and store data
    const handleInput = (event) => {
        if (event.target.name === "assign_to" && event.target.value !== "2") {
            fields["user_id"] = userData.user_id;
            fields['roles'] = userData.roles
        }
        fields[event.target.name] = event.target.value;
        setFields(fields)
        setUpdate(moment());
    };

    // Handle user selected dropdown
    const onUserSelected = async (response) => {
        try {
            if (response.data) {
                fields['user_id'] = response.data[0].user_id
                fields['roles'] = response.data[0].roles.map((item) => item.role_id);
                setFields(fields)

                const index = customErrors.indexOf("select_user");
                if (index > -1) {
                    customErrors.splice(index, 1);
                };
            } else {
                customErrors.push('select_user');
            }
            setCustomErrors(customErrors);
            setUpdate(moment());
        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message)
            await sendExceptionEmail(error)
        };
    }

    // Save assign report form
    const handleSubmit = async (response) => {
        try {
            let customErrorsTemp = [];
            if (!fields.user_id) {
                customErrorsTemp.push('select_user');
                setCustomErrors(customErrorsTemp);
                return false
            }
            setIsProcessing(true)
            setUpdate(moment());
            await sleep(0.5)
            onSubmitUserAssignForm(fields)
        } catch (errors) {
            await sendExceptionEmail(errors);
        }
    }

    return (
        <>
            <Modal className="modal-dialog-centered resume-select-option-modal assign-analyst-modal" style={{ maxWidth: "530px" }} isOpen={analystAssignModal.current} toggle={() => handleToggleModal(isResumeModal)}>
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title text-center w-100">
                        Select a Option
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isResumeModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <AvForm onValidSubmit={(e) => handleSubmit(e)} model={fields}>
                    <CardBody className="pb-0">
                        <Row className="mb-2">
                            <Col className="custom-av-radio-btn inline">
                                <div>
                                    <AvRadioGroup className="radio-group mb-0" name="assign_to" required>
                                        {!(checkRoles(["ACCOUNT_REP"])) && <AvRadio onChange={e => handleInput(e)} label="Assign to me" value="1" />}
                                        <AvRadio onChange={e => handleInput(e)} label="Assign to other user" value="2" />
                                        {selectedOrders?.length > 0 && <AvRadio onChange={e => handleInput(e)} label="Unassign Order" value="3" />}
                                    </AvRadioGroup>
                                </div>
                            </Col>
                        </Row>
                        <Row className={fields.assign_to === "2" ? 'mb-3' : 'mb-3 hide'} >
                            <Col className="">
                                <div>
                                    <UsersByRole size="sm" defaultSelection={[]} label={"Select User"} isMultiSelect={false} onUserSelected={onUserSelected} />
                                    {(customErrors.includes('select_user')) && <span className="form-error">This field is required.</span>}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12" className="text-right mt-2">
                                <FormGroup>
                                    {isProcessing ?
                                        <Button type="button" color="warning" size="sm">
                                            <div className="fa fa-spinner fa-spin"></div> Processing...
                                        </Button>
                                        :
                                        <>
                                            <Button size="sm" color="danger" outline onClick={() => handleToggleModal(isResumeModal)}>
                                                Close
                                            </Button>
                                            <Button size="sm" color="primary"  >
                                                Submit
                                            </Button>
                                        </>
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </AvForm>
            </Modal>
        </>
    );
};

export default AssignAnalystModal;