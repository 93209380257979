import { generateRandomString } from "components/Common/Helpers";
import React from "react";
import { useState } from "react";
import { Button, UncontrolledTooltip } from "reactstrap";

const RenderActionButton = ({ propButtonOptions, propButtonTitle, propOnClickEvent }) => {

    const [uniqueId] = useState(generateRandomString(15));

    return (
        <>
            <Button
                color={propButtonOptions?.color ? propButtonOptions.color : "success"}
                outline={(propButtonOptions?.outline === 'true' ? true : (propButtonOptions?.outline === 'false' ? false : true))}
                block={(propButtonOptions?.block === 'true' ? true : (propButtonOptions?.block === 'false' ? false : false))}
                size={propButtonOptions?.size ? propButtonOptions.size : 'sm'}
                type="button"
                id={"action_button_" + uniqueId}
                onClick={e => propOnClickEvent(e)}
            >
                {propButtonOptions?.faClass && <i className={`${propButtonOptions?.faClass}`} aria-hidden="true"></i>}{propButtonOptions?.label ? ` ${propButtonOptions.label}` : null}
            </Button>
            <UncontrolledTooltip delay={0} placement="auto" target={"action_button_" + uniqueId} >{propButtonTitle}</UncontrolledTooltip>

        </>
    );
};

export default RenderActionButton;