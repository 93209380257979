import React, { useState, useEffect } from "react";
import { Button, Modal } from "reactstrap";
import * as moment from "moment";

const EarlierDueDateOrdersModal = ({ isOpenModal, onConfirmation }) => {

    const [isOpenCancelReportOrderModal, setIsOpenCancelReportOrderModal] = useState(false);
    const [update, setUpdate] = useState(moment());

    useEffect(() => { }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isOpenModal.current) {
            handleActionClick()
        }
    }, [isOpenModal.current]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleActionClick = (e, row) => {
        setIsOpenCancelReportOrderModal(true);
    };

    const handleToggleModal = (state) => {
        state = !state;
        isOpenModal.current = state
        setIsOpenCancelReportOrderModal(state);
        setUpdate(moment());
    };

    return (
        <>
            {/* Cancel report order Modal */}
            <Modal className="modal-dialog-centered" isOpen={isOpenCancelReportOrderModal} toggle={() => handleToggleModal(isOpenCancelReportOrderModal)}>
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title text-center w-100">
                        Please Read Below Message
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenCancelReportOrderModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body" >
                    <div className="text-left">
                        There are orders with earlier due dates which are preferred to work on those first.
                    </div>
                    <div><br /></div>
                    <div className="text-left">
                        Are you sure that you need to work on this file now?
                    </div>
                </div>
                <div className="modal-footer" >
                    <Button aria-label="Close" color="success" type="button" onClick={() => { onConfirmation(); handleToggleModal(isOpenCancelReportOrderModal); }}>
                        YES
                    </Button>

                    <Button aria-label="Close" color="primary" type="button" onClick={() => handleToggleModal(isOpenCancelReportOrderModal)}>
                        NO
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default EarlierDueDateOrdersModal;