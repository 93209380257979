import React, { Fragment, useState } from 'react';
import Spinner from "components/Common/Spinner.js";
import { Redirect, Link } from 'react-router-dom';
import { loginAndRedirectToPHP } from 'services/PHP.service';
import { toast } from 'react-toastify';

const AutoLoginDecrypt = ({ data, style }) => {
    const [isProcess, setProcess] = useState(false);
    const [isNotUserData, setNotUserData] = useState(false);

    if (isNotUserData) {
        return <Redirect to="auth/login" />
    };

    const handleRedirect = async (e) => {
        e.preventDefault();
        try {
            setProcess(true);
            await loginAndRedirectToPHP(data);
            setProcess(false);
        } catch (error) {
            setNotUserData(true);
            toast["error"]("Something went wrong!");
            setProcess(false);
        }
    };

    return (
        <>
            <Link to="#" className='custom-link' onClick={handleRedirect} target="_blank" style={style ? style : null}>{data.label}</Link>
            <Spinner isShow={isProcess} />
        </>
    )
}
export default AutoLoginDecrypt;