import React from "react";
import { Button, Col, FormGroup, Label, Modal, Row } from "reactstrap";
import { useState } from "react";

import { PostData } from "services/Api.service";
import { GetData as OrderIntakeGet } from "services/OrderIntakeData";
import { GetData } from "services/Api.service";

import { toast } from "react-toastify";
import eventBus from "../../EventBus";
import { AvField, AvForm, AvGroup, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import * as moment from "moment";
import { useEffect } from "react";
import { sendExceptionEmail } from "components/Common/Helpers";
import RenderActionButton from "../RenderActionButton";
import { loggedInUser } from "components/Common/Helpers";
import { IO_CANCELLED, IO_COMPLETED, RO_BUDGET_UPDATED, RO_DISPUTE_INITIATED, RO_MARKET_UPDATE, RO_NEW_SPECS, RO_REPORT_CANCELLED, RO_REVISION_REQUESTED } from "../../Constants";
import { checkRoles } from "components/Common/Helpers";
import { checkRolesWithLevel } from "components/Common/Helpers";
import { formatDate } from "components/Common/Helpers";
import ReportOrderOriginalSource from "../../ReportOrderOriginalSource";
import PaymentForm from "../../PaymentForm";
import DragAndDropOrUpload from "../../File-Upload/DragAndDropOrUpload";
import Spinner from "components/Common/Spinner";
import ValuationReviewRequestModal from "../../DisputeOrder/ValuationReviewRequestModal";

const ReportOrderReopenButton = ({ propRowData, propButtonOptions }) => {
    const userData = loggedInUser();

    let [isProcess, setProcess] = useState(false);
    let [isOpenReopenFilesModal, setOpenReopenFilesModal] = useState(false);
    let [reopenStatus, setReopenStatus] = useState("");
    let [questionComment, setQuestionComment] = useState("");
    let [turnAroundTime, setTurnAroundTime] = useState("");
    let [tat, setTat] = useState([]);
    let [reopenData, setReopenData] = useState([]);
    let [showStripeMsg, setShowStripeMsg] = useState(false);
    let [showRefundMsg, setShowRefundMsg] = useState(false);
    let [totalCharge, setTotalCharge] = useState(0);
    let [selectedDisputeFiles, setSelectedDisputeFiles] = useState([]);

    // let [maxBudgetFileSize] = useState(5);
    let [selectedBudgetFiles, setSelectedBudgetFiles] = useState([]);

    // let [maxPhotoFileSize] = useState(15);
    let [selectedPhotoFiles, setSelectedPhotoFiles] = useState([]);

    // let [maxInspectionFileSize] = useState(20);
    let [selectedInspectionFiles, setSelectedInspectionFiles] = useState([]);

    let [updateOrKeepInspection, setUpdateOrKeepInspection] = useState("0");
    let [update, setUpdate] = useState(moment());
    let [isReopenInspection, setReopenInspection] = useState("");
    let [inspectionTypeData, setInspectionTypeData] = useState({});

    let [inspectionType, setInspectionType] = useState(null);
    let [inspectionOptionType, setInspectionOptionType] = useState(null);
    let [eligibleInspectionTypes, setEligibleInspectionTypes] = useState([]);
    let [isContactInfoRequired, setContactInfoRequired] = useState(false);
    let [fields, setFields] = useState({});

    let [isOpenPaymentSourceModal, setIsOpenPaymentSourceModal] = useState({ current: false });
    let [clientId, setClientId] = useState();
    let [selectedPaymentSource, setSelectedPaymentSource] = useState(null);
    let [paymentError, setPaymentError] = useState(null);
    let [defaultSourceChecked, setDefaultSourceChecked] = useState(false);
    let [doPaymentProcess, setDoPaymentProcess] = useState(null);
    let [glaAndLicensingSettings, setGlaAndLicensingSettings] = useState([]);

    let [isDisputeModal, setDisputeModal] = useState(false);
    let [orderData, setOrderData] = useState({});

    let defaultValues = {
        vendor_id: null,
        inspection_type: null,
        contact_name: propRowData.property_access_contacts.length ? propRowData.property_access_contacts[0].contact_name : '',
        contact_email: propRowData.property_access_contacts.length ? propRowData.property_access_contacts[0].contact_email : '',
        contact_phone: propRowData.property_access_contacts.length ? propRowData.property_access_contacts[0].contact_phone : '',
    };

    useEffect(() => {
        setSelectedPaymentSource(null);
        setPaymentError(null);
        setDefaultSourceChecked(false);
        setEligibleInspectionTypes([]);
        setClientId(propRowData.company_id);
    }, [propRowData]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
    }, [update]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let asyncFuncs = async () => {
            if (inspectionType) {
                await getInspectionsFees(inspectionType);
            }
        }
        asyncFuncs()
    }, [inspectionType, fields?.gla_include, fields?.licensing_required]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        showHideStripeMessage();
    }, [inspectionTypeData, reopenData, doPaymentProcess]);// eslint-disable-line react-hooks/exhaustive-deps

    let handleToggleModal = (state) => {
        state = !state;

        setOpenReopenFilesModal(state);
        setShowStripeMsg(false);
        setShowRefundMsg(false);
        setPaymentError(null);
        setDefaultSourceChecked(false);
        setInspectionTypeData({});
        setEligibleInspectionTypes([]);
        setReopenInspection(null)
        setInspectionOptionType(null)
        setDoPaymentProcess(null);
        setSelectedPaymentSource(null)

        setSelectedDisputeFiles([]);

        setSelectedBudgetFiles([]);
        setSelectedPhotoFiles([]);
        setSelectedInspectionFiles([]);

        setSelectedDisputeFiles([]);

        setSelectedBudgetFiles([]);
        setSelectedPhotoFiles([]);
        setSelectedInspectionFiles([]);

        defaultValues.inspection_type = '0';
        setUpdate(moment());
    };

    let handleStatusTypeBtn = (id) => {
        setEligibleInspectionTypes([]);
        setInspectionTypeData({});
        setTat([]);
        setReopenStatus(id);

        setSelectedDisputeFiles([]);

        setSelectedBudgetFiles([]);
        setSelectedPhotoFiles([]);
        setSelectedInspectionFiles([]);

        if (RO_DISPUTE_INITIATED === parseInt(id)) {
            setDisputeModal(true);
            orderData.id = propRowData.id;
            orderData.property_id = propRowData.property_id;
            orderData.company_id = propRowData.company_id;
            setOrderData(orderData);
        }

        setUpdate(moment());
    }

    let getReportOrderFees = async (val, action_id) => {
        try {
            setEligibleInspectionTypes([]);
            handleStatusTypeBtn(val);
            setShowStripeMsg(false);
            setShowRefundMsg(false);
            setReopenInspection(null)
            setSelectedPaymentSource(null);
            setTat([]);
            setReopenStatus(action_id);
            setProcess(true);
            let data = {
                id: propRowData.id,
                action_id: action_id,
            }

            let result = await PostData("orders/report_order_fees", data);
            if (result.data) {
                let reportFeesData = {
                    amount: result.data.orderAmount,
                }
                setReopenData(reportFeesData);
            }
            setProcess(false);
        } catch (error) {
            setProcess(false);
            toast["error"](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error)
        }
    };

    let handleReopenFilesModal = async (e) => {
        e.preventDefault();
        if ((reopenStatus || [RO_REPORT_CANCELLED].includes(propRowData.order_status)) && propRowData.id) {
            try {
                let tatValues = turnAroundTime.split("/");
                let current_inspection_type = null;
                if (propRowData.inspection_order) {
                    current_inspection_type = propRowData.inspection_order.inspection_type?.slug;
                }

                let params = {};
                const data = new FormData();
                if (selectedDisputeFiles.length) {
                    data.append(`dispute_files`, selectedDisputeFiles[0]);
                }
                if (selectedBudgetFiles.length) {
                    data.append(`budget_files`, selectedBudgetFiles[0]);
                }
                if (selectedInspectionFiles.length) {
                    data.append(`inspection_files`, selectedInspectionFiles[0]);
                }
                if (selectedPhotoFiles.length) {
                    data.append(`photo_files`, selectedPhotoFiles[0]);
                }

                params.id = propRowData.id;
                params.property_id = propRowData.property_id;
                params.action_id = reopenStatus;
                params.user_id = userData.user_id;
                params.question_comment = questionComment;
                params.tat_type = (tatValues[0] === 'Standard') ? 'standard' : 'rush';
                params.tat_days = tatValues[1];
                params.cancel_reopen_inspection_option_type = isReopenInspection;
                params.completed_inspection_update_or_keep = updateOrKeepInspection;
                if (isReopenInspection === 'No' || isReopenInspection === 'choose_different' || inspectionOptionType === 'updated_inspection') {
                    params.inspection_type = inspectionType ? inspectionType : current_inspection_type;
                }

                if (inspectionOptionType === 'updated_inspection' || isReopenInspection === 'No' || isReopenInspection === 'choose_different') {
                    params.order_new_inspection_amt = inspectionTypeData.totalVariance;
                    params.order_new_inspection = 'true';
                } else {
                    params.order_new_inspection = 'false';
                }

                params.inspections_status = propRowData.inspection_order.inspection_status;
                params.inspection_order_id = propRowData.inspection_order.id;

                let fieldsTemp = { ...fields }

                fieldsTemp.gla_include = fieldsTemp.gla_applicable === '1' ? fieldsTemp.gla_include : '0'
                fieldsTemp.licensing_required = fieldsTemp.licensing_applicable === '1' ? fieldsTemp.licensing_required : '0'

                params.other_fields = fieldsTemp;

                params.do_payment_process = doPaymentProcess;

                if (selectedPaymentSource && selectedPaymentSource.payment_source) {
                    for (let key in selectedPaymentSource.payment_source) {
                        data.append('payment_source[' + key + ']', selectedPaymentSource.payment_source[key]);
                    }
                }

                if (fields) {
                    for (let currentField in fields) {
                        data.append('other_fields[' + currentField + ']', fields[currentField]);
                    }
                }

                for (let currentField in params) {
                    data.append(currentField, params[currentField]);
                }

                setProcess(true);
                let result = await PostData("orders/reopen_files", data);

                setProcess(false);
                toast["success"](result.message);
                eventBus.dispatch("report_order_status_updated", { client_id: propRowData.company_id });
                setOpenReopenFilesModal(false);
            } catch (error) {
                setProcess(false);
                if (error?.errors?.err_code === 'PAYMENT_ERROR') {
                    setPaymentError(error.message);
                    setSelectedPaymentSource(null);
                    setUpdate(moment());
                } else {
                    await sendExceptionEmail(error)
                    toast["error"]("Something went wrong.");
                }
            }
        } else {
            setProcess(false);
            toast["error"]("Something went wrong.");
        }
    };


    let handleUpdateInspection = (updateInspectionType, ven_id) => {
        setReopenInspection(updateInspectionType);
        setInspectionTypeData(null);
        if (updateInspectionType === "No") {
            onHandleVendorChange(ven_id);
        } else if (updateInspectionType === "choose_different") {
            onHandleVendorChange(ven_id);
        } else if (updateInspectionType === "same_inspection") {
            setShowStripeMsg(false);
            setShowRefundMsg(false);
            setInspectionTypeData({});
            setEligibleInspectionTypes([]);
            defaultValues.inspection_type = '0';
            setTat([]);
        } else if (updateInspectionType === "Yes") {
            setShowStripeMsg(false);
            setShowRefundMsg(false);
            setInspectionTypeData({});
            defaultValues.inspection_type = '0';
            setTat([]);
        } else {
            setInspectionType(null);
            setTat([]);
            setEligibleInspectionTypes([]);
            getReportOrderFees(parseInt(reopenStatus), reopenStatus, updateInspectionType)
        }
        setUpdate(moment());
    }

    // Get Tat
    let getTat = async (reportTypeSlug) => {
        try {
            if (reportTypeSlug) {
                let result = await OrderIntakeGet("tat", { company_token: "{company_token}", report_type: reportTypeSlug }, clientId);
                let responseData = result.data.tat;
                setTat(responseData);
            }
        } catch (error) {
            toast["error"](error.errors && error.errors.length ? error.errors[0].message : error.message);
        }
    }

    let onHandleVendorChange = async (vendor_id) => {
        if (parseInt(reopenStatus) === RO_MARKET_UPDATE) {
            getTat(propRowData.slug);
        }
        setInspectionTypeData({});
        setEligibleInspectionTypes([]);
        defaultValues.inspection_type = '0';
        setContactInfoRequired(false);
        if (vendor_id !== 0) {
            try {
                let params = {
                    vendor_id: vendor_id,
                    company_id: propRowData.company_id
                };

                if (propRowData?.isStandalone === '1') {
                    params.inspection_type = 'standalone-inspection'
                } else {
                    params.inspection_type = 'report-inspection'
                    params.report_type_id = propRowData.report_type_id
                }

                const result = await GetData("inspection/vendor-eligible-inspection-types", params)

                setEligibleInspectionTypes(result.data.inspectionTypes);
                setProcess(false);
            } catch (error) {
                setProcess(false);
                toast["error"]("Something went wrong!");
            }
        }
    };

    let onInspectionTypeChanged = async (val) => {
        let { slug, contact_info_required, gla_applicable, licensing_applicable } = val

        let fieldsTemp = fields

        fieldsTemp.gla_applicable = gla_applicable
        fieldsTemp.licensing_applicable = licensing_applicable

        setFields(fieldsTemp);

        setInspectionType(slug);
        setContactInfoRequired(contact_info_required === '1' ? true : false);
        setUpdate(moment());
    };

    let retrieveGLAAndLicensingSettings = async () => {
        let requestDataTemp = {};
        try {
            const glaAndLicensingSettingsResp = await OrderIntakeGet("company/gla-licensing-settings", { company_token: '{company_token}', }, propRowData.company_id);
            setGlaAndLicensingSettings(glaAndLicensingSettingsResp.data)

            if (!requestDataTemp['gla_include'] && (glaAndLicensingSettingsResp.data?.glaAndLicensingSettings?.gla_default === '1' || glaAndLicensingSettingsResp.data?.glaAndLicensingSettings?.gla_required === '1')) {
                requestDataTemp['gla_include'] = '1'
            }

            if (!requestDataTemp['licensing_required'] && (glaAndLicensingSettingsResp.data?.glaAndLicensingSettings?.licensed_default === '1' || glaAndLicensingSettingsResp.data?.glaAndLicensingSettings?.licensed_required === '1')) {
                requestDataTemp['licensing_required'] = '1'
            }

            return requestDataTemp;
        } catch (error) {
            toast["error"](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error);
        }
    };

    let onDataChange = (e) => {

        let { name, value, checked } = e.target;

        if (['gla_include', 'licensing_required'].includes(name)) {
            value = checked ? '1' : '0';
        }

        let fieldsTemp = { ...fields };
        fieldsTemp[name] = value

        setFields(fieldsTemp);
        setUpdate(moment());
    };

    let getInspectionsFees = async (inspection_type) => {

        let params = {
            inspection_order_type: "report-inspection",
            report_order_id: propRowData.id,
            new_inspection_type: inspection_type,
            gla_include: fields?.gla_applicable === '1' ? fields.gla_include : '0',
            licensing_required: fields?.licensing_applicable === '1' ? fields.licensing_required : '0',
        };

        setShowStripeMsg(false);
        setShowRefundMsg(false);

        try {
            defaultValues.inspection_type = '0';
            setProcess(true);

            const result = await PostData("inspection/price-comparison", params)

            let reportFeesData = {
                amount: result.data.totalVariance,
            }
            setUpdateOrKeepInspection(reportFeesData.amount);

            let doPaymentProcessTemp = null;
            if (parseFloat(reportFeesData.amount) > 0) {
                setShowStripeMsg(true);
                doPaymentProcessTemp = 'yes';
            } else if (parseFloat(reportFeesData.amount) < 0) {
                setShowRefundMsg(true);
                doPaymentProcessTemp = 'no';
            }

            setDoPaymentProcess(doPaymentProcessTemp)
            setInspectionTypeData(result.data);
            setProcess(false);
        } catch (errors) {
            setProcess(false);
            toast["error"](errors?.errors?.length ? errors.errors[0].message : errors.message);
        };

    };

    let showHideStripeMessage = async () => {
        setShowStripeMsg(false);
        setShowRefundMsg(false);

        // let ro_amt = reopenData?.amount ? reopenData.amount : 0
        let ro_amt = 0;
        if ((parseInt(reopenStatus) === RO_BUDGET_UPDATED || parseInt(reopenStatus) === RO_NEW_SPECS || parseInt(reopenStatus) === RO_MARKET_UPDATE)) {
            ro_amt = reopenData?.amount ? reopenData.amount : 0
        }

        let io_amt = doPaymentProcess === 'yes' ? (inspectionTypeData?.totalVariance ? inspectionTypeData.totalVariance : 0) : 0

        let total_charge = parseFloat(ro_amt) + parseFloat(io_amt);
        total_charge = parseFloat(total_charge).toFixed(2)

        setTotalCharge(total_charge);

        if (parseFloat(total_charge) > 0) {
            setShowStripeMsg(true);
        } else if (parseFloat(total_charge) < 0) {
            setShowRefundMsg(true);
        }
    };

    let openPaymentMethodSelector = () => {
        setSelectedPaymentSource(null);
        setIsOpenPaymentSourceModal({ current: true });
    };

    let handlePaymentSourceCb = (result) => {
        if (result) {
            setSelectedPaymentSource(result);
            setUpdate(moment());
        }
    };

    let onReportOrderOriginalSourceCB = (result) => {
        setSelectedPaymentSource(result);
        setDefaultSourceChecked(true);
        setUpdate(moment());
    };

    let onInspectionOptionChange = (e) => {
        // e.preventDefault();
        const { value } = e.target;

        setInspectionOptionType(value);

        if (value === 'updated_inspection') {
            onHandleVendorChange(propRowData.inspection_order.inspection_vendor.id);
        } else {
            onHandleVendorChange(0);
        }
    }

    let handleActionClick = async (e, type, data) => {
        e.preventDefault();
        try {
            setReopenStatus("");
            setOpenReopenFilesModal(true);
            setProcess(true);
            // if (propRowData.inspection_order.inspection_status === IO_CANCELLED) {
            //     await PostData("list/inspection_vendors_by_id", { ven_id: propRowData.inspection_order.inspection_vendor.id });
            // }

            let fieldsTemp = {
                contact_name: propRowData.property_access_contacts.length ? propRowData.property_access_contacts[0].contact_name : '',
                contact_email: propRowData.property_access_contacts.length ? propRowData.property_access_contacts[0].contact_email : '',
                contact_phone: propRowData.property_access_contacts.length ? propRowData.property_access_contacts[0].contact_phone : '',
            }

            fieldsTemp = { ...fieldsTemp, ...await retrieveGLAAndLicensingSettings() }
            fieldsTemp = { ...fieldsTemp }

            setFields(fieldsTemp);
            setUpdate(moment());
            setProcess(false);
        } catch (error) {
            setProcess(false);
            toast["error"](error.errors && error.errors.length ? error.errors[0].message : error.message);
        }
    };

    let getReviewRequestModal = (data) => {
        setDisputeModal(data.state);
        if (!data.state) {
            setOpenReopenFilesModal(data.state);
        }
    }

    let renderInspectionTypes = () => {
        return (
            <>
                <Row>
                    <Col >
                        <Label className="required">Inspection Type</Label>
                        <div className="custom-av-radio-btn inline">

                            <AvRadioGroup name="inspection_type" required >
                                {eligibleInspectionTypes.map(function (inspectionEligibility, i) {
                                    return (
                                        inspectionEligibility &&
                                        <AvRadio key={i} label={inspectionEligibility.name} value={inspectionEligibility.slug} onClick={(e) => onInspectionTypeChanged(inspectionEligibility)} />
                                    );
                                })}
                            </AvRadioGroup>
                        </div>
                    </Col>
                </Row>

                {fields?.gla_applicable === '1' &&
                    <Row>
                        <Col className="my-2">
                            <div color="info" className="custom-control custom-checkbox" sm="2">
                                <input
                                    className="custom-control-input"
                                    name="gla_include"
                                    id="gla_include"
                                    type="checkbox"
                                    value='1'
                                    onChange={(e) => onDataChange(e)}
                                    disabled={glaAndLicensingSettings?.glaAndLicensingSettings?.gla_required === '1'}
                                    checked={(fields?.gla_include === '1')}
                                />
                                <label className="custom-control-label" htmlFor="gla_include">
                                    Include GLA Measurement and Floorplan (${glaAndLicensingSettings?.glaAndLicensingCharges?.gla_surcharge})
                                </label>
                            </div>
                        </Col>
                    </Row>
                }

                {fields?.licensing_applicable === '1' &&
                    <Row>
                        <Col className="my-2">
                            <div color="info" className="custom-control custom-checkbox" sm="2">
                                <input
                                    className="custom-control-input"
                                    name="licensing_required"
                                    id="licensing_required"
                                    type="checkbox"
                                    value='1'
                                    onChange={(e) => onDataChange(e)}
                                    disabled={glaAndLicensingSettings?.glaAndLicensingSettings?.licensed_required === '1'}
                                    checked={fields?.licensing_required === '1'}
                                />
                                <label className="custom-control-label" htmlFor="licensing_required">
                                    Require licensed agents only (${glaAndLicensingSettings?.glaAndLicensingCharges?.licensing_surcharge})
                                </label>
                            </div>
                        </Col>
                    </Row>
                }

                {isContactInfoRequired &&
                    <Row className="form-control-table-narrow">
                        <Col lg="12">
                            <h2>Property Access Contact Information</h2>
                        </Col>
                        <Col md="4" >
                            <AvGroup>
                                <AvField
                                    name={"contact_name"}
                                    onChange={(event) => setFields(prevState => ({
                                        ...prevState,
                                        contact_name: event.target.value,
                                    }))}
                                    className="form-control"
                                    placeholder="Contact Name"
                                    type="text"
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: "This field is required."
                                        },
                                        maxLength: {
                                            value: 50,
                                            errorMessage: 'Max length is 50'
                                        }
                                    }}
                                />
                            </AvGroup>
                        </Col>
                        <Col md="4" >
                            <AvGroup>
                                <AvField
                                    name={"contact_email"}
                                    onChange={(event) => setFields(prevState => ({
                                        ...prevState,
                                        contact_email: event.target.value,
                                    }))}
                                    className="form-control"
                                    placeholder="Contact Email"
                                    type="email"
                                    validate={{
                                        pattern: {
                                            value: '/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$/',
                                            errorMessage: 'Invalid Email'
                                        },
                                        maxLength: {
                                            value: 50,
                                            errorMessage: 'Max length is 50'
                                        }
                                    }}
                                />
                            </AvGroup>
                        </Col>
                        <Col md="4" >
                            <AvGroup>
                                <AvField
                                    name={"contact_phone"}
                                    onChange={(event) => setFields(prevState => ({
                                        ...prevState,
                                        contact_phone: event.target.value,
                                    }))}
                                    className="form-control"
                                    placeholder="Contact Phone"
                                    type="text"
                                    validate={{
                                        pattern: {
                                            value: '/^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{4})(?: *x(\\d+))?\\s*$/',
                                            errorMessage: 'Invalid phone number'
                                        }
                                    }}
                                />
                            </AvGroup>
                        </Col>
                    </Row>
                }
            </>
        )
    }

    return (
        <>
            {((checkRoles(['ADMINISTRATOR', 'PROCESSOR', 'SALES_REP', 'ACCOUNT_REP']) === true || checkRolesWithLevel(['REVIEWER'], ">=", 3))) && (propRowData && propRowData.id) &&
                <>
                    <RenderActionButton propButtonTitle="Reopen File" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />
                </>
            }

            {/* Reopen Files Modal */}

            <Modal className="modal-dialog-centered modal-lg reopen-files reopen-file-popup" isOpen={isOpenReopenFilesModal} toggle={() => handleToggleModal("isOpenReopenFilesModal")}>
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title w-100 text-center" id="reassignModalLabel">
                        Reopen Files
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal("isOpenReopenFilesModal")}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body text-left">
                    <AvForm onValidSubmit={(e) => handleReopenFilesModal(e)} >
                        <Spinner isShow={isProcess} />
                        {checkRoles(['ADMINISTRATOR', 'PROCESSOR', 'SALES_REP', 'ACCOUNT_REP']) &&
                            <>
                                {([RO_REPORT_CANCELLED].includes(propRowData.order_status) === true && parseInt(propRowData.inspection_order.inspection_status) === IO_CANCELLED) &&
                                    <>
                                        <Row>
                                            <Col className="custom-av-radio-btn inline">
                                                <p>The previous order included <b>{propRowData.inspection_order.inspection_type.name}</b> inspection. Would you like to reopen the inspection ? </p>
                                                <AvRadioGroup name="cancel_inspection" required onChange={e => handleUpdateInspection(e.target.value, propRowData.inspection_order.inspection_vendor.id)} >
                                                    <AvRadio label='Yes, the same inspection' value='same_inspection' />
                                                    <AvRadio label='No, choose a different inspection' value='choose_different' />
                                                    <AvRadio label="Don't order an inspection" value='none' />
                                                </AvRadioGroup>
                                            </Col>
                                        </Row>
                                    </>
                                }
                                {([RO_REPORT_CANCELLED].includes(propRowData.order_status) === true && parseInt(propRowData.inspection_order.inspection_status) === IO_COMPLETED) &&
                                    <>
                                        <Row>
                                            <Col className="custom-av-radio-btn inline">
                                                <p>The previous <b>{propRowData.inspection_order.inspection_type.name}</b> completed on <b>{formatDate(null, propRowData.inspection_order.last_update_date, "MMM D, YY h:mm a")}</b>. Would you like to order updated inspection OR keep existing inspection ? </p>
                                                <AvRadioGroup name="completed_inspection" required  >
                                                    <AvRadio label="Updated Inspection (Additional Charge)" value='updated_inspection' onChange={(e) => onInspectionOptionChange(e)} />
                                                    <AvRadio label='Keep existing ($0)' value='keep_existing' onChange={(e) => onInspectionOptionChange(e)} />
                                                </AvRadioGroup>
                                            </Col>
                                        </Row>
                                    </>
                                }

                                {eligibleInspectionTypes.length > 0 && parseInt(reopenStatus) !== RO_MARKET_UPDATE &&
                                    <>
                                        {renderInspectionTypes()}
                                    </>
                                }

                                {
                                    showRefundMsg ?
                                        <>
                                            <label >
                                                <b>${Math.abs(inspectionTypeData.totalVariance)}</b> will be refunded in your account soon.
                                            </label>
                                        </>
                                        : ''
                                }
                            </>
                        }

                        {/* Reopen report oder */}
                        {
                            ([RO_REPORT_CANCELLED].includes(propRowData.order_status) === false) && <>

                                <Row>
                                    <Col xs="12" sm="12" md="12">
                                        <h4 className="">Purpose of Reopening this file:</h4>
                                        <div className="custom-radio-btn inline">
                                            <div>
                                                <input type="radio" id="edits" name="action_id" value={RO_REVISION_REQUESTED} onChange={(e) => handleStatusTypeBtn(e.target.value)} />
                                                <label htmlFor="edits">Edits</label>
                                            </div>
                                            {parseInt(propRowData.renovation_budget_needed) === 1 &&
                                                <div>
                                                    <input type="radio" id="new_budget" name="action_id" value={RO_BUDGET_UPDATED} onChange={(e) => getReportOrderFees(e.target.value, RO_BUDGET_UPDATED)} />
                                                    <label htmlFor="new_budget">New Budget</label>
                                                </div>
                                            }
                                            <div>
                                                <input type="radio" id="new_specs" name="action_id" value={RO_NEW_SPECS} onChange={(e) => getReportOrderFees(e.target.value, RO_NEW_SPECS)} />
                                                <label htmlFor="new_specs">New Specs</label>
                                            </div>

                                            <div>
                                                <input type="radio" id="dispute" name="action_id" value={RO_DISPUTE_INITIATED} onChange={(e) => handleStatusTypeBtn(e.target.value)} />
                                                <label htmlFor="dispute">ROV</label>
                                            </div>

                                            <div>
                                                <input type="radio" id="market_update" name="action_id" value={RO_MARKET_UPDATE} onChange={(e) => getReportOrderFees(e.target.value, RO_MARKET_UPDATE)} />
                                                <label htmlFor="market_update">Market Update</label>
                                            </div>
                                        </div>
                                    </Col>
                                </Row >

                                {(parseInt(reopenStatus) === RO_MARKET_UPDATE) ? (
                                    <Row>
                                        <Col lg='12'>
                                            <Label className="required">Select option</Label>
                                        </Col>
                                        <Col className="custom-av-radio-btn inline mb--3 ml-0">
                                            <AvRadioGroup name="cancel_inspection" required onChange={e => handleUpdateInspection(e.target.value, (propRowData.inspection_order && propRowData.inspection_order.length > 0) ? propRowData.inspection_order.inspection_vendor.id : 1)} >
                                                <AvRadio label='Choose a new inspection' value='No' />
                                                <AvRadio label="Don't order an inspection" value='None' />
                                            </AvRadioGroup>
                                        </Col>
                                    </Row>
                                ) : (null)}

                                {eligibleInspectionTypes.length > 0 && parseInt(reopenStatus) === RO_MARKET_UPDATE &&
                                    <>
                                        {renderInspectionTypes()}
                                    </>
                                }

                                {tat && tat.length > 0 &&
                                    <Row >
                                        <Col>
                                            <Label className="required">Turnaround Time</Label>
                                            <div className="custom-av-radio-btn inline mb--3">
                                                <AvRadioGroup onChange={(e) => setTurnAroundTime(e.target.value)} className="radio-group" name="turnaround_time" validate={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: "This field is required."
                                                    }
                                                }} >
                                                    {tat.map(function (t, i) {
                                                        return (
                                                            <AvRadio key={i} label={t.name + " (" + t.turnaround_time_text + ")"} value={t.name + '/' + t.turnaround_time} />
                                                        );
                                                    })}
                                                </AvRadioGroup>
                                            </div>
                                            <sup className="font-weight-bold text-danger">*Inspection times and coverage cannot be guaranteed. Property access must be granted within 24 hrs from placing an order, and otherwise can cause delays</sup>
                                        </Col>
                                    </Row>
                                }

                                {
                                    ((parseInt(reopenStatus) === RO_BUDGET_UPDATED || parseInt(reopenStatus) === RO_NEW_SPECS || parseInt(reopenStatus) === RO_MARKET_UPDATE)) ? (
                                        <>
                                            <Row>
                                                <Col xs="12" sm="12" md="12" lg="12" className="my-2">
                                                    {(reopenData?.amount > 0) &&
                                                        <Label className="required">Reopening a file required additional charge of <b> ${reopenData?.amount}</b>. </Label>
                                                    }
                                                </Col>
                                            </Row>
                                        </>
                                    ) : null
                                }

                            </>
                        }

                        {
                            (inspectionTypeData?.totalVariance && inspectionTypeData?.totalVariance !== 0) ?
                                <>
                                    {checkRoles(["ADMINISTRATOR", "SALES_REP", "ACCOUNT_REP"]) ?
                                        <Row>
                                            <Col xs="12" sm="12" md="12" lg="12" className="my-2">
                                                {(inspectionTypeData?.totalVariance > 0) ?
                                                    <Label className="required"><b> Fee Increase:</b> Ordering/Updating an inspection required the additional charge of <b> ${inspectionTypeData?.totalVariance}</b>. Would you like to charge the client in the amount of ${Math.abs(inspectionTypeData.totalVariance)} ? </Label>
                                                    : null}
                                                {(inspectionTypeData?.totalVariance < 0) ?
                                                    <Label className="required"><b>Possible Refund:</b> The previous inspection was charged at ${inspectionTypeData.totalCurrent} and the new inspection has a fee of ${inspectionTypeData.totalNew}.  Would you like to provide a refund to the client of ${Math.abs(inspectionTypeData.totalVariance)} ? </Label>
                                                    : null}

                                                <div className="custom-radio-btn inline">
                                                    <div >
                                                        <input type="radio" id='do_payment_process_yes' name="do_payment_process" value='yes'
                                                            onChange={(event) => {
                                                                setSelectedPaymentSource(null);
                                                                setDoPaymentProcess(event.target.value)
                                                                setUpdate(moment())
                                                            }
                                                            }
                                                            checked={'yes' === doPaymentProcess}
                                                        />
                                                        <label htmlFor='do_payment_process_yes'>Yes </label>
                                                    </div>
                                                    <div >
                                                        <input type="radio" id='do_payment_process_no' name="do_payment_process" value='no'
                                                            onChange={(event) => {
                                                                setSelectedPaymentSource(null);
                                                                setDoPaymentProcess(event.target.value)
                                                                setUpdate(moment())
                                                            }
                                                            }
                                                            checked={'no' === doPaymentProcess}
                                                        />
                                                        <label htmlFor='do_payment_process_no'>No</label>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row> :
                                        <>
                                            {(inspectionTypeData?.totalVariance > 0) &&
                                                <Row>
                                                    <Col className="mb-3">
                                                        <Label>New inspection order required the additional charge of <b> ${inspectionTypeData.totalVariance}</b>. </Label>
                                                    </Col>
                                                </Row>
                                            }
                                        </>
                                    }
                                </> : null
                        }

                        {(totalCharge > 0) ?
                            <Row>
                                <Col className="mb-3">
                                    {!paymentError && <ReportOrderOriginalSource reportOrderId={propRowData.id} inspectionOrderId={propRowData.inspection_order_id} onReportOrderOriginalSourceCB={onReportOrderOriginalSourceCB} />}
                                    {paymentError &&
                                        <Row>
                                            <span className="col-md-12 text-danger font-weight-bold">
                                                The payment was failed because of following reason. <br />
                                                <ul><li>{paymentError}</li></ul>
                                            </span >
                                        </Row>
                                    }
                                    {(selectedPaymentSource && selectedPaymentSource?.isOriginalSource === false) &&
                                        <Label><span className="text-success mr-2"><i className="ni ni-check-bold"></i> {selectedPaymentSource.selectionText}</span><i className="fa fa-pencil-alt text-danger" onClick={() => openPaymentMethodSelector()} aria-hidden="true"></i></Label>
                                    }

                                    {(!selectedPaymentSource && defaultSourceChecked) &&
                                        <Button size="sm" color="warning" outline onClick={() => openPaymentMethodSelector()}> <i className="fas fa-credit-card"></i> Select Payment Method </Button>
                                    }
                                </Col>
                            </Row> : null
                        }

                        {
                            [RO_BUDGET_UPDATED, RO_DISPUTE_INITIATED, RO_MARKET_UPDATE].includes(parseInt(reopenStatus)) ?
                                <Row className="mb-2">
                                    {[RO_BUDGET_UPDATED].includes(parseInt(reopenStatus)) ?
                                        <>
                                            <Col lg="6">
                                                <DragAndDropOrUpload label="Budgets" accept={"pdf,excel"} fileUploadCB={(files) => setSelectedBudgetFiles(files?.selectedFiles)} />
                                            </Col>
                                        </>
                                        : null
                                    }

                                    {/* {[RO_DISPUTE_INITIATED].includes(parseInt(reopenStatus)) ?
                                        <>
                                            <Col lg="6">
                                                <DragAndDropOrUpload label="Dispute File" maxFileSize={maxDisputeFileSize} accept={"pdf,excel"} fileUploadCB={(files) => { setSelectedDisputeFiles(files?.selectedFiles); }} />
                                                {disputeFileUploadError && (
                                                    <div className="text-danger">
                                                        {disputeFileUploadError}
                                                    </div>
                                                )}
                                            </Col>
                                        </>
                                        : null
                                    } */}

                                    {
                                        [RO_MARKET_UPDATE].includes(parseInt(reopenStatus)) ?
                                            <>
                                                <Col lg="6">
                                                    <DragAndDropOrUpload label="Budgets" accept={"pdf,excel"} fileUploadCB={(files) => setSelectedBudgetFiles(files?.selectedFiles)} />
                                                </Col>
                                                <Col lg="6">
                                                    <DragAndDropOrUpload label="Photos" accept={"pdf,jpg,png"} fileUploadCB={(files) => setSelectedPhotoFiles(files?.selectedFiles)} />
                                                </Col>
                                                <Col lg="6">
                                                    <DragAndDropOrUpload label="Inspections" accept={"pdf"} fileUploadCB={(files) => setSelectedInspectionFiles(files?.selectedFiles)} />
                                                </Col>
                                            </>
                                            : null
                                    }
                                </Row>
                                : null
                        }

                        {RO_DISPUTE_INITIATED !== parseInt(reopenStatus) ?
                            <>
                                <Row>
                                    <Col>
                                        <AvGroup>
                                            <Label>Reason / Request <span className="required">*</span></Label>
                                            <AvField maxLength="2000" type="textarea" onChange={(e) => setQuestionComment(e.target.value)} cols="100" name="question_comment" id="question_comment" placeholder="Type here..." required />
                                        </AvGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12" className="text-right mt-2 mb--3">
                                        <FormGroup>
                                            <Button size="sm" color="danger" outline onClick={() => handleToggleModal("isOpenCancelReportOrderModal")}>
                                                Close
                                            </Button>

                                            <Button size="sm" color="primary"
                                                className={
                                                    (showStripeMsg && !selectedPaymentSource) ? "disabled" : ""
                                                }>
                                                Submit
                                            </Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </> : null
                        }
                    </AvForm >
                </div >
            </Modal >

            {isDisputeModal && <ValuationReviewRequestModal isDisputeModal={isDisputeModal} propReviewRequestModal={getReviewRequestModal} orderData={orderData} reopenStatus={parseInt(reopenStatus)} />}

            <PaymentForm onPay={isOpenPaymentSourceModal}
                paymentDetails={{
                    total_amount: totalCharge,
                    cc_surcharge: null,
                    paymentFor: 'Reopen Order'
                }}
                onPaymentSourceCb={handlePaymentSourceCb} clientId={clientId} />

        </>
    );
};

export default ReportOrderReopenButton;