import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Row } from "reactstrap";
import * as moment from "moment";
import RenderBootstrapTable from "components/Common/RenderBootstrapTable";
import { AvField } from "availity-reactstrap-validation";
import Geocode from "react-geocode";
import GoogleAutoSearchTextInput from "../GoogleAutoSearchTextInput";
import { PostData } from "services/Api.service";
import Slider from "./Slider";
import './style.css';
import { sendExceptionEmail, hasDuplicate, formatDate } from "components/Common/Helpers";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import Spinner from "components/Common/Spinner";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_ADDRESS_KEY);
moment.tz.setDefault("America/phoenix");

const Comparable = ({ compData, comparableDivRec, propDeleteComparable, disputeData, propAddComp, type }) => {
    let [isProcess, setProcess] = useState(false);
    let [addCompsData, setAddCompsData] = useState([]);
    let [update, setUpdate] = useState(moment());
    let [distance, setDistance] = useState(null);
    let [googleAddress, setGoogleAddress] = useState("");
    let compDataRef = useRef(compData);
    let [tz] = useState(moment.tz.guess());
    let compListRef = useRef(comparableDivRec);
    let [isListURLOptional, setListURLOptional] = useState(true);

    useEffect(() => {
        compListRef.current = comparableDivRec;
        setUpdate(moment());
    }, [comparableDivRec]);

    useEffect(() => {
        if (compDataRef.current) {
            const data = Array.isArray(compDataRef.current)
                ? compDataRef.current
                : [compDataRef.current];
            setAddCompsData(data);
            setGoogleAddress(data[0].formatted_address);
        }
        //eslint-disable-next-line
    }, [compDataRef.current]);

    useEffect(() => {
        if ('valid' in compData && !compData.valid) {
            setGoogleAddress("clear");
            setUpdate(moment());
        }
        //eslint-disable-next-line
    }, [compData]);

    useEffect(() => {
    }, [update]);// eslint-disable-line react-hooks/exhaustive-deps

    let getAddressObject = async (data, index) => {
        index = (index - 1);
        let addressObj = data.address;
        const newItem = { city: addressObj.city, state: addressObj.state, address: addressObj.address_1, zip: addressObj.postal_code };
        const propertiesToCheck = ['city', 'state', 'address', 'zip'];
        let allComps = [...compListRef.current];
        let isDuplicate = hasDuplicate(allComps, newItem, propertiesToCheck, index);
        if (isDuplicate) {
            confirmAlert({
                closeOnClickOutside: false,
                closeOnEscape: false,
                customUI: ({ onClose }) => {
                    return (
                        <div className="react-confirm-alert-body custom-confirm-body">
                            <p>This comparable already exists. Please select a different comparable.</p>
                            <button className="btn btn-danger btn-sm" onClick={() => {
                                cancelCompData(); onClose();
                            }}>Ok</button>
                        </div>
                    )
                }
            });
            return false;
        }

        let compAddress = {
            address_1: addressObj.address_1,
            city: addressObj.city,
            postal_code: addressObj.postal_code,
            state: addressObj.state,
            place_id: addressObj.place_id,
            property_id: disputeData.property_id,
            order_id: disputeData.order_id,
            loan_officer_id: disputeData.loan_officer_id,
            company_id: disputeData.company_id,
            latitude: addressObj.google_latitude,
            longitude: addressObj.google_longitude,
            shortAddress: data.shortAddress,
            longAddress: data.longAddress,
            mixAddress: data.mixAddress,
            formatted_address: addressObj.formatted_address
        }
        await getPropertyCompDetails(compAddress);
    }

    let getPropertyCompDetails = async (params) => {
        try {
            setProcess(true);
            let result = await PostData("orders/check-property-comp-detail", params);
            let responseJson = result.data;
            if (responseJson && result.success) {
                let compStatus = responseJson.compStatus;
                if (compStatus !== "comp_not_exits" && compStatus !== "not_valid") {
                    setListURLOptional(true);
                }

                if (compStatus === "included_comp_section") {
                    confirmAlert({
                        closeOnClickOutside: false,
                        closeOnEscape: false,
                        customUI: ({ onClose }) => {
                            return (
                                <div className="react-confirm-alert-body">
                                    This comparable was already used as a primary basis of our opinion of value.
                                    <br /> Are you requesting that this comp be given a higher weight of influence?
                                    <div className="react-confirm-alert-button-group">
                                        <button className="btn btn-primary btn-sm" onClick={async () => {
                                            await addCompsDataTable({ ...responseJson, googleData: params }); onClose();
                                        }}>Yes</button>
                                        <button className="btn btn-secondary btn-sm" onClick={() => {
                                            cancelCompData(); onClose();
                                        }}>No</button>
                                    </div>
                                </div>
                            );
                        }
                    });
                } else if (compStatus === "not_included_custom_sections") {
                    confirmAlert({
                        closeOnClickOutside: false,
                        closeOnEscape: false,
                        customUI: ({ onClose }) => {
                            return (
                                <div className="react-confirm-alert-body">
                                    This comparable was already evaluated by our team, and intentionally excluded from the main analysis.
                                    <br /> Are you requesting that this comp be considered for inclusion in the main analysis?
                                    <div className="react-confirm-alert-button-group">
                                        <button className="btn btn-primary btn-sm" onClick={async () => {
                                            await addCompsDataTable({ ...responseJson, googleData: params }); onClose();
                                        }}>Yes</button>
                                        <button className="btn btn-secondary btn-sm" onClick={() => {
                                            cancelCompData(); onClose();
                                        }}>No</button>
                                    </div>
                                </div>
                            );
                        }
                    });
                } else if (compStatus === "not_included_report") {
                    confirmAlert({
                        closeOnClickOutside: false,
                        closeOnEscape: false,
                        customUI: ({ onClose }) => {
                            return (
                                <div className="react-confirm-alert-body">
                                    This comparable is in our system, and did not make it into our analysis, either due to distance from the subject, or due to incomplete property data.
                                    <br /> Would you like us to consider adding this comp into the analysis?
                                    <div className="react-confirm-alert-button-group">
                                        <button className="btn btn-primary btn-sm" onClick={async () => {
                                            await addCompsDataTable({ ...responseJson, googleData: params }); onClose();
                                        }}>Yes</button>
                                        <button className="btn btn-secondary btn-sm" onClick={() => {
                                            cancelCompData(); onClose();
                                        }}>No</button>
                                    </div>
                                </div>
                            );
                        }
                    });
                } else if (compStatus === 'comp_not_exits') {
                    await addCompsDataTable({ ...responseJson, googleData: params });
                } else if (compStatus === 'not_valid') {
                    confirmAlert({
                        title: 'Error',
                        message: responseJson.message,
                        closeOnClickOutside: false,
                        buttons: [
                            {
                                label: 'Ok',
                                onClick: () => {
                                    var randomNum = Math.floor(Math.random() * 100) + 1;
                                    setGoogleAddress(`clear_${randomNum}`);
                                },
                                className: "btn btn-danger btn-sm"
                            }
                        ]
                    });
                }
            }
            setProcess(false);
        } catch (errors) {
            setProcess(false);
            await sendExceptionEmail(errors)
            toast["error"]("Something went wrong.");
        };
    };

    let addCompsDataTable = (data) => {
        return new Promise((resolve, reject) => {
            let maxDistance = parseFloat(data.maxDistance);
            let distance = parseFloat(data.distance);
            let totalException = parseInt(data.totalException);
            let selectedAddress = data.googleData.address_1;

            const sumOfExcludedColumn = compListRef.current.reduce((sum, item) => {
                if (item.address !== selectedAddress) {
                    sum += item.exception || 0;
                }
                return sum;
            }, 0);

            totalException = totalException + sumOfExcludedColumn;

            // Manage conditions
            let thresholdDistance = Math.max(2, 2 * maxDistance);
            data.threshold_distance = thresholdDistance;
            data.distance = distance;

            if (distance < maxDistance) {
                setCompData(data); resolve(true);
            } else if (distance < thresholdDistance) {
                confirmAlert({
                    closeOnClickOutside: false,
                    closeOnEscape: false,
                    customUI: ({ onClose }) => {
                        return (
                            <div className="react-confirm-alert-body">
                                Our team already evaluated {disputeData.total_comps} comps within {maxDistance} distance.The comparable you are asking to submit falls within {distance} miles from the subject,
                                <br />and is therefore likely to be dismissed by our team. Would you still like to proceed?
                                <div className="react-confirm-alert-button-group">
                                    <button className="btn btn-primary btn-sm" onClick={() => {
                                        setCompData({ ...data, override: true }); resolve(true); onClose();
                                    }}>Yes</button>
                                    <button className="btn btn-secondary btn-sm" onClick={() => {
                                        cancelCompData(); onClose();
                                    }}>No</button>
                                </div>
                            </div>
                        );
                    }
                });
            } else if (distance > thresholdDistance) {
                confirmAlert({
                    closeOnClickOutside: false,
                    closeOnEscape: false,
                    customUI: ({ onClose }) => {
                        return (
                            <div className="react-confirm-alert-body">
                                {totalException < 2 ? (
                                    <>
                                        <span>
                                            <p className="mb-0">The comparable you are trying to submit is {distance} miles from the subject property, and is too far for our team to consider.</p>
                                            <p className="mb-0">Our maximum allowable distance for this report is {thresholdDistance} miles (threshold distance). Please submit a different comp.</p>
                                            <p className="mb-0 red-text">You are allowed only two submissions per month which fall outside this threshold. Would you like to submit this comp as one of your exceptions for this month?</p>
                                        </span>
                                        <div className="react-confirm-alert-button-group">
                                            <button className="btn btn-primary btn-sm" onClick={() => {
                                                setCompData({ ...data, exception: 1, override: true }); resolve(true); onClose();
                                            }}>Yes</button>
                                            <button className="btn btn-secondary btn-sm" onClick={() => {
                                                cancelCompData(); onClose();
                                            }}>No</button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <span>
                                            The comparable you are trying to submit is {distance} miles from the subject property, and is too far for our team to consider.
                                            <br />Our maximum allowable distance for this report is {thresholdDistance} miles (threshold distance). Please submit a different comp.
                                        </span>
                                        <div className="react-confirm-alert-button-group">
                                            <button className="btn btn-primary btn-sm" onClick={() => {
                                                cancelCompData(); onClose();
                                            }}>Okay</button>
                                        </div>
                                    </>
                                )}
                            </div>
                        );
                    }
                });
            }
        });
    }

    let setCompData = (data) => {
        let googleData = data.googleData;
        let propertyCompData = data.compData[0];
        let prepareComp = {
            id: compDataRef.current.id,
            flags: '',
            exception: data.exception,
            latitude: googleData.latitude ?? 0,
            longitude: googleData.longitude ?? 0,
            address: googleData.address_1 ?? '',
            google_place_id: googleData.place_id ?? null,
            city: googleData.city ?? null,
            state: googleData.state ?? null,
            zip: googleData.postal_code ?? null,
            comp_status: data.compStatus,
            max_distance: parseFloat(data.maxDistance),
            threshold_distance: data.threshold_distance,
            client_override_yn: data.override ? '1' : '0',
            distance: data.distance,
            type: disputeData.apexPropTypes[0].property_type_normalized,
            formatted_address: googleData.formatted_address
        };

        if (propertyCompData) {
            let propertyCompObj = {
                type: propertyCompData.property_type_normalized,
                above: parseInt(propertyCompData.living_area_square_feet) > 0 ? parseInt(propertyCompData.living_area_square_feet) : 0,
                below: propertyCompData.finished_basement > 0 ? propertyCompData.finished_basement : 0,
                beds: propertyCompData.bedrooms_total,
                baths: propertyCompData.bathrooms_total,
                year: propertyCompData.year_built,
                lot_size: propertyCompData.lot_size_square_feet,
                stories: parseInt(propertyCompData.stories_total),
                garage: propertyCompData.garage,
                sale_price: propertyCompData.close_price,
                sale_date: propertyCompData.close_date,
                condition_rating: propertyCompData.condition_rating ? parseFloat(propertyCompData.condition_rating) : 1,
                property_comp_id: propertyCompData.id ?? 0,
                distance: prepareComp.distance ? prepareComp.distance : propertyCompData.distance,
                golfcourse: propertyCompData.key_flags_golf_course ?? 0,
                waterfront: propertyCompData.key_flags_water_front ?? 0,
                pool: propertyCompData.pool ?? 0,
                mls_number: propertyCompData.mls_listing_number ?? ''
            }
            prepareComp = { ...prepareComp, ...propertyCompObj };
        }

        let mergeComps = [];
        if (addCompsData && propertyCompData && (addCompsData[0].property_comp_id !== propertyCompData.id)) {
            mergeComps = { ...prepareComp };
        } else {
            mergeComps = { ...prepareComp, ...addCompsData[0] };
        }

        if (!("condition_rating" in mergeComps)) {
            mergeComps.condition_rating = 1;
        }
        setAddCompsData([mergeComps]);
        let tempComps = [...compListRef.current];
        const updatedObject = { ...tempComps[(compDataRef.current.id - 1)], ...mergeComps };
        tempComps[(compDataRef.current.id - 1)] = updatedObject;
        setDistance(data.distance);
        propAddComp({ compsData: mergeComps, index: (compDataRef.current.id - 1), comps: tempComps, type: "add comp" });
    }

    let cancelCompData = () => {
        let compListTemp = compListRef.current.filter(obj => obj.id !== compDataRef.current.id);
        compListTemp[(compDataRef.current.id - 1)] = { id: compDataRef.current.id };
        compData = Array.isArray(compDataRef.current) ? compDataRef.current : [compDataRef.current];
        setAddCompsData(compData);
        setDistance(null);

        var randomNum = Math.floor(Math.random() * 100) + 1;
        setGoogleAddress(`clear_${randomNum}`);
        setProcess(false);
    }

    let textBox = (cell, row, params) => {
        let name = params.field;
        let validate = {
            required: {
                value: true,
                errorMessage: "This field is required."
            },
        };

        if (params.type === "number") {
            validate.pattern = { value: /^[0-9]+(\.[0-9]+)?$/, errorMessage: "Invalid format." }
        }

        if (params.minLength) {
            validate.minLength = { value: params.minLength, errorMessage: `Value must be between ${params.minLength} and ${params.maxLength} characters` }
        }

        if (params.field === "listing_url") {
            if (isListURLOptional) {
                delete validate.required;
            }

            validate.custom = (value, context) => {
                if (value) {
                    const urlRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/\S*)?$/;
                    if (!urlRegex.test(value)) {
                        return 'Please enter a valid URL';
                    }
                }
                return true;
            }
        }
        let maxLength = params.maxLength ? params.maxLength : 10;

        return (<AvField
            name={name + "_" + row.id}
            value={row[name] != null ? `${row[name]}` : ""}
            maxLength={maxLength}
            autoComplete="off"
            onChange={(e) => handleInput(this, row.id, e)}
            className="form-control-alternative form-control"
            type={params.type}
            validate={validate}
        />)
    };

    let selectBox = (cell, row, params) => {
        let name = params.field;
        return (
            <AvField type="select"
                name="type"
                value={row[name] != null ? row[name] : ""}
                onChange={(e) => handleInput(this, row.id, e)}
            >
                {disputeData.apexPropTypes.map((option, index) => (
                    <option key={index} value={option.property_type_normalized}>{option.property_type_normalized}</option>
                ))}
            </AvField>
        );
    }

    let handleDateSelect = (value, name, index) => {
        value = value ? moment(value).tz(tz).format('YYYY-MM-DD') : null;
        const dynamicObject = {
            [name]: formatDate('', value, "YYYY-MM-DD")
        };

        let tempComps = [...compListRef.current];
        const updatedObject = { ...addCompsData[0], ...dynamicObject };
        tempComps[(compDataRef.current.id - 1)] = updatedObject;

        setAddCompsData([updatedObject]);
        propAddComp({ compsData: updatedObject, index, comps: tempComps, type: "date select" });
    }

    let datePicker = (cell, row, params) => {
        let name = params.field;
        let startDate = row.sale_date && moment(row.sale_date).isValid() ? new Date(row.sale_date) : new Date();
        return (
            <DatePicker
                autoComplete="off"
                name={name + "_" + row.id}
                className="form-control"
                dateFormat="yyyy-MM-dd"
                selected={startDate}
                onChange={(date) => handleDateSelect(date, `${name}`, (row.id - 1))}
            />
        );
    }

    let getSelectedRating = (ratingData) => {
        const dynamicObject = {
            condition_rating: ratingData.rating
        };
        let index = (compDataRef.current.id - 1)
        let tempComps = [...compListRef.current];
        const updatedObject = { ...tempComps[index], ...dynamicObject };
        tempComps[index] = updatedObject;
        setAddCompsData([updatedObject]);
        propAddComp({ compsData: updatedObject, index, comps: tempComps, type: "select rating" });
    }

    let handleFlag = (row) => {
        let id = row.id;
        let golfCourseVal = parseInt(row.golfcourse) === 1 ? true : false;
        let waterfrontVal = parseInt(row.waterfront) === 1 ? true : false;
        let poolVal = parseInt(row.pool) === 1 ? true : false;
        return (
            <>
                <div className="flag-div">
                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id={"waterfront-check-" + id}
                            type="checkbox"
                            value="1"
                            name={"waterfront_" + row.id}
                            checked={waterfrontVal}
                            onChange={(e) => handleInput(this, row.id, e)}
                        />
                        <label className="custom-control-label" htmlFor={"waterfront-check-" + id}>
                            Waterfront
                        </label>
                    </div>
                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id={"golfcourse-check-" + id}
                            type="checkbox"
                            value="1"
                            name={"golfcourse_" + row.id}
                            checked={golfCourseVal}
                            onChange={(e) => handleInput(this, row.id, e)}
                        />
                        <label className="custom-control-label" htmlFor={"golfcourse-check-" + id}>
                            Golfcourse
                        </label>
                    </div>
                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id={"pool-check-" + id}
                            type="checkbox"
                            value="1"
                            name={"pool_" + row.id}
                            checked={poolVal}
                            onChange={(e) => handleInput(this, row.id, e)}
                        />
                        <label className="custom-control-label" htmlFor={"pool-check-" + id}>
                            Pool
                        </label>
                    </div>
                </div>
            </>
        );
    }

    let handleInput = (el, i, e) => {
        let index = i - 1;
        let { name, value, checked, type } = e.target;

        if (type === "checkbox") {
            value = checked ? 1 : 0;
        }
        name = name.replaceAll('_' + i, '');

        const dynamicObject = {
            [name]: typeof value === "string" ? value.trim() : value,
        };

        let tempComps = [...compListRef.current];
        let updatedObject = [{ ...addCompsData[0], ...dynamicObject }];
        tempComps[index] = updatedObject[0];

        setAddCompsData(updatedObject);
        propAddComp({ compsData: updatedObject[0], index, comps: tempComps, type: "handle input" });
        setUpdate(moment());
    }

    let columns = [
        { title: "id", width: "0%", dataField: "id", isKey: true, hidden: true },
        { title: "Type", width: "6%", dataFormat: (cell, row) => selectBox(cell, row, { "field": "type", "type": "text" }), align: "left", required: true },
        { title: "Above", width: "6%", dataFormat: (cell, row) => textBox(cell, row, { "field": "above", "type": "number", "maxLength": 10 }), align: "left", required: true },
        { title: "Below", width: "6%", dataFormat: (cell, row) => textBox(cell, row, { "field": "below", "type": "number", "maxLength": 10 }), align: "left", required: true },
        { title: "Beds", width: "4%", dataFormat: (cell, row) => textBox(cell, row, { "field": "beds", "type": "number", "maxLength": 3 }), align: "left", required: true },
        { title: "Baths", width: "4%", dataFormat: (cell, row) => textBox(cell, row, { "field": "baths", "type": "number", "maxLength": 5 }), align: "left", required: true },
        { title: "Year", width: "5%", dataFormat: (cell, row) => textBox(cell, row, { "field": "year", "type": "number", "maxLength": 4 }), align: "left", required: true },
        { title: "Lot Size", width: "6%", dataFormat: (cell, row) => textBox(cell, row, { "field": "lot_size", "type": "number", "maxLength": 10 }), align: "left", required: true },
        { title: "Stories", width: "4%", dataFormat: (cell, row) => textBox(cell, row, { "field": "stories", "type": "number", "maxLength": 1 }), align: "left", required: true },
        { title: "Flags", width: "6%", dataFormat: (cell, row) => handleFlag(row, { "field": "flags", "type": "text" }), align: "left" },
        { title: "Garage", width: "5%", dataFormat: (cell, row) => textBox(cell, row, { "field": "garage", "type": "number" }), align: "left", required: true },
        { title: "Sale Price", width: "7%", dataFormat: (cell, row) => textBox(cell, row, { "field": "sale_price", "type": "number" }), align: "left", required: true },
        { title: "Sale Date", width: "6%", dataFormat: (cell, row) => datePicker(cell, row, { "field": "sale_date", "type": "date" }), align: "left", required: true },
        { title: "MLS URL", width: "11%", dataFormat: (cell, row) => textBox(cell, row, { "field": "listing_url", "type": "text", "maxLength": 50 }), align: "left" },
        { title: "MLS Number", width: "8%", dataFormat: (cell, row) => textBox(cell, row, { "field": "mls_number", "type": "text", "maxLength": 50, "minLength": 6 }), align: "left", required: true },
        { title: `Reason for Consideration`, width: "17%", dataFormat: (cell, row) => textBox(cell, row, { "field": "reason", "type": "text", "maxLength": 2000 }), align: "left", required: true },
    ];

    const tableParams = {
        tableData: addCompsData,
        columns,
        trClassName: 'add-comparable-table'
    };

    return (
        <>
            <Spinner isShow={isProcess} />
            <Row className="mb-3">
                <Col xl="1">
                    <label className="comparable-label-number">Comparable #{(compData.id)}</label>
                </Col>
                <Col xl="3">
                    <GoogleAutoSearchTextInput googleAddress={googleAddress} propAddressObject={(data) => getAddressObject(data, compDataRef.current.id)} />
                </Col>

                <Col xl="3">
                    {distance &&
                        <p className="distance-text">Distance from Subject: <strong className="font-weight-bold"> {distance} miles</strong></p>
                    }
                </Col>

                <Col xl="5">
                    <Col xl="11" className="float-left">
                        <Slider id={compData.id} conditionRating={addCompsData.length > 0 && addCompsData[0].condition_rating ? addCompsData[0].condition_rating : 1} propConditionRating={getSelectedRating} />
                    </Col>
                    <Col xl="1" className="float-left">
                        {compData.id > 3 &&
                            <span className="delete-comparable text-right">
                                <Button color="warning" size="sm" type="button" onClick={e => propDeleteComparable(compData.id, type)}>
                                    <i className="fa fa-remove" aria-hidden="true"></i>
                                </Button>
                            </span>
                        }
                    </Col>
                </Col>
            </Row>
            <Row>
                <Col xl="12" className="comparable-table-div">
                    {addCompsData.length &&
                        <RenderBootstrapTable tableParams={tableParams} pagination={false} />
                    }
                </Col>
            </Row>
        </>
    )
}
export default Comparable;