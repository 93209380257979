
import React from "react";
import RenderActionButton from "views/modules/Common/ActionsButtons/RenderActionButton";

const InspectionOrderDownloadPDFButton = ({ propRowData, propButtonOptions }) => {

    const handleActionClick = (e, row) => {
        window.open(propRowData.downloadUrl, "_blank");
    }

    return (<>
        <RenderActionButton propButtonTitle="Download" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />
    </>);
};

export default InspectionOrderDownloadPDFButton;