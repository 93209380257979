import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import "./style.scss";
import Spinner from "components/Common/Spinner.js";
import { loggedInUser } from "components/Common/Helpers.js"
import { PostData as ApiPostData } from "services/Api.service";
import SubjectPropertyCondition from "./SubjectPropertyCondition";
import BudgetReview from "./BudgetReview";
import LandUses from "./LandUses";
import SubjectStreet from "./SubjectStreet";
import * as moment from "moment";
import { toast } from 'react-toastify';
import { sendExceptionEmail } from "components/Common/Helpers";
import AutoLoginDecrypt from "components/Common/AutoLoginDecrypt.js";
import { periodObservance } from "components/Common/TimeTrackingHelpers";
import { useSelector } from 'react-redux';
import { TASK_SLUG } from "../Common/Constants/TimeTracking.constant";
import { isPdf } from "components/Common/Helpers";
import { GoogleApiWrapper } from "google-maps-react";

const phpUrl = process.env.REACT_APP_PHP_ENDPOINT;
const userData = loggedInUser();

const Neighborhood = ({ onNeighborhoodLandUser, propReportRow, onHandleResponse, onCloseNeighborhoodLandUser, google }) => {

    const [update, setUpdate] = useState(moment());
    const [isProcess, setProcess] = useState(false);
    const [isOpenNeighborhoodLandUserModal, setOpenNeighborhoodLandUserModal] = useState(false);
    const [step, setSteps] = useState(1);
    const [headerText, setHeaderText] = useState("Neighborhood Land Uses");
    const [headerStyle, setHeaderStyle] = useState({});
    const taskData = useSelector((state) => state.taskIdsList.data);

    useEffect(() => {
        async function fetchData() {
            if (onNeighborhoodLandUser.current) {
                getLandUses(0);
                await periodObservance({ taskData, slug: TASK_SLUG.LAND_USES_LAND_USES, orderData: propReportRow, userData, closingTask: 0, newTabCheck: 1 });
            }
        }
        fetchData();
    }, [onNeighborhoodLandUser.current, propReportRow]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

    }, [update]);

    let handleToggleModal = async (state, closeTimeTrackingTask = false) => {
        state = !state;

        if (!state && closeTimeTrackingTask) {
            if (step === 1) {                                                                                                                     // closing the opened task when clicked x button from popup based on the current step
                await periodObservance({ taskData, slug: TASK_SLUG.LAND_USES_LAND_USES, orderData: propReportRow, userData, closingTask: 1, newTabCheck: 0 });  // if step = 1 it means we are in the Neighborhood Land Uses Popup and that task is opened so we are closing it and for all other step logic is the same
            } else if (step === 2) {
                await periodObservance({ taskData, slug: TASK_SLUG.NEIGHBORHOOD_STREET_VIEW_NEIGHBORHOOD_STREET_VIEW, orderData: propReportRow, userData, closingTask: 1, newTabCheck: 0 });
            } else if (step === 3) {
                await periodObservance({ taskData, slug: TASK_SLUG.SUBJECT_CONDITION_LISTING, orderData: propReportRow, userData, closingTask: 1, newTabCheck: 0 });
            } else if (step === 4) {
                await periodObservance({ taskData, slug: TASK_SLUG.BUDGET_LISTING, orderData: propReportRow, userData, closingTask: 1, newTabCheck: 0 });
            }
        }

        setOpenNeighborhoodLandUserModal(state);
        onNeighborhoodLandUser.current = false;
        onCloseNeighborhoodLandUser(state);
        setSteps(0);
        delete propReportRow.land_uses;
        delete propReportRow.subject_property;
        delete propReportRow.building_condition_ratings;
        delete propReportRow.building_quality_ratings;
        delete propReportRow.inspection_file;
        delete propReportRow.inspection_file;
    };

    let getLandUses = async (status) => {
        try {
            setProcess(true);
            let data = {
                report_order_id: propReportRow.id,
                property_id: propReportRow.property_id,
                user_id: userData.user_id,
                company_id: userData.company_id
            }

            let result = await ApiPostData("orders/get_land_uses", data);

            if (result) {
                let responseJson = result.data;
                propReportRow.subject_property_comps = responseJson.subject_property_comps;
                propReportRow.building_condition_ratings = responseJson.building_condition_ratings;
                propReportRow.building_quality_ratings = responseJson.building_quality_ratings;
                propReportRow.land_uses = responseJson.land_uses;
                propReportRow.current_land_uses = responseJson.current_land_uses;
                propReportRow.current_report_subject_q_ratings = responseJson.current_report_subject_q_ratings;
                propReportRow.current_report_subject_c_ratings = responseJson.current_report_subject_c_ratings;
                propReportRow.subject_property = responseJson.subject_property;
                propReportRow.inspection_file = responseJson.inspection_files ? responseJson.inspection_files.location : "";
                propReportRow.budget_file = responseJson.budget_files ? responseJson.budget_files.location : "";
                propReportRow.building_quality_apex_ratings = responseJson.building_quality_apex_ratings;
                propReportRow.arv_user_value = responseJson.current_arv_user_value;
                propReportRow.budget_analysis = responseJson.current_budget_analysis;

                if (responseJson.photos_files) {
                    propReportRow.photos_files = responseJson.photos_files.map(item => isPdf(item.location) ? { pdf: item.location } : { img: item.location });

                }

                if (responseJson) {
                    setOpenNeighborhoodLandUserModal(true);
                    if (status === 0) {
                        setSteps(1);
                    }
                    setUpdate(moment());
                }
                setProcess(false);
            }
        } catch (errors) {
            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message);
            await sendExceptionEmail(errors);
            setProcess(false);
        }
    }

    let handleLandUsesResponse = async (response, headText = "") => {
        if (response === "cancel") {
            handleToggleModal(isOpenNeighborhoodLandUserModal);

            // closing the task "land uses when cancel button is clicked from Neighborhood Land Uses Popup"
            await periodObservance({ taskData, slug: TASK_SLUG.LAND_USES_LAND_USES, orderData: propReportRow, userData, closingTask: 1, newTabCheck: 0 });
        } else {
            getLandUses(1);
            setHeaderText(headText);
            setSteps(response);

            // closing the task "land uses" when next button is clicked from Neighborhood Land Uses Popup and opening new task neighborhood street view"
            await periodObservance({ taskData, slug: TASK_SLUG.LAND_USES_LAND_USES, orderData: propReportRow, userData, closingTask: 1, newTabCheck: 0 });
            await periodObservance({ taskData, slug: TASK_SLUG.NEIGHBORHOOD_STREET_VIEW_NEIGHBORHOOD_STREET_VIEW, orderData: propReportRow, userData, closingTask: 0, newTabCheck: 1 });
        }
    }

    let handleSubjectStreetResponse = async (response, headText = "") => {

        if (response === "cancel") {
            handleToggleModal(isOpenNeighborhoodLandUserModal);
            await periodObservance({ taskData, slug: TASK_SLUG.NEIGHBORHOOD_STREET_VIEW_NEIGHBORHOOD_STREET_VIEW, orderData: propReportRow, userData, closingTask: 1, newTabCheck: 0 }); // closing the task "NEIGHBORHOOD_STREET_VIEW_NEIGHBORHOOD_STREET_VIEW" when cancel button is clicked from street view Popup"
        } else {
            getLandUses(1);
            setHeaderText(headText);
            setSteps(response);
            if (response === 1) {
                //time tracking - land_uses open and neighborhood street view close on back button
                await periodObservance({ taskData, slug: TASK_SLUG.LAND_USES_LAND_USES, orderData: propReportRow, userData, closingTask: 0, newTabCheck: 1 });
                await periodObservance({ taskData, slug: TASK_SLUG.NEIGHBORHOOD_STREET_VIEW_NEIGHBORHOOD_STREET_VIEW, orderData: propReportRow, userData, closingTask: 1, newTabCheck: 0 });
            } else if (response === 3) {
                //time tracking - subject condition open and neighborhood street view close on next button
                await periodObservance({ taskData, slug: TASK_SLUG.NEIGHBORHOOD_STREET_VIEW_NEIGHBORHOOD_STREET_VIEW, orderData: propReportRow, userData, closingTask: 1, newTabCheck: 0 });
                await periodObservance({ taskData, slug: TASK_SLUG.SUBJECT_CONDITION_LISTING, orderData: propReportRow, userData, closingTask: 0, newTabCheck: 1 });
            }
        }

    }

    let handleSubjectPropertyCondition = async (response, headText = "") => {

        if (response === "cancel") {
            handleToggleModal(isOpenNeighborhoodLandUserModal);
            await periodObservance({ taskData, slug: TASK_SLUG.SUBJECT_CONDITION_LISTING, orderData: propReportRow, userData, closingTask: 1, newTabCheck: 0 });  //time tracking - subject condition close on cancel assignment button
        } else {
            if (propReportRow.renovation_budget_needed === 1 && propReportRow.budget_file) {
                if (response === 4) {
                    setHeaderStyle({ color: 'white' });
                }
                setHeaderText(headText);
                setSteps(response);
                getLandUses(1);

                if (response === 2) {         // when back button is clicked from subject popup and also budget is needed then we will open NEIGHBORHOOD_STREET_VIEW_NEIGHBORHOOD_STREET_VIEW task from here
                    await periodObservance({ taskData, slug: TASK_SLUG.NEIGHBORHOOD_STREET_VIEW_NEIGHBORHOOD_STREET_VIEW, orderData: propReportRow, userData, closingTask: 0, newTabCheck: 1 });
                } else if (response === 4) {  // when next button is clicked from subject popup and also budget is needed then we will open budget task from here
                    await periodObservance({ taskData, slug: TASK_SLUG.BUDGET_LISTING, orderData: propReportRow, userData, closingTask: 0, newTabCheck: 1 });
                }
                await periodObservance({ taskData, slug: TASK_SLUG.SUBJECT_CONDITION_LISTING, orderData: propReportRow, userData, closingTask: 1, newTabCheck: 0 }); //time tracking - subject condition close on next button when there is budget popup is there
            } else if (response === 2) {
                setHeaderText(headText);
                setSteps(response);
                getLandUses(1);

                await periodObservance({ taskData, slug: TASK_SLUG.SUBJECT_CONDITION_LISTING, orderData: propReportRow, userData, closingTask: 1, newTabCheck: 0 });  //time tracking - neighborhood_street_view open and subject condition close on back button
                await periodObservance({ taskData, slug: TASK_SLUG.NEIGHBORHOOD_STREET_VIEW_NEIGHBORHOOD_STREET_VIEW, orderData: propReportRow, userData, closingTask: 0, newTabCheck: 1 });
            } else {
                handleToggleModal(isOpenNeighborhoodLandUserModal);
                onHandleResponse({ status: "success", data: propReportRow });
                await periodObservance({ taskData, slug: TASK_SLUG.SUBJECT_CONDITION_LISTING, orderData: propReportRow, userData, closingTask: 1, newTabCheck: 0 }); //time tracking - subject condition close on analyze button
            }
        }

    }

    let handleBudgetReview = async (response, headText = "") => {
        setProcess(false);
        if (response === 'done') {
            handleToggleModal(isOpenNeighborhoodLandUserModal);
            onHandleResponse({ status: "success", data: propReportRow });
            await periodObservance({ taskData, slug: TASK_SLUG.BUDGET_LISTING, orderData: propReportRow, userData, closingTask: 1, newTabCheck: 0 }); //time tracking - budget close on analyze button
        } else if (response === "cancel") {
            handleToggleModal(isOpenNeighborhoodLandUserModal);
            await periodObservance({ taskData, slug: TASK_SLUG.BUDGET_LISTING, orderData: propReportRow, userData, closingTask: 1, newTabCheck: 0 }); //time tracking - budget close on cancel assignment button
        } else {
            setSteps(response);
            setHeaderText(headText);

            //time tracking - budget close and subject condition open on back button
            await periodObservance({ taskData, slug: TASK_SLUG.BUDGET_LISTING, orderData: propReportRow, userData, closingTask: 1, newTabCheck: 0 });
            await periodObservance({ taskData, slug: TASK_SLUG.SUBJECT_CONDITION_LISTING, orderData: propReportRow, userData, closingTask: 0, newTabCheck: 1 });
        }
    }

    return (
        <>
            <Spinner isShow={isProcess} />
            <Modal backdrop="static" className="modal-dialog-centered neighborhood-land-user" style={{ maxWidth: "90%" }} isOpen={onNeighborhoodLandUser.current} toggle={() => handleToggleModal(isOpenNeighborhoodLandUserModal)}>
                <div className="modal-header modal-header-colored">
                    <h4 className="modal-title text-center text-white w-100" id="reassignModalLabel">
                        {step === 4 || step === 3 ? <>
                            {headerText} - <AutoLoginDecrypt key={propReportRow.id} data={{ label: propReportRow.address, url: `${phpUrl}valuation/deals/details/${propReportRow.property_id}` }} style={headerStyle} />
                        </> : headerText}

                    </h4>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenNeighborhoodLandUserModal, true)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                {(step === 1) &&
                    <>
                        <LandUses propData={{ ...propReportRow, google }} onHandleLandUsesResponse={handleLandUsesResponse} />
                    </>
                }
                {step === 2 &&
                    <>
                        <SubjectStreet propData={{ ...propReportRow, google }} onHandleSubjectStreetResponse={handleSubjectStreetResponse} />
                    </>
                }
                {step === 3 &&
                    <>
                        <SubjectPropertyCondition propData={{ ...propReportRow, google }} onHandleSubjectPropertyConditionResponse={handleSubjectPropertyCondition} />
                    </>
                }
                {step === 4 &&
                    <>
                        <BudgetReview propData={{ ...propReportRow, google }} onHandleBudgetReviewResponse={handleBudgetReview} />
                    </>
                }
            </Modal>
        </>
    );
};

export default GoogleApiWrapper({
    apiKey: userData.google_geocoding_api_key
})(Neighborhood);