import React, { useState, useEffect } from "react";
import { Card, Row, Col, UncontrolledTooltip, Modal, Button } from "reactstrap";
import RenderBootstrapTable from "components/Common/RenderBootstrapTable.js";
import "./style.css";
import { PostData } from "services/Api.service";
import Spinner from "components/Common/Spinner.js";
import { toast } from 'react-toastify';

const ReportUpdateFeesModal = ({ onOpenReportUpdateFeesModal }) => {
    const [isProcess, setProcess] = useState(false);
    let [totalRecordSize, setTotalRecordSize] = useState(0);
    let [currentPage, setCurrentPage] = useState(1);
    let [recordsPerPage, setRecordsPerPage] = useState(10);
    let [tableData, setTableData] = useState([]);
    let [isOpenReportUpdateFeesModal, setIsOpenReportUpdateFeesModal] = useState(false);

    useEffect(() => {
        if (onOpenReportUpdateFeesModal.current) {
            getReportFees();
        }
    }, [currentPage, recordsPerPage, onOpenReportUpdateFeesModal.current]);// eslint-disable-line react-hooks/exhaustive-deps

    let openReportUpdateFeesModal = () => {
        onOpenReportUpdateFeesModal.current = true;
        setIsOpenReportUpdateFeesModal(true);
    }

    // Handle Error Modal
    let handleToggleModal = (state) => {
        state = !state;
        setIsOpenReportUpdateFeesModal(state);
        onOpenReportUpdateFeesModal.current = false;
    };

    // Get Report Fees 
    let getReportFees = async () => {
        try {
            let currentOffset = (currentPage - 1) * recordsPerPage;
            let data = {
                offset: currentOffset,
                limit: recordsPerPage
            };
            let reportFeesData = [];
            setProcess(true);
            let result = await PostData("report/get-report-fees", data);
            const reportFees = result.data.reportFeesData;
            for (const reportFee of reportFees.rows) {
                let reportFeesDataObj = { ...reportFee };
                reportFeesDataObj.market_update_fee_90 = reportFee['report_update_fees.market_update_fee_90'];
                reportFeesDataObj.market_update_fee_149 = reportFee['report_update_fees.market_update_fee_149'];
                reportFeesDataObj.market_update_fee_150 = reportFee['report_update_fees.market_update_fee_150'];
                reportFeesData.push(reportFeesDataObj);
            }
            setTableData(reportFeesData);
            setTotalRecordSize(reportFees.count);
            setIsOpenReportUpdateFeesModal(true);
            setProcess(false);
        } catch (error) {
            setProcess(false);
            toast["error"](error.errors && error.errors.length ? error.errors[0].message : error.message);
        }
    }

    let onPageChangeEvent = (currentPageTemp, recordsPerPageTemp) => {
        setCurrentPage(currentPageTemp);
        setRecordsPerPage(recordsPerPageTemp);
    };

    let paginationTotalText = (start, to, total) => {
        return (
            <p>From {start} to {to}, totals is {total}</p>
        );
    };

    const tableParams = {
        currentPage,
        recordsPerPage,
        onPageChangeEvent,
        paginationTotalText,
        tableData,
        totalRecordSize,
        columns: [
            { title: 'Report', dataField: 'nickname', isKey: true, width: "10%" },
            { title: '90 Days', dataField: 'market_update_fee_90', width: "10%" },
            { title: '149 Days', dataField: 'market_update_fee_149', width: "10%" },
            { title: '150 Days', dataField: 'market_update_fee_150', width: "10%" },
        ],
    };

    return (
        <>
            <Button id="report_fees" color="success" size="sm" type="button" onClick={() => openReportUpdateFeesModal()}>
                <i className="fas fa-usd" aria-hidden="true"></i>
            </Button>
            <UncontrolledTooltip delay={0} placement="auto" target="report_fees"> Report Fees </UncontrolledTooltip>

            <Modal className="modal-dialog-centered" style={{ maxWidth: "70%" }} isOpen={isOpenReportUpdateFeesModal} toggle={() => handleToggleModal(isOpenReportUpdateFeesModal)}>
                <Spinner isShow={isProcess} />
                <div className="modal-header">
                    <h2 className="modal-title" id="reassignModalLabel">
                        Report Fees
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenReportUpdateFeesModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Card className="shadow">
                        <Row className="mt-3">
                            <Col>
                                <RenderBootstrapTable tableParams={tableParams} />
                            </Col>
                        </Row>
                    </Card >
                </div >
            </Modal >
        </>
    );
};

export default ReportUpdateFeesModal;