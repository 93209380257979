import React from "react";
import RenderActionButton from "../RenderActionButton";
import { useHistory } from "react-router-dom";

const EpoConvertToEvalClientButton = ({ propRowData, propButtonOptions }) => {
    const history = useHistory();
    const handleActionClick = async() => {
        history.push(`/client/order-intake/${propRowData.report_intake_batch_id}`);
    };

    return (
        <>
            <RenderActionButton propButtonTitle="Order Full Report" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />   
        </>
    );
};

export default EpoConvertToEvalClientButton;