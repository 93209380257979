import React, { useState, useEffect } from "react";
import { Button, Modal, Col, Row, Label } from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; // Import
import Dropzone from 'react-dropzone';

import { PostData } from "../../../../../services/PostData";
import * as moment from "moment";

import eventBus from "../../EventBus";
import RenderActionButton from "views/modules/Common/ActionsButtons/RenderActionButton";
import { loggedInUser, checkRoles, formatBytes, sendExceptionEmail } from "components/Common/Helpers";

const ReportOrderReleaseHoldClientButton = ({ propButtonOptions, propReportRow }) => {
    const userData = loggedInUser();
    const [isProcess, setProcess] = useState(false);
    const [isOpenReleaseHoldModal, setOpenReleaseHoldModal] = useState(false);
    const [maxBudgetSize] = useState(10485760);
    const [budgetFileUploadError, setBudgetFileUploadError] = useState("");
    const [selectedBudgetFiles, setSelectedBudgetFiles] = useState([]);
    const [maxPhotoSize] = useState(10485760);
    const [selectedPhotoFiles, setSelectedPhotoFiles] = useState([]);
    const [photoFileUploadError, setPhotoFileUploadError] = useState("");
    const [maxInspectionSize] = useState(10485760);
    const [inspectionFileUploadError, setInspectionFileUploadError] = useState("");
    const [selectedInspectionFiles, setSelectedInspectionFiles] = useState([]);
    const [fields, setFields] = useState({});
    const [update, setUpdate] = useState(moment());

    useEffect(() => {
        if (propReportRow && propReportRow.missing_docs_arr) {
            propReportRow.requiredDocCount = propReportRow.missing_docs_arr.length;
        };
    }, [propReportRow, update]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleActionClick = async (e, row) => {
        e.preventDefault();
        handleReleaseHold()
    };

    const handleReleaseHold = () => {
        setOpenReleaseHoldModal(true);
    };

    const handleToggleModal = (state) => {
        if (!isProcess) {
            state = !state;
            setOpenReleaseHoldModal(state);
            setSelectedBudgetFiles([]);
            setSelectedPhotoFiles([]);
            setSelectedInspectionFiles([]);
            setBudgetFileUploadError("");
            setPhotoFileUploadError("");
            setInspectionFileUploadError("");
            setFields({});
            setUpdate(moment());
        }
    };

    const onDropBudget = (files) => {
        let tempArr = selectedBudgetFiles;
        files.forEach(file => {
            if (!containsObject(file, tempArr)) {
                tempArr.push(file);
            }
        });

        setSelectedBudgetFiles(tempArr);
        setBudgetFileUploadError("");
        setUpdate(moment());
    };

    const onDropRejectedBudget = (rejectedFiles) => {
        if (rejectedFiles[0].errors[0].code === 'file-too-large') {
            rejectedFiles[0].errors[0].message = "File is larger than 10MB.";
        }

        const budgetFileUploadError = rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].errors[0].message;
        setBudgetFileUploadError(budgetFileUploadError);
        setUpdate(moment());
    };

    const onDropPhoto = (files) => {
        let tempArr = selectedPhotoFiles;

        files.forEach(file => {
            if (!containsObject(file, tempArr)) {
                tempArr.push(file);
            }
        });

        setSelectedPhotoFiles(tempArr);
        setPhotoFileUploadError("");
        setUpdate(moment());
    };

    const onDropRejectedPhoto = (rejectedFiles) => {
        if (rejectedFiles[0].errors[0].code === 'file-too-large') {
            rejectedFiles[0].errors[0].message = "File is larger than 10MB.";
        }

        const photoFileUploadError = rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].errors[0].message;
        setPhotoFileUploadError(photoFileUploadError);
        setUpdate(moment());
    };

    const onDropInspection = (files) => {
        let tempArr = selectedInspectionFiles;

        files.forEach(file => {
            if (!containsObject(file, tempArr)) {
                tempArr.push(file);
            }
        });

        setSelectedInspectionFiles(tempArr);
        setInspectionFileUploadError("");
        setUpdate(moment());
    };

    const onDropRejectedInspection = (rejectedFiles) => {
        if (rejectedFiles[0].errors[0].code === 'file-too-large') {
            rejectedFiles[0].errors[0].message = "File is larger than 10MB.";
        }

        const inspectionFileUploadError = rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].errors[0].message;
        setInspectionFileUploadError(inspectionFileUploadError);
        setUpdate(moment());
    };

    const deleteFile = (e, type, files, index) => {
        files.splice(index, 1);

        if (type === 'budgetFiles') {
            setSelectedBudgetFiles(files);
        }
        if (type === 'photoFiles') {
            setSelectedPhotoFiles(files);
        }
        if (type === 'inspectionFiles') {
            setSelectedInspectionFiles(files)
        }

        setUpdate(moment());
    };

    const containsObject = (obj, list) => {
        var i;
        for (i = 0; i < list.length; i++) {
            if (list[i].name === obj.name) {
                return true;
            }
        }

        return false;
    };

    const handleSubmit = () => {
        let uploadDocTypeCount = 0;

        if (selectedPhotoFiles.length) { uploadDocTypeCount++; };
        if (selectedInspectionFiles.length) { uploadDocTypeCount++; };
        if (selectedBudgetFiles.length) { uploadDocTypeCount++; };
        if (fields.property_info) { uploadDocTypeCount++; };
        if (fields.zoning_info) { uploadDocTypeCount++; };
        if (fields.property_type_confirmation) { uploadDocTypeCount++; };
        if (fields.subject_property_mapping_issue) { uploadDocTypeCount++; };
        if (fields.processing_or_calculation_issue) { uploadDocTypeCount++; };
        if (fields.client_request) { uploadDocTypeCount++; };

        if (!checkRoles(['ADMINISTRATOR', 'SALES_REP', 'REVIEWER']) && uploadDocTypeCount === 0) {
            toast["error"]("Please provide at least one document/information.");
            return false;
        };

        confirmAlert({
            title: '',
            message: "Are you sure you want to release the hold ?",
            closeOnClickOutside: false,
            buttons: [
                {
                    label: 'Yes, Release Hold',
                    onClick: () => releaseHold('Y'),
                    className: "btn btn-primary btn-sm"
                }, {
                    label: 'Close',
                    onClick: () => { },
                    className: "btn btn-outline-danger btn-sm"
                }
            ]
        });
    };

    const releaseHold = async (isReleaseHold) => {
        try {
            setProcess(true);

            let row = propReportRow;
            let params = fields;

            params.report_id = row.id;
            params.property_id = row.property_id;
            params.user_id = userData.user_id;
            params.hold_id = row.report_hold.id;
            params.release_hold = isReleaseHold;

            const data = new FormData()

            for (let i = 0; i < selectedBudgetFiles.length; i++) {
                data.append(`budget_files`, selectedBudgetFiles[i])
            }

            for (let i = 0; i < selectedInspectionFiles.length; i++) {
                data.append(`inspection_files`, selectedInspectionFiles[i])
            }

            for (let i = 0; i < selectedPhotoFiles.length; i++) {
                data.append(`photo_files`, selectedPhotoFiles[i])
            }

            for (var key in params) {
                data.append(key, params[key]);
            }

            const result = await PostData("client/orders/release_hold_file", data)
            const responseJson = result.data;
            if (responseJson.status === 200) {
                toast["success"](responseJson.message);
                handleToggleModal(isOpenReleaseHoldModal)
            } else {
                toast["error"](responseJson.message);
            }

            eventBus.dispatch("report_order_status_updated", { client_id: propReportRow.company_id });
            setProcess(false);
        } catch (error) {
            await sendExceptionEmail(error);
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            setProcess(false);
        }
    };

    const handleInput = (e) => {
        let tempFields = fields;
        tempFields[e.target.name] = e.target.value

        setFields(tempFields);
    };

    const budgetFiles = selectedBudgetFiles.map((file, i) => (
        <li key={file.name} style={{ listStyle: 'none' }}>
            <i className="fa fa-trash text-danger" onClick={e => deleteFile(e, "budgetFiles", selectedBudgetFiles, i)}></i> {file.name} - {formatBytes(file.size)}
        </li>
    ));

    const photoFiles = selectedPhotoFiles.map((file, i) => (
        <li key={file.name} style={{ listStyle: 'none' }}>
            <i className="fa fa-trash text-danger" onClick={e => deleteFile(e, "photoFiles", selectedPhotoFiles, i)}></i> {file.name} - {formatBytes(file.size)}
        </li>
    ));

    const inspectionFiles = selectedInspectionFiles.map((file, i) => (
        <li key={file.name} style={{ listStyle: 'none' }}>
            <i className="fa fa-trash text-danger" onClick={e => deleteFile(e, "inspectionFiles", selectedInspectionFiles, i)}></i> {file.name} - {formatBytes(file.size)}
        </li>
    ));


    return (
        <>
            <RenderActionButton propButtonTitle="Release Hold" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />

            <Modal className="modal-dialog-centered modal-lg release-hold" style={{ maxWidth: "800px" }} isOpen={isOpenReleaseHoldModal} toggle={() => handleToggleModal(isOpenReleaseHoldModal)}>
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title text-center w-100">
                        Release Hold
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenReleaseHoldModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="text-left">
                        <AvForm onValidSubmit={(e) => handleSubmit()} >
                            <Row>
                                <Col xs="12" sm="12" md="6" lg="6">
                                    <Label>Client Request</Label>
                                    <AvField
                                        name="client_request"
                                        onChange={(e) => handleInput(e)}
                                        className="form-control"
                                        placeholder="Enter Client Request Details"
                                        type="textarea"
                                        validate={{
                                            maxLength: {
                                                value: 255,
                                                errorMessage: 'Property must be less than 255 character'
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                            {userData.oms_access === 1 && userData.report_order_company_id !== userData.company_id &&
                                <Row>
                                    <Col>
                                        <hr></hr>
                                        <Label className="w-100">If desired, please upload any document</Label>
                                    </Col>
                                </Row>
                            }
                            <Row>
                                <Col xs="12" sm="12" md="6" lg="6">
                                    <Label>Budget</Label>
                                    <Dropzone
                                        onDrop={onDropBudget}
                                        onDropRejected={onDropRejectedBudget}
                                        accept="application/pdf"
                                        minSize={0}
                                        maxSize={maxBudgetSize}
                                        // maxFiles={2}
                                        require={true}>

                                        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
                                            // const isFileTooLarge = rejectedFiles &&  rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
                                            return (
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    {!isDragActive && 'Click here or drop a file to upload. Upload only .PDF files. Max size for every file is 10MB. A file with size more than 10MB will be ignored.'}
                                                    {/* {isDragActive && !isDragReject && "Drop it like it's hot!"} */}
                                                    {/* {isDragReject && "File type not accepted, sorry!"} */}
                                                    {budgetFileUploadError && (
                                                        <div className="text-danger mt-2">
                                                            {budgetFileUploadError}
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        }
                                        }
                                    </Dropzone>
                                    {/* {this.state.property_file_error && <div className="text-danger">{this.state.property_file_error_message}</div>} */}
                                    <aside className="selected-file-list">
                                        <ul>{budgetFiles}</ul>
                                    </aside>
                                </Col>

                                <Col xs="12" sm="12" md="6" lg="6">
                                    <Label>Photos</Label>
                                    <Dropzone
                                        onDrop={onDropPhoto}
                                        onDropRejected={onDropRejectedPhoto}
                                        accept="application/pdf,image/jpg,image/jpeg,image/png"
                                        minSize={0}
                                        maxSize={maxPhotoSize}
                                        // maxFiles={2}
                                        require={true}>

                                        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
                                            // const isFileTooLarge = rejectedFiles &&  rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
                                            return (
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    {!isDragActive && 'Click here or drop a file to upload. Upload only .PDF, .JPEG, .PNG files. Max size for every file is 10MB. A file with size more than 10MB will be ignored.'}
                                                    {/* {isDragActive && !isDragReject && "Drop it like it's hot!"} */}
                                                    {/* {isDragReject && "File type not accepted, sorry!"} */}
                                                    {photoFileUploadError && (
                                                        <div className="text-danger mt-2">
                                                            {photoFileUploadError}
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        }
                                        }
                                    </Dropzone>
                                    {/* {this.state.property_file_error && <div className="text-danger">{this.state.property_file_error_message}</div>} */}
                                    <aside className="selected-file-list">
                                        <ul>{photoFiles}</ul>
                                    </aside>
                                </Col>

                                <Col xs="12" sm="12" md="6" lg="6">
                                    <Label>Inspection</Label>
                                    <Dropzone
                                        onDrop={onDropInspection}
                                        onDropRejected={onDropRejectedInspection}
                                        accept="application/pdf"
                                        minSize={0}
                                        maxSize={maxInspectionSize}
                                        // maxFiles={2}
                                        require={true}>

                                        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
                                            // const isFileTooLarge = rejectedFiles &&  rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
                                            return (
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    {!isDragActive && 'Click here or drop a file to upload. Upload only .PDF files. Max size for every file is 10MB. A file with size more than 10MB will be ignored.'}
                                                    {/* {isDragActive && !isDragReject && "Drop it like it's hot!"} */}
                                                    {/* {isDragReject && "File type not accepted, sorry!"} */}
                                                    {inspectionFileUploadError && (
                                                        <div className="text-danger mt-2">
                                                            {inspectionFileUploadError}
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        }
                                        }
                                    </Dropzone>
                                    {/* {property_file_error && <div className="text-danger">{this.state.property_file_error_message}</div>} */}
                                    <aside className="selected-file-list">
                                        <ul>{inspectionFiles}</ul>
                                    </aside>
                                </Col>

                                <Col xs="12" sm="12" md="12" lg="12">
                                    <div className="text-right">
                                        <AvGroup>
                                            {isProcess ?
                                                <Button type="button" color="warning" size="sm">
                                                    <div className="fa fa-spinner fa-spin"></div> Processing...
                                                </Button>
                                                :
                                                <>
                                                    <Button size="sm" color="danger" outline onClick={() => handleToggleModal(isOpenReleaseHoldModal)}>
                                                        Cancel
                                                    </Button>
                                                    <Button size="sm" color="primary">
                                                        Submit
                                                    </Button>
                                                </>}
                                        </AvGroup>
                                    </div>
                                </Col>
                            </Row>
                        </AvForm>
                    </div>
                </div>
            </Modal>

        </>
    );
};

export default ReportOrderReleaseHoldClientButton;