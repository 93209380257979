/* ---------------------------------------COMPONENT INSTRUCTION---------------------------------------
--Import Component--
import PaymentForm from "../Common/PaymentForm.js";
--------------------
--Include Component--
<PaymentForm onPay={} onPaymentSourceCb={} clientId=""  />
------------------------------------------------------------------------------------------------------*/

import React, { useState, useEffect } from "react";
import "./style.css";
import { AvField, AvForm, AvInput, AvGroup } from "availity-reactstrap-validation";
import { Button, Col, Label, Modal, Row } from "reactstrap";
import { toast } from 'react-toastify';
import * as moment from "moment";
import { Link } from "react-router-dom";
import Spinner from "components/Common/Spinner.js";

import {
    Elements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PostData as OmsPost } from "../../../services/PostData";

import { loggedInUser } from "components/Common/Helpers.js";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { GetData as OrderIntakeGet } from "services/OrderIntakeData";
import { sendExceptionEmail } from "components/Common/Helpers";
import { priceNumber } from "components/Common/Helpers";

const PaymentForm = ({ onPay, paymentDetails, onPaymentSourceCb, clientId = null, sourcePage = null, otherData = {} }) => {

    let userData = loggedInUser();
    let [isOpenPayModal, setOpenPayModal] = useState(false);
    let [update, setUpdate] = useState(moment());

    const [sourPageForNewCardOnly] = useState(['inspectionPublicPaymentPage', 'borrowerPublicPaymentPage', 'transferOrderPaymentPage']);

    let [ACHSources, setACHSources] = useState([]);
    let [creditCardSources, setCreditCardSources] = useState([]);
    let [reportInvoicing, setReportInvoicing] = useState(false);
    let [companyData, setCompanyData] = useState(false);

    let [paymentSourceType, setPaymentSourceType] = useState("");
    let [paymentMethod, setPaymentMethod] = useState("");
    let [ccSurchargeText, setCCSurchargeText] = useState("");

    let [cardUsageType, setCardUsageType] = useState("multiple_use");

    let [validSingleUse] = useState({ cardNumber: false, cardExpiry: false, cardCvc: false });
    let [isValidSingle, setIsValidSingle] = useState(true);
    let cardProcessing = false;
    let [stripePromise, setStripePromise] = useState("");
    let [existingSourceId, setExistingSourceId] = useState("");
    let [isProcess, setProcess] = useState(false);
    let [defaultValues, setDefaultValues] = useState({});

    useEffect(() => {
        if (onPay.current) {
            setPaymentSourceType("");
            setPaymentMethod("");
            setOpenPayModal(onPay.current)
            if (!sourPageForNewCardOnly.includes(sourcePage)) {
                getExistingSource();
            }
            getSettingData();
        }
    }, [onPay, onPaymentSourceCb, clientId]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { }, [update]);

    let getSettingData = async () => {
        try {
            let result = await OmsPost("public/settings", { keys: ['stripe_public_key'] });
            let responseJson = result.data.data;
            let stripePromiseTemp = loadStripe(responseJson[0].value);
            setStripePromise(stripePromiseTemp);

            if (sourPageForNewCardOnly.includes(sourcePage)) {
                setPaymentSourceType("CREDIT_CARD_USING_TOKEN");
                setPaymentMethod("ADD_NEW_SOURCE");
                setCardUsageType('single_use')
            } else {
                setPaymentSourceType("");
                setPaymentMethod("");
                setCardUsageType('multiple_use')
            }

            setProcess(false);
        } catch (errors) {
            setProcess(false);
            toast["error"](errors.message);
        }
    };

    let getExistingSource = async () => {

        if (clientId) {
            try {

                let responseData = null;
                if (sourcePage === 'reportOrderPaymentPage') {
                    let response = await OrderIntakeGet(`public/${otherData.intakeToken}/payment-sources`, { company_id: clientId });
                    responseData = response.data;
                } else {
                    let response = await OmsPost("company/client_payment_sources", { company_id: clientId });
                    responseData = response.data.data;
                }

                const creditCards = responseData.paymentSources.stripe.creditCards;
                const ach = responseData.paymentSources.stripe.ach;
                const companyData = responseData.companyData;

                setACHSources(ach)
                setCreditCardSources(creditCards)
                setReportInvoicing(responseData.report_invoicing);
                setCompanyData(companyData);

                let surchargeText = "";
                if (paymentDetails?.cc_surcharge) {
                    surchargeText = "$" + paymentDetails.cc_surcharge;
                } else if (responseData.cc_surcharge.cc_surcharge_type === 'percentage') {
                    surchargeText = responseData.cc_surcharge.cc_surcharge + "%";
                } else if (responseData.cc_surcharge.cc_surcharge_type === 'flat') {
                    surchargeText = "$" + responseData.cc_surcharge.cc_surcharge;
                }

                setCCSurchargeText(surchargeText);

                if (creditCards.length > 0) {
                    for (let i = 0; i < creditCards.length; i++) {
                        if (parseInt(creditCards[i].is_default) === 1) {
                            defaultValues.payment_source_id = creditCards[i].payment_source_id;
                            setDefaultValues(defaultValues);
                            setExistingSourceId(creditCards[i].payment_source_id);
                        }
                    }
                }

                if (ach.length > 0) {
                    for (let i = 0; i < ach.length; i++) {
                        if (parseInt(ach[i].is_default) === 1) {
                            defaultValues.payment_source_id = ach[i].payment_source_id;
                            setDefaultValues(defaultValues);
                            setExistingSourceId(ach[i].payment_source_id);
                        }

                    }
                }

                setUpdate(moment());

            } catch (errors) {
                toast["error"](errors.message);
                await sendExceptionEmail(errors);
            }
        }
    };

    let handleToggleModal = (state) => {
        state = !state;
        setOpenPayModal(state);
        onPay.current = false;
        setPaymentSourceType("");
        setPaymentMethod("");
        setUpdate(moment());
    };

    let onPaymentSourceTypeChange = (e) => {

        let type = e.target.value;
        setPaymentSourceType(type);
        if (type === 'ACH') {
            setPaymentMethod("USE_EXISTING_SOURCE");
        } else {
            setPaymentMethod("");
        }
        setUpdate(moment());
    };

    let onPaymentMethodChange = (e) => {
        setPaymentMethod(e.target.value);
        setUpdate(moment());
    }

    let handlePaymentSubmit = (e) => {
        if (existingSourceId && paymentMethod) {
            let result = {}
            result.payment_method = paymentMethod;
            result.payment_source_id = existingSourceId;

            let arr = {
                'CREDIT_CARD_USING_TOKEN': "Existing Credit Card",
                'ACH': "Existing ACH account",
            }

            handlePaymentData({
                selectionText: `Payment method has been selected (${arr[paymentSourceType]}).`,
                payment_source: result,
                isOriginalSource: false,
            });
        }
    }

    const handleSubmit = (stripe, elements, payment_type) => async () => {
        if (!cardProcessing) {
            cardProcessing = true;
            const cardElement = elements.getElement(CardNumberElement);
            const stripeToken = await stripe.createToken(cardElement);

            cardProcessing = false;
            if (stripeToken.error) {
                toast["error"](stripeToken.error.message);
            } else {
                let result = {};
                result.payment_transaction_type = payment_type;
                result.payment_source_type = paymentSourceType
                result.payment_method = paymentMethod;
                result.source_token = stripeToken.token.id;

                handlePaymentData({
                    selectionText: "Payment method has been selected. (New Credit Card)",
                    payment_source: result,
                    isOriginalSource: false,
                });
            }
        }
    };

    let handlePaymentData = (response) => {
        handleToggleModal(isOpenPayModal);
        onPaymentSourceCb(response);
    };

    const handleCardNumber = (element) => {
        if (element.elementType === "cardNumber" && element.complete) {
            validSingleUse.cardNumber = true;
        } else if (element.elementType === "cardNumber" && !element.complete) {
            validSingleUse.cardNumber = false;
        }

        if (element.elementType === "cardExpiry" && element.complete) {
            validSingleUse.cardExpiry = true;
        } else if (element.elementType === "cardExpiry" && !element.complete) {
            validSingleUse.cardExpiry = false;
        }

        if (element.elementType === "cardCvc" && element.complete) {
            validSingleUse.cardCvc = true;
        } else if (element.elementType === "cardCvc" && !element.complete) {
            validSingleUse.cardCvc = false;
        }
        validateFormSingle(validSingleUse);

    }

    let validateFormSingle = (cardElement) => {
        if (cardElement.cardNumber && cardElement.cardExpiry && cardElement.cardCvc) {
            setIsValidSingle(true);
        }
    }

    let FeatureUseForm = () => {
        const stripe = useStripe();
        const elements = useElements();
        return (
            <Col xs="6">
                <Col sm="12" md="12" className="text-center">
                    <div className="btn-group stripe-buttons" role="group">
                        <AvInput type="radio" name="customCheckbox" className={cardUsageType === 'multiple_use' ? 'btn-check selected' : "btn-check"} id="multi_use_id" value="multiple_use" onChange={event => setCardUsageType(event.target.value)} />
                        <Label className="btn btn-outline-info" for="multi_use_id">Enter Card for Future Orders</Label>
                    </div>
                    <hr className="mt-2 mb-2" />
                </Col>
                <div className={cardUsageType === 'multiple_use' ? '' : "disabled"}>
                    <Col sm="12" md="12">
                        <AvGroup>
                            <Label className="form-control-label">Card Number<span className="required">*</span></Label>
                            <CardNumberElement className="form-control" />
                        </AvGroup>
                    </Col>
                    <Col sm="12" md="12">
                        <AvGroup>
                            <Label className="form-control-label">Expiration<span className="required">*</span></Label>
                            <CardExpiryElement className="form-control" />
                        </AvGroup>
                    </Col>
                    <Col sm="12" md="12">
                        <AvGroup>
                            <Label className="form-control-label">CVC<span className="required">*</span></Label>
                            <CardCvcElement className="form-control" />
                        </AvGroup>
                    </Col>
                    <Col sm="12" md="12">

                        <AvGroup>
                            <Button size="sm" color="primary" onClick={handleSubmit(stripe, elements, 'multiple_use')}>
                                Submit
                            </Button>
                        </AvGroup>
                    </Col>
                </div>
            </Col>
        )
    }

    let SingleUseForm = () => {
        const stripe = useStripe();
        const elements = useElements();
        return (
            <Col xs="6">
                {!sourPageForNewCardOnly.includes(sourcePage) &&
                    <Col sm="12" md="12" className="text-center">
                        <div className="btn-group stripe-buttons" role="group">
                            <AvInput type="radio" name="customCheckbox" className={cardUsageType === 'single_use' ? 'btn-check selected' : "btn-check"} id="single_use_id" value="single_use" onChange={event => setCardUsageType(event.target.value)} />
                            <Label className="btn btn-outline-info" for="single_use_id">Use Card for Single Use</Label>
                        </div>
                        <hr className="mt-2 mb-2" />
                    </Col>
                }

                <div className={cardUsageType === 'single_use' ? '' : "disabled"}>
                    <Col sm="12" md="12">
                        <AvGroup>
                            <Label className="form-control-label">Card Number<span className="required">*</span></Label>
                            <CardNumberElement
                                className="form-control"
                                onChange={handleCardNumber} />
                        </AvGroup>
                    </Col>
                    <Col sm="12" md="12">
                        <AvGroup>
                            <Label className="form-control-label">Expiration<span className="required">*</span></Label>
                            <CardExpiryElement
                                className="form-control" />
                        </AvGroup>
                    </Col>
                    <Col sm="12" md="12">
                        <AvGroup>
                            <Label className="form-control-label">CVC<span className="required">*</span></Label>
                            <CardCvcElement
                                className="form-control" />
                        </AvGroup>
                    </Col>
                    <Col sm="12" md="12">
                        <AvGroup>
                            <Button size="sm" color="primary" className={isValidSingle ? '' : 'disabled'} onClick={handleSubmit(stripe, elements, 'single_use')}>
                                Submit
                            </Button>
                        </AvGroup>
                    </Col>
                </div>
            </Col>
        )
    }

    const CheckoutForm = () => {
        return (
            <>
                <AvForm onValidSubmit={(e) => handleSubmit()} className="ml--2 text-left" >
                    <Row className="mt-3">
                        {!sourPageForNewCardOnly.includes(sourcePage) &&
                            <Elements stripe={stripePromise}>
                                <FeatureUseForm />
                            </Elements>
                        }
                        <Elements stripe={stripePromise}>
                            <SingleUseForm />
                        </Elements>

                    </Row>
                </AvForm>
            </>
        );
    };

    const addToInvoice = () => {
        confirmAlert({
            title: '',
            message: "Are you sure you want to add this amount to the invoice ?",
            closeOnClickOutside: false,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleAddToInvoice(),
                    className: "btn btn-primary btn-sm"
                }, {
                    label: 'Close',
                    onClick: () => { },
                    className: "btn btn-outline-danger btn-sm"
                }
            ]
        });
    }

    const handleAddToInvoice = () => {
        handlePaymentData({
            selectionText: "Payment method has been selected. (Add To Invoice)",
            payment_source: { payment_method: 'ADD_TO_INVOICE' },
            isOriginalSource: false,
        });
    }

    return (
        <>
            <Modal className="modal-dialog-centered modal-xl" isOpen={isOpenPayModal} toggle={() => handleToggleModal(onPay.current)}>
                <Spinner isShow={isProcess} />
                <div className="modal-header modal-header-colored">
                    <h4 className="modal-title w-100 text-center">
                        Add/Select Payment Source
                    </h4>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenPayModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col md="3" className="text-left">
                            <Row>
                                <Col md="12">
                                    <label className="form-control-label">Total Amount</label>
                                    <h2><b> {priceNumber(parseFloat(paymentDetails?.total_amount) + parseFloat(sourPageForNewCardOnly.includes(sourcePage) ? (paymentDetails?.cc_surcharge ? paymentDetails?.cc_surcharge : 0) : 0))} </b></h2>
                                    <hr className="mt-1 mb-2" />
                                </Col>
                                {companyData?.nickname &&
                                    <Col md="12">
                                        <label className="form-control-label">Client</label>
                                        <h3><b>{companyData?.nickname}</b></h3>
                                        <hr className="mt-1 mb-2" />
                                    </Col>
                                }
                                <Col md="12">
                                    <label className="form-control-label">Payment For</label>
                                    <h3><b>{paymentDetails?.paymentFor}</b></h3>
                                    <hr className="mt-1 mb-2" />
                                </Col>
                            </Row>
                        </Col>
                        <Col md="9">
                            <AvForm onValidSubmit={(e) => handlePaymentSubmit(e)} className="text-left" model={defaultValues}>
                                {!sourPageForNewCardOnly.includes(sourcePage) &&

                                    <>
                                        <Row>
                                            <Col md="12">
                                                <AvGroup>
                                                    <Label className="required form-control-label">Please select a payment method.</Label>
                                                    <div className="custom-radio-btn inline">
                                                        <div>
                                                            <input
                                                                type="radio"
                                                                id="ACH"
                                                                name="payment_type"
                                                                value="ACH"
                                                                onChange={(e) => onPaymentSourceTypeChange(e)}
                                                            // checked={propertyType.slug === (intakeData.orderInformation && intakeData.orderInformation.property_type)}
                                                            />
                                                            <label htmlFor="ACH">ACH</label>
                                                        </div>
                                                        <div>
                                                            <input
                                                                type="radio"
                                                                id="CREDIT_CARD_USING_TOKEN"
                                                                name="payment_type"
                                                                value="CREDIT_CARD_USING_TOKEN"
                                                                onChange={(e) => onPaymentSourceTypeChange(e)}
                                                            // checked={propertyType.slug === (intakeData.orderInformation && intakeData.orderInformation.property_type)}
                                                            />
                                                            <label htmlFor="CREDIT_CARD_USING_TOKEN">Credit Card <span className="cc_surcharge">( <b>{ccSurchargeText}</b>&nbsp;Additional charge)</span></label>
                                                        </div>
                                                        {reportInvoicing === '1' && <>
                                                            <div>
                                                                <input
                                                                    type="radio"
                                                                    id="ADD_TO_INVOICE"
                                                                    name="payment_type"
                                                                    value="ADD_TO_INVOICE"
                                                                    onChange={(e) => addToInvoice()}
                                                                // checked={propertyType.slug === (intakeData.orderInformation && intakeData.orderInformation.property_type)}
                                                                />
                                                                <label htmlFor="ADD_TO_INVOICE">Add To Invoice </label>
                                                            </div>
                                                        </>}
                                                    </div>
                                                </AvGroup>
                                            </Col>
                                        </Row>

                                        {(paymentSourceType !== "" && paymentSourceType !== "ACH") &&
                                            <Row>
                                                <Col md="8">
                                                    <AvGroup>

                                                        <Label className="required form-control-label">Please select either you want to use existing card or add new card.</Label>
                                                        <div className="custom-radio-btn inline radio-w-50-p">
                                                            <div>
                                                                <input type="radio" id="USE_EXISTING_SOURCE" name="payment_method"
                                                                    value="USE_EXISTING_SOURCE"
                                                                    onChange={(e) => onPaymentMethodChange(e)}
                                                                />
                                                                <label htmlFor="USE_EXISTING_SOURCE">Pay Using Existing Source</label>
                                                            </div>
                                                            <div>
                                                                <input type="radio" id="ADD_NEW_SOURCE" name="payment_method"
                                                                    value="ADD_NEW_SOURCE"
                                                                    onChange={(e) => onPaymentMethodChange(e)}
                                                                />
                                                                <label htmlFor="ADD_NEW_SOURCE">Add New Source</label>
                                                            </div>
                                                        </div>

                                                    </AvGroup>
                                                </Col>
                                            </Row>
                                        }
                                        <Row>
                                            {(paymentSourceType === "ACH") ?
                                                ACHSources.length > 0 ?
                                                    <>
                                                        <Col md="4">
                                                            <Label className="required form-control-label">Select ACH Account</Label>
                                                            <AvField type="select" name="ach_payment_source_id" onChange={e => setExistingSourceId(e.target.value)} required>
                                                                <option value="">Select Your ACH</option>
                                                                {ACHSources.map(function (source, i) {
                                                                    return (
                                                                        <option key={i} value={source.payment_source_id}>************{source.last_4}</option>
                                                                    );
                                                                })}

                                                            </AvField>
                                                        </Col>
                                                    </>
                                                    :
                                                    <>
                                                        <Col className="col-md-12">
                                                            <Label>You don't have ACH account on file for this order. Normal approval time is 1-3 business days.</Label>
                                                            <Label>Click <Link className="custom-link" to={userData.is_client === true ? "/client/payment-methods" : "/admin/payment-methods"} target="_blank">here</Link> to setup your account so it's available for your next order.</Label>
                                                        </Col>
                                                    </>
                                                : null
                                            }
                                        </Row>

                                        {paymentMethod === 'USE_EXISTING_SOURCE' &&
                                            <>
                                                <Row className="">
                                                    {(paymentSourceType === "CREDIT_CARD_USING_TOKEN") ?
                                                        creditCardSources.length > 0 ?
                                                            <>
                                                                <Col md="4">
                                                                    <Label className="required form-control-label">Select Credit Card</Label>
                                                                    <AvField type="select" name="card_payment_source_id" onChange={e => setExistingSourceId(e.target.value)} required>
                                                                        <option value="">Select Your Card</option>
                                                                        {creditCardSources.map(function (source, i) {
                                                                            return (
                                                                                <option key={i} value={source.payment_source_id}>************{source.last_4}</option>
                                                                            );
                                                                        })}
                                                                    </AvField>
                                                                </Col>
                                                            </>
                                                            :
                                                            <>
                                                                <Col className="col-md-12">
                                                                    <Label>You don't have Credit Card on this order.</Label>
                                                                </Col>

                                                            </>
                                                        : null}
                                                </Row>
                                                <Row className="m-3">
                                                    <Col className="col-md-6"><hr className="mt-1 mb-2" /></Col>
                                                    <Col className="col-md-6 text-right">
                                                        <hr className="mt-1 mb-2" />
                                                        <Button size="sm" color="danger" outline className="w-30 " onClick={() => handleToggleModal(isOpenPayModal)} >
                                                            Cancel
                                                        </Button>
                                                        {paymentMethod === "USE_EXISTING_SOURCE" &&
                                                            <Button size="sm" color="primary" className="w-30" >
                                                                Select
                                                            </Button>
                                                        }
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                    </>
                                }

                            </AvForm>

                            {paymentMethod === 'ADD_NEW_SOURCE' &&
                                <>
                                    {!sourPageForNewCardOnly.includes(sourcePage) &&
                                        <Row className="mt-0">
                                            <Col ><hr className="mt-1 mb-2" /></Col>
                                        </Row>
                                    }
                                    <CheckoutForm />

                                    <Row className="mt-3">
                                        <Col className="col-md-6"><hr className="mt-1 mb-2" /></Col>
                                        <Col className="col-md-6 text-right">
                                            <hr className="mt-1 mb-2" />
                                            <Button size="sm" color="danger" outline className="w-30 " onClick={() => handleToggleModal(isOpenPayModal)} >
                                                Cancel
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                            }
                        </Col>
                    </Row>

                </div>
            </Modal>
        </>
    );
};

export default PaymentForm;