import { useState } from "react";
import { Popover, PopoverBody, PopoverHeader, UncontrolledPopover } from "reactstrap";

let popoverIdCounter = 0;

const PopoverOnHover = ({ children, placement = "right", title = "", description = "" }) => {

    const [popoverOpen, setPopoverOpen] = useState(false);

    const [uniqueId] = useState(() => {
        popoverIdCounter++;
        return `popover-${popoverIdCounter}`;
    });

    const togglePopover = () => {
        setPopoverOpen(!popoverOpen);
    };

    return (
        <>
            <span id={uniqueId} onMouseEnter={togglePopover} onMouseLeave={togglePopover}>{children}</span>
            <Popover placement={placement} isOpen={popoverOpen} target={uniqueId}>
                <PopoverHeader>{title}</PopoverHeader>
                <PopoverBody>{description}</PopoverBody>
            </Popover>
        </>
    )
}

export const PopoverOnClick = ({ id, placement = "right", type = "focus", title = "", children = "", description = "" }) => {

    const [uniqueId] = useState(() => {
        return `popover-${parseInt(Math.random() * 1000 * new Date().getTime())}`;
    });


    return (
        <>
            <div
                id={String(id || uniqueId)}
                style={{ cursor: 'pointer' }}
            >
                {children}
            </div>

            <UncontrolledPopover
                placement={placement}
                target={String(id || uniqueId)}
                trigger={type}
            >
                <PopoverHeader>
                    {title}
                </PopoverHeader>
                <PopoverBody>
                    {description}
                </PopoverBody>
            </UncontrolledPopover>
        </>
    );
};


export default PopoverOnHover;
