import React from "react";

const Error = ({ errorMessage = "" }) => {
    return (
        <>
            {errorMessage &&
                <div className="text-danger mt-2">
                    {errorMessage}
                </div>
            }
        </>
    );
}
export default Error;
