import React, { useEffect } from "react";
import { Button, Col, Modal, Row } from "reactstrap";
import Spinner from "components/Common/Spinner";
import { useState } from "react";
import * as moment from "moment";
import { PutData as OrderIntakePut } from "services/OrderIntakeData";
import { sendExceptionEmail } from "components/Common/Helpers";
import { toast } from "react-toastify";
import { AvForm } from "availity-reactstrap-validation";

import GoogleAddressOrLatLong from "../GoogleOrLatLongAddress/GoogleOrLatLongAddress";
import RenderActionButton from "./RenderActionButton";

const UpdateAddressButton = ({ propRowData, propButtonOptions }) => {

    let [isOpenModal, setIsOpenModal] = useState(false);
    let [update, setUpdate] = useState(moment());
    let [isProcess, setProcess] = useState(false);
    let [addressInfo, setAddressInfo] = useState([]);

    useEffect(() => { }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

    let handleActionClick = async (e, row) => {
        e.preventDefault();
        setIsOpenModal(true);
        setUpdate(moment())
    };

    let handleToggleModal = (state) => {
        setIsOpenModal(!state);
        setUpdate(moment());
    };

    let sendToBorrower = async (e) => {
        try {

            let addressObj = addressInfo?.googleAddress?.formatted_address ? addressInfo?.googleAddress : addressInfo?.latLongAddress;

            let bodyParams = {
                "property_address": addressObj?.address_1,
                "city": addressObj?.city,
                "state": addressObj?.state,
                "postal_code": addressObj?.postal_code,
            };

            if (addressObj?.address_2) {
                bodyParams.property_address_line_2 = addressObj?.address_2;
            }

            setProcess(true);
            let response = await OrderIntakePut(`inspection/${propRowData.inspection_order_token}/modify-address`, bodyParams, propRowData.company_id)
            handleToggleModal(isOpenModal);
            setProcess(false);
            toast["success"](response.message);
        } catch (error) {
            await sendExceptionEmail(error);
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            setProcess(false);
        }
    };

    let handleAddressCB = (addressInfo) => {
        setAddressInfo(addressInfo);
    };

    return (
        <>
            <RenderActionButton propButtonTitle="Update Address" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />

            {isOpenModal &&

                <Modal
                    className="modal-dialog-centered"
                    size="lg"
                    isOpen={isOpenModal}
                    toggle={() => handleToggleModal(isOpenModal)}
                >
                    <div className="modal-header modal-header-colored">
                        <h2 className="modal-title text-center w-100">
                            Update Address
                        </h2>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenModal)}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Spinner isShow={isProcess} />
                        <AvForm className="text-left">
                            <GoogleAddressOrLatLong customErrors={[]} cb={handleAddressCB} />
                            <Row className="mt-5">
                                <Col lg="12" className="text-right">
                                    <Button color="danger" size="sm" outline onClick={() => handleToggleModal(isOpenModal)} >
                                        <i className="fa fa-times"></i> Cancel
                                    </Button>
                                    <Button color="info" size="sm" outline onClick={(e) => { sendToBorrower(e) }}>
                                        <i className="fa fa-save"></i> Submit
                                    </Button>
                                </Col>
                            </Row>
                        </AvForm>
                    </div>
                </Modal>
            }
        </>
    );
};

export default UpdateAddressButton;