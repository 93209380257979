import React, { useState, useEffect } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Col, Row, UncontrolledTooltip } from "reactstrap";
import { PostData } from "services/Api.service";
import { TASK_SLUG } from "../Constants/TimeTracking.constant";
import { periodObservance } from 'components/Common/TimeTrackingHelpers';
import { loggedInUser } from "components/Common/Helpers.js"
import DivSpinner from "components/Common/DivSpinner";
import { sendExceptionEmail } from "components/Common/Helpers";
import { toast } from "react-toastify";
const userData = loggedInUser();

const OtherLinks = (props) => {
    const [data, setData] = useState([]);
    const [realtorLink, setRealtorLink] = useState("");
    const [redfinLink, setRedfinLink] = useState("");
    const [isProcess, setProcess] = useState(false);

    useEffect(() => {
        let tempData = props.props;
        setData(tempData);
    }, [props]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setProcess(true);
                const tempData = props.props;
                const response = await PostData('public/search-property-on-google', {
                    address: tempData.address,
                    city: tempData.city,
                    state: tempData.state,
                    postal_code: tempData.postal_code
                });

                if (response.success && response.data) {
                    setRealtorLink(response.data.realtor);
                    setRedfinLink(response.data.redfin);
                }
                setProcess(false);
            } catch (error) {
                setProcess(false);
                await sendExceptionEmail(error)
                toast["error"]("Something went wrong.");
            }
        };
        fetchData();
    }, [props.props.address]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleRedfinClick = async () => {
        if (data.isFromSubjectPropertyCondition) {
            await periodObservance({
                taskData: data.taskData,
                slug: TASK_SLUG.SUBJECT_CONDITION_REDFIN,
                orderData: data.orderData,
                userData,
                closingTask: 0,
                newTabCheck: 0
            });
        }
    };

    const handleRealtorClick = async () => {
        if (data.isFromSubjectPropertyCondition) {
            await periodObservance({
                taskData: data.taskData,
                slug: TASK_SLUG.SUBJECT_CONDITION_REALTOR,
                orderData: data.orderData,
                userData,
                closingTask: 0,
                newTabCheck: 0
            });
        }
    };

    return (
        <div className="others-site text-center  mt-5">

            <DivSpinner isShow={isProcess} />
            {!isProcess &&
                <>
                    <Row>
                        <Col md="12">
                            <CopyToClipboard text={`${data.address} ${data.city} ${data.state} ${data.postal_code}`}>
                                <a href={redfinLink} target="_blank" rel="noreferrer" onClick={handleRedfinClick}>
                                    <img alt="redfin" src={require("../../../../assets/img/marketplace/redfin.png").default} />
                                </a>
                            </CopyToClipboard>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <CopyToClipboard text={`${data.address} ${data.city} ${data.state} ${data.postal_code}`}>
                                <a href={realtorLink} target="_blank" rel="noreferrer" onClick={handleRealtorClick}>
                                    <img alt="realtor" src={require("../../../../assets/img/marketplace/realtor.png").default} />
                                </a>
                            </CopyToClipboard>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <a href={`https://www.zillow.com/homes/${data.address}-${data.city}-${data.state}-${data.postal_code}_rb/`} target="_blank" rel="noreferrer">
                                <img alt="Zillow" src={require("../../../../assets/img/marketplace/zillow.png").default} />
                            </a>
                        </Col>
                    </Row>
                
                    <Row>
                        <Col md="12">
                            <a href={`https://app.regrid.com/search?query=${encodeURIComponent(data.address)}, ${encodeURIComponent(data.city)}, ${data.state} ${data.postal_code}&context=false&map_id=`} target="_blank" rel="noreferrer" id="regrid-tooltip" >
                                <img alt="regrid" src={require("../../../../assets/img/marketplace/regrid_new.png").default} className="regrid-image"/>
                            </a>
                            <UncontrolledTooltip placement="right" target="regrid-tooltip">
                                Regrid is an outside service which provides parcel information for properties across the country. An account is not required, and you can search any address from a browser and obtain relevant information. To use our direct property links to the parcel, though, will require that you set up a personal account which is available to individuals at no charge.
                            </UncontrolledTooltip>
                        </Col>
                    </Row>
             
                    <Row >
                        <Col md="12">
                            <a href={`https://www.google.com/maps/place/${encodeURIComponent(data.address)},+${encodeURIComponent(data.city)},+${data.state}+${data.postal_code}/`} target="_blank" rel="noreferrer">
                                <img alt="google-map" src={require("../../../../assets/img/marketplace/google-map.png").default} className="map-image" />
                            </a>
                        </Col>
                    </Row>
                </>
            }
        </div>
    );
};

export default OtherLinks;
