import React from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { sendExceptionEmail } from "components/Common/Helpers";
import { showSpinner } from "ShowHideSpinner.reducer";
import RenderActionButton from "../RenderActionButton";
import { loggedInUser } from "components/Common/Helpers";
import { PostData } from "services/Api.service";
import eventBus from "../../EventBus";

const ReportOrderRevisionAcceptRelinquishButton = ({ propRowData, propButtonOptions }) => {

    const dispatch = useDispatch();
    const userData = loggedInUser();

    const handleActionClick = async (e) => {
        try {
            let data = {
                report_id: propRowData.id,
                user_id: userData.user_id,
                status: propRowData.status
            };
            dispatch(showSpinner(true));

            if (propRowData.status === 'assign') {
                const response = await PostData(`orders/revise-assign-reassign`, data);
                if (response.data) {
                    dispatch(showSpinner(false));
                    toast["success"](response.message);
                    eventBus.dispatch("report_order_status_updated", { client_id: propRowData.company_id });
                }
            } else if (propRowData.status === 'reassign') {
                const response = await PostData(`orders/revise-assign-reassign`, data);
                if (response.data) {
                    dispatch(showSpinner(false));
                    toast["success"](response.message);
                    eventBus.dispatch("report_order_status_updated", { client_id: propRowData.company_id });
                }
            }
        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error)
            dispatch(showSpinner(false));
        };
    };

    return (<RenderActionButton propButtonTitle={propRowData.status === 'assign' ? 'Assign Reviewer' : 'Unassign Reviewer'} propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />);
};

export default ReportOrderRevisionAcceptRelinquishButton;