import React from "react";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import { showSpinner } from './../../../../../ShowHideSpinner.reducer'

import eventBus from "../../EventBus";
import { PostData } from "services/Api.service";
import { sendExceptionEmail } from "components/Common/Helpers";
import RenderActionButton from "../RenderActionButton";

const ReportOrderReleaseSnagButton = ({ propRowData, propButtonOptions }) => {

    const dispatch = useDispatch();

    let handleActionClick = (e) => {
        e.preventDefault();
        confirmAlert({
            title: '',
            message: 'You are about to release Snag. Are you sure ?',
            closeOnClickOutside: false,
            buttons: [
                {
                    label: 'Yes, Release Snag',
                    onClick: async () => {
                        await handleDismissReportIntakeProcess();
                    },
                    className: "btn btn-primary btn-sm"
                }, {
                    label: 'Close',
                    onClick: () => { },
                    className: "btn btn-outline-danger btn-sm"
                }
            ]
        });
    };

    let handleDismissReportIntakeProcess = async () => {

        try {
            dispatch(showSpinner(true));
            const response = await PostData("orders/release-snag", { reportOrderId: propRowData.report_order_id });
            toast["success"](response.message);
            dispatch(showSpinner(false));
            eventBus.dispatch("report_order_status_updated", { client_id: propRowData.company_id });
        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error);
            dispatch(showSpinner(false));
        }
    };

    return (
        <>
            <RenderActionButton propButtonTitle="Release Snag" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />
        </>
    );
};

export default ReportOrderReleaseSnagButton;