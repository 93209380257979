import React, { useState, useEffect } from "react";
import { Button, Modal, FormGroup, Col, Row, Label } from "reactstrap";

import { PostData, GetData as NewGetData } from "../../../../../services/Api.service.js";
import { PostData as oldPostData } from "../../../../../services/PostData.js";
import { loggedInUser, encryptData } from "components/Common/Helpers.js"
import { toast } from 'react-toastify';
import { AvForm, AvGroup, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import { checkRoles } from "components/Common/Helpers.js"
import eventBus from "../../EventBus.js";

import PaymentForm from "../../PaymentForm.js";
import ReportUpdateFeesModal from "../../../ClientPortal/Common/ReportUpdateFeesModal.js";
import ReportOrderOriginalSource from "../../ReportOrderOriginalSource.js";
import Dropzone from 'react-dropzone';
import * as moment from "moment";
import { RO_BUDGET_UPDATED, RO_DISPUTE_INITIATED, RO_MARKET_UPDATE, RO_NEW_SPECS, RO_REVISION_REQUESTED } from "../../Constants.js";
import { GetData as OrderIntakeGet } from "../../../../../services/OrderIntakeData.js";
import { sendExceptionEmail } from "components/Common/Helpers";
import ValuationReviewRequestModal from "views/modules/Common/DisputeOrder/ValuationReviewRequestModal";
import RenderActionButton from "views/modules/Common/ActionsButtons/RenderActionButton";

const ReopenFilesClientButton = ({ propReportRow, propButtonOptions }) => {

    const userData = loggedInUser();
    let [isProcess, setProcess] = useState(false);
    let [isOpenReopenFilesModal, setOpenReopenFilesModal] = useState(false);
    let [reopenStatus, setReopenStatus] = useState("");
    let [questionComment, setQuestionComment] = useState("");
    let [turnAroundtime, setTurnAroundtime] = useState("");
    let [tat, setTat] = useState([]);
    let [isReopenInspection, setReopenInspection] = useState("");
    let [inspectionTypeData, setInspectionTypeData] = useState([]);

    let [isContactInfoRequired, setContactInfoRequired] = useState(false);
    let [fields, setFields] = useState({
        contact_name: propReportRow.property_access_contacts.length ? propReportRow.property_access_contacts[0].contact_name : '',
        contact_email: propReportRow.property_access_contacts.length ? propReportRow.property_access_contacts[0].contact_email : '',
        contact_phone: propReportRow.property_access_contacts.length ? propReportRow.property_access_contacts[0].contact_phone : '',
    });
    let [reopenData, setReopenData] = useState([]);
    let [showStripeMsg, setShowStripeMsg] = useState(false);
    let [totalCharge, setTotalCharge] = useState(0);
    let [showRefundMsg, setShowRefundMsg] = useState(false);

    let [maxDisputeSize] = useState(10485760);
    let [DisputeFileUploadError, setDisputeFileUploadError] = useState("");
    let [selectedFileName, setSelectedFileName] = useState("");
    let [selectedDisputeFiles, setSelectedDisputeFiles] = useState([]);
    let [update, setUpdate] = useState(moment());

    let [isOpenPaymentSourceModal, setIsOpenPaymentSourceModal] = useState({ current: false });
    let [clientId, setClientId] = useState();
    let [selectedPaymentSource, setSelectedPaymentSource] = useState(null);
    let [paymentError, setPaymentError] = useState(null);
    let [defaultSourceChecked, setDefaultSourceChecked] = useState(false);
    let isOpenReportUpdateFeesModal = React.useRef(false);
    let [eligibleInspectionTypes, setEligibleInspectionTypes] = useState([]);
    let [inspectionType, setInspectionType] = useState(null);
    let [glaAndLicensingSettings, setGlaAndLicensingSettings] = useState([]);

    let [isDisputeModal, setDisputeModal] = useState(false);
    let [orderData, setOrderData] = useState({});

    let defaultValues = {
        vendor_id: null,
        inspection_vendor_eligibility_id: null,
        contact_name: propReportRow.property_access_contacts.length ? propReportRow.property_access_contacts[0].contact_name : '',
        contact_email: propReportRow.property_access_contacts.length ? propReportRow.property_access_contacts[0].contact_email : '',
        contact_phone: propReportRow.property_access_contacts.length ? propReportRow.property_access_contacts[0].contact_phone : '',
    };

    useEffect(() => {
        setSelectedPaymentSource(null);
        setPaymentError(null);
        setDefaultSourceChecked(false);
        setEligibleInspectionTypes([]);
        setClientId(propReportRow.company_id);
    }, [propReportRow]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { }, [update, fields]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let asyncFuncs = async () => {
            if (inspectionType) {
                await getInspectionsFees(inspectionType);
            }
        }
        asyncFuncs()
    }, [inspectionType, fields?.gla_include, fields?.licensing_required]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        showHideStripeMessage();
    }, [inspectionTypeData, reopenData]);// eslint-disable-line react-hooks/exhaustive-deps

    let handleToggleModal = (state) => {
        if (!isProcess) {
            state = !state;
            setOpenReopenFilesModal(state);
            setSelectedDisputeFiles([]);
            setSelectedFileName("");
            setDisputeFileUploadError("")
            setShowStripeMsg(false);
            setPaymentError(null);
            setReopenInspection(null);
            setInspectionTypeData([]);
            setEligibleInspectionTypes([]);

            defaultValues.inspection_vendor_eligibility_id = '0';
            setUpdate(moment());
        }
    };

    let handleStatusTypeBtn = (id) => {
        setInspectionTypeData([]);
        setEligibleInspectionTypes([]);
        setTat([]);
        setSelectedDisputeFiles([]);
        setSelectedFileName("");
        setDisputeFileUploadError("")

        if (RO_DISPUTE_INITIATED === parseInt(id)) {
            setDisputeModal(true);
            orderData.id = propReportRow.id;
            orderData.property_id = propReportRow.property_id;
            orderData.company_id = propReportRow.company_id;
            setOrderData(orderData);
        }

        setUpdate(moment());
        setReopenStatus(id);
    }

    let handleOrderStatus = async (e, type, data) => {
        setReopenStatus("");
        setOpenReopenFilesModal(true);

        let fieldsTemp = {}
        fieldsTemp = { ...fieldsTemp, ...await retrieveGLAAndLicensingSettings() }
        fieldsTemp = { ...fieldsTemp, fields }

        setFields(fieldsTemp);
        setUpdate(moment());
    }

    let getReportOrderFees = async (val, action_id) => {
        try {
            setEligibleInspectionTypes([]);
            handleStatusTypeBtn(val);
            setReopenInspection(null);
            setTat([]);
            setReopenStatus(action_id);
            setProcess(true);
            let data = {
                id: propReportRow.id,
                action_id: action_id,
            }

            let encrypt = encryptData(data);
            let result = await PostData("client/orders/report_order_fees", { data: encrypt });
            if (result.data) {
                let reportFeesData = {
                    amount: result.data.orderAmount,
                }
                setReopenData(reportFeesData);
            }
            setProcess(false);
        } catch (error) {
            setProcess(false);
            toast["error"](error.errors && error.errors.length ? error.errors[0].message : error.message);
        }
    };

    let handleReopenFilesModal = async (e) => {
        e.preventDefault();
        if (parseInt(reopenStatus) === RO_DISPUTE_INITIATED && selectedDisputeFiles.length === 0) {
            setDisputeFileUploadError("Please upload one file.");
            return false;
        }

        if (reopenStatus && propReportRow.id) {
            let tatValues = turnAroundtime.split("/");
            let params = {};
            const data = new FormData();

            params.id = propReportRow.id;
            params.property_id = propReportRow.property_id;
            params.action_id = reopenStatus;
            params.question_comment = questionComment;
            params.inspections_status = propReportRow.inspection_order.inspection_status;
            params.tat_type = (tatValues[0] === 'Standard') ? 'standard' : 'rush';
            params.tat_days = tatValues[1];
            params.inspection_order_id = propReportRow.inspection_order.id;

            if (isReopenInspection && isReopenInspection !== 'none') {
                params.cancel_reopen_inspection = isReopenInspection;
                params.inspection_type = inspectionType;
            }

            if (selectedDisputeFiles.length) {
                data.append(`uploaded_files`, selectedDisputeFiles[0]);
            }

            if (selectedPaymentSource && selectedPaymentSource.payment_source) {
                for (let key in selectedPaymentSource.payment_source) {
                    data.append('payment_source[' + key + ']', selectedPaymentSource.payment_source[key]);
                }
            }

            if (fields) {
                for (let currentField in fields) {
                    data.append('other_fields[' + currentField + ']', fields[currentField]);
                }
            }

            for (var key in params) {
                data.append(key, params[key]);
            }

            setProcess(true);
            try {
                let result = await oldPostData("client/orders/reopen_files", data);
                setProcess(false);
                if (result.data.status === 200) {
                    toast["success"](result.data.message);
                    eventBus.dispatch("report_order_status_updated", { client_id: propReportRow.company_id });
                    setOpenReopenFilesModal(false);
                } else {
                    if (result.data.err_code === 'PAYMENT_ERROR' && selectedPaymentSource?.isOriginalSource === true) {
                        setPaymentError(result.data.message);
                        setSelectedPaymentSource(null);
                    } else {
                        eventBus.dispatch("report_order_status_updated", { client_id: propReportRow.company_id });
                        toast["error"](result.data.message);
                    }
                }
            } catch (errors) {
                setProcess(false);
                toast["error"]("Something went wrong.");
            }
        } else {
            setProcess(false);
            toast["error"]("Something went wrong.");
        }
    };

    let onDropDispute = (files) => {
        setSelectedFileName("");
        let tempArr = [];
        tempArr = files;
        let fileName = tempArr ? tempArr[0] : "";
        if (fileName) {
            setSelectedFileName(fileName.name);
        }
        setSelectedDisputeFiles(tempArr);
        setDisputeFileUploadError("");
        setUpdate(moment());
    };

    let onDropRejectedDispute = (rejectedFiles) => {
        console.log('rejectedFiles', rejectedFiles);

        const DisputeFileUploadError = rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].errors[0].message;
        setDisputeFileUploadError(DisputeFileUploadError);
        setUpdate(moment());
    };

    let handleUpdateInspection = (updateInspectionType, ven_id) => {
        setReopenInspection(updateInspectionType);
        setInspectionType(null);
        if (updateInspectionType === "choose_different") {
            onHandleVendorChange(ven_id);
        } else {
            setEligibleInspectionTypes([]);
            setTat([]);
        }
        setUpdate(moment());

    }

    // Get Tat
    let getTat = async (reportTypeSlug) => {
        try {
            if (reportTypeSlug) {
                let result = await OrderIntakeGet("tat", { company_token: "{company_token}", report_type: reportTypeSlug }, clientId);
                let responseData = result.data.tat;
                setTat(responseData);
            }
        } catch (error) {
            await sendExceptionEmail(error);
            toast["error"](error.errors && error.errors.length ? error.errors[0].message : error.message);
        }
    }

    let onHandleVendorChange = async (vendor_id) => {
        try {
            if (parseInt(reopenStatus) === RO_MARKET_UPDATE) {
                getTat(propReportRow.slug);
            }

            setInspectionTypeData([]);
            setEligibleInspectionTypes([]);
            defaultValues.inspection_vendor_eligibility_id = '0';
            setContactInfoRequired(false);

            let params = {
                vendor_id: vendor_id,
                company_id: propReportRow.company_id,
                inspection_type: 'report-inspection',
                report_type_id: propReportRow.report_type_id
            };

            const result = await NewGetData("inspection/vendor-eligible-inspection-types", params)
            setEligibleInspectionTypes(result.data.inspectionTypes);
            setProcess(false);

        } catch (error) {
            toast["error"](error.errors && error.errors.length ? error.errors[0].message : error.message);
        }
    };

    let onInspectionTypeChanged = async (val) => {

        let { slug, contact_info_required, gla_applicable, licensing_applicable } = val

        let fieldsTemp = fields

        fieldsTemp.gla_applicable = gla_applicable
        fieldsTemp.licensing_applicable = licensing_applicable

        setFields(fieldsTemp);

        setInspectionType(slug);
        setContactInfoRequired(contact_info_required === '1' ? true : false);
        setUpdate(moment());
    };

    let retrieveGLAAndLicensingSettings = async () => {
        let requestDataTemp = {};
        try {
            const glaAndLicensingSettingsResp = await OrderIntakeGet("company/gla-licensing-settings", { company_token: '{company_token}', }, propReportRow.company_id);
            setGlaAndLicensingSettings(glaAndLicensingSettingsResp.data)

            if (!requestDataTemp['gla_include'] && (glaAndLicensingSettingsResp.data?.glaAndLicensingSettings?.gla_default === '1' || glaAndLicensingSettingsResp.data?.glaAndLicensingSettings?.gla_required === '1')) {
                requestDataTemp['gla_include'] = '1'
            }

            if (!requestDataTemp['licensing_required'] && (glaAndLicensingSettingsResp.data?.glaAndLicensingSettings?.licensed_default === '1' || glaAndLicensingSettingsResp.data?.glaAndLicensingSettings?.licensed_required === '1')) {
                requestDataTemp['licensing_required'] = '1'
            }

            return requestDataTemp;
        } catch (error) {
            toast["error"](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error);
        }
    };

    let onDataChange = (e) => {

        let { name, value, checked } = e.target;

        if (['gla_include', 'licensing_required'].includes(name)) {
            value = checked ? '1' : '0';
        }

        let fieldsTemp = { ...fields };
        fieldsTemp[name] = value

        setFields(fieldsTemp);
        setUpdate(moment());
    };

    let getInspectionsFees = async (inspection_type) => {
        let params = {
            inspection_order_type: "report-inspection",
            report_order_id: propReportRow.id,
            new_inspection_type: inspection_type,
            gla_include: fields?.gla_applicable === '1' ? fields.gla_include : '0',
            licensing_required: fields?.licensing_applicable === '1' ? fields.licensing_required : '0',
        };
        try {
            defaultValues.inspection_type = '0';
            setProcess(true);
            const result = await PostData("inspection/price-comparison", params)
            const totalVariance = result.data.totalVariance;
            let reportFeesData = {
                amount: totalVariance,
            }
            setInspectionTypeData(reportFeesData);
            setProcess(false);
        } catch (errors) {
            setProcess(false);
            toast["error"]("Something went wrong.");
        };
    };

    let showHideStripeMessage = async () => {
        setShowStripeMsg(false);
        setShowRefundMsg(false);

        let ro_amt = reopenData?.amount ? reopenData.amount : 0
        let io_amt = inspectionTypeData?.amount ? inspectionTypeData.amount : 0

        let total_charge = parseFloat(ro_amt) + parseFloat(io_amt);
        total_charge = parseFloat(total_charge).toFixed(2)

        setTotalCharge(total_charge);

        if (parseFloat(total_charge) > 0) {
            setShowStripeMsg(true);
        } else if (parseFloat(total_charge) < 0) {
            setShowRefundMsg(true);
        }
    };

    let openPaymentMethodSelector = () => {
        setSelectedPaymentSource(null);
        setIsOpenPaymentSourceModal({ current: true });
    };

    let handlePaymentSourceCb = (result) => {
        if (result) {
            setSelectedPaymentSource(result);
            setUpdate(moment());
        }
    };

    let onReportOrderOriginalSourceCB = (result) => {
        setSelectedPaymentSource(result);
        setDefaultSourceChecked(true);
        setUpdate(moment());
    };

    let getReviewRequestModal = (data) => {
        setDisputeModal(data.state);
        if (!data.state) {
            setOpenReopenFilesModal(data.state);
        }
    }

    return (
        <>
            <RenderActionButton propButtonTitle="Reopen File" propButtonOptions={propButtonOptions} propOnClickEvent={e => handleOrderStatus(e, 'reopen_files', propReportRow)} />

            {/* Reopen Files Modal */}
            <Modal className="modal-dialog-centered reopen-files modal-lg" isOpen={isOpenReopenFilesModal} toggle={() => handleToggleModal("isOpenReopenFilesModal")}>
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title w-100 text-center" id="reassignModalLabel">
                        Reopen Files
                    </h2>

                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal("isOpenReopenFilesModal")}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body text-left">
                    <h2 className="text-center">Purpose of Reopening this file:</h2>
                    <AvForm onValidSubmit={(e) => handleReopenFilesModal(e)} >
                        <Row>
                            <Col xs="12" sm="12" md="12">
                                <div className="custom-radio-btn inline">

                                    <div>
                                        <input type="radio" id="edits" name="action_id" value={RO_REVISION_REQUESTED} onChange={(e) => handleStatusTypeBtn(e.target.value)} />
                                        <label htmlFor="edits">Edits</label>
                                    </div>
                                    {parseInt(propReportRow.renovation_budget_needed) === 1 &&
                                        <div>
                                            <input type="radio" id="new_budget" name="action_id" value={RO_BUDGET_UPDATED} onChange={(e) => getReportOrderFees(e.target.value, RO_BUDGET_UPDATED)} />
                                            <label htmlFor="new_budget">New Budget</label>
                                        </div>
                                    }
                                    <div>
                                        <input type="radio" id="new_specs" name="action_id" value={RO_NEW_SPECS} onChange={(e) => getReportOrderFees(e.target.value, RO_NEW_SPECS)} />
                                        <label htmlFor="new_specs">New Specs</label>
                                    </div>
                                    {(checkRoles(['MODERATOR']) === true || parseInt(userData.auth_for_disputes) === 1 || parseInt(propReportRow.revoke_dispute_permissions) === 1) &&
                                        <div>
                                            <input type="radio" id="dispute" name="action_id" value={RO_DISPUTE_INITIATED} onChange={(e) => handleStatusTypeBtn(e.target.value)} />
                                            <label htmlFor="dispute">ROV</label>
                                        </div>
                                    }
                                    <div>
                                        <input type="radio" id="market_update" name="action_id" value={RO_MARKET_UPDATE} onChange={(e) => getReportOrderFees(e.target.value, RO_MARKET_UPDATE)} />
                                        <label htmlFor="market_update">Market Update</label>
                                    </div>
                                    {
                                        (parseInt(reopenStatus) === RO_MARKET_UPDATE) ? (
                                            <ReportUpdateFeesModal onOpenReportUpdateFeesModal={isOpenReportUpdateFeesModal} />
                                        ) : (null)

                                    }
                                </div>
                            </Col>
                        </Row >


                        {
                            (parseInt(reopenStatus) === RO_MARKET_UPDATE) ? (
                                <Row>
                                    <Col className="custom-av-radio-btn inline">
                                        <AvRadioGroup name="cancel_inspection" required onChange={e => handleUpdateInspection(e.target.value, (propReportRow.inspection_order && propReportRow.inspection_order.length > 0) ? propReportRow.inspection_order.inspection_vendor.id : 1)} >
                                            <AvRadio label='Choose a new inspection' value='choose_different' />
                                            <AvRadio label="Don't order an inspection" value='none' />
                                        </AvRadioGroup>
                                    </Col>
                                </Row>
                            ) : (null)

                        }

                        {eligibleInspectionTypes.length > 0 && parseInt(reopenStatus) === RO_MARKET_UPDATE &&
                            <>
                                <Row>
                                    <Col >
                                        <Label className="required label-font">Inspection Type</Label>
                                        <div className="custom-av-radio-btn inline">
                                            <AvRadioGroup name="inspection_vendor_eligibility_id" required >
                                                {eligibleInspectionTypes.map(function (inspectionEligibility, i) {
                                                    return (
                                                        inspectionEligibility &&
                                                        <AvRadio key={i} label={inspectionEligibility.name} value={inspectionEligibility.slug} onClick={(e) => onInspectionTypeChanged(inspectionEligibility)} />
                                                    );
                                                })}
                                            </AvRadioGroup>
                                        </div>
                                    </Col>
                                </Row>

                                {fields?.gla_applicable === '1' &&
                                    <Row>
                                        <Col className="my-2">
                                            <div color="info" className="custom-control custom-checkbox" sm="2">
                                                <input
                                                    className="custom-control-input"
                                                    name="gla_include"
                                                    id="gla_include"
                                                    type="checkbox"
                                                    value='1'
                                                    onChange={(e) => onDataChange(e)}
                                                    disabled={glaAndLicensingSettings?.glaAndLicensingSettings?.gla_required === '1'}
                                                    checked={(fields?.gla_include === '1')}
                                                />
                                                <label className="custom-control-label" htmlFor="gla_include">
                                                    Include GLA Measurement and Floorplan (${glaAndLicensingSettings?.glaAndLicensingCharges?.gla_surcharge})
                                                </label>
                                            </div>
                                        </Col>
                                    </Row>
                                }

                                {fields?.licensing_applicable === '1' &&
                                    <Row>
                                        <Col className="my-2">
                                            <div color="info" className="custom-control custom-checkbox" sm="2">
                                                <input
                                                    className="custom-control-input"
                                                    name="licensing_required"
                                                    id="licensing_required"
                                                    type="checkbox"
                                                    value='1'
                                                    onChange={(e) => onDataChange(e)}
                                                    disabled={glaAndLicensingSettings?.glaAndLicensingSettings?.licensed_required === '1'}
                                                    checked={fields?.licensing_required === '1'}
                                                />
                                                <label className="custom-control-label" htmlFor="licensing_required">
                                                    Require licensed agents only (${glaAndLicensingSettings?.glaAndLicensingCharges?.licensing_surcharge})
                                                </label>
                                            </div>
                                        </Col>
                                    </Row>
                                }

                                {isContactInfoRequired &&
                                    <Row className="form-control-table-narrow">
                                        <Col lg="12">
                                            <h2>Property Access Contact Information</h2>
                                        </Col>
                                        <Col md="4" >
                                            <AvGroup>
                                                <AvField
                                                    name={"contact_name"}
                                                    onChange={(event) => setFields(prevState => ({
                                                        ...prevState,
                                                        contact_name: event.target.value,
                                                    }))}
                                                    className="form-control"
                                                    placeholder="Contact Name"
                                                    type="text"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "This field is required."
                                                        },
                                                        maxLength: {
                                                            value: 50,
                                                            errorMessage: 'Max length is 50'
                                                        }
                                                    }}
                                                />
                                            </AvGroup>
                                        </Col>
                                        <Col md="4" >
                                            <AvGroup>
                                                <AvField
                                                    name={"contact_email"}
                                                    onChange={(event) => setFields(prevState => ({
                                                        ...prevState,
                                                        contact_email: event.target.value,
                                                    }))}
                                                    className="form-control"
                                                    placeholder="Contact Email"
                                                    type="email"
                                                    validate={{
                                                        pattern: {
                                                            value: '/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$/',
                                                            errorMessage: 'Invalid Email'
                                                        },
                                                        maxLength: {
                                                            value: 50,
                                                            errorMessage: 'Max length is 50'
                                                        }
                                                    }}
                                                />
                                            </AvGroup>
                                        </Col>
                                        <Col md="4" >
                                            <AvGroup>
                                                <AvField
                                                    name={"contact_phone"}
                                                    onChange={(event) => setFields(prevState => ({
                                                        ...prevState,
                                                        contact_phone: event.target.value,
                                                    }))}
                                                    className="form-control"
                                                    placeholder="Contact Phone"
                                                    type="text"
                                                    validate={{
                                                        pattern: {
                                                            value: '/^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{4})(?: *x(\\d+))?\\s*$/',
                                                            errorMessage: 'Invalid phone number'
                                                        }
                                                    }}
                                                />
                                            </AvGroup>
                                        </Col>
                                    </Row>
                                }
                            </>
                        }

                        {tat && tat.length > 0 &&
                            <Row className="mb-2">
                                <Col className="">
                                    <Label className="required">Turnaround Time</Label>
                                    <div className="custom-av-radio-btn inline">
                                        <AvRadioGroup onChange={(e) => setTurnAroundtime(e.target.value)} className="radio-group" name="turnaround_time" validate={{
                                            required: {
                                                value: true,
                                                errorMessage: "This field is required."
                                            }
                                        }} >
                                            {tat.map(function (t, i) {
                                                return (
                                                    <AvRadio key={i} label={t.name + " (" + t.turnaround_time_text + ")"} value={t.slug} />
                                                );
                                            })}
                                        </AvRadioGroup>
                                    </div>
                                    <sup className="font-weight-bold text-danger">*Inspection times and coverage cannot be guaranteed. Property access must be granted within 24 hrs from placing an order, and otherwise can cause delays</sup>
                                </Col>
                            </Row>
                        }

                        {
                            showStripeMsg
                                ? (
                                    <Row>
                                        <Col className="mb-3">
                                            {(parseInt(reopenStatus) === RO_MARKET_UPDATE || parseInt(reopenStatus) === RO_BUDGET_UPDATED || parseInt(reopenStatus) === RO_NEW_SPECS) &&
                                                <>
                                                    <Label>Reopening order required the additional charge of <b> ${totalCharge}</b>. </Label>
                                                    <br />
                                                    {!paymentError && <ReportOrderOriginalSource reportOrderId={propReportRow.id} onReportOrderOriginalSourceCB={onReportOrderOriginalSourceCB} />}
                                                </>
                                            }
                                            {paymentError &&
                                                <Row>
                                                    <span className="col-md-12 text-danger font-weight-bold">
                                                        The payment was failed because of following reason. <br />
                                                        <ul><li>{paymentError}</li></ul>
                                                    </span >
                                                </Row>
                                            }
                                            {(selectedPaymentSource && selectedPaymentSource?.isOriginalSource === false) &&
                                                <Label><span className="text-success mr-2"><i className="ni ni-check-bold"></i> {selectedPaymentSource.selectionText}</span><i className="fa fa-pencil-alt text-danger" onClick={() => openPaymentMethodSelector()} aria-hidden="true"></i></Label>
                                            }

                                            {(!selectedPaymentSource && defaultSourceChecked) &&
                                                <Button size="sm" color="warning" outline onClick={() => openPaymentMethodSelector()}> <i className="fas fa-credit-card"></i> Select Payment Method </Button>
                                            }
                                        </Col>
                                    </Row>
                                ) : (null)
                        }

                        {
                            showRefundMsg ?
                                <>
                                    <label >
                                        <b>${Math.abs(totalCharge)}</b> will be refunded in your account soon.
                                    </label>
                                </>
                                : ''
                        }


                        {
                            [RO_BUDGET_UPDATED, RO_MARKET_UPDATE].includes(parseInt(reopenStatus)) ?
                                <Row className="mb-2">
                                    <Col>
                                        <Dropzone
                                            onDrop={onDropDispute}
                                            onDropRejected={onDropRejectedDispute}
                                            accept={parseInt(reopenStatus) === RO_DISPUTE_INITIATED ? "application/pdf,application/docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document" : "application/pdf"}
                                            minSize={0}
                                            maxSize={maxDisputeSize}
                                            maxFiles={1}
                                            require={true}>

                                            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
                                                return (
                                                    <div {...getRootProps({ className: 'dropzone' })}>
                                                        <input {...getInputProps()} />
                                                        {!isDragActive && parseInt(reopenStatus) === RO_DISPUTE_INITIATED ? 'Click here or drop a file to upload. Upload only .PDF and .docx file. Max size is 10MB.' : 'Click here or drop a file to upload. Upload only .PDF file. Max size is 10MB.'}
                                                        {DisputeFileUploadError && (
                                                            <div className="text-danger mt-2">
                                                                {DisputeFileUploadError}
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                            }
                                            }
                                        </Dropzone>
                                        <p className="mt-2 text-center">{selectedFileName}</p>
                                    </Col>
                                </Row>
                                : null
                        }
                        {RO_DISPUTE_INITIATED !== parseInt(reopenStatus) ?
                            <>
                                <Row>
                                    <Col>
                                        <AvGroup>
                                            <Label>Reason / Request <span className="required">*</span></Label>
                                            <AvField maxLength="2000" type="textarea" onChange={(e) => setQuestionComment(e.target.value)} cols="100" name="question_comment" id="question_comment" placeholder="Type here..." required />
                                        </AvGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12" className="text-right mt-2">
                                        <FormGroup>
                                            {isProcess ?
                                                <Button type="button" color="warning" size="sm">
                                                    <div className="fa fa-spinner fa-spin"></div> Processing...
                                                </Button>
                                                :
                                                <>
                                                    <Button size="sm" color="danger" outline onClick={() => handleToggleModal("isOpenCancelReportOrderModal")}>
                                                        Close
                                                    </Button>

                                                    <Button size="sm" color="primary"
                                                        className={
                                                            (showStripeMsg && !selectedPaymentSource) ? "disabled" : ""
                                                        }>
                                                        Submit
                                                    </Button>
                                                </>
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </> : null
                        }
                    </AvForm >
                </div >
            </Modal >

            {isDisputeModal && <ValuationReviewRequestModal isDisputeModal={isDisputeModal} propReviewRequestModal={getReviewRequestModal} orderData={orderData} reopenStatus={parseInt(reopenStatus)} />}

            <PaymentForm onPay={isOpenPaymentSourceModal}
                paymentDetails={{
                    total_amount: totalCharge,
                    cc_surcharge: null,
                    paymentFor: 'Reopen Order'
                }}
                onPaymentSourceCb={handlePaymentSourceCb} clientId={clientId} />
        </>
    );
};

export default ReopenFilesClientButton;