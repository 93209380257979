import React from "react";
import { useHistory } from "react-router-dom";
import { loggedInUser } from "components/Common/Helpers";
import RenderActionButton from "./RenderActionButton";

const EditReportOrderIntakeFormButton = ({ propRowData, propButtonOptions }) => {

    const history = useHistory();
    const userData = loggedInUser();

    let handleActionClick = () => {
        let preFix = userData.is_RV_user ? '/admin' : '/client';
        history.push(`${preFix}/order-intake/${propRowData.report_intake_batch_id}`);
    };

    return (
        <>
            <RenderActionButton propButtonTitle="Edit Intake Form" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />
        </>
    );
};

export default EditReportOrderIntakeFormButton;