import React from "react";
import { Button, UncontrolledTooltip } from "reactstrap";

const InspectionBudgetStatusButton = ({ propRowData }) => {

    return (
        <>
            {propRowData?.budget_status === 'INSPECTION_BUDGET_EXTRACTION_FAILED' &&
                <>
                    <Button id={"payment_link_1" + propRowData.intake_token} outline color="warning" size="sm" type="button">
                        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
                    </Button>
                    <UncontrolledTooltip delay={0} placement="auto" target={"payment_link_1" + propRowData.intake_token} >Budget Extraction Failed</UncontrolledTooltip>
                </>
            }
            {propRowData?.budget_status === 'INSPECTION_BUDGET_EXTRACTION_PROCESSING' &&
                <>
                    <Button id={"payment_link_1" + propRowData.intake_token} outline color="success" size="sm" type="button">
                        <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                    </Button>
                    <UncontrolledTooltip delay={0} placement="auto" target={"payment_link_1" + propRowData.intake_token} >Budget Processing</UncontrolledTooltip>
                </>
            }
            {propRowData?.budget_status === 'INSPECTION_BUDGET_EXTRACTED_AND_READY' &&
                <>
                    <Button id={"payment_link_1" + propRowData.intake_token} outline color="success" size="sm" type="button">
                        <i className="fa fa-check" aria-hidden="true"></i>
                    </Button>
                    <UncontrolledTooltip delay={0} placement="auto" target={"payment_link_1" + propRowData.intake_token} >Budget Ready</UncontrolledTooltip>
                </>
            }
        </>
    );
};

export default InspectionBudgetStatusButton;