import { createSlice } from '@reduxjs/toolkit'

const initialStates = {
    initialDataRendered: false
};

export const PropertyAnalysisReducer = createSlice({
    name: 'PropertyAnalysis',
    initialState: { ...initialStates },
    reducers: {
        setPropertyAnalysisData: (state, data) => {
            data?.payload?.forEach(element => {
                // console.log(element.key, element.value)
                state[element.key] = element.value
            });
        },
        resetForm: (state) => {
            for (const key in { ...initialStates }) {
                if (initialStates.hasOwnProperty(key)) {
                    state[key] = initialStates[key]
                }
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setPropertyAnalysisData,
    resetForm
} = PropertyAnalysisReducer.actions

export default PropertyAnalysisReducer.reducer