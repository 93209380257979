import React from "react";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import { showSpinner } from './../../../../ShowHideSpinner.reducer'

import { PutData as OrderIntakePut } from "services/OrderIntakeData";
import { sendExceptionEmail } from "components/Common/Helpers";
import RenderActionButton from "./RenderActionButton";

const DismissReportOrderIntakeButton = ({ propRowData, propButtonOptions }) => {

    const dispatch = useDispatch();

    let handleActionClick = (e) => {
        e.preventDefault();
        let alertMessage = 'Are you sure you want to dismiss this order?'
        if (parseInt(propRowData.incomplete) === 1) {
            alertMessage = "Are you sure you want to permanently delete this order?"
        }
        confirmAlert({
            title: '',
            message: alertMessage,
            closeOnClickOutside: false,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        await handleDismissReportIntakeProcess();
                    },
                    className: "btn btn-primary btn-sm"
                }, {
                    label: 'Close',
                    onClick: () => { },
                    className: "btn btn-secondary btn-sm"
                }
            ]
        });
    };

    let handleDismissReportIntakeProcess = async () => {
        try {
            dispatch(showSpinner(true));
            const response = await OrderIntakePut("order/" + propRowData.intake_token + "/dismiss", { incomplete: propRowData.incomplete }, propRowData.company_id);
            toast["success"](response.message);
            dispatch(showSpinner(false));
        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error);
            dispatch(showSpinner(false));
        }
    };

    return (
        <>
            <RenderActionButton propButtonTitle="Dismiss" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />
        </>
    );
};

export default DismissReportOrderIntakeButton;