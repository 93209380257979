import React, { useState } from "react";
import { loggedInUser } from "components/Common/Helpers.js"
import { MESSAGE_TYPES } from "../Common/Constants/Conversation.constant";

const userData = loggedInUser();
const MessageType = ({ onChangeMessageType }) => {
    let [messageType, setMessageType] = useState([1, 2, 3]);

    let handleChange = (e) => {
        if (e.target.checked) {
            messageType.push(parseInt(e.target.value));
        } else {
            let index = messageType.indexOf(parseInt(e.target.value));
            if (index !== -1) {
                messageType.splice(index, 1);
            }
        }
        setMessageType(messageType);
        onChangeMessageType(messageType);
    }

    return (
        <>
            {userData.is_RV_user &&
                <li className="custom-control custom-checkbox mb-3 ml-3" sm="2" >
                    <label key="client">
                        <input className="custom-control-input" type="checkbox" value={1}
                            name="message_type"
                            id="client"
                            onChange={(e) => handleChange(e)}
                            checked={(messageType?.includes(MESSAGE_TYPES.CLIENT)) ? true : false}
                        />
                        <label className="custom-control-label mr-5 message-type-client" htmlFor={"client"}>Client</label>
                    </label>

                    <label key="internal">
                        <input className="custom-control-input" type="checkbox" value={2}
                            name="message_type"
                            id="internal"
                            onChange={(e) => handleChange(e)}
                            checked={(messageType?.includes(MESSAGE_TYPES.INTERNAL)) ? true : false}
                        />
                        <label className="custom-control-label mr-5 message-type-internal" htmlFor={"internal"}>Internal</label>
                    </label>

                    <label key="inspector">
                        <input className="custom-control-input" type="checkbox" value={3}
                            name="message_type"
                            id="inspector"
                            onChange={(e) => handleChange(e)}
                            checked={(messageType?.includes(MESSAGE_TYPES.INSPECTOR)) ? true : false}
                        />
                        <label className="custom-control-label message-type-inspector" htmlFor={"inspector"}>Inspector</label>
                    </label>
                </li>
            }
        </>
    )
}
export default MessageType;