import React, { useState } from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import PropertyCompDetailModal from "../Modals/PropertyCompDetail";

const PropertyCompListingDetailButton = ({ propRowData, propButtonOptions }) => {
    const [isCompModal, setIsCompModal] = useState({ current: false });

    const getPropData = async (data) => {
        if (data.state) {
            setIsCompModal({ current: false });
        }
    }

    return (
        <>
            <Button
                color={propButtonOptions?.color ? propButtonOptions.color : "accept"}
                outline={propButtonOptions?.outline ? propButtonOptions.outline : true}
                block={propButtonOptions?.block ? propButtonOptions.block : false}
                size={propButtonOptions?.size ? propButtonOptions.size : 'sm'}
                type="button"
                id={"comp_view_" + propRowData.id}
                onClick={e => setIsCompModal({ current: true })}
            >
                {propRowData.action_type === 'accept' ?
                    <><i className="fa fa-check" aria-hidden="true"></i>{propButtonOptions?.label ? ` ${propButtonOptions.label}` : null}</> :
                    <><i className="fa fa-eye" aria-hidden="true"></i>{propButtonOptions?.label ? ` ${propButtonOptions.label}` : null}</>
                }
            </Button>
            <UncontrolledTooltip delay={0} placement="top" target={"comp_view_" + propRowData.id} >View</UncontrolledTooltip>

            {isCompModal.current && <PropertyCompDetailModal isCompModal={isCompModal} compData={{ ...propRowData }} propCompModal={getPropData} />}
        </>
    );
};

export default PropertyCompListingDetailButton;