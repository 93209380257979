
/*
### Example:

Import : import NumberFormatter from "components/Common/NumberFormatter.js";

### Component:

<NumberFormatter
price={YUOR NUMBER}               
/>

*/
const NumberFormatter =({number}) => {
  return (
    <>
     { parseFloat(number).toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
    </>
  )
}
export default NumberFormatter;
