import React, { useState, useEffect } from "react";
import "./style.css";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import * as moment from "moment";
import { Label } from "reactstrap";
import { sendExceptionEmail } from "components/Common/Helpers";
import { PostData } from "services/PostData";
import { ROLE_ANALYST } from "./Constants";

const UsersByRole = (props) => {
    let [users, setUsers] = useState([]);
    let [update, setUpdate] = useState(moment());

    useEffect(() => {
        getClients();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { }, [update]);

    // Get user list by role
    let getClients = async () => {
        try {
            const roles = 'roles' in props && props.roles ? props.roles : [ROLE_ANALYST];
            const result = await PostData("list/users", { roles })
            const responseJson = result.data.data;
            setUsers(responseJson);
            setUpdate(moment());
        } catch (errors) {
            await sendExceptionEmail(errors);
        }
    }

    // Handle user selection and send data to parent comp
    let handleSelect = (selectedUsers) => {
        if (selectedUsers.length) {
            let ids = selectedUsers.map(function (user) {
                return user.user_id;
            });

            let returnData = {
                ids: ids,
                data: selectedUsers
            };
            props.onUserSelected(returnData);
        } else {
            props.onUserSelected([])
        }
    }

    return (
        <>
            {props.label &&
                <Label>{props.label}</Label>
            }
            <Typeahead
                size={props.size}
                clearButton
                multiple={props.isMultiSelect}
                defaultSelected={props.defaultSelection}
                id="selected-user"
                labelKey="full_name"
                onChange={(e) => handleSelect(e)}
                options={users}
                placeholder="Select User"
            />
        </>
    );
};

export default UsersByRole;