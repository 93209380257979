import React, { useState } from "react";
import { Button, Col, Modal, Row } from "reactstrap";

import { openInNewTab } from "components/Common/Helpers";
import CopyToClipboard from "react-copy-to-clipboard";

const MarketPlaceInfo = ({ propReportRow }) => {
    const [isOpenMarketPlaceInfoModal, setOpenMarketPlaceInfoModal] = useState(false);

    const [realtorUrl] = useState("https://www.realtor.com/")
    const [redfinUrl] = useState("https://www.redfin.com/")

    let handleToggleModal = (state) => {
        state = !state;
        setOpenMarketPlaceInfoModal(state);
    };

    return (
        <>
            <i onClick={(e) => setOpenMarketPlaceInfoModal(true)} className="fa fa-info-circle m-2 cursor-pointer"></i>

            <Modal className="modal-dialog-centered modal-lg" isOpen={isOpenMarketPlaceInfoModal} toggle={() => handleToggleModal(isOpenMarketPlaceInfoModal)}>
                <div className="modal-header modal-header-colored">
                    <h3 className="modal-title w-100 text-center">
                        Outside Links
                    </h3>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenMarketPlaceInfoModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <p>For convenience and efficiency, we provide links to some outside sites for research purposes. For compliance with their requirement, some sites allow direct property links while others do not</p>
                    <Row>
                        <Col className="col-md-6">
                            <h3>Direct Property Links:</h3>
                            <p>Click on these links to take you directly to the property of interest</p>
                        </Col>
                        <Col className="col-md-6">
                            <h3>Indirect Website Links:</h3>
                            <p>These sites do not allow direct property links. Therefore, when you click these links from RicherValues website, we will copy the relevant property address, city, state and zip code to your clipboard, so that all you have to do is click Paste when arriving at the outside website. Enjoy the convenience!</p>
                        </Col>
                    </Row>
                    <Row className="property-market-place mt-5">
                        <Col className="text-center">
                            <Button color="secondary" size="sm" title="Zillow" className="marketplace-url-btn" onClick={(e) => openInNewTab(propReportRow ? propReportRow.zillowUrl : "")}>
                                <img className="cursor-pointer" alt="Zillow" width={20} src={require("../../assets/img/marketplace/zillow_logo.png").default}
                                />
                            </Button>

                            <CopyToClipboard text={propReportRow ? propReportRow.fullAddress : ""}>
                                <Button color="secondary" size="sm" title="Realtor" className="marketplace-url-btn" onClick={(e) => openInNewTab(propReportRow?.realtorUrl ? propReportRow.realtorUrl : realtorUrl)}>
                                    <img className="cursor-pointer" alt="Realtor" width={20} src={require("../../assets/img/marketplace/realtor_small.png").default}
                                    />
                                </Button>
                            </CopyToClipboard>

                            <CopyToClipboard text={propReportRow ? propReportRow.fullAddress : ""}>
                                <Button color="secondary" size="sm" title="Redfin" className="marketplace-url-btn" onClick={(e) => openInNewTab(propReportRow?.redfinUrl ? propReportRow.redfinUrl : redfinUrl)}>
                                    <img className="cursor-pointer" alt="Redfin" width={20} src={require("../../assets/img/marketplace/redfin_favicon.ico").default}
                                    />
                                </Button>
                            </CopyToClipboard>

                            <Button color="secondary" size="sm" title="Regrid" className="marketplace-url-btn" onClick={(e) => openInNewTab(propReportRow ? propReportRow.regridUrl : "")}>
                                <img className="cursor-pointer" alt="Regrid" width={20} src={require("../../assets/img/marketplace/regrid_new.png").default}
                                />
                            </Button>

                            <Button color="secondary" size="sm" title="Google Map" className="marketplace-url-btn" onClick={(e) => openInNewTab(propReportRow ? propReportRow.googleMapUrl : "")}>
                                <img className="cursor-pointer" alt="Google Map" width={20} src={require("../../assets/img/marketplace/google-maps.png").default}
                                />
                            </Button>

                            {propReportRow?.googleSearch &&
                                <Button color="secondary" size="sm" title="Google Search" className="marketplace-url-btn" onClick={(e) => openInNewTab(propReportRow?.googleSearchUrl)}>
                                    <img className="cursor-pointer" alt="Google Search" width={20} src={require("../../assets/img/marketplace/google-search.png").default}
                                    />
                                </Button>
                            }
                        </Col>
                    </Row>
                </div>
            </Modal>
        </>
    );
};

export default MarketPlaceInfo;