import React from "react";
import { PostData } from "../../../../services/Api.service";
import { toast } from 'react-toastify';
import { sendExceptionEmail } from "components/Common/Helpers";
import { useDispatch } from "react-redux";
import RenderActionButton from "./RenderActionButton";
import { showSpinner } from "ShowHideSpinner.reducer";

const EpoRegenerateReportButton = ({ propRowData, propButtonOptions, propCb }) => {
    const dispatch = useDispatch();

    const handleActionClick = async () => {
        try {
            dispatch(showSpinner(true));
            const response = await PostData(`epo-orders/regenerate-pdf`, { propertyId: propRowData.property_id });
            if (propCb) {
                propCb();
            }
            toast['success'](response.message);
            dispatch(showSpinner(false));
        } catch (error) {
            toast["error"](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error)
            dispatch(showSpinner(false));
        }
    };

    return (
        <>
            <RenderActionButton propButtonTitle="Regenerate report" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />
        </>
    );
};

export default EpoRegenerateReportButton;