/* ---------------------------------------COMPONENT INSTRUCTION---------------------------------------
--Import Component--
Import : import CcEmailsTag from "components/Common/CcEmailsTag.js";

--------------------
--Include Component--
<CcEmailsTag onHandleCcEmail={}  />  

------------------------------------------------------------------------------------------------------*/

import React, { useState, useEffect } from "react";
import { InputGroup, InputGroupAddon, InputGroupText, Label } from "reactstrap";
import { AvField, AvGroup } from "availity-reactstrap-validation";
import "./style.css";
import * as moment from "moment";

const CcEmailsTag = ({ onHandleCcEmail, defaultCC = [] }) => {

    let [ccEmails, setCcEmails] = useState([]);
    let [ccEmailValue, setCcEmailValue] = useState("");
    let [update, setUpdate] = useState(moment());
    let [error, setError] = useState("");

    useEffect(() => {
        setCcEmails(defaultCC);
    }, [onHandleCcEmail, defaultCC]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { }, [update]);

    let isValid = (email) => {
        let error = null;

        if (isInList(email)) {
            error = `${email} has already been added.`;
        }

        if (!isEmail(email)) {
            error = `${email} is not a valid email address.`;
        }

        if (error) {
            setError(error);
            return false;
        }

        return true;
    }

    let isInList = (email) => {
        return ccEmails.includes(email);
    }

    let isEmail = (email) => {
        //eslint-disable-next-line
        return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
    }

    let handleKeyDown = (evt) => {
        if (['Enter', 'Tab', ',', ' '].includes(evt.key)) {
            evt.preventDefault();
            validateInput();
        }
    };

    let validateInput = () => {

        let email = ccEmailValue.trim();

        if (email && isValid(email)) {
            //eslint-disable-next-line
            let isValid = email.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g); //
            if (isValid) {
                ccEmails = [...ccEmails, email];
                setCcEmails(ccEmails)
                setCcEmailValue("");
                setError("");
                onHandleCcEmail(ccEmails);
                setUpdate(moment());
            }
        }
    };

    let handleChange = evt => {
        setError("");
        setCcEmailValue(evt.target.value);
    }

    let handleDelete = item => {
        ccEmails = ccEmails.filter(i => i !== item);
        setCcEmails(ccEmails);
        onHandleCcEmail(ccEmails);
        setUpdate(moment());
    };

    return (
        <>
            <AvGroup className="custom-tag-input">
                <Label className=""><b>CC (Separated by Comma, Tab and Enter):</b></Label>
                <br />

                <InputGroup className={`input-group-alternative email-group`}>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            {ccEmails.map(item => (
                                <div className="tag-item" key={item}>
                                    {item}
                                    <button
                                        type="button"
                                        className="button"
                                        onClick={() => handleDelete(item)}
                                    >
                                        &times;
                                    </button>
                                </div>
                            ))}
                        </InputGroupText>
                    </InputGroupAddon>
                    <AvField name="cc_email"
                        autoComplete="off"
                        value={ccEmailValue}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        onBlur={validateInput}
                        validate={{
                            required: { value: !ccEmails ? true : false, },
                            email: {
                                value: true,
                                errorMessage: "Please enter valid email."
                            }
                        }} />
                </InputGroup>
                <div className="form-error">{error}</div>
            </AvGroup>

        </>
    );
};

export default CcEmailsTag;
