import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import "./style.scss";

const QualityGradeComponent = ({ grade, gradeID, tooltipId, onHandleQualityGrade, selected, propDescription }) => {

    let handleSelection = (gradeID, grade) => {
        onHandleQualityGrade(gradeID, grade);
    }

    return (
        <>
            <div onClick={() => handleSelection(gradeID, grade)} className={selected === gradeID ? "selected" : ""}>
                <UncontrolledTooltip delay={0} placement="auto" target={"tooltip_" + tooltipId} >{propDescription}</UncontrolledTooltip>
                <span id={"tooltip_" + tooltipId}>{gradeID}</span>
            </div>

        </>
    );
};
export default QualityGradeComponent;