import React, { useEffect } from "react";
import { Button, Col, Modal, Row } from "reactstrap";
import Spinner from "components/Common/Spinner";
import { useState } from "react";
import * as moment from "moment";
import { PostData, PutData } from "services/Api.service";
import { sendExceptionEmail } from "components/Common/Helpers";
import { toast } from "react-toastify";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import { IAT_CANCELLED, IAT_COMPLETED, IAT_ON_HOLD, IAT_QC, IAT_RECEIVED, IAT_RESCHEDULED, IAT_DISMISSED } from "../Constants";
import eventBus from "../EventBus";
import RenderActionButton from "./RenderActionButton";

const ReopenInspectionOrderButton = ({ propRowData, propButtonOptions }) => {

    let [isOpenModal, setIsOpenModal] = useState(false);
    let [update, setUpdate] = useState(moment());
    let [isProcess, setProcess] = useState(false);
    let [inspectionActionTypes, setInspectionActionTypes] = useState([]);
    let [fields, setFields] = useState({
        action_id: IAT_QC
    });

    useEffect(() => { }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

    let handleActionClick = async (e, row) => {
        e.preventDefault();
        await getInspectionActions();
        setIsOpenModal(true);
        setUpdate(moment())
    };

    let handleToggleModal = (state) => {
        setIsOpenModal(!state);
        setUpdate(moment());
    };

    let handleInput = (e) => {
        e.preventDefault();
        let { name, value } = e.target;

        fields[name] = value;
        setFields(fields)
        setUpdate(moment());
    }

    let getInspectionActions = async (e) => {
        try {
            setProcess(true);
            const actionTypesResp = await PostData("list/inspection_action_types", {});
            setInspectionActionTypes(actionTypesResp.data);
            handleToggleModal(isOpenModal);
            setProcess(false);
        } catch (error) {
            await sendExceptionEmail(error);
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            setProcess(false);
        }
    };

    let sendToBorrower = async (e) => {
        try {
            setProcess(true);
            let response = await PutData(`inspection/${propRowData.inspection_order_token}/reopen`, fields)
            handleToggleModal(isOpenModal);
            setProcess(false);
            eventBus.dispatch("inspection_order_status_updated", { client_id: propRowData.company_id });
            toast["success"](response.message);
        } catch (error) {
            await sendExceptionEmail(error);
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            setProcess(false);
        }
    };

    return (
        <>
            <RenderActionButton propButtonTitle="Reopen Inspection" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />

            {isOpenModal &&

                <Modal
                    className="modal-dialog-centered"
                    // size="sm"
                    isOpen={isOpenModal}
                    toggle={() => handleToggleModal(isOpenModal)}
                >
                    <div className="modal-header modal-header-colored">
                        <h2 className="modal-title text-center w-100">
                            Reopen Inspection
                        </h2>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenModal)}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Spinner isShow={isProcess} />
                        <AvForm className="text-left" model={fields}>
                            <Row>
                                <Col xs="12" sm="12">
                                    <AvGroup className="auto-complete-form-control">
                                        <AvField label="Select Reopen Action" type="select" name="action_id"
                                            onChange={(event) => handleInput(event)}
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: "This field is required."
                                                }
                                            }}>
                                            <option value="">Select Action</option>
                                            {inspectionActionTypes.filter(element => ![IAT_DISMISSED, IAT_COMPLETED, IAT_RECEIVED, IAT_CANCELLED, IAT_RESCHEDULED, IAT_ON_HOLD].includes(element.status_id)).map((inspection_action_type) =>
                                                <option key={inspection_action_type.status_id} value={inspection_action_type.status_id}> {inspection_action_type.status_name}</option>
                                            )}
                                        </AvField>
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Row className="mt-5">
                                <Col lg="12" className="text-right">
                                    <Button color="danger" size="sm" outline onClick={() => handleToggleModal(isOpenModal)} >
                                        <i className="fa fa-times"></i> Cancel
                                    </Button>
                                    <Button color="info" size="sm" outline onClick={(e) => { sendToBorrower(e) }}>
                                        <i className="fa fa-save"></i> Submit
                                    </Button>
                                </Col>
                            </Row>
                        </AvForm>
                    </div>
                </Modal>
            }
        </>
    );
};

export default ReopenInspectionOrderButton;