import React, { useEffect, useRef } from 'react';
import nouislider from 'nouislider';
import 'nouislider/distribute/nouislider.css';
import { CONDITION } from '../Constants/Property.constant';

const Slider = ({ id, conditionRating, propConditionRating }) => {
    const sliderRef = useRef(null);

    useEffect(() => {
        const slider = nouislider.create(sliderRef.current, {
            connect: [true, false],
            start: [conditionRating],
            range: {
                min: 1,
                max: 8,
            },
            step: 0.1, // Allow decimal values
            tooltips: {
                to: function (value) {
                    return parseFloat(value).toFixed(1);
                },
            },
        });

        slider.on('update', (values) => {
            const sliderValue = parseFloat(values[0]);
            updateSliderColor(sliderValue);
        });

        slider.on('set', (values, handle) => {
            const sliderValue = parseFloat(values[handle]);
            propConditionRating({ rating: sliderValue });
        });

        return () => {
            slider.destroy();
        };
    }, [conditionRating]); // eslint-disable-line react-hooks/exhaustive-deps

    const updateSliderColor = (value) => {
        const sliderTrackConn = document.querySelector(`.custom-slider-${id} .noUi-base .noUi-connect`);
        if (value >= CONDITION.UNSALVAGE_MIN && value <= CONDITION.UNSALVAGE_MAX) {
            sliderTrackConn.style.backgroundColor = '#575757';
        } else if (value >= CONDITION.VERY_POOR_MIN && value <= CONDITION.VERY_POOR_MAX) {
            sliderTrackConn.style.backgroundColor = '#A63322';
        } else if (value >= CONDITION.POOR_MIN && value <= CONDITION.POOR_MAX) {
            sliderTrackConn.style.backgroundColor = '#F4AB2C';
        } else if (value >= CONDITION.MODERATE_MIN && value <= CONDITION.MODERATE_MAX) {
            sliderTrackConn.style.backgroundColor = '#E6E85E';
        } else if (value >= CONDITION.MAINTAINED_MIN && value <= CONDITION.MAINTAINED_MAX) {
            sliderTrackConn.style.backgroundColor = '#74D170';
        } else if (value >= CONDITION.PARTIAL_REMODEL_MIN && value <= CONDITION.PARTIAL_REMODEL_MAX) {
            sliderTrackConn.style.backgroundColor = '#50C9D6';
        } else if (value >= CONDITION.FULLY_REMODEL_MIN && value <= CONDITION.FULLY_REMODEL_MAX) {
            sliderTrackConn.style.backgroundColor = '#3D3DDF';
        } else if (value >= CONDITION.NEW_BUILT_MIN && value <= CONDITION.NEW_BUILT_MAX) {
            sliderTrackConn.style.backgroundColor = '#AB40D7';
        }
    };

    return (
        <div>
            <div ref={sliderRef} className={`custom-slider custom-slider-${id}`}>
                <div className="noUi-connect"></div> {/* Hide the default track */}
            </div>
            <div className="row custom-slider-icons">
                <div className="col-md-12 range_slider_min_max">
                    <span><img title="Unsalvageable" alt="Unsalvageable" className="first-element" src={require("../../../../assets/img/conditions/unsalvageable-grey.png").default} /></span>
                    <span><img title="Very Poor" src={require("../../../../assets/img/conditions/very-poor.png").default} alt="Very Poor" /></span>
                    <span><img title="Poor" alt="Poor" src={require("../../../../assets/img/conditions/poor.png").default} /></span>
                    <span><img title="Moderate" alt="Moderate" src={require("../../../../assets/img/conditions/moderate.png").default} /></span>
                    <span><img title="Maintained" alt="Maintained" src={require("../../../../assets/img/conditions/maintained.png").default} /></span>
                    <span><img title="Partial Remodel" alt="Partial Remodel" src={require("../../../../assets/img/conditions/partial-remodel.png").default} /></span>
                    <span><img title="Full Remodel" alt="Full Remodel" src={require("../../../../assets/img/conditions/full-remodel.png").default} /></span>
                    <span><img title="Newly Built" alt="Newly Built" src={require("../../../../assets/img/conditions/new-build-purple.png").default} /></span>
                </div>
            </div>
        </div >
    );
};

export default Slider;
