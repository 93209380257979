import React, { useEffect, useState } from "react";
import { Button, Modal } from "reactstrap";
import { toast } from "react-toastify";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";

import { PostData } from "services/PostData";
import eventBus from "../EventBus";

import { sendExceptionEmail, loggedInUser } from "components/Common/Helpers";
import { RO_ANALYSIS_REVIEW_UNASSIGNED, RO_ANALYSIS_UNASSIGNED, RO_PROPERTY_ANALYSIS_COMPLETED } from "../Constants";
import * as moment from "moment";

const ReportOrderRelinquishModal = ({ isOpenReassignModal, propRowData }) => {

    const userData = loggedInUser();

    const [isOpenCancelReportOrderModal, setIsOpenCancelReportOrderModal] = useState(false);

    const [relinquishReason, setRelinquishReason] = useState(null);
    const [reassignOrderStatus, setReassignOrderStatus] = useState(null);

    const [update, setUpdate] = useState(moment());
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => { }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isOpenReassignModal.current) {
            handleActionClick()
        }
    }, [isOpenReassignModal.current]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleActionClick = (e, row) => {
        // e.preventDefault();
        setReassignOrderStatus(propRowData?.reassignOrderStatus)
        setIsOpenCancelReportOrderModal(true);
    };

    const handleToggleModal = (state) => {
        if (!isProcessing) {
            state = !state;
            isOpenReassignModal.current = state
            setIsOpenCancelReportOrderModal(state);
            setUpdate(moment());
        }
    };

    const handleReassignProcess = async () => {
        try {
            setIsProcessing(true);
            const result = await PostData("orders/reassign", { report_id: propRowData.report_order_id, user_id: userData.user_id, order_status: reassignOrderStatus, reason: relinquishReason })
            const responseJson = result.data;
            setIsProcessing(false);
            if (responseJson.status !== 200) {
                toast["error"](responseJson.message);
            } else {
                toast["success"](responseJson.message);
            }
            handleToggleModal(isOpenCancelReportOrderModal)
            eventBus.dispatch("report_order_status_updated", { client_id: propRowData.company_id });
        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error)
            setIsProcessing(false);
        };
    };

    return (
        <>
            {/* Cancel report order Modal */}
            <Modal className="modal-dialog-centered" isOpen={isOpenCancelReportOrderModal} toggle={() => handleToggleModal(isOpenCancelReportOrderModal)}>
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title text-center w-100">
                        Relinquish Assignment
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenCancelReportOrderModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <AvForm onValidSubmit={(e) => handleReassignProcess()} >
                        {propRowData.order_status >= RO_PROPERTY_ANALYSIS_COMPLETED ?
                            <AvGroup className="text-left">
                                <label>Order Status</label>
                                <AvField bsSize="sm" type="select" name="order_status" onChange={(e) => setReassignOrderStatus(e.target.value)} required>
                                    <option value=''> Select </option>
                                    <option value={RO_ANALYSIS_UNASSIGNED}> Analysis Unassigned </option>
                                    <option value={RO_ANALYSIS_REVIEW_UNASSIGNED}> Analysis Review Unassigned </option>
                                </AvField>
                            </AvGroup> : null
                        }
                        <AvGroup className="text-left">
                            <label>Relinquish Reason</label>
                            <AvField
                                name="relinquish_reason"
                                onChange={(e) => { setRelinquishReason(e.target.value); setUpdate(moment()) }}
                                className="form-control"
                                placeholder="Enter Reason"
                                type="textarea"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "This field is required."
                                    },
                                    maxLength: {
                                        value: 255,
                                        errorMessage: 'Reason must be less than 255 character'
                                    }
                                }}
                            />
                        </AvGroup>
                        <div className="text-right">
                            {isProcessing ?
                                <Button type="button" color="warning" size="sm">
                                    <div className="fa fa-spinner fa-spin"></div> Processing...
                                </Button>
                                :
                                <>
                                    <Button size="sm" color="danger" outline className="w-30 " onClick={() => handleToggleModal(isOpenCancelReportOrderModal)}>
                                        Cancel
                                    </Button>
                                    <Button size="sm" color="primary" className="w-30" >
                                        Submit
                                    </Button>
                                </>
                            }
                        </div>
                    </AvForm>
                </div>
            </Modal>
        </>
    );
};

export default ReportOrderRelinquishModal;