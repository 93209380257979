import { configureStore } from '@reduxjs/toolkit'

import rootReducer from "./reducers/index";

export default configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore specific actions or paths
                ignoredActions: ['inspectionOrderIntake/setIntakeData'],
                ignoredPaths: ['inspectionOrderIntake.intakeData.orderInformation.batch_property_file'],
            },
        }),
})
