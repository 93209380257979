import React from "react";
import { Redirect } from "react-router-dom";
// reactstrap components
import { DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Navbar, Nav, Container, Media } from "reactstrap";
import OrderManagementSearch from "views/modules/ClientPortal/OrderManagementSearch/Index.js";
import { PostData } from "../../services/PostData";
import { toast } from "react-toastify";
import Spinner from "components/Common/Spinner.js";
import { loggedInUser, loginUserAvatarUrl, updateLoginLogoutLocalStorage } from "components/Common/Helpers";
import { Link } from "react-router-dom/cjs/react-router-dom.min";



class ClientNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLogout: false,
      userData: loggedInUser() ? loggedInUser() : "",
      redirect: false,
      isCollapsedSideBar: this.props.isCollapsedSideBar
    };
  };

  /*
  | Call before component render on the dom
  */
  componentWillMount() { };

  componentWillReceiveProps() { }
  /*
  | Call after component render on the dom
  */
  componentDidMount() { };

  /*
  | Call before component delete/leave from dom
  */
  componentWillUnmount() {
  };

  /*
  | Handle Logout
  */
  handleLogout(e) {

    this.setState({ isLogout: true });
    let userData = this.state.userData;

    PostData("auth/logout", { token: userData.token })
      .then((result) => {
        let responseJson = result.data;

        if (responseJson.status === 200) {
          e.preventDefault();

          localStorage.removeItem("userData");
          localStorage.removeItem("OCT"); //Other client token
          updateLoginLogoutLocalStorage(false);
          this.setState({ isLogout: false });
          this.props.history.push("/auth/login");
        } else {
          toast(responseJson.message);
          this.setState({ isLogout: false });
        }
      })
      .catch((errors) => {
        toast(errors);
        this.setState({ isLogout: false });
      });
  }

  handleChangePassword(e) {
    e.preventDefault();
  };

  // For Nav bat toggle
  handleToggleSidebar(e, status) {
    let newStatus = status = !status;
    this.props.isOpen(newStatus)
    this.setState({ isCollapsedSideBar: newStatus });
  }

  render() {
    if (!this.state.userData) {
      return <Redirect to='/auth/login' />
    }
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          {/* Loader Spinner */}
          <Spinner isShow={this.state.isLogout} />
          <Container fluid>
            {/* For Nav bar toggle */}
            <Link to="#" onClick={(e) => this.handleToggleSidebar(e, this.state.isCollapsedSideBar)}><span className="menu-nav-icon" > <i className={this.state.isCollapsedSideBar ? "fas fa-angle-double-right" : "fas fa-angle-double-left"}></i>  </span></Link>

            <span className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block">
              {this.props.brandText}
            </span>
            <OrderManagementSearch className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto" />
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img alt="..." loading="lazy" src={loginUserAvatarUrl(`${this?.state?.userData?.first_name} ${this?.state?.userData?.last_name}`)} />
                    </span>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title text-center" tag="div">
                    <h4 className="text-overflow m-0">{this?.state?.userData?.first_name + " " + this?.state?.userData?.last_name}</h4>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem href="/client/change-password">
                    <i className="fa fa-key" aria-hidden="true"></i>
                    <span>Change Password</span>
                  </DropdownItem>
                  <DropdownItem href="#" onClick={(e) => this.handleLogout(e)}>
                    <i className="ni ni-button-power" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  };
}
export default ClientNavbar;