import React, { useRef, useState } from "react";

import { showSpinner } from '../../../../../ShowHideSpinner.reducer'
import { useDispatch } from "react-redux";
import { PostData } from "services/PostData";
import { toast } from "react-toastify";
import eventBus from "../../EventBus";

import * as moment from "moment";
import { useEffect } from "react";

import RenderActionButton from "../RenderActionButton";
import { loggedInUser, sendExceptionEmail, checkRoles, AutoLogin } from "components/Common/Helpers";
import { RO_ANALYSIS_REVIEW_EDITS_REQUESTED, RO_ANALYSIS_REVIEW_INITIATED, RO_ANALYSIS_REVIEW_QUESTIONS, RO_ANALYSIS_REVIEW_QUESTIONS_RESOLVED } from "../../Constants";
import Neighborhood from "views/modules/NeighborhoodMetrics/Neighborhood";
import { PostData as PostDataNew } from "services/Api.service";
import CommonModal from "components/Common/CommonModal";
import ResumeAnalysisReview from "views/modules/NeighborhoodMetrics/ResumeAnalysisReview";
import ReportOrderRelinquishModal from "../../Modals/ReportOrderRelinquishModal";
import ReportOrderManyOpenOrdersModal from "../../Modals/ReportOrderManyOpenOrdersModal";
import EarlierDueDateOrdersModal from "../../Modals/EarlierDueDateOrdersModal";
import Recalculate from "../../../NeighborhoodMetrics/RecalculateModal";

const AnalysisReviewButton = ({ propRowData, propButtonOptions }) => {

    const userData = loggedInUser();
    const phpUrl = process.env.REACT_APP_PHP_ENDPOINT;
    const dispatch = useDispatch();

    const [update, setUpdate] = useState(moment());

    const [isOpenCommonModal, setIsOpenCommonModal] = useState(false);
    const [openOrders, setOpenOrders] = useState([]);

    const [reassignOrderStatus, setReassignOrderStatus] = useState(null);

    const isOpenNeighborhoodLandUseModal = useRef(false);
    const isOpenResumeNeighborhoodLandUseModal = useRef(false);

    const isOpenReassignModal = useRef(false);
    const isOpenOrderModal = useRef(false);
    const isOpenEarlierDueDateModal = useRef(false);
    const isRecalculateModal = useRef(false);

    const [isShowButton, setIsShowButton] = useState(false);
    const [commonModalTextType, setCommonModalTextType] = useState(null);
    const [commonModalText, setCommonModalText] = useState(null);
    const [neighborHoodData, setNeighborHoodData] = useState(null);

    useEffect(() => { }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleAnalysisClick = async () => {
        if (propRowData.neighborhood_metrics === 1) {
            await handleLandUsesProcess('review')
        } else {
            await handleOrderStatus('review')
        }
    };

    const handleRelinquishClick = async () => {
        if (propRowData.neighborhood_metrics === 1) {
            await handleLandUsesProcess('reassign')
        } else {
            await handleOrderStatus("reassign");
        }
    };

    const handleLandUsesProcess = async (type) => {
        if (type === 'reassign') {
            isOpenResumeNeighborhoodLandUseModal.current = true;
            setUpdate(moment());
        } else {
            await handleOrderStatus("review")
        }
    };

    const handleNeighborhoodLandUseModal = async (type) => {
        const neighborHoodDataTemp = await PostDataNew("orders/get-neighborhood-data", { reportOrderId: propRowData.report_order_id });
        setNeighborHoodData(neighborHoodDataTemp.data.orders);
        isOpenNeighborhoodLandUseModal.current = true;
        setUpdate(moment());
    };

    const handleNeighborhoodResponse = async (response) => {
        try {
            if (response.status === "success") {
                isOpenResumeNeighborhoodLandUseModal.current = false;
                let redirectUrl = phpUrl + 'valuation/deals/details/' + response.data.property_id;
                handleAutoLogin(redirectUrl);
            }
            eventBus.dispatch("report_order_status_updated", { client_id: propRowData.company_id });
        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error)
            dispatch(showSpinner(false));
        }
    };

    const handleCloseNeighborhoodLandUser = async (response) => {
        try {
            isOpenResumeNeighborhoodLandUseModal.current = response;
            setUpdate(moment());
            eventBus.dispatch("report_order_status_updated", { client_id: propRowData.company_id });
        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error)
            dispatch(showSpinner(false));
        }
    };

    const handleResumeResponseAnalysisReview = async (response) => {
        try {
            isOpenResumeNeighborhoodLandUseModal.current = false;
            if (response === "resume") {
                try {
                    let data = {
                        property_id: propRowData.property_id
                    }
                    await PostDataNew("orders/get-arv-data", data);
                    handleNeighborhoodLandUseModal();
                    setUpdate(moment());
                } catch (error) {
                    if (error.errors === "ARV_DATA_NOT_FOUND_ERROR") {
                        isRecalculateModal.current = true;
                        setUpdate(moment());
                    } else {
                        throw error
                    }
                }
            } else {
                await handleOrderStatus("reassign");
            }
        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error)
            dispatch(showSpinner(false));
        }
    };

    const handleAutoLogin = (url) => {
        const data = { url: url, user_id: userData.user_id };
        AutoLogin(data, (response) => { });
    };

    const handleOrderStatus = async (status, flag = false) => {
        try {
            setIsShowButton(true)

            if (status === 'review') {

                const reviewParams = {
                    report_id: propRowData.report_order_id,
                    user_id: userData.user_id,
                    roles: userData.roles,
                    reviewer_level_check: (checkRoles(['REVIEWER'])) ? 'yes' : 'no',
                    reviewer_modal_flag: flag
                };
                const result = await PostData("orders/review", reviewParams)
                const responseJson = result.data;

                setUpdate(moment())

                if (responseJson.error_code && responseJson.error_code === 'many_open_orders') {
                    setOpenOrders(responseJson.openOrders);
                    isOpenOrderModal.current = true;
                    setUpdate(moment());
                    return false;
                } else if (responseJson.status === 401) {
                    if (responseJson.error_name === 'earlier_due_date' && (checkRoles(['REVIEWER', 'ACCOUNT_REP']))) {
                        isOpenEarlierDueDateModal.current = true;
                        setUpdate(moment());
                        return false;
                    } else {
                        setIsOpenCommonModal(true)
                        setCommonModalTextType(['do_not_allow_to_take_order', 'do_not_allow_to_take_order_questions_pending'].includes(responseJson.error_name) ? 'Info' : 'Error')
                        setCommonModalText(responseJson.message)
                        setUpdate(moment());
                        return false;
                    }
                } else {
                    toast["success"](responseJson.message);
                    isOpenEarlierDueDateModal.current = false;

                    if (propRowData.neighborhood_metrics) {
                        handleNeighborhoodLandUseModal();
                    } else if (!propRowData.neighborhood_metrics) {
                        const redirectUrl = phpUrl + 'valuation/deals/details/' + propRowData.property_id;
                        handleAutoLogin(redirectUrl);
                    }
                    // eventBus.dispatch("report_order_status_updated", { client_id: row.company_id });
                }

            } else if (status === 'reassign') {

                if ([RO_ANALYSIS_REVIEW_INITIATED, RO_ANALYSIS_REVIEW_QUESTIONS, RO_ANALYSIS_REVIEW_QUESTIONS_RESOLVED, RO_ANALYSIS_REVIEW_EDITS_REQUESTED].includes(propRowData.order_status)) {
                    setReassignOrderStatus(null);
                    setIsShowButton(false);
                    isOpenReassignModal.current = true;
                    setUpdate(moment());
                    return false;
                }
            }

        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message)
            await sendExceptionEmail(error)
            dispatch(showSpinner(false));
        };
    };

    const handleRecalculateResponse = (response) => {
        isOpenResumeNeighborhoodLandUseModal.current = response
    };


    return (
        <>
            <RenderActionButton propButtonTitle={propButtonOptions?.type === 'review' ? 'Review' : 'Resume/Relinquish'} propButtonOptions={propButtonOptions}
                propOnClickEvent={propButtonOptions?.type === 'review' ? handleAnalysisClick : handleRelinquishClick} />

            {isOpenNeighborhoodLandUseModal.current &&
                <Neighborhood onCloseNeighborhoodLandUser={handleCloseNeighborhoodLandUser} onNeighborhoodLandUser={isOpenNeighborhoodLandUseModal} propReportRow={neighborHoodData} onHandleResponse={handleNeighborhoodResponse} />
            }

            {isOpenResumeNeighborhoodLandUseModal.current &&
                <ResumeAnalysisReview handleResumeAnalysisReview={isOpenResumeNeighborhoodLandUseModal} onHandleResponse={handleResumeResponseAnalysisReview} />
            }

            {isOpenReassignModal.current &&
                <ReportOrderRelinquishModal
                    isOpenReassignModal={isOpenReassignModal}
                    propRowData={{
                        report_order_id: propRowData.report_order_id,
                        company_id: propRowData.company_id,
                        order_status: propRowData.order_status,
                        reassignOrderStatus: reassignOrderStatus,
                    }} />
            }

            {isOpenOrderModal.current &&
                <ReportOrderManyOpenOrdersModal
                    isOpenModal={isOpenOrderModal}
                    propRowData={{
                        openOrders: openOrders,
                    }} />
            }

            {isOpenEarlierDueDateModal.current &&
                <EarlierDueDateOrdersModal
                    isOpenModal={isOpenEarlierDueDateModal}
                    onConfirmation={async () => {
                        dispatch(showSpinner(true));
                        await handleOrderStatus('review', true)
                        isOpenEarlierDueDateModal.current = false;
                        dispatch(showSpinner(false));
                    }} />
            }

            {isRecalculateModal.current &&
                <Recalculate handleRecalculate={isRecalculateModal} propertyId={propRowData.property_id} onHandleResponse={handleRecalculateResponse} />
            }

            <CommonModal
                isModal={isOpenCommonModal}
                title={commonModalTextType}
                text={commonModalText}
                onHandleToggle={() => { setIsOpenCommonModal(false) }}
                onButton={(state, isValue) => {
                    if (isValue === true) {
                        setIsOpenCommonModal(false);
                        eventBus.dispatch("report_order_status_updated", { client_id: propRowData.company_id });
                    }
                }}
                isShowModalBtn={isShowButton}
            />
        </>
    );
};

export default AnalysisReviewButton;