
/*
### Example:

Import : import PriceFormatter from "components/Common/PriceFormatter.js";

### Component:

<PriceFormatter
price={YUOR PRICE VALUE}               
/>

*/
const PriceFormatter = ({ price, decimals = 0 }) => {
  return (
    <>
      {price < 0 ? '-' : ''}{'$' + parseFloat(Math.abs(price)).toFixed(decimals).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
    </>
  )
}
export default PriceFormatter;
