import React, { useState, useEffect } from "react";
import { Row, Button, UncontrolledTooltip, Modal, FormGroup } from "reactstrap";
import * as moment from "moment";
import { PostData } from "../../../../../services/PostData";
import { checkRoles, loggedInUser, encryptData } from "components/Common/Helpers.js"
import Spinner from "components/Common/Spinner.js";
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import eventBus from "../../EventBus";
import { sendExceptionEmail } from "components/Common/Helpers";

const HandlePdfReportClientButton = ({ onHandlePDF, propButtons, propReportRow }) => {

    const userData = loggedInUser();
    const [isProcess, setProcess] = useState(false);
    const [update, setUpdate] = useState(moment());
    const [downloadUrl, setDownloadUrl] = useState("");
    const [isOpenDownLoadPDFModal, setIsOpenDownLoadPDFModal] = useState(false);

    const [selectedRow, setSelectedRow] = useState([]);
    const [reportButtons, setReportButtons] = useState([]);

    useEffect(() => {
        setReportButtons(propButtons ? propButtons.split(',') : []);
        setSelectedRow(propReportRow);
    }, [propButtons, propReportRow]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { }, [update]);

    const handleModal = (state) => {
        if (state === "isOpenDownLoadPDFModal") {
            state = !state;
            setIsOpenDownLoadPDFModal(state);
        }
    };

    const getReportOrderDataForPDF = async () => {
        const settingData = { keys: ['json_payload_token'] }

        try {
            let encrypt = encryptData(settingData);

            let result = await PostData("client/list/settings", { data: encrypt });
            let responseJson = result.data;
            let data = {
                report_order_id: selectedRow.id,
                full_action_id: selectedRow.full_action_id ? selectedRow.full_action_id : null
            }

            let encryptedData = encryptData(data);
            let orderDetailsResp = await PostData("client/orders/details", { data: encryptedData });
            let orderDetailsRespJson = orderDetailsResp.data;
            orderDetailsRespJson.data[0].report_token = responseJson.data[0].value;

            return orderDetailsRespJson.data[0];
        } catch (errors) {
            setProcess(false);
            await sendExceptionEmail(errors)
            toast["error"]("Something went wrong.");
        };
    };

    // Handle report order status
    const handleOrderStatus = async (e, status, row) => {
        if (status === 'resend_pdf_url') {
            confirmAlert({
                title: '',
                message: 'Are you sure you want to resend PDF URL to client?',
                closeOnClickOutside: false,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            await handlePdfReportProcess(status, row);
                        },
                        className: "btn btn-primary btn-sm"
                    }, {
                        label: 'Close',
                        onClick: () => { },
                        className: "btn btn-secondary btn-sm"
                    }
                ]
            });
        } else if (status === 'download_report') {
            await handlePdfReportProcess(status, row);
        }

        e.preventDefault();
    };

    const handlePdfReportProcess = async (status, row) => {
        try {
            setProcess(true);
            let selectedOrderDetails = await getReportOrderDataForPDF();

            selectedOrderDetails.custom_analysis_ids = selectedOrderDetails.analysis_trackings && selectedOrderDetails.analysis_trackings[0] ? JSON.parse(selectedOrderDetails.analysis_trackings[0].custom_analysis_ids) : [];
            selectedOrderDetails.payload_json = selectedOrderDetails.analysis_trackings && selectedOrderDetails.analysis_trackings[0] && selectedOrderDetails.analysis_trackings[0].payload_json ? JSON.parse(selectedOrderDetails.analysis_trackings[0].payload_json) : {};
            selectedOrderDetails.merged_comp_array = selectedOrderDetails.analysis_trackings && selectedOrderDetails.analysis_trackings[0] ? JSON.parse(selectedOrderDetails.analysis_trackings[0].merged_comp_array) : [];
            selectedOrderDetails.analysis_tracking_id = selectedOrderDetails.analysis_trackings && selectedOrderDetails.analysis_trackings[0] ? selectedOrderDetails.analysis_trackings[0].id : '';
            selectedOrderDetails.action_type = status;

            setProcess(false);
            let pdfUrl = selectedOrderDetails?.report_fulfillment_actions && selectedOrderDetails?.report_fulfillment_actions[0]?.report_pdf_url ? selectedOrderDetails?.report_fulfillment_actions[0]?.report_pdf_url : selectedOrderDetails.report_pdf_url;

            if (status === 'resend_pdf_url') {
                setProcess(true);
                if (pdfUrl) {
                    setProcess(true);
                    sendPdfUrlToClient(selectedOrderDetails, { pdf_downloaded_url: pdfUrl }, function (sendPDFResponse) { setProcess(false); });
                } else {
                    setProcess(false);
                    toast["error"]("PDF url not exists!");
                }
            } else if (status === 'download_report') {
                if (pdfUrl) {
                    let downloadUrl = pdfUrl;
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', pdfUrl);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    setProcess(false);
                    toast["error"]("PDF url not exists!");
                }
            };
            setUpdate(moment());
        } catch (errors) {
            await sendExceptionEmail(errors);
        }
    }

    const sendPdfUrlToClient = async (row, data, cb) => {
        try {
            let params = { pdf_url: data.pdf_downloaded_url, report_order_id: row.id, user_id: userData.user_id };

            let encrypt = encryptData(params);
            const result = await PostData("client/orders/send_finalized_email_with_pdf_url", { data: encrypt })
            if (result.data.status !== 200) {
                toast["error"](result.data.message);
                setProcess(false);
            } else {
                toast["success"]("An email with report PDF URL has been sent to the client.");
                setDownloadUrl(data.pdf_downloaded_url);
                setIsOpenDownLoadPDFModal(true);

                setProcess(false);
                setUpdate(moment());
                eventBus.dispatch("report_order_status_updated", { client_id: row.company_id });
            }
        } catch (error) {
            await sendExceptionEmail(error);
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            cb(error?.errors?.length ? error.errors[0].message : error.message, null);
        }
    };

    const row_id = selectedRow.full_action_id ? selectedRow.full_action_id : selectedRow.id;

    return (
        <>
            {reportButtons.includes('resend_pdf_url') === true &&
                <>
                    <Button id={"resend_pdf_url" + row_id} color="warning" outline disabled={selectedRow.disabled ? true : false} size="sm" type="button" onClick={e => handleOrderStatus(e, 'resend_pdf_url', selectedRow)}>
                        <i className="fa fa-paper-plane" aria-hidden="true"></i>
                    </Button>
                    <UncontrolledTooltip delay={0} placement="auto" target={"resend_pdf_url" + row_id} >Resend PDF to Client</UncontrolledTooltip>
                </>
            }
            {reportButtons.includes('download_report') === true &&
                <>
                    <Button id={"download_report" + row_id} color="warning" outline disabled={selectedRow.disabled ? true : false} size="sm" type="button" onClick={e => handleOrderStatus(e, 'download_report', selectedRow)}>
                        <i className="fa fa-download" aria-hidden="true"></i>
                    </Button>
                    <UncontrolledTooltip delay={0} placement="auto" target={"download_report" + row_id} > Download Report</UncontrolledTooltip>
                </>
            }

            <Modal className="modal-dialog-centered" style={{ maxWidth: "600px" }} isOpen={isOpenDownLoadPDFModal} toggle={() => handleModal("isOpenDownLoadPDFModal")}>
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title text-center w-100">
                        Success
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleModal("isOpenDownLoadPDFModal")}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Spinner isShow={isProcess} />
                    <Row>
                        <h2 className="text-center col-lg-12 pt-2"> Email has been sent.</h2>
                        <div className="col-lg-12 py-2 text-center">
                            <FormGroup>
                                {checkRoles(['ADMINISTRATOR']) ?
                                    <>
                                        <a href={downloadUrl} id="download_file" className="btn btn-md btn-outline-warning" target="_blank" rel="noreferrer"><i className="fa fa-download"></i>  Download </a>
                                        <UncontrolledTooltip delay={0} placement="right" target="download_file" > Click here to download file. </UncontrolledTooltip>
                                    </>
                                    :
                                    <Button color="primary" outline onClick={() => handleModal("isOpenDownLoadPDFModal")} type="button"> OK </Button>
                                }
                            </FormGroup>
                        </div>
                    </Row>
                </div>
            </Modal>
        </>
    );
};

export default HandlePdfReportClientButton;