import React from "react";
import RenderActionButton from "./RenderActionButton";

const EpoDownloadReportButton = ({ propRowData, propButtonOptions }) => {
    const handleActionClick = () => {
        window.open(propRowData.report_pdf_url, "_self")
    };

    return (
        <>
            <RenderActionButton propButtonTitle="Download report" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />
        </>
    );
};

export default EpoDownloadReportButton;