import React from 'react';
/* Admin Routes */

import AnalysisComparison from "views/modules/AnalysisComparison/AnalysisComparison";

import { checkRoles, loggedInUser } from "components/Common/Helpers.js"
import Login from "views/modules/Login.js";
import ForgetPassword from "views/modules/ForgetPassword.js";
import RetrieveUsername from 'views/modules/RetrieveUsername.js';
import NewPassword from 'views/modules/NewPassword.js';
import MasterFiles from 'views/modules/TrainingOrders/MasterFiles';
import InvoiceAllSearch from 'views/modules/InvoiceSearchResult/InvoiceAllSearch';
import FloodCertsRevenue from 'views/modules/FloodCertsRevenue/FloodCertsRevenue';
import ManageDrawLineItem from 'views/modules/Inspection/ManageDrawLineItem';
import Messaging from 'views/modules/Conversation/Messaging';


const Dashboard = React.lazy(() => import('views/modules/Dashboard/Index.js'));
const TimeTrackingDashboard = React.lazy(() => import('views/modules/TimeTrackingDashboard/Index.js'));
const OrderManagement = React.lazy(() => import('views/modules/OrderManagement/Index.js'));
const CompanyEligibility = React.lazy(() => import('views/modules/Companies/CompanyEligibility/Index.js'));
const PropertyVideos = React.lazy(() => import('views/modules/PropertyVideos/index.js'));
const ChangePassword = React.lazy(() => import('views/modules/ChangePassword/index.js'));
const SupplementalInfo = React.lazy(() => import('views/modules/OrderManagement/SupplementalInfo.js'));
const Compensation = React.lazy(() => import('views/modules/Performance/Compensation/Compensation.js'));
const Comps = React.lazy(() => import('views/modules/Performance/Comps/Comps'));
const ReportInspection = React.lazy(() => import('views/modules/ReportInspection/index.js'));
const OrderManagementSearch = React.lazy(() => import('views/modules/OrderManagementSearch/SearchResult'));
const Invoice = React.lazy(() => import('views/modules/Invoice/Index.js'));
const CreateInvoiceDetail = React.lazy(() => import('views/modules/Invoice/CreateInvoiceDetail.js'));
const PaymentMethod = React.lazy(() => import('views/modules/PaymentMethod/Index'));
const RoleManagement = React.lazy(() => import('views/modules/Performance/RoleManagement/RoleManagement.js'));
const PlaceNewOrderLanding = React.lazy(() => import('views/modules/PlaceNewOrder/Landing'));
const OrderIntake = React.lazy(() => import('views/modules/ClientPortal/OrderIntake/Index'));
const EpoOrderIntake = React.lazy(() => import('views/modules/EpoOrderIntake/EpoOrderIntake'));
const EditEpoOrderIntake = React.lazy(() => import('views/modules/EpoOrderIntake/EditEpoOrderIntake'));
const InspectionOrderIntake = React.lazy(() => import('views/modules/InspectionOrderIntake/InspectionOrderIntake'));
const EditInspectionOrderIntake = React.lazy(() => import('views/modules/InspectionOrderIntake/EditInspectionOrderIntake'));
const Team = React.lazy(() => import('views/modules/Team/Team.js'));
const TopicList = React.lazy(() => import('views/modules/Conversation/TopicList.js'));
const SubtopicList = React.lazy(() => import('views/modules/Conversation/SubTopicList.js'));
const ReportFees = React.lazy(() => import('views/modules/ReportFees/Index.js'));
const AddEditCompany = React.lazy(() => import('views/modules/Companies/AddCompany/AddEditCompany'));
const Company = React.lazy(() => import('views/modules/Companies/AddCompany/Company.js'));
const EmailRecipients = React.lazy(() => import('views/modules/EmailRecipients/Index'));
const IntegrationPartners = React.lazy(() => import('views/modules/IntegrationPartners/IntegrationPartners'));
const ReferralPartners = React.lazy(() => import('views/modules/IntegrationPartners/ReferralPartners'));
const ErrorLogs = React.lazy(() => import('views/modules/ErrorLogs/ErrorLogs'));
const EmailLogs = React.lazy(() => import('views/modules/EmailLogs/EmailLogs'));
const InspectionPricing = React.lazy(() => import('views/modules/InspectionPricing/InspectionPricing'));
const TrainingVideos = React.lazy(() => import('views/modules/Performance/TrainingVideos/TrainingVideos.js'));
const Properties = React.lazy(() => import('views/modules/Properties/Properties'));
const PropertyFilePreview = React.lazy(() => import('views/modules/Properties/PropertyFilePreview'));
const ValDetails = React.lazy(() => import('views/modules/Deals/Details/ValDetails'));
const TrainingMVP = React.lazy(() => import('views/modules/TrainingMVP/Overview'));
const WrittenMaterials = React.lazy(() => import('views/modules/TrainingMVP/WrittenMaterials'));
const SectionList = React.lazy(() => import('views/modules/TrainingMVP/SectionList'));
const ComponentList = React.lazy(() => import('views/modules/TrainingMVP/ComponentList'));
const OrderTypes = React.lazy(() => import('views/modules/OrderTypes/UpdateOrderTypes.js'));
const ReleaseLogs = React.lazy(() => import('views/modules/ReleaseLogs/ReleaseLogs'));

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    iconImage: require('./assets/img/img-icons/business-report.png').default,
    component: Dashboard,
    layout: "/admin",
    is_visible: true,
  },
  {
    path: "/order-management",
    name: "Orders",
    icon: "ni ni-bullet-list-67 text-info",
    component: OrderManagement,
    layout: "/admin",
    is_visible: (loggedInUser().is_RV_user === true) ? true : false,
  },
  {
    path: "/supplemental-info",
    name: "Supplemental Info",
    icon: "ni ni-book-bookmark text-success",
    component: SupplementalInfo,
    layout: "/admin",
    is_visible: (checkRoles(['ADMINISTRATOR', 'SALES_REP', 'ACCOUNT_REP', 'PROCESSOR', 'DRV_ADMIN', 'REVIEWER'])),
  },
  {
    path: "/performance",
    name: "Performance",
    // icon: "ni ni-chart-pie-35 text-primary",
    iconImage: require('./assets/img/img-icons/growth.png').default,
    layout: "/admin",
    is_visible: (checkRoles(['ADMINISTRATOR', 'MANAGER', 'REVIEWER', 'DRV_ADMIN', 'DATA_RECONCILIATION', 'ANALYST', 'TRAINING_ADMINISTRATOR']) === true ? true : false),
    children: [
      {
        path: "/comps",
        name: "Comps",
        // icon: "fas fa-tasks",
        iconImage: require('./assets/img/img-icons/comp.png').default,
        component: Comps,
        layout: "/admin",
        is_visible: (checkRoles(['ADMINISTRATOR', 'DRV_ADMIN', 'DATA_RECONCILIATION']) === true ? true : false)
      },
      // {
      //   path: "/analysis",
      //   name: "Analysis",
      //   icon: "ni ni-chart-bar-32",
      //   component: Analysis,
      //   layout: "/admin",
      //   is_visible: (checkRoles(['ADMINISTRATOR', 'MANAGER', 'ANALYST']) === true ? true : false)
      // },
      // {
      //   path: "/review",
      //   name: "Reviews ",
      //   icon: "ni ni-check-bold",
      //   component: Reviews,
      //   layout: "/admin",
      //   is_visible: (checkRoles(['ADMINISTRATOR', 'MANAGER', 'REVIEWER']) === true ? true : false)
      // },
      {
        path: "/role-management",
        name: "Role Management",
        // icon: "ni ni-lock-circle-open",
        iconImage: require('./assets/img/img-icons/role-manager.png').default,
        component: RoleManagement,
        layout: "/admin",
        is_visible: (checkRoles(['ADMINISTRATOR', 'TRAINING_ADMINISTRATOR', 'MANAGER']) === true ? true : false)
      },
      {
        path: "/training-master-files",
        name: "Training Master Files",
        icon: "fa fa-envelope",
        component: MasterFiles,
        layout: "/admin",
        is_visible: (checkRoles(['ADMINISTRATOR', 'TRAINING_ADMINISTRATOR']) && process.env.REACT_APP_SERVER_ENVIRONMENT === "training" ? true : false)
      },
      {
        path: "/property-videos",
        name: "Property Videos",
        // icon: "ni ni-camera-compact",
        iconImage: require('./assets/img/img-icons/video-marketing.png').default,
        component: PropertyVideos,
        layout: "/admin",
        is_visible: !checkRoles(['ACCOUNT_REP']) || checkRoles(['ADMINISTRATOR']) ? true : false,
      },
      {
        path: "/training-videos",
        name: "Training Videos",
        // icon: "ni ni-camera-compact text-danger",
        iconImage: require('./assets/img/img-icons/training.png').default,
        component: TrainingVideos,
        layout: "/admin",
        is_visible: checkRoles(['ADMINISTRATOR', 'TRAINING_ADMINISTRATOR', 'DRV_ADMIN', 'DATA_RECONCILIATION', 'ANALYST', 'REVIEWER']) ? true : false,
      },
      {
        path: "/tracking",
        name: "Tracking",
        // icon: "fa fa-clock-o",
        iconImage: require('./assets/img/img-icons/track-of-time.png').default,
        component: TimeTrackingDashboard,
        layout: "/admin",
        is_visible: checkRoles(['ADMINISTRATOR', 'TRAINING_ADMINISTRATOR', 'MANAGER', 'ANALYST', 'REVIEWER']) ? true : false,
      }

    ]
  },
  {
    path: "/accounting",
    name: "Accounting",
    icon: "fas fa-calculator text-warning",
    layout: "/admin",
    is_visible: (checkRoles(['ADMINISTRATOR', 'MANAGER', 'REVIEWER', 'ANALYST', 'ACCOUNTING', 'SALES_REP', 'ACCOUNT_REP']) === true ? true : false),
    children: [
      {
        path: "/compensation",
        name: "Compensation ",
        // icon: "fas fa-dollar-sign",
        iconImage: require('./assets/img/img-icons/compensation.png').default,
        component: Compensation,
        layout: "/admin",
        is_visible: (checkRoles(['ADMINISTRATOR', 'MANAGER', 'REVIEWER', 'ANALYST']) === true ? true : false)
      },
      {
        path: "/commissions",
        name: "Commissions ",
        // icon: "fas fa-percent",
        iconImage: require('./assets/img/img-icons/commission.png').default,
        component: Dashboard,
        layout: "/admin",
        is_visible: (checkRoles(['ADMINISTRATOR', 'SALES_REP']) === true ? true : false)
      },
      {
        path: "/invoicing",
        name: "Invoicing ",
        // icon: "fas fa-file-invoice-dollar",
        iconImage: require('./assets/img/img-icons/bill.png').default,
        component: Invoice,
        layout: "/admin",
        is_visible: (checkRoles(['ADMINISTRATOR', 'ACCOUNTING', 'SALES_REP', 'ACCOUNT_REP']) ? true : false),
      },
      {
        path: "/create-invoice-detail/:id",
        name: "Create Invoice Detail",
        icon: "fas fa-file-invoice-dollar",
        component: CreateInvoiceDetail,
        layout: "/admin",
        is_visible: (checkRoles(['ADMINISTRATOR', 'ACCOUNTING', 'SALES_REP', 'ACCOUNT_REP']) ? true : false),
        is_show_label: false
      },
      {
        path: "/referral-partners",
        name: "Referral Partners",
        // icon: "fa fa-users",
        iconImage: require('./assets/img/img-icons/agreement.png').default,
        component: ReferralPartners,
        layout: "/admin",
        is_show_label: true,
        is_visible: (checkRoles(['ADMINISTRATOR']) === true ? true : false)
      },
      {
        path: "/flood-certs-revenue",
        name: "Flood Certs Revenue",
        // icon: "fas fa-hand-holding-usd",
        iconImage: require('./assets/img/img-icons/flood-certs-revenue.png').default,
        component: FloodCertsRevenue,
        layout: "/admin",
        is_show_label: true,
        is_visible: (checkRoles(['ADMINISTRATOR', 'ACCOUNTING']) === true ? true : false)
      },

    ]
  },
  {
    path: "/sales-admin",
    name: "Sales Admin",
    // icon: "ni ni-settings text-info",
    iconImage: require('./assets/img/img-icons/admin.png').default,
    layout: "/admin",
    is_visible: (checkRoles(['ADMINISTRATOR', 'ACCOUNT_REP', 'SALES_REP', 'PROCESSOR']) ? true : false),
    children: [
      {
        path: "/company-list",
        name: "Company List",
        // icon: "fas fa-dollar-sign",
        iconImage: require('./assets/img/img-icons/building.png').default,
        component: Company,
        layout: "/admin",
        is_show_label: true,
        is_visible: (checkRoles(['ADMINISTRATOR', 'ACCOUNT_REP', 'SALES_REP', 'PROCESSOR']) === true ? true : false)
      },
      {
        path: "/add-company",
        name: "Add Company ",
        icon: "fas fa-dollar-sign",
        is_show_label: false,
        component: AddEditCompany,
        layout: "/admin",
        is_visible: (checkRoles(['ADMINISTRATOR', 'ACCOUNT_REP', 'SALES_REP']) === true ? true : false)
      },
      {
        path: "/company-eligibility",
        name: "Company Eligibility",
        // icon: "ni ni-settings",
        iconImage: require('./assets/img/img-icons/eligibility.png').default,
        component: CompanyEligibility,
        layout: "/admin",
        is_visible: (checkRoles(['ADMINISTRATOR', 'ACCOUNT_REP', 'SALES_REP']) ? true : false),
      },
      {
        path: "/integration-partners",
        name: "Integration Partners",
        // icon: "fa fa-building",
        iconImage: require('./assets/img/img-icons/partner.png').default,
        component: IntegrationPartners,
        layout: "/admin",
        is_show_label: true,
        is_visible: (checkRoles(['ADMINISTRATOR']) === true ? true : false)
      },
      {
        path: "/inspection-pricing",
        name: "Inspection Pricing",
        // icon: "fas fa-dollar-sign",
        iconImage: require('./assets/img/img-icons/inspection.png').default,
        component: InspectionPricing,
        layout: "/admin",
        is_visible: (checkRoles(['ADMINISTRATOR', 'SALES_REP', 'ACCOUNT_REP']) ? true : false),
      },
      {
        path: "/report-fees",
        name: "Update Fees",
        // icon: "fa fa-edit",
        iconImage: require('./assets/img/img-icons/update-fee.png').default,
        component: ReportFees,
        layout: "/admin",
        is_visible: (checkRoles(['ADMINISTRATOR', 'ACCOUNT_REP', 'SALES_REP']) ? true : false),
      },
      {
        path: "/payment-methods",
        name: "Payment Methods",
        // icon: "ni ni-credit-card",
        iconImage: require('./assets/img/img-icons/debit-card.png').default,
        component: PaymentMethod,
        layout: "/admin",
        is_visible: (checkRoles(['ADMINISTRATOR', 'SALES_REP', 'ACCOUNT_REP', 'ACCOUNTING']) ? true : false),
      },
      {
        path: "/report-inspection",
        name: "Report Inspections",
        // icon: "fas fa-pencil-alt",
        iconImage: require('./assets/img/img-icons/report-inspection.png').default,
        component: ReportInspection,
        layout: "/admin",
        is_visible: (checkRoles(['ADMINISTRATOR']) ? true : false),
      },
      {
        path: "/team",
        name: "Team",
        // icon: "ni ni-circle-08",
        iconImage: require('./assets/img/img-icons/team.png').default,
        component: Team,
        layout: "/admin",
        is_visible: (checkRoles(['ADMINISTRATOR', 'SALES_REP', 'ACCOUNT_REP']) ? true : false),
      },
      {
        path: "/email-recipients",
        name: "Email Recipients",
        // icon: "fa fa-envelope",
        iconImage: require('./assets/img/img-icons/recipient.png').default,
        component: EmailRecipients,
        layout: "/admin",
        is_visible: (checkRoles(['ADMINISTRATOR']) ? true : false)
      },
      {
        path: "/order-types",
        name: "Order Types",
        icon: "fa fa-bars",
        component: OrderTypes,
        layout: "/admin",
        is_visible: (checkRoles(['ADMINISTRATOR', 'SALES_REP', 'ACCOUNT_REP']) ? true : false),
      },
    ]
  },
  {
    path: "/place-new-order",
    name: "Place New Order",
    icon: "ni ni-basket text-success",
    // iconImage: require('./assets/img/img-icons/order.png').default,
    component: PlaceNewOrderLanding,
    layout: "/admin",
    is_visible: (checkRoles(['ADMINISTRATOR', 'SALES_REP', 'ACCOUNT_REP', 'PROCESSOR']) ? true : false),
  },
  {
    path: "/order-intake/:intake_batch_id",
    name: "Place New Order",
    icon: "ni ni-basket text-success",
    component: OrderIntake,
    layout: "/admin",
    is_visible: (checkRoles(['ADMINISTRATOR', 'SALES_REP', 'ACCOUNT_REP', 'PROCESSOR']) ? true : false),
    is_show_label: false
  },
  {
    path: "/order-intake",
    name: "Place Eval Order",
    component: OrderIntake,
    layout: "/admin",
    is_visible: (checkRoles(['ADMINISTRATOR', 'SALES_REP', 'ACCOUNT_REP', 'PROCESSOR']) ? true : false),
    is_show_label: false
  },
  {
    path: "/epo-order/:epoIntakeBatchToken/:companyId/edit-intake-data",
    name: "Edit Epo Order",
    icon: "ni ni-basket text-success",
    component: EditEpoOrderIntake,
    layout: "/admin",
    is_visible: (checkRoles(['ADMINISTRATOR', 'SALES_REP', 'ACCOUNT_REP', 'PROCESSOR']) ? true : false),
    is_show_label: false
  },
  {
    path: "/epo-order",
    name: "Place AVM Order",
    component: EpoOrderIntake,
    layout: "/admin",
    is_visible: (checkRoles(['ADMINISTRATOR', 'SALES_REP', 'ACCOUNT_REP', 'PROCESSOR']) ? true : false),
    is_show_label: false
  },
  {
    path: "/inspection-order",
    name: "Place Inspection Order",
    icon: "ni ni-basket text-success",
    component: InspectionOrderIntake,
    layout: "/admin",
    is_visible: (checkRoles(['ADMINISTRATOR', 'SALES_REP', 'ACCOUNT_REP', 'PROCESSOR']) ? true : false),
    is_show_label: false
  },
  {
    path: "/:inspection_intake_batch_token/:company_id/edit-intake-data",
    name: "Inspection Order",
    icon: "ni ni-basket text-success",
    component: EditInspectionOrderIntake,
    layout: "/admin",
    is_visible: (checkRoles(['ADMINISTRATOR', 'SALES_REP', 'ACCOUNT_REP', 'PROCESSOR']) ? true : false),
    is_show_label: false
  },
  {
    path: "/change-password",
    name: "Change Password",
    icon: "ni ni-key-25 text-info",
    component: ChangePassword,
    layout: "/admin",
    is_visible: true,
    is_show_label: false
  },
  {
    path: "/conversation",
    name: "Messages",
    // icon: "ni ni-settings text-info",
    iconImage: require('./assets/img/img-icons/chat.png').default,
    layout: "/admin",
    is_visible: (checkRoles(['ADMINISTRATOR', 'ACCOUNT_REP', 'SALES_REP']) ? true : false),
    children: [
      {
        path: "/topics",
        name: "Topics",
        // icon: "fa fa-list",
        iconImage: require('./assets/img/img-icons/topic.png').default,
        component: TopicList,
        layout: "/admin",
        is_show_label: true,
        is_visible: (checkRoles(['ADMINISTRATOR', 'ACCOUNT_REP', 'SALES_REP']) === true ? true : false)
      },
      {
        path: "/subtopics",
        name: "Subtopics ",
        // icon: "fa fa-indent",
        iconImage: require('./assets/img/img-icons/sub-topic.png').default,
        is_show_label: true,
        component: SubtopicList,
        layout: "/admin",
        is_visible: (checkRoles(['ADMINISTRATOR', 'ACCOUNT_REP', 'SALES_REP']) === true ? true : false)
      },
      {
        path: "/messaging",
        name: "Messaging",
        iconImage: require('./assets/img/img-icons/messaging.png').default,
        is_show_label: true,
        component: Messaging,
        layout: "/admin",
        is_visible: (checkRoles(['ADMINISTRATOR', 'ACCOUNT_REP', 'SALES_REP']))
      }
    ]
  },
  {
    path: "/messaging",
    name: "Messaging",
    iconImage: require('./assets/img/img-icons/messaging.png').default,
    is_show_label: true,
    component: Messaging,
    layout: "/admin",
    is_visible: !checkRoles(['ADMINISTRATOR', 'ACCOUNT_REP', 'SALES_REP']) && checkRoles(['REVIEWER', 'PROCESSOR', 'DRV_ADMIN','ANALYST'])
  },
  {
    path: "/team",
    name: "Team",
    // icon: "ni ni-circle-08 text-success",
    iconImage: require('./assets/img/img-icons/team.png').default,
    component: Team,
    layout: "/admin",
    is_visible: (checkRoles(['ADMINISTRATOR', 'SALES_REP', 'ACCOUNT_REP']) ? true : false),
  },
  {
    path: "/training",
    name: "Training Modules",
    iconImage: require('./assets/img/img-icons/presentation.png').default,
    layout: "/admin",
    is_visible: (loggedInUser().is_RV_user === true && checkRoles(['ADMINISTRATOR', 'TRAINING_ADMINISTRATOR', 'ANALYST', 'REVIEWER'])) ? true : false,
    children: [
      {
        path: "/training-modules",
        name: "Training Modules",
        iconImage: require('./assets/img/img-icons/video-content.png').default,
        component: TrainingMVP,
        layout: "/admin",
        is_visible: (loggedInUser().is_RV_user === true && checkRoles(['ADMINISTRATOR', 'TRAINING_ADMINISTRATOR', 'ANALYST', 'REVIEWER'])) ? true : false,
        is_show_label: true
      },
      {
        path: "/training-section",
        name: "Sections",
        iconImage: require('./assets/img/img-icons/new.png').default,
        component: SectionList,
        layout: "/admin",
        is_visible: (loggedInUser().is_RV_user === true && checkRoles(['ADMINISTRATOR', 'TRAINING_ADMINISTRATOR'])) ? true : false,
        is_show_label: true
      },
      {
        path: "/training-components",
        name: "Components",
        iconImage: require('./assets/img/img-icons/time-management.png').default,
        component: ComponentList,
        layout: "/admin",
        is_visible: (loggedInUser().is_RV_user === true && checkRoles(['ADMINISTRATOR', 'TRAINING_ADMINISTRATOR'])) ? true : false,
        is_show_label: true
      },
      {
        path: "/written-material/:componentID",
        name: "Written Material",
        icon: "ni ni-bullet-list-67 text-info",
        component: WrittenMaterials,
        layout: "/admin",
        is_visible: (loggedInUser().is_RV_user === true && checkRoles(['ADMINISTRATOR', 'TRAINING_ADMINISTRATOR', 'ANALYST', 'REVIEWER'])) ? true : false,
        is_show_label: false
      },
    ]
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/forget-password",
    name: "Renew Password",
    icon: "ni ni-key-25 text-info",
    component: ForgetPassword,
    layout: "/auth",
  },
  {
    path: "/retrieve-username",
    name: "Retrieve Username",
    icon: "ni ni-key-25 text-info",
    component: RetrieveUsername,
    layout: "/auth",
  },
  {
    path: "/new-password/:token",
    name: "New Password",
    icon: "ni ni-key-25 text-info",
    component: NewPassword,
    layout: "/auth",
  },
  {
    path: "/search",
    name: "Search Results",
    icon: "ni ni-key-25 text-info",
    component: OrderManagementSearch,
    layout: "/admin",
    is_visible: true,
    is_show_label: false
  },
  {
    path: "/invoice-search",
    name: "Invoice Search Results",
    icon: "ni ni-key-25 text-info",
    component: InvoiceAllSearch,
    layout: "/admin",
    is_visible: (checkRoles(['ADMINISTRATOR', 'ACCOUNTING', 'SALES_REP', 'ACCOUNT_REP']) ? true : false),
    is_show_label: false
  },
  {
    path: "/analysis-comparison/:report_id/:amts_id",
    name: "Analysis Comparison",
    icon: "ni ni-settings text-info",
    component: AnalysisComparison,
    layout: "/admin",
    is_visible: (checkRoles(['ADMINISTRATOR', 'MANAGER', 'REVIEWER', 'TRAINING_ADMINISTRATOR', 'ANALYST']) ? true : false),
    is_show_label: false
  },
  {
    path: "/properties",
    name: "Properties",
    iconImage: require('./assets/img/img-icons/properties.png').default,
    component: Properties,
    layout: "/admin",
    is_visible: (checkRoles(['TECH', 'ANALYST']) ? true : false),
    is_show_label: false,
  },
  {
    path: "/property-file/:propertyId/:propertyFileId",
    name: "PropertY File",
    iconImage: require('./assets/img/img-icons/properties.png').default,
    component: PropertyFilePreview,
    layout: "/admin",
    is_visible: (checkRoles(['TECH', 'ANALYST']) ? true : false),
    is_show_label: false,
  },
  {
    path: "/deals",
    name: "Deals",
    icon: "fa fa-city text-primary",
    layout: "/admin",
    is_visible: (checkRoles(['TECH', 'ANALYST']) ? true : false),
    is_show_label: false,
    children: [
      {
        path: "/details/:propertyId",
        name: "Val Details",
        icon: "fas fa-tasks",
        component: ValDetails,
        layout: "/admin",
        is_visible: (checkRoles(['TECH', 'ANALYST']) ? true : false),
        is_show_label: false,
      },
    ]
  },
  {
    path: "/logs",
    name: "Logs",
    // icon: "fa fa-history text-info",
    iconImage: require('./assets/img/img-icons/log-format.png').default,
    layout: "/admin",
    is_visible: (checkRoles(['TECH']) === true ? true : false),
    children: [
      {
        path: "/error-logs",
        name: "Errors",
        // icon: "fa fa-bug",
        iconImage: require('./assets/img/img-icons/error.png').default,
        component: ErrorLogs,
        layout: "/admin",
        is_visible: (checkRoles(['TECH']) ? true : false),
        is_show_label: true
      },
      {
        path: "/email-logs",
        name: "Emails",
        // icon: "fa fa-envelope",
        iconImage: require('./assets/img/img-icons/email.png').default,
        component: EmailLogs,
        layout: "/admin",
        is_visible: (checkRoles(['TECH']) ? true : false),
        is_show_label: true
      },
      {
        path: "/release-logs",
        name: "Release Logs",
        iconImage: require('./assets/img/img-icons/release-log.png').default,
        component: ReleaseLogs,
        layout: "/admin",
        is_visible: (checkRoles(['TECH']) ? true : false),
        is_show_label: true
      },
    ]
  },
  {
    path: "/manage-draw-line-items/:inspection_order_id/:type",
    name: "Inspection",
    icon: "ni ni-bullet-list-67 text-info",
    component: ManageDrawLineItem,
    layout: "/admin",
    is_visible: (loggedInUser().is_RV_user === true) ? true : false,
    is_show_label: false
  },
];
export default routes;
