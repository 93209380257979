import React, { useState } from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import './style.scss';
import { PostData } from "../../../services/Api.service";
import { loggedInUser } from "components/Common/Helpers.js"
import Spinner from "components/Common/Spinner.js";
import { toast } from 'react-toastify';
import { confirmAlert } from "react-confirm-alert";
import eventBus from "./EventBus";

const HandleAssignOrder = ({ orderData }) => {
    const userData = loggedInUser();
    let [isProcess, setProcess] = useState(false);

    /**
    ** description: Save final reviewer user
    */
    let saveFinalReviewer = (data) => {
        let postData = {
            report_id: data.id,
            finalize_reviewer_id: userData.user_id
        }
        setProcess(true);
        PostData("orders/save-final-reviewer", postData)
            .then(result => {

                toast["success"](result.message);
                eventBus.dispatch("report_order_status_updated", { client_id: orderData.company_id });

                setProcess(false);
            }).catch(errors => {
                toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message);
                setProcess(false);
            });
    }

    /**
    ** description: Update final reviewer user
    */
    let updateFinalReviewer = (data) => {
        let postData = {
            report_id: data.id,
        }
        setProcess(true);
        PostData("orders/relinquish-final-reviewer", postData)
            .then(result => {

                toast["success"](result.message);
                eventBus.dispatch("report_order_status_updated", { client_id: orderData.company_id });

                setProcess(false);
            }).catch(errors => {
                toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message);
                setProcess(false);
            });
    }

    /**
    ** description: Assign final reviewer to logged in user
    */
    let handleAssign = () => {
        confirmAlert({
            title: '',
            message: 'Would you like to take this Finalize assignment?',
            closeOnClickOutside: false,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        saveFinalReviewer(orderData);
                    },
                    className: "btn btn-primary btn-sm"
                }, {
                    label: 'No',
                    onClick: () => { },
                    className: "btn btn-secondary btn-sm"
                }
            ]
        });
    }

    /**
    ** description: Relinquish final reviewer from user
    */
    let handleRelinquish = () => {
        let message = `Would you like to relinquish your assignment?`;
        if (orderData.finalize_reviewer_id !== userData.user_id) {
            message = `Would you like to relinquish this assignment from ${orderData.final_reviewer}?`;
        }
        confirmAlert({
            title: '',
            message: message,
            closeOnClickOutside: false,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        updateFinalReviewer(orderData);
                    },
                    className: "btn btn-primary btn-sm"
                }, {
                    label: 'No',
                    onClick: () => { },
                    className: "btn btn-secondary btn-sm"
                }
            ]
        });
    }
    return (
        <>
            <Spinner isShow={isProcess} />
            {orderData.finalize_reviewer_id ? (
                <>
                    <Button id={"relinquish_" + orderData.id} color="warning" size="sm" outline type="button" onClick={e => handleRelinquish(e)}>
                        <i className="fa fa-tasks" aria-hidden="true"></i>
                    </Button>
                    <UncontrolledTooltip delay={0} placement="auto" target={"relinquish_" + orderData.id} >Relinquish assignment </UncontrolledTooltip>
                </>
            ) : (
                <>
                    <Button id={"assign_" + orderData.id} className="btn-accept" size="sm" outline type="button" onClick={e => handleAssign(e)} >
                        <i className="fa fa-check" aria-hidden="true"></i>
                    </Button>
                    <UncontrolledTooltip delay={0} placement="auto" target={"assign_" + orderData.id} >Assign</UncontrolledTooltip>
                </>
            )}
        </>
    )
}
export default HandleAssignOrder;