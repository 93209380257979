
export function Validate(value, validations) {

	let error = false;
	let message = "Validation Passed.";
	var BreakException = {};

	try {
		validations.forEach(function (element) {

			switch (element[0]) {

				case 'required':

					if (element[1] === true && !value) {
						error = true;
						message = 'This field is required.';
						throw BreakException;
					}
					break;

				case 'multi_image_required':

					if (element[1] === true && !value.length) {
						error = true;
						message = 'This field is required.';
						throw BreakException;
					}
					break;

				case 'accepted_file_type':
					let types = element[1].split(',');

					if (!types.includes(value.type)) {
						error = true;
						message = 'Invalid file type.';
						throw BreakException;
					}
					break;

				case 'array_max_length':

					if (value.length > element[1]) {
						error = true;
						message = 'Max 1 file allowed!';
						throw BreakException;
					}
					break;

				default:
					break;
			}
		});

	} catch (e) {
		if (e !== BreakException) throw e;
	}

	return { error: error, message: message };
}