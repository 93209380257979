import React, { useEffect } from "react";
import { Button, Col, FormGroup, Modal, Row, UncontrolledTooltip } from "reactstrap";
import { useState } from "react";
import { toast } from "react-toastify";
import { AvForm } from "availity-reactstrap-validation";
import { PutData } from "services/Api.service";
import { sendExceptionEmail } from "components/Common/Helpers";
import * as moment from "moment";

const RequeueEmail = ({ propRowData, propButtonOptions, callback }) => {

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isProcessing, setProcessing] = useState(false);
    const [update, setUpdate] = useState(moment());

    useEffect(() => { }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleActionClick = async (e, row) => {
        e.preventDefault();
        setIsOpenModal(true);
        setUpdate(moment())
    };

    const handleToggleModal = (state) => {
        if (!isProcessing) {
            setIsOpenModal(!state);
            setUpdate(moment());
        }
    };

    let handleSubmit = async () => {
        try {
            const data = {
                status: propRowData.activeTab
            };

            setProcessing(true);
            const response = await PutData(`admin/email-logs/${propRowData.id}/requeue`, data);
            toast["success"](response.message);
            callback();
            setProcessing(false);
        } catch (error) {
            toast["error"](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error)
            setProcessing(false);
        }
    };

    return (
        <>
            <Button
                color={propButtonOptions?.color ? propButtonOptions.color : "warning"}
                outline={propButtonOptions?.outline ? propButtonOptions.outline : true}
                block={propButtonOptions?.block ? propButtonOptions.block : false}
                size={propButtonOptions?.size ? propButtonOptions.size : 'sm'}
                type="button"
                id={`unassign_user_${propRowData.id}`}
                onClick={e => handleActionClick(e)}
            >
                <i className="fa fa-history" ></i>{propButtonOptions?.label ? ` ${propButtonOptions.label}` : null}
            </Button>
            <UncontrolledTooltip delay={0} placement="top" target={`unassign_user_${propRowData.id}`} >Requeue Email</UncontrolledTooltip>

            {isOpenModal &&
                <Modal className="modal-dialog-centered modal-md" isOpen={isOpenModal} toggle={() => handleToggleModal(isOpenModal)}>
                    <div className="modal-header modal-header-colored">
                        <h2 className="modal-title w-100 text-center">
                            Requeue Email
                        </h2>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenModal)}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="text-left">
                            <AvForm onValidSubmit={(e) => handleSubmit()} >
                                <Row>
                                    <h2> Are you sure you want to requeue this email ? </h2>

                                    <Col xs="12" sm="12" md="12" lg="12" className="text-right">
                                        <FormGroup>
                                            {isProcessing ?
                                                <Button size="sm" type="button" color="warning" >
                                                    <div className="fa fa-spinner fa-spin"></div> Processing...
                                                </Button>
                                                :
                                                <>
                                                    <Button size="sm" color="danger" outline onClick={() => handleToggleModal(isOpenModal)} >
                                                        Cancel
                                                    </Button>
                                                    <Button outline size="sm" color="primary"> Yes </Button>
                                                </>
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </AvForm>
                        </div>
                    </div>
                </Modal>
            }
        </>
    );
};

export default RequeueEmail;