import React from "react";
import "./file-upload.scss";

const FilesList = ({ filesList = "" }) => {
    return (
        <>
            {filesList &&
                <aside className="selected-file-list mt-2">
                    <ul>{filesList}</ul>
                </aside>
            }
        </>
    );
}
export default FilesList;
