import React, { useState, useEffect } from "react";
import { Col, Modal, Row } from "reactstrap";
import Spinner from "components/Common/Spinner";
import * as moment from "moment";
import RenderBootstrapTable from "components/Common/RenderBootstrapTable";
import { AvField, AvForm } from "availity-reactstrap-validation";
import Comparable from "./Comparable";
import { PostData } from "services/Api.service";
import { toast } from "react-toastify";
import { priceNumber, sendExceptionEmail } from "components/Common/Helpers";

import { useDispatch } from 'react-redux'
import { setAsIsComps, setArvComps, clearComps } from './DisputeOrder.reducer'
import { confirmAlert } from "react-confirm-alert";
import eventBus from "../EventBus";

moment.tz.setDefault("America/phoenix");
let myFormRef;

const ValuationReviewRequestModal = ({ isDisputeModal, propReviewRequestModal, orderData, reopenStatus, otherData = {} }) => {
    let [isReviewOpen, setReviewOpen] = useState(true);
    let [isProcess, setProcess] = useState(false);
    let [defaultValue, setDefaultValue] = useState();
    let [disputeData, setDisputeData] = useState({ maxWidth: "50%" });
    let [currentStep, setCurrentStep] = useState(1);
    let [comparableDiv, setComparableDiv] = useState([{ id: 1 }, { id: 2 }, { id: 3 }]);
    let [arvDiv, setArvDiv] = useState([{ id: 1 }, { id: 2 }, { id: 3 }]);
    let [update, setUpdate] = useState(moment());
    let [disputeFormData, setDisputeFormData] = useState({});
    let [isEdit, setEditMode] = useState(false);
    let dispatch = useDispatch();

    useEffect(() => {
        if (isDisputeModal) {
            dispatch(clearComps());
            let asyncFuncs = async () => {
                await getOrderData(orderData);

                if (Object.keys(otherData).length) {
                    if (otherData.currentStep) {
                        setCurrentStep(otherData.currentStep);
                    }
                    if (otherData.property_value_checkbox_1) {
                        disputeFormData.property_value_checkbox_1 = otherData.property_value_checkbox_1;
                        let comparableDivComps = await prepareCompsData(otherData.asIsComps);
                        setComparableDiv(comparableDivComps);
                    }
                    if (otherData.property_value_checkbox_2) {
                        disputeFormData.property_value_checkbox_2 = otherData.property_value_checkbox_2;
                        let arvDivComps = await prepareCompsData(otherData.arvComps);
                        setArvDiv(arvDivComps);
                    }
                    setDisputeFormData(disputeFormData);

                    disputeData.maxWidth = otherData.maxWidth ? otherData.maxWidth : '95%';
                    setDisputeData(disputeData);
                    setEditMode(true);
                }
            }
            asyncFuncs()
            handleValuationReviewModal(isDisputeModal);
        }

        //eslint-disable-next-line
    }, [isDisputeModal]);

    useEffect(() => {
    }, [update]);// eslint-disable-line react-hooks/exhaustive-deps

    // Handle valuation review modal popup
    let handleValuationReviewModal = (state, row, field) => {
        setReviewOpen(state);
        propReviewRequestModal({ state });
    };

    // description: Generate SAAS and OMS column checkbox
    let handleCheckbox = (row) => {
        let row_id = `${row.id}`;
        return (
            <>
                <div className="custom-control custom-control-alternative custom-checkbox report-type-checkbox" sm="2">
                    <input className="custom-control-input" type="checkbox" value={row.id} onChange={handleChange}
                        name={"property_value_checkbox_" + row_id}
                        id={"property_value_checkbox_" + row_id}
                        checked={disputeFormData[`property_value_checkbox_${row_id}`] ? 'checked' : ''}
                    />
                    <label className="custom-control-label" htmlFor={"property_value_checkbox_" + row_id}></label>
                </div>
            </>
        )
    }

    // Handle input and checkbox change event
    let handleChange = (e) => {
        const { name, value, checked, type } = e.target;
        if (type === "checkbox") {
            if (checked) {
                let selected = { [name]: value };
                disputeFormData = { ...disputeFormData, ...selected };
            } else {
                if (disputeFormData.hasOwnProperty(name)) {
                    delete disputeFormData[name];
                }
            }
        } else {
            let selected = { [name]: value };
            disputeFormData = { ...disputeFormData, ...selected };
        }
        setDisputeFormData(disputeFormData);
        setUpdate(moment());
    }

    // Generate the Reason textarea field
    let handleReasonField = (row) => {
        let row_id = `${row.id}`;
        let validate = {
            required: {
                value: false,
                errorMessage: 'This field is required'
            },
        }
        return (<AvField
            name={"reason_" + row_id}
            maxLength="250"
            autoComplete="off"
            onChange={(e) => { handleChange(e) }}
            className="form-control-alternative form-control"
            placeholder="Please enter a reason"
            type="text"
            required
            minLength="2"
            value={disputeFormData[`reason_${row_id}`]}
            validate={validate}
        />)
    }

    // Handle First step, select property value
    let handleStepFirst = async () => {
        let validate = await validateObject();
        if (!validate.status) {
            confirmAlert({
                title: 'Error',
                message: validate.message,
                closeOnClickOutside: false,
                closeOnEscape: false,
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => { },
                        className: "btn btn-danger btn-sm"
                    }
                ]
            });
            return false;
        }

        if (disputeFormData.property_value_checkbox_2 === '2' && !disputeFormData.property_value_checkbox_1) {
            await handleStepTwo();
        } else {
            setCurrentStep(2);
            disputeData.maxWidth = '95%';
            setDisputeData(disputeData);
        }
        setUpdate(moment());
    }

    // Validate json object for property value checkbox
    let validateObject = async () => {
        const asIsCheckbox = disputeFormData.property_value_checkbox_1 ?? null;
        const arvCheckbox = disputeFormData.property_value_checkbox_2 ?? null;
        const asIsReason = disputeFormData.reason_1 ? disputeFormData.reason_1.trim() : null;
        const arvReason = disputeFormData.reason_2 ? disputeFormData.reason_2.trim() : null;

        let status = true;
        let message = null;
        if (!asIsCheckbox && !arvCheckbox) {
            status = false;
            message = 'Please select at least one "Value" and enter "Reason/Request" values.';
        }

        if (asIsCheckbox === '1') {
            if (!asIsReason) {
                status = false;
                message = 'Please enter values in "Reason/Request" field.';
            }
        }
        if (asIsReason) {
            if (!asIsCheckbox) {
                status = false;
                message = 'Please select a "As Is Value" field.';
            }
        }

        if (arvCheckbox === '2') {
            if (!arvReason) {
                status = false;
                message = 'Please enter values in "Reason/Request" field.';
            }
        }
        if (arvReason) {
            if (!arvCheckbox) {
                status = false;
                message = 'Please select a "After Repair Value" field.';
            }
        }
        return { status, message };
    }

    // Handle step 2, if valid open step-3 modal
    let handleStepTwo = async () => {
        if (disputeFormData.property_value_checkbox_1 === '1' && !disputeFormData.property_value_checkbox_2) {
            if (handleManualValidation('asIs')) {
                await submitComparable();
            }
        } else {
            setCurrentStep(3);
            disputeData.maxWidth = '95%';
            setDisputeData(disputeData);
            setUpdate(moment());
        }
    }

    // Back button, handle width of modal and step
    let handleBackStep = (currentStep) => {
        let previousStep = currentStep - 1;
        if (!disputeFormData.property_value_checkbox_1 && disputeFormData.property_value_checkbox_2 === '2') {
            previousStep = 1;
        }
        setCurrentStep(previousStep);
        disputeData.maxWidth = previousStep === 1 ? '50%' : '95%';
        setDisputeData(disputeData);
    }

    // Add Comps by Add Comparable button
    const addComparable = (type) => {
        if (type === 'asIs') {
            const newObject = { id: comparableDiv.length + 1 };
            const updatedDiv = [...comparableDiv, newObject];
            setComparableDiv(updatedDiv);
            dispatch(setAsIsComps(updatedDiv));
        } else if (type === 'arv') {
            const newObject = { id: arvDiv.length + 1 };
            const updatedDiv = [...arvDiv, newObject];
            setArvDiv(updatedDiv);
            dispatch(setArvComps(updatedDiv));
        }
        setUpdate(moment());
    };

    // Remove comps
    let removeComparable = (data, type) => {
        if (type === "asIs") {
            comparableDiv = comparableDiv.filter(item => item.id !== data);
            setComparableDiv(comparableDiv);
        } else {
            arvDiv = arvDiv.filter(item => item.id !== data);
            setArvDiv(arvDiv);
        }
        setUpdate(moment());
    }

    // Add comp data
    let addCompData = async (data, type) => {
        if (type === "asIs") {
            let updatedComparableDiv = [...data.comps];
            let filterComparableDiv = updatedComparableDiv.filter((element) => element?.address !== undefined);
            let comparableDivMerged = removeDuplicate(comparableDiv, filterComparableDiv);
            setComparableDiv(comparableDivMerged);
            dispatch(setAsIsComps(comparableDivMerged));
            setUpdate(moment());
        } else if (type === "arv") {
            let updatedArvDiv = [...data.comps];
            let filterComparableDiv = updatedArvDiv.filter((element) => element.address !== undefined);
            let arvDivMerged = removeDuplicate(arvDiv, filterComparableDiv);
            setArvDiv(arvDivMerged);
            dispatch(setArvComps(arvDivMerged));
            setUpdate(moment());
        }
    }

    let removeDuplicate = (array1, array2) => {
        let mergedArray = array1.map(item1 => {
            let matchingItem = array2.find(item2 => item2.id === item1.id);
            return { ...item1, ...matchingItem };
        });
        return mergedArray;
    }

    // On page load, load order data and other required data
    let getOrderData = async (params) => {
        try {
            setProcess(true);
            let result = await PostData("orders/get-property-value", { order_id: params.id, property_id: params.property_id });
            let responseJson = result.data;

            if (responseJson && result.success) {
                let arvData = responseJson.arvData;
                disputeData.apexPropTypes = responseJson.apexPropTypes;
                disputeData.property_id = params.property_id;
                disputeData.order_id = params.id;
                disputeData.loan_officer_id = responseJson.reportOrder.loan_officer_id;
                disputeData.company_id = responseJson.reportOrder.company_id;
                disputeData.total_comps = responseJson.totalComps;
                setDisputeData(disputeData);
                setDefaultValue([{
                    id: 1,
                    value: "As Is Value",
                    amount: priceNumber(arvData.as_is_value),
                    reason: "No"
                }, {
                    id: 2,
                    value: "After Repair Value",
                    amount: priceNumber(arvData.arv),
                    reason: "No"
                }]);
            }
            setProcess(false);
        } catch (errors) {
            setProcess(false);
            await sendExceptionEmail(errors)
            toast["error"]("Something went wrong.");
        };
    };

    // Check form is valid or not
    const handleManualValidation = async (type) => {
        const values = myFormRef.getValues();
        let { isValid } = await myFormRef.validateAll(values, false);

        if (type === "asIs") {
            let validateResult = validateComp(comparableDiv, isValid);
            let comps = validateResult.comps;
            isValid = validateResult.isValid;
            setComparableDiv(comps);
        } else if (type === "arv") {
            let validateResult = validateComp(arvDiv, isValid);
            let comps = validateResult.comps;
            isValid = validateResult.isValid;
            setComparableDiv(comps);
        }

        return isValid;
    };

    let validateComp = (arvDiv, isValid) => {
        let tempArvDiv = [];
        for (const comp of arvDiv) {
            const isValidAddress = validateAddress(comp);
            let tempComp = { ...comp };
            if (!isValidAddress) {
                isValid = false;
                tempComp.valid = false;
            } else {
                isValid = true;
                tempComp.valid = true;
            }
            tempArvDiv.push(tempComp);
        }
        return { comps: tempArvDiv, isValid };
    }

    let validateAddress = (obj) => {
        if ('address' in obj) {
            if (obj.address === undefined || obj.address.trim() === '') {
                return false;
            }
            return true;
        }
        return false;
    }

    // Submit the comps
    let submitComparable = async () => {
        try {
            setProcess(true);
            const arvCompsLength = arvDiv.filter(item => Object.keys(item).length > 1);
            const asIsCompsLength = comparableDiv.filter(item => Object.keys(item).length > 1);

            let postData = {
                order_id: disputeData.order_id,
                loan_officer_id: disputeData.loan_officer_id,
                company_id: disputeData.company_id,
                action_id: reopenStatus,
                is_edit: isEdit
            }

            if ("property_value_checkbox_1" in disputeFormData) {
                postData.asis_data = asIsCompsLength.length > 0 ? comparableDiv : [];
            }
            if ("property_value_checkbox_2" in disputeFormData) {
                postData.arv_data = arvCompsLength.length > 0 ? arvDiv : [];
            }

            let result = await PostData("orders/save-comparable", postData);
            if (result.success) {
                toast["success"](result.message);
            }
            setProcess(false);
            propReviewRequestModal({ state: false, refreshData: true });
            eventBus.dispatch("report_order_status_updated", { client_id: orderData.company_id });
        } catch (error) {
            toast["error"](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error)
            setProcess(false);
        }
    }

    let mergeObjectsById = async (arr) => {
        const result = [];

        for (const item of arr) {
            const existingItem = result.find((existing) => existing.id === item.id);

            if (existingItem) {
                Object.assign(existingItem, item);
            } else {
                result.push({ ...item });
            }
        }

        return result;
    }

    let prepareAddress = (compDetail) => {
        return `${compDetail.address}, ${compDetail.city}, ${compDetail.state}, ${compDetail.zip}`;
    }

    let prepareCompsData = async (compsList) => {
        if (compsList) {
            for (let [index, compData] of compsList.entries()) {
                let address = prepareAddress(compData);
                let newObject = {
                    id: (index + 1),
                    address: compData.address,
                    formatted_address: address,
                    type: compData.property_type_normalized,
                    above: compData.above_grade_sqft,
                    below: compData.below_grade_sqft,
                    beds: compData.beds,
                    baths: compData.baths,
                    year: compData.year,
                    lot_size: compData.lot_sqft,
                    stories: compData.stories ? parseInt(compData.stories) : 0,
                    garage: compData.garage,
                    sale_price: compData.close_price,
                    sale_date: compData.sale_date,
                    condition_rating: compData.condition_rating ? parseFloat(compData.condition_rating) : 1,
                    property_comp_id: compData.property_comp_id ?? 0,
                    distance: compData.distance ? compData.distance : 0,
                    golfcourse: compData.golf ?? 0,
                    waterfront: compData.waterfront ?? 0,
                    pool: compData.pool ?? 0,
                    listing_url: compData.listing_url ?? '',
                    mls_number: compData.mls_number ?? '',
                    reason: compData.client_comments ?? '',
                    dispute_id: compData.id
                };
                comparableDiv = [...comparableDiv, newObject];
            }
        }
        comparableDiv = await mergeObjectsById(comparableDiv);
        return comparableDiv;
    }

    let columns = [
        { title: "", width: "0%", dataField: "id", isKey: true, hidden: true },
        { title: "", width: "5%", dataFormat: (cell, row) => handleCheckbox(row), align: "left" },
        { title: "Value", width: "30%", dataField: "value", align: "left" },
        { title: "Amount", width: "30%", dataField: "amount", align: "left" },
        { title: `Reason/Request`, width: "35%", dataFormat: (cell, row) => handleReasonField(row), align: "left" },
    ];

    const tableParams = {
        tableData: defaultValue,
        columns,
    };

    return (
        <>
            <Spinner isShow={isProcess} />
            <Modal className="modal-dialog-centered modal-xl dispute-process-modal" style={{ maxWidth: disputeData.maxWidth }} isOpen={isReviewOpen} toggle={() => handleValuationReviewModal(false)} backdrop="static" keyboard={false}>
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title text-center w-100">
                        {currentStep === 1 ? `Select Property Value` : 'Add comparable'}
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => { handleValuationReviewModal(false); }}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Col xl="12">
                        <div>
                            {(currentStep === 1) &&
                                <AvForm onValidSubmit={handleStepFirst}>
                                    <div className={`dispute-step-1 ${currentStep === 1 ? 'show' : 'hide'}`}>
                                        <Row>
                                            <h2 className="modal-title w-100">Which property value would you like to have our team reconsider and why?</h2>
                                            <div className="table-responsive dispute-step1-table mt-3">
                                                <RenderBootstrapTable tableParams={tableParams} pagination={false} />
                                                <p className="red-text">You will be required to provide specific comparable for each value you would like to review</p>
                                            </div>
                                        </Row>
                                        <Row className="float-right">
                                            <button type="submit" className="btn btn-primary" >Continue</button>
                                        </Row>
                                    </div>
                                </AvForm>
                            }

                            {(currentStep === 2 && disputeFormData.property_value_checkbox_1 === '1') &&

                                <AvForm onValidSubmit={handleStepTwo} onInvalidSubmit={(e) => handleManualValidation('asIs')} ref={(el) => myFormRef = el}>
                                    <div className={`dispute-step-2 ${currentStep === 2 ? 'show' : 'hide'}`}>
                                        {disputeFormData.property_value_checkbox_1 === '1' &&
                                            <>
                                                <Row>
                                                    <h2 className="text-left w-100">
                                                        As Is Value: Please submit at least 3 comparables
                                                    </h2>
                                                    {comparableDiv.map((comp, divIndex) => (
                                                        <div className="mb-3" key={divIndex}>
                                                            <Comparable compData={comp} comparableDivRec={comparableDiv} propDeleteComparable={removeComparable} disputeData={disputeData} propAddComp={(data) => addCompData(data, "asIs")} type="asIs" />
                                                            <hr className="hr-color" />
                                                        </div>
                                                    ))}
                                                    <Col xl="12" className="text-right mb-4">
                                                        <button type="button" className="btn btn-primary" onClick={(e) => addComparable('asIs')}><i className="fas fa-plus"></i> Add comparable</button>
                                                    </Col>
                                                </Row>
                                                <Row className="float-right">
                                                    {!isEdit && <button type="button" className="btn btn-secondary" onClick={(e) => handleBackStep(currentStep)}>Back</button>}
                                                    {disputeFormData.property_value_checkbox_2 === '2' ?
                                                        <button type="submit" className="btn btn-primary">Continue</button>
                                                        :
                                                        <button type="submit" className="btn btn-primary">Save changes</button>
                                                    }
                                                </Row>
                                            </>
                                        }
                                    </div>
                                </AvForm>
                            }

                            {(currentStep === 3 && disputeFormData.property_value_checkbox_2 === '2') &&
                                <>
                                    <AvForm onValidSubmit={submitComparable} onInvalidSubmit={(e) => handleManualValidation('arv')} ref={(el) => myFormRef = el}>
                                        <div className={`dispute-step-3 ${currentStep === 3 ? 'show' : 'hide'}`}>
                                            {disputeFormData.property_value_checkbox_2 === '2' &&
                                                <>
                                                    <Row>
                                                        <h2 className="text-left w-100">
                                                            ARV: Please submit at least 3 comparables
                                                        </h2>
                                                        {arvDiv.map((comp, divIndex) => (
                                                            <div className="mb-3" key={divIndex}>
                                                                <Comparable compData={comp} comparableDivRec={arvDiv} propDeleteComparable={removeComparable} disputeData={disputeData} propAddComp={(data) => addCompData(data, "arv")} type="arv" />
                                                            </div>
                                                        ))}
                                                        <Col xl="12" className="text-right mb-4">
                                                            <button type="button" className="btn btn-primary" onClick={(e) => addComparable('arv')}><i className="fas fa-plus"></i> Add comparable</button>
                                                        </Col>
                                                    </Row>
                                                    <Row className="float-right">
                                                        {!isEdit && <button type="button" className="btn btn-secondary" onClick={(e) => handleBackStep(currentStep)}>Back</button>}
                                                        <button type="submit" className="btn btn-primary">Save changes</button>
                                                    </Row>
                                                </>
                                            }
                                        </div>
                                    </AvForm>
                                </>
                            }
                        </div>
                    </Col>
                </div >
            </Modal >
        </>
    )
};

export default ValuationReviewRequestModal;