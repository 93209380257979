import React, { useState, useEffect } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Card, Row, Col, UncontrolledTooltip, Modal, Button, FormGroup } from "reactstrap";
import * as moment from "moment";
import { PostData } from "../../../../../services/PostData";
import { loggedInUser, truncate } from "components/Common/Helpers.js"
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import eventBus from "../../EventBus";
import { BT_SIZE_PER_PAGE_LIST } from "../../Constants";
import RenderActionButton from "../RenderActionButton";
import { sendExceptionEmail } from "components/Common/Helpers";

const ReportOrderQuestionAnswerButton = ({ propButtonOptions, onQuestionAnswerData }) => {

    const userData = loggedInUser();
    const [isProcess, setProcess] = useState(false);
    const [update, setUpdate] = useState(moment());
    const [sortFieldNameHistory, setSortFieldNameHistory] = useState('date_added');
    const [sortOrderHistory, setSortOrderHistory] = useState('desc');
    const [totalSizeHistory, setTotalSizeQA] = useState(0);
    const [pageHistory, setPageQA] = useState(1);
    const [sizePerPageQA, setSizePerPageQA] = useState(10);
    const [isReportCheckbox, setReportCheckbox] = useState(true);
    const [isInspectionCheckbox, setInspectionCheckbox] = useState(true);
    const [reportOrderData, setReportOrderData] = useState("");
    const [historyData, setQuestionAnswerData] = useState([]);
    const [isOpenQuestionAnswerModal, setIsOpenQuestionAnswerModal] = useState(false);
    const [isOpenAnswerModal, setIsOpenAnswerModal] = useState(false);
    const [questionId, setQuestionId] = useState(false);
    const [answer, setAnswer] = useState(false);
    const [selectedRow, setSelectedRow] = useState([]);

    const handleActionClick = () => {
        setReportCheckbox(true);
        setPageQA(1);
        setReportOrderData("");
        setQuestionAnswerData([]);
        setInspectionCheckbox(true);
        setIsOpenQuestionAnswerModal(false);
        setIsOpenAnswerModal(false);
        setSelectedRow(onQuestionAnswerData);
        handleQuestionAnswer(pageHistory, sizePerPageQA, sortFieldNameHistory, sortOrderHistory, onQuestionAnswerData);
    };

    useEffect(() => { }, [update]);

    useEffect(() => {
        if (isOpenQuestionAnswerModal) {
            setPageQA(1);
            setReportOrderData("");
            setQuestionAnswerData([]);
            setSelectedRow(onQuestionAnswerData);
            handleQuestionAnswer(pageHistory, sizePerPageQA, sortFieldNameHistory, sortOrderHistory, selectedRow);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReportCheckbox, isInspectionCheckbox]);

    // Handle Error Modal
    let handleModal = (state) => {
        if (state === "isOpenQuestionAnswerModal") {
            state = !state;
            setIsOpenQuestionAnswerModal(state);
            setPageQA(1);
        } else if (state === "isOpenAnswerModal") {
            state = !isOpenAnswerModal;
            setIsOpenAnswerModal(state);
        }
        setUpdate(moment());
    };

    // Get History Reports
    const handleQuestionAnswer = async (currentPage = pageHistory, currentSizePerPage = sizePerPageQA, currentFieldName = sortFieldNameHistory, currentSortOrder = sortOrderHistory, row) => {
        try {
            if (row) {
                let currentOffset = (currentPage - 1) * currentSizePerPage;
                const data = {
                    report_order_id: row.id,
                    offset: currentOffset,
                    limit: currentSizePerPage,
                    sort_field: currentFieldName,
                    sort_order: currentSortOrder,
                };

                let historyData = [];
                setProcess(true);
                const result = await PostData("orders/question_answers", data)

                let responseJson = result.data;
                let reportOrderData = {};
                reportOrderData.id = row.id;
                reportOrderData.address = row.address;

                if (responseJson.status !== 200) {
                    toast["error"](responseJson.message);
                } else {
                    Object.keys(responseJson.data).forEach(i => {
                        // for (let i = 0; i < responseJson.data.length; i++) {
                        let historyDataObj = {};

                        historyDataObj.id = responseJson.data[i].id;
                        historyDataObj.question = responseJson.data[i].question;
                        historyDataObj.question_user = responseJson.data[i].question_user ? responseJson.data[i].question_user.first_name + ' ' + responseJson.data[i].question_user.last_name : '-';
                        historyDataObj.answer = responseJson.data[i].answer ? responseJson.data[i].answer : "-";
                        historyDataObj.answer_user = responseJson.data[i].answer_user ? responseJson.data[i].answer_user.first_name + ' ' + responseJson.data[i].answer_user.last_name : '-';
                        historyDataObj.date_added = responseJson.data[i].date_added ? moment(responseJson.data[i].date_added).format('MMM DD hh:mm A') : '-';
                        historyDataObj.date_answered = responseJson.data[i].date_answered ? moment(responseJson.data[i].date_answered).format('MMM DD hh:mm A') : '-';
                        historyDataObj.resolved_status = responseJson.data[i].resolved_status;

                        historyData.push(historyDataObj);
                    });
                };

                setReportOrderData(reportOrderData);
                setQuestionAnswerData(historyData);
                setTotalSizeQA(result.data.count);
                setSizePerPageQA(currentSizePerPage);
                setPageQA(currentPage);
                setIsOpenQuestionAnswerModal(true);

                setTimeout(() => {
                    setProcess(false);
                }, 200);
            }
        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error)
            setProcess(false);
        };
    };

    // Report section
    const historyActionBtn = (cell, row) => {

        return (
            <>
                {row.resolved_status === 0 ? (
                    <>
                        <Button id={"answer_" + row.id} color="warning" size="sm" type="button" onClick={e => handleOrderStatus(e, row, 'answer')}>
                            <i className="fa fa-reply" aria-hidden="true"></i>
                        </Button>
                        <UncontrolledTooltip delay={0} placement="auto" target={"answer_" + row.id} >Answer</UncontrolledTooltip>
                    </>
                ) : "-"}
            </>
        );
    };

    const questionData = (cell, row) => {
        const question_id = `question_text_${row.id}`;
        return (
            <div>
                <span id={question_id}>{truncate(row.question, 20)}</span>
                <UncontrolledTooltip
                    delay={0}
                    placement="auto"
                    target={question_id}
                >
                    {row.question}
                </UncontrolledTooltip>
            </div>

        );
    };

    const answerData = (cell, row) => {
        const answer_id = `answer_text_${row.id}`;
        return (
            <div>
                <span id={answer_id}>{truncate(row.answer, 20)}</span>
                <UncontrolledTooltip
                    delay={0}
                    placement="auto"
                    target={answer_id}
                >
                    {row.answer}
                </UncontrolledTooltip>
            </div>

        );
    };

    const handleOrderStatus = (e, row, type) => {
        setQuestionId(row.id);
        handleModal("isOpenAnswerModal");
    };

    const handlePageChangeHistory = (pageHistory, sizePerPageQA) => {
        handleQuestionAnswer(pageHistory, sizePerPageQA, sortFieldNameHistory, sortOrderHistory, onQuestionAnswerData);
        setUpdate(moment());
    };

    const renderShowsTotalHistory = (start, to, total) => {
        return (
            <p>From {start} to {to}, totals is {total}</p>
        );
    };

    const onSortChangeHistory = (fieldName, order) => {
        if (order === 'desc') {
            order = 'asc';
        } else {
            order = 'desc';
        }

        setSortFieldNameHistory(fieldName);
        setSortOrderHistory(order);
        handleQuestionAnswer(pageHistory, sizePerPageQA, fieldName, order);
        setUpdate(moment());
    };

    const handleAnswerProcess = async (question_id) => {
        try {
            setProcess(true);
            const result = await PostData("orders/answer_order_question", { report_order_id: reportOrderData.id, question_id: question_id, answer: answer, user_id: userData.user_id });

            let responseJson = result.data;
            setProcess(false);

            if (responseJson.status !== 200) {
                toast["error"](responseJson.message);
            } else {
                toast["success"](responseJson.message);
                handleModal("isOpenAnswerModal");
                handleModal("isOpenQuestionAnswerModal");
            }

            eventBus.dispatch("report_order_status_updated", { client_id: onQuestionAnswerData.company_id });
        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error)
            setProcess(false);
        };
    };

    // History table option
    const historyOptions = {
        sizePerPageList: BT_SIZE_PER_PAGE_LIST,
        onPageChange: handlePageChangeHistory,
        onSortChange: onSortChangeHistory,
        page: pageHistory,
        sizePerPage: sizePerPageQA,
        paginationSize: 3,
        prePage: <i className="fas fa-angle-left"></i>,
        nextPage: <i className="fas fa-angle-right"></i>,
        firstPage: <i className="fas fa-angle-double-left"></i>,
        alwaysShowAllBtns: true,
        lastPage: <i className="fas fa-angle-double-right"></i>,
        paginationShowsTotal: renderShowsTotalHistory
    };

    const historyTable = (
        <div className="table-responsive search-table">
            <BootstrapTable
                responsive
                data={historyData}
                options={historyOptions}
                fetchInfo={{ dataTotalSize: totalSizeHistory }}
                remote
                pagination
                striped
                hover
                className="align-items-center table-flush table"
                condensed>
                <TableHeaderColumn width="20%" dataFormat={questionData} dataAlign="left">Question</TableHeaderColumn>
                <TableHeaderColumn width="15%" dataField="question_user" dataAlign="left">Question User</TableHeaderColumn>
                <TableHeaderColumn width="20%" dataFormat={answerData} dataAlign="left">Answer</TableHeaderColumn>
                <TableHeaderColumn width="15%" dataField="answer_user" dataAlign="left">Answer User</TableHeaderColumn>
                <TableHeaderColumn isKey width="10%" dataField="date_added" dataAlign="left">Que Date</TableHeaderColumn>
                <TableHeaderColumn width="10%" dataField="date_answered" dataAlign="left">Ans Date</TableHeaderColumn>
                <TableHeaderColumn width="10%" dataFormat={historyActionBtn} dataAlign="center">Action</TableHeaderColumn>
            </BootstrapTable>
        </div >
    );

    return (
        <>
            <RenderActionButton propButtonTitle="Question/Answer" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />

            <Modal className="modal-dialog-centered" style={{ maxWidth: "70%" }} isOpen={isOpenQuestionAnswerModal} toggle={() => handleModal("isOpenQuestionAnswerModal")}>
                <div className="modal-header modal-header-colored">
                    <h4 className=" modal-title text-center w-100">Report Question/Answer {reportOrderData ? ": " + reportOrderData.address : ""}</h4>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() =>
                        handleModal("isOpenQuestionAnswerModal")}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Card className="shadow">
                        <Row className="mt-3">
                            <Col>
                                {historyTable}
                            </Col>
                        </Row>
                    </Card>
                </div>
            </Modal>


            <Modal className="modal-dialog-centered" style={{ maxWidth: "40%" }} isOpen={isOpenAnswerModal} toggle={() => handleModal("isOpenAnswerModal")}>
                <div className="modal-header modal-header-colored">
                    <h4 className="modal-title w-100 text-center" id="reassignModalLabel">
                        Answer
                    </h4>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleModal("isOpenAnswerModal")}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <AvForm onValidSubmit={(e) => handleAnswerProcess(questionId, false)} >
                        <AvGroup className="text-left">
                            <AvField
                                name="cancellation_reason"
                                onChange={(e) => setAnswer(e.target.value)}
                                className="form-control"
                                placeholder="Enter Answer"
                                type="textarea"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "This field is required."
                                    },
                                    maxLength: {
                                        value: 700,
                                        errorMessage: 'Answer must be less than 700 character'
                                    }
                                }}
                            />
                        </AvGroup>
                        <div className="text-right">
                            <FormGroup>
                                {isProcess ?
                                    <Button type="button" color="warning" size="sm">
                                        <div className="fa fa-spinner fa-spin"></div> Processing...
                                    </Button>
                                    :
                                    <>
                                        <Button size="sm" color="danger" outline onClick={() => handleModal("isOpenAnswerModal")}>
                                            Close
                                        </Button>
                                        <Button size="sm" color="primary">
                                            Submit
                                        </Button>
                                    </>
                                }
                            </FormGroup>
                        </div>
                    </AvForm>
                </div>
            </Modal>
        </>
    );
};

export default ReportOrderQuestionAnswerButton;