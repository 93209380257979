import React, { useState, useEffect } from "react";
import { Button, Modal } from "reactstrap";
import { loggedInUser, AutoLogin, formatDate } from "components/Common/Helpers";
import * as moment from "moment";

const ReportOrderManyOpenOrdersModal = ({ isOpenModal, propRowData }) => {

    const phpUrl = process.env.REACT_APP_PHP_ENDPOINT;
    const userData = loggedInUser();

    const [isOpenCancelReportOrderModal, setIsOpenCancelReportOrderModal] = useState(false);
    const [update, setUpdate] = useState(moment());

    useEffect(() => { }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isOpenModal.current) {
            handleActionClick()
        }
    }, [isOpenModal.current]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleActionClick = (e, row) => {
        setIsOpenCancelReportOrderModal(true);
    };

    const handleToggleModal = (state) => {
        state = !state;
        isOpenModal.current = state
        setIsOpenCancelReportOrderModal(state);
        setUpdate(moment());
    };

    const handleAutoLogin = (url) => {
        const data = { url: url, user_id: userData.user_id };
        AutoLogin(data, (response) => { });
    };

    return (
        <>
            {/* Cancel report order Modal */}
            <Modal className="modal-dialog-centered modal-xl" isOpen={isOpenCancelReportOrderModal} toggle={() => handleToggleModal(isOpenCancelReportOrderModal)}>
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title text-center w-100" id="reassignModalLabel">
                        Many Open Orders
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenCancelReportOrderModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body" >
                    <h3 className="mb-3 text-danger">You already have too many open orders. Please complete your other orders before accepting new ones.</h3>

                    <table className='table'>
                        <thead>
                            <tr>
                                <th>ADDRESS</th>
                                <th>DESCRIPTION</th>
                                <th>REPORT</th>
                                <th>STATUS</th>
                                <th>DUE</th>
                                <th>AMT</th>
                                <th>ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            {propRowData?.openOrders.map((open, idx) =>
                                <tr key={idx}>
                                    <td >{open.property.address + ", " + open.property.city + ", " + open.property.state + ", " + open.property.postal_code}</td><td>
                                        <span>
                                            {parseFloat(open.order_intake.above_grade_sqft).toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                                            sqft, {open.order_intake.bedrooms + " + " + Math.round(open.order_intake.bathrooms)}
                                        </span><br /><span>
                                            {open.order_intake.year_built + " " + open.order_intake.property_type_normalized}
                                        </span>
                                    </td>
                                    <td>
                                        {open.report_type.formal_name} </td><td>{open.report_action_type.report_status}
                                    </td>
                                    <td>
                                        {formatDate(open, open.current_due_date, "MMM D")}
                                    </td>
                                    <td>${Math.round(open.underwriter_labor_rate).toFixed(0)}
                                    </td>
                                    <td>
                                        <Button size="sm" color="primary" outline onClick={(e) => handleAutoLogin(phpUrl + "valuation/deals/details/" + open.property.id)}>Continue</Button>
                                        {/* <Link className='btn btn-primary btn-sm text-white' to='#'  > Continue</Link> */}
                                    </td >
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </Modal>
        </>
    );
};

export default ReportOrderManyOpenOrdersModal;