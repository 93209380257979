import React, { useEffect, useState } from "react";
import { Col, Label, Row, UncontrolledTooltip } from "reactstrap";
import Dropzone from 'react-dropzone';
import "./file-upload.scss";
import * as moment from "moment";

import Ul from "components/Ui/Ul";
import Error from "components/Ui/Error";
import FilesList from "./FilesList";

const DragAndDropOrUpload = (props) => {

    let [update, setUpdate] = useState(moment());

    let [budgetFileUploadError, setFileUploadError] = useState("");
    let [selectedFiles, setSelectedFiles] = useState(props.selectedFiles ? props.selectedFiles : []);
    let [selectedUrls, setSelectedUrls] = useState(props.selectedUrls ? props.selectedUrls : []);

    let [maxFileSizeMB] = useState(props.maxFileSize ? props.maxFileSize : 10);
    let [maxFiles] = useState(props.maxFiles ? props.maxFiles : 1);

    let [acceptExtensionText, setAcceptExtensionText] = useState("");
    let [acceptExtensions, setAcceptExtensions] = useState("");

    useEffect(() => {
        parseAcceptedFileTypes()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { }, [update]);

    let parseAcceptedFileTypes = () => {
        let acceptFileArr = props.accept.split(",")

        let acceptExtensionTextTemp = [];
        let acceptExtensionsTemp = [];

        if (acceptFileArr.includes('pdf')) { acceptExtensionTextTemp.push("pdf"); acceptExtensionsTemp.push("application/pdf"); }
        if (acceptFileArr.includes('jpg') || acceptFileArr.includes('jpeg')) { acceptExtensionTextTemp.push("jpg ,jpeg"); acceptExtensionsTemp.push("image/jpg"); acceptExtensionsTemp.push("image/jpeg"); }
        if (acceptFileArr.includes('png')) { acceptExtensionTextTemp.push("png"); acceptExtensionsTemp.push("image/png"); }
        if (acceptFileArr.includes('excel')) { acceptExtensionTextTemp.push("excel"); acceptExtensionsTemp.push("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"); acceptExtensionsTemp.push("application/vnd.ms-excel"); }
        if (acceptFileArr.includes('word')) { acceptExtensionTextTemp.push("word"); acceptExtensionsTemp.push("application/msword"); acceptExtensionsTemp.push("application/vnd.openxmlformats-officedocument.wordprocessingml.document"); }
        if (acceptFileArr.includes('mp4')) { acceptExtensionTextTemp.push("mp4"); acceptExtensionsTemp.push("video/mp4"); }

        setAcceptExtensionText(acceptExtensionTextTemp);
        setAcceptExtensions(acceptExtensionsTemp);
    };

    let onDropFiles = (files) => {
        files = [...files, ...selectedFiles]
        if (files.length > maxFiles) {
            setFileUploadError(`Only ${maxFiles} file(s) allowed!.`);
        } else {
            setSelectedFiles(files);
            props.fileUploadCB({ selectedFiles: files, selectedUrls: selectedUrls });
            setFileUploadError("");
            setUpdate(moment());
        }
    };

    let onDropRejectedFiles = (rejectedFiles) => {

        if (rejectedFiles[0].errors[0].code === 'file-too-large') {
            rejectedFiles[0].errors[0].message = `File is larger than ${maxFileSizeMB} MB.`;
        }

        if (rejectedFiles[0].errors[0].code === 'file-invalid-type') {
            rejectedFiles[0].errors[0].message = `Please upload ${acceptExtensionText.toString()} file(s) only.`;
        }

        else if (rejectedFiles[0].errors[0].code === 'too-many-files') {
            rejectedFiles[0].errors[0].message = `Only ${maxFiles} file(s) allowed!.`;
        }

        const budgetFileUploadError = rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].errors[0].message;
        setFileUploadError(budgetFileUploadError);
        setUpdate(moment());
    };

    let deleteFile = (e, index) => {
        e.preventDefault();

        let selectedFileTemp = selectedFiles;
        selectedFileTemp.splice(index, 1);
        setSelectedFiles(selectedFileTemp);
        props.fileUploadCB({ selectedFiles: selectedFileTemp, selectedUrls: selectedUrls });

        setUpdate(moment());
    };

    let deleteUrl = (e, index) => {
        e.preventDefault();

        let selectedUrlTemp = selectedUrls;
        selectedUrlTemp.splice(index, 1);
        setSelectedUrls(selectedUrlTemp);
        props.fileUploadCB({ selectedFiles: selectedFiles, selectedUrls: selectedUrlTemp });

        setUpdate(moment());
    };

    let budgetFiles = selectedFiles && selectedFiles.map((file, i) => (
        <li key={file.name}>
            <i className="fa fa-trash text-danger" onClick={e => deleteFile(e, i)}></i><div> {file.name} ({(file.size / 1024 / 1024).toFixed(2)} MB)</div>
        </li>
    ));
    let s3BudgetFiles = selectedUrls && selectedUrls.map((file, i) => (
        <li key={i + file.name} style={{ listStyle: 'none' }}>
            <i className="fa fa-trash text-danger" onClick={e => deleteUrl(e, i)}></i> Server - {file.file_name}
        </li>
    ));

    return (
        <>
            <Row className="drop-zone-area">
                <Col lg="12">
                    {props.label &&
                        <Label className="">{props.label}</Label>
                    }
                    <>
                        <Dropzone
                            onDrop={onDropFiles}
                            onDropRejected={onDropRejectedFiles}
                            disabled={(props.conversationIssueResolved && props.conversationIssueResolved > 0) ? true : false}
                            accept={acceptExtensions}
                            minSize={0}
                            maxSize={1048576 * maxFileSizeMB} //1048576 equal to 1 MB
                            maxFiles={maxFiles}
                            require={true}>

                            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
                                return (
                                    <>
                                        {
                                            props.type && props.type === "pin" ?
                                                <div {...getRootProps({})}>
                                                    <input {...getInputProps()} />
                                                    <i id={"file_attached"} className="fa fa-paperclip fa-2x"></i>
                                                    <UncontrolledTooltip delay={0} placement="auto" target={"file_attached"} >Attach File</UncontrolledTooltip>
                                                </div>
                                                :
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <h2 className="drag-drop-text">Click to Select or Drag it Here.</h2>

                                                    <Ul propLiContent={[
                                                        `Acceptable file formats for upload: ${acceptExtensionText}`,
                                                        `Maximum size per file is ${maxFileSizeMB} MB.`,
                                                        `Maximum ${maxFiles} file(s) allowed.`
                                                    ]} />

                                                    <Error errorMessage={budgetFileUploadError} />
                                                </div>
                                        }
                                    </>
                                )
                            }}
                        </Dropzone>
                        {props.type !== "pin" &&
                            <>
                                <FilesList filesList={budgetFiles} />
                                <FilesList filesList={s3BudgetFiles} />

                            </>
                        }
                    </>
                </Col>
            </Row >
        </>
    );
};

export default DragAndDropOrUpload;