import React, { useState, useEffect } from "react";
import { Button, Modal, FormGroup, Label } from "reactstrap";
import { PostData } from "../../../../../services/PostData";
import { loggedInUser } from "components/Common/Helpers.js"
import { toast } from 'react-toastify';
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import * as moment from "moment";
import eventBus from "../../EventBus";
import RenderActionButton from "../RenderActionButton";
import { sendExceptionEmail } from "components/Common/Helpers";

const ReportOrderDismissRevisionButton = ({ propReportRow, propButtonOptions }) => {

    const userData = loggedInUser();
    const [isProcess, setProcess] = useState(false);
    const [isOpenDismissReportOrderModal, setOpenDismissReportOrderModal] = useState(false);
    const [dismissReason, setDismissReason] = useState("");
    const [update, setUpdate] = useState(moment());

    useEffect(() => { }, [update]); // eslint-disable-line react-hooks/exhaustive-deps


    const handleActionClick = () => {
        setOpenDismissReportOrderModal(true);
    };

    const handleToggleModal = (state) => {
        if (!isProcess) {
            state = !state;
            setOpenDismissReportOrderModal(state);
            setUpdate(moment());
        }
    };

    const handleDismissOrderProcess = async (report_order_id) => {
        try {
            setProcess(true);

            const result = await PostData("orders/dismiss_report_order", { report_order_id: report_order_id, user_id: userData.user_id, dismiss_reason: dismissReason })
            let responseJson = result.data;
            setProcess(false);

            if (responseJson.status !== 200) {
                toast["error"](responseJson.message);
            } else {
                toast["success"](responseJson.message);
                handleToggleModal(isOpenDismissReportOrderModal);
            }
            eventBus.dispatch("report_order_status_updated", { client_id: propReportRow.company_id });
        } catch (errors) {
            setProcess(false);
            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message)
            await sendExceptionEmail(errors);
        }
    };

    return (
        <>
            <RenderActionButton propButtonTitle="Cancel/Dismiss Revision" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />

            {/* Cancel report order Modal */}
            <Modal className="modal-dialog-centered" style={{ maxWidth: "500px" }} isOpen={isOpenDismissReportOrderModal} toggle={() => handleToggleModal(isOpenDismissReportOrderModal)}>
                <div className="modal-header modal-header-colored">
                    <h4 className="modal-title w-100 text-center" id="reassignModalLabel"> Dismiss </h4>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenDismissReportOrderModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <AvForm onValidSubmit={(e) => handleDismissOrderProcess(propReportRow.id, false)} >
                        <AvGroup className="text-left">
                            <Label className="required">Reason</Label>
                            <AvField
                                name="dismiss_reason"
                                onChange={(e) => setDismissReason(e.target.value)}
                                className="form-control"
                                placeholder="Enter Reason"
                                type="textarea"
                                rows="6"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "This field is required."
                                    },
                                    maxLength: {
                                        value: 500,
                                        errorMessage: 'Reason must be less than 255 character'
                                    }
                                }}
                            />
                        </AvGroup>
                        <div className="text-right">
                            <FormGroup>
                                {isProcess ?
                                    <Button type="button" color="warning" size="sm">
                                        <div className="fa fa-spinner fa-spin"></div> Processing...
                                    </Button>
                                    :
                                    <>
                                        <Button size="sm" color="danger" outline onClick={() => handleToggleModal(isOpenDismissReportOrderModal)}>
                                            Close
                                        </Button>
                                        <Button size="sm" color="primary">
                                            Submit
                                        </Button>
                                    </>
                                }
                            </FormGroup>
                        </div>
                    </AvForm>
                </div>
            </Modal>
        </>
    );
};

export default ReportOrderDismissRevisionButton;