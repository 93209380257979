import jwt_decode from "jwt-decode";
import { PostData } from "../../services/PostData";
import DateFormat from "components/Common/DateFormat";
import axios from "axios";

import moment from 'moment-timezone';
import { CONDITION } from "views/modules/Common/Constants/Property.constant";

// Common Constants
import {  ROLE_ACCOUNT_REP, ROLE_SALES_REP, ROLE_REVIEWER, ROLE_ANALYST, ROLE_DRV_ADMIN, ROLE_PROCESSOR, ROLE_INSPECTOR, A_PLUS, A, A_MINUS, B_PLUS, B, B_MINUS, C_PLUS, C_OR_C_MINUS, D, E } from "../../views/modules/Common/Constants";
            
moment.tz.setDefault("America/phoenix");

let CryptoJS = require("crypto-js");

const phpUrl = process.env.REACT_APP_PHP_ENDPOINT;

export const checkColorCondition = (row) => {
    const userData = loggedInUser();
    let classNameForRow = '';

    if (userData.report_order_company_id === userData.company_id) {
        let currentDate = new Date();
        currentDate = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;
        currentDate = new Date(currentDate);

        let dueDate = new Date(row.current_due_date);
        const day = dueDate.getDay();

        dueDate = `${dueDate.getMonth() + 1}/${dueDate.getDate()}/${dueDate.getFullYear()}`;
        dueDate = new Date(dueDate);

        if (day === 5) {
            dueDate.setDate(dueDate.getDate() + 3);
        } else if (day === 6) {
            dueDate.setDate(dueDate.getDate() + 2);
        } else {
            dueDate.setDate(dueDate.getDate() + 1);
        }

        if (dueDate < currentDate && row.is_rush_order < 3) {
            classNameForRow = 'custom-due-rush';
        }
        else if (dueDate < currentDate) {
            classNameForRow = 'custom-due-date';
        } else if (row.is_rush_order < 3) {
            classNameForRow = 'custom-rush-order';
        }
    } else {
        if (row.is_rush_order < 3) {
            classNameForRow = 'custom-rush-order';
        }
    }
    return classNameForRow;
};

export const AutoLogin = (data, callback) => {

    PostData("auth/encrypt", { id: data.user_id }).then(result => {
        let responseJson = result.data;
        if (responseJson.status === 200) {
            window.open(phpUrl + "login?token=" + responseJson.data.string + "&url=" + data.url + "&id=" + data.user_id, "_blank");
            callback(true);
            return true;
        }

    }).catch(errors => {
        return false;
    });
};

export const checkRoles = function (roleArray) {
    const userData = loggedInUser();
    let loggedInUserRoles = userData ? userData.roleNames : [];
    let is_exist = false;
    if (loggedInUserRoles) {

        loggedInUserRoles.forEach((currentRole, key) => {
            loggedInUserRoles[key] = currentRole.replace(' ', '_').toUpperCase();
        });
        roleArray.forEach(role => {
            role = role.replace(' ', '_');

            let index = loggedInUserRoles.findIndex(item => role.toUpperCase() === item.toUpperCase());

            if (index > -1) {
                is_exist = true;
            }
        });
    }


    return is_exist;
};

export const checkRolesWithLevel = function (rolesArrayToCheck, operatorToMatch, levelToCheck) {
    const userData = loggedInUser();
    let loggedInUserRoles = userData ? userData.roleData : [];

    loggedInUserRoles.forEach((currentRole, key) => {
        loggedInUserRoles[key].role_name = currentRole.role_name.replace(' ', '_').toUpperCase();
    });

    rolesArrayToCheck.forEach((role, key) => {
        rolesArrayToCheck[key] = role.replace(' ', '_').toUpperCase();
    });

    let is_exist = false;
    loggedInUserRoles.forEach(role => {
        let index = rolesArrayToCheck.findIndex(item => role.role_name === item);

        if (operatorToMatch && levelToCheck) {

            role.user_role.level = Number(role.user_role.level);
            levelToCheck = Number(levelToCheck);

            if (operatorToMatch === '=' || operatorToMatch === '==' || operatorToMatch === '===') {
                if (index > -1 && role.user_role.level === levelToCheck) {
                    is_exist = true;
                }
            } else if (operatorToMatch === '>') {
                if (index > -1 && role.user_role.level > levelToCheck) {
                    is_exist = true;
                }
            } else if (operatorToMatch === '>=') {
                if (index > -1 && role.user_role.level >= levelToCheck) {
                    is_exist = true;
                }
            } else if (operatorToMatch === '<') {
                if (index > -1 && role.user_role.level < levelToCheck) {
                    is_exist = true;
                }
            } else if (operatorToMatch === '<=') {
                if (index > -1 && role.user_role.level <= levelToCheck) {
                    is_exist = true;
                }
            }
        } else {
            if (index > -1) {
                is_exist = true;
            }
        }
    });

    return is_exist;
};

export const checkRolesWithEval = function (rolesArrayToCheck, operatorToMatch, signatureToCheck) {
    const userData = loggedInUser();
    let loggedInUserRoles = userData ? userData.roleData : [];

    loggedInUserRoles.forEach((currentRole, key) => {
        loggedInUserRoles[key].role_name = currentRole.role_name.replace(' ', '_').toUpperCase();
    });

    rolesArrayToCheck.forEach((role, key) => {
        rolesArrayToCheck[key] = role.replace(' ', '_').toUpperCase();
    });

    let is_exist = false;
    loggedInUserRoles.forEach(role => {
        let index = rolesArrayToCheck.findIndex(item => role.role_name === item);

        if (operatorToMatch && signatureToCheck) {
            role.user_role.signature = Number(role.user_role.signature);
            signatureToCheck = Number(signatureToCheck);

            if (operatorToMatch === '=' || operatorToMatch === '==' || operatorToMatch === '===') {
                if (index > -1 && role.user_role.signature === signatureToCheck) {
                    is_exist = true;
                }
            }
        } else {
            if (index > -1) {
                is_exist = false;
            }
        }
    });

    return is_exist;
};

export const loggedInUser = function () {
    // return localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : {};
    if (localStorage.getItem("userData")) {
        let userToken = JSON.parse(localStorage.getItem("userData"));
        let userData = jwt_decode(userToken.token);
        userData.token = userToken.token;
        return userData;
    } else {
        return {};
    }
};

export const getPropertyTypeLabel = function (value) {
    switch (value) {
        case 'sfr':
            return 'Single-Family Residential (SFR) - Detached';
        case 'townhouse':
            return 'Townhouse/Rowhouse (Single-Family Attached)';
        case 'condo':
            return 'Condominium';
        case 'duplex':
            return 'Duplex (Both Units - Single Ownership)';
        case 'triplex':
            return 'Triplex';
        case 'quadruplex':
            return 'Quadruplex';
        case 'mobile':
            return 'Mobile';
        default:
            return '';
    }
}

export const decodeJwt = function (string) {
    // return localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : {};
    if (string) {
        let data = jwt_decode(string);
        return data;
    } else {
        return {};
    }
};

export const truncate = function (str, maximumLen) {
    return str && str.length > maximumLen ? str.substring(0, maximumLen) + "..." : str;
};

export const formatNumber = function (amount, decimal = 2) {
    return amount && parseFloat(amount).toFixed(decimal).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const priceNumber = function (amount, decimal = 2) {
    let prefix = amount < 0 ? "-$" : '$'
    amount = amount < 0 ? Math.abs(amount) : amount

    if (decimal > 0) {
        return amount ? prefix + parseFloat(amount).toFixed(decimal).replace(/\d(?=(\d{3})+\.)/g, '$&,') : prefix + parseFloat(0).toFixed(decimal);
    } else {
        return amount ? prefix + number_format(amount, 0) : prefix + number_format(0, 0);
    }
};

export const removePriceFormat = function (amount) {
    if (amount) {
        amount = amount.toString();
        return amount.includes("$") ? Number(amount.replace(/[^0-9]+/g, "")) : amount;
    } else {
        return 0;
    }
};

export const contactFormat = function (contact) {
    if (contact) {
        if (contact.includes('(') || contact.includes('-')) {
            return contact;
        } else {
            contact = contact.slice(-10).match(/(\d{3})(\d{3})(\d{4})/);
            return `(${contact[1]}) ${contact[2]}-${contact[3]}`;
        }
    }
}

export const getBase64 = function (file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

export const objectToQueryParams = function (obj) {
    let paramsData = [];

    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            paramsData.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return paramsData.join("&");
}

/*
| Number format, Identical to php number_format function
*/
export const number_format = function (number, decimals, dec_point, thousands_sep) {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec).replace(/\.0+$/, '');
};

/*
| Capital first character
*/
export const capitalizeFirstLetter = function (string) {
    return string && string.charAt(0).toUpperCase() + string.slice(1);
};

export const moneyFormat = function (amount) {
    return parseFloat(amount).toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
};

/*
| Date formatter
*/
export const formatDate = function (row, dateVal, dateFormat) {

    if (dateVal && dateVal !== '-') {
        return moment(dateVal).format(dateFormat)
        // return (<DateFormat date={dateVal} format={dateFormat} />);
    }
    return "-";
};

export const formatDateComponent = function (fieldName, row, format, customToolTip, hoverFormat) {
    return (
        <>
            <DateFormat fieldName={fieldName} format={format} row={row} customToolTip={customToolTip} hoverFormat={hoverFormat} />
        </>
    );
};

export const formatPropertyImagesArray = function (propertyImgs) {
    if (propertyImgs) {
        propertyImgs = propertyImgs.replace("[", "");
        propertyImgs = propertyImgs.replace("]", "");
        let isQoute = propertyImgs.indexOf('"');

        if (isQoute !== -1) {
            propertyImgs = JSON.parse("[" + propertyImgs + "]");
        } else {
            propertyImgs = propertyImgs.split(",");
        }
        propertyImgs = propertyImgs.filter(element => element !== "\"\"" && element !== "");

        return propertyImgs;
    }

    return [];
};

export const encryptData = function (data) {
    if (data) {
        return CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_ENCRYPT_KEY).toString();

    }
    return "";
};

export const decryptData = function (string) {
    if (string) {
        var bytes = CryptoJS.AES.decrypt(string, process.env.REACT_APP_ENCRYPT_KEY);
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
    }
    return "";
}

/*
| Convert Byte to Mb
*/
export const formatBytes = function (bytes) {
    var marker = 1024;
    var decimal = 2;
    var kiloBytes = marker;
    var megaBytes = marker * marker;
    var gigaBytes = marker * marker * marker;

    // return bytes if less than a KB
    if (bytes < kiloBytes) return bytes + " Bytes";
    // return KB if less than a MB
    else if (bytes < megaBytes) return (bytes / kiloBytes).toFixed(decimal) + " KB";
    // return MB if less than a GB
    else if (bytes < gigaBytes) return (bytes / megaBytes).toFixed(decimal) + " MB";

};

export const handleFlags = function (flags) {
    if (flags) {
        return (
            <>
                {flags.golf ? (
                    <img src={require("../../assets/img/flags/golf.png").default} alt="Golf" width={20} />
                ) : (null)}

                {flags.water ? (
                    <img src={require("../../assets/img/flags/water.png").default} alt="Water" width={20} />
                ) : (null)}

                {flags.pool ? (
                    <img src={require("../../assets/img/flags/pool.png").default} alt="Pool" width={20} />
                ) : (null)}
            </>
        )
    }
}

export const isNumeric = function (value) {
    return /^-?\d+$/.test(value);
}

export const checkValidPhone = function (phone) {
    let regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
    return regex.test(phone);
}

export function checkValueInArrayObjBykey(data, key) {
    let isThere = false;
    if (data.length > 0) {
        data.forEach(element => {
            if (element[key] === "") {
                isThere = true;
            }
        });
    }
    return isThere;
}

export const requestedMissingDocsFormat = function (docsArray) {
    let requested_missing_docs_arr = docsArray;

    let requested_missing_docs_display_val_arr = [];

    if (requested_missing_docs_arr.includes("plans")) {
        requested_missing_docs_display_val_arr.push("Plans");
    }
    if (requested_missing_docs_arr.includes("budget")) {
        requested_missing_docs_display_val_arr.push("Budget");
    }
    if (requested_missing_docs_arr.includes("property_info")) {
        requested_missing_docs_display_val_arr.push("Property Info");
    }
    if (requested_missing_docs_arr.includes("photo")) {
        requested_missing_docs_display_val_arr.push("Photos");
    }
    if (requested_missing_docs_arr.includes("inspection")) {
        requested_missing_docs_display_val_arr.push("Inspection");
    }
    if (requested_missing_docs_arr.includes("zoning_info")) {
        requested_missing_docs_display_val_arr.push("Zoning Info");
    }
    if (requested_missing_docs_arr.includes("property_type_confirmation")) {
        requested_missing_docs_display_val_arr.push("Property Type Confirmation");
    }
    if (requested_missing_docs_arr.includes("subject_property_mapping_issue")) {
        requested_missing_docs_display_val_arr.push("Subject Property Mapping Issue");
    }
    if (requested_missing_docs_arr.includes("processing_or_calculation_issue")) {
        requested_missing_docs_display_val_arr.push("Processing Or Calculation Issue");
    }
    if (requested_missing_docs_arr.includes("client_request")) {
        requested_missing_docs_display_val_arr.push("Client Request");
    }

    let requested_docs_formatted = requested_missing_docs_display_val_arr.length ? requested_missing_docs_display_val_arr.toString(", ") : "-";

    return requested_docs_formatted;
};

export const rowColorCondition = (row) => {
    let classNameForRow = (row.issue_resolved === '0') ? 'custom-conversation' : '';
    return classNameForRow;
};
export const currencyFormatToNumber = function (value) {

    value = value.toString();
    value = value.replace("$", "");
    value = value.replace(",", "");

    return value;
};

export const isNotEmpty = function (value) {
    if (value !== "" && value !== null && value !== "null" && value !== undefined) {
        return value
    }
    return false;

};
export const sendExceptionEmail = async function (error) {
    console.log("In sendExceptionEmail function", error)

    if (!error.api_error) {
        console.log("Need to send an email");
    }
}

/*
| display Username condition viz
*/
export const displayUserName = function (row, rvUser) {
    const userData = loggedInUser();
    const userRolesArray = [];
    let byName = (rvUser) ? "Client" : "RicherValues";
    if (rvUser) {
        if (row && ((row.user_id === userData.proxy_user_id) || (row.company_id === userData.company_id) || (userData.allowClientMessaging) || (checkRoles(['ADMINISTRATOR', 'SALES_REP', 'ACCOUNT_REP'])))) {
            byName = row.first_name + ' ' + row.last_name;
        }
        if (!row) {
            byName = "Inspector";
        }
    } else {
        row.userRoles && row.userRoles.forEach(element => {
            userRolesArray.push(element.role_id);
        });
        if (userRolesArray.includes(ROLE_ACCOUNT_REP) || userRolesArray.includes(ROLE_SALES_REP)) {
            byName = row.first_name + ' ' + row.last_name;
        } else if (userRolesArray.includes(ROLE_REVIEWER)) {
            byName = "Preparer";
        } else if (userRolesArray.includes(ROLE_ANALYST)) {
            byName = "Valuation Analyst";
        } else if (userRolesArray.includes(ROLE_DRV_ADMIN)) {
            byName = "QC & Processing Manager";
        } else if (userRolesArray.includes(ROLE_PROCESSOR)) {
            byName = "Processor";
        } else if (userRolesArray.includes(ROLE_INSPECTOR)) {
            byName = "Inspector";
        }
    }
    return byName;
}

export const generateRandomString = function (length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const secondsToHHMMSS = function (seconds) {
    const duration = moment.duration(seconds, 'seconds');
    return moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
};

export const getConditionRatingColor = function (currentRating) {

    let color = null;
    let actualCondition = null;
    let imageUrl = null;
    let imageSrc = null;

    if (currentRating >= CONDITION.UNSALVAGE_MIN && currentRating <= CONDITION.UNSALVAGE_MAX) {
        color = '#575757';
        actualCondition = 1;
        imageSrc = require("../../assets/img/conditions/unsalvageable-grey.png").default;
        imageUrl = `<img src="${imageSrc}" alt="Tear Down" />`
    } else if (currentRating >= CONDITION.VERY_POOR_MIN && currentRating <= CONDITION.VERY_POOR_MAX) {
        color = '#A63322';
        actualCondition = 2;
        imageSrc = require("../../assets/img/conditions/very-poor.png").default;
        imageUrl = `<img src="${imageSrc}" alt="Very Poor" />`
    } else if (currentRating >= CONDITION.POOR_MIN && currentRating <= CONDITION.POOR_MAX) {
        color = '#F4AB2C';
        actualCondition = 3;
        imageSrc = require("../../assets/img/conditions/poor.png").default;
        imageUrl = `<img src="${imageSrc}" alt="Poor" />`
    } else if (currentRating >= CONDITION.MODERATE_MIN && currentRating <= CONDITION.MODERATE_MAX) {
        color = '#E6E85E';
        actualCondition = 4;
        imageSrc = require("../../assets/img/conditions/moderate.png").default;
        imageUrl = `<img src="${imageSrc}" alt="Moderate" />`
    } else if (currentRating >= CONDITION.MAINTAINED_MIN && currentRating <= CONDITION.MAINTAINED_MAX) {
        color = '#74D170';
        actualCondition = 5;
        imageSrc = require("../../assets/img/conditions/maintained.png").default;
        imageUrl = `<img src="${imageSrc}" alt="Maintained" />`
    } else if (currentRating >= CONDITION.PARTIAL_REMODEL_MIN && currentRating <= CONDITION.PARTIAL_REMODEL_MAX) {
        color = '#50C9D6';
        actualCondition = 6;
        imageSrc = require("../../assets/img/conditions/partial-remodel.png").default;
        imageUrl = `<img src="${imageSrc}" alt="Partially Remodeled"  />`
    } else if (currentRating >= CONDITION.FULLY_REMODEL_MIN && currentRating <= CONDITION.FULLY_REMODEL_MAX) {
        color = '#3D3DDF';
        actualCondition = 7;
        imageSrc = require("../../assets/img/conditions/full-remodel.png").default;
        imageUrl = `<img src="${imageSrc}" alt="Fully Remodeled" />`
    } else if (currentRating >= CONDITION.NEW_BUILT_MIN && currentRating <= CONDITION.NEW_BUILT_MAX) {
        color = '#AB40D7';
        actualCondition = 8;
        imageSrc = require("../../assets/img/conditions/new-build-purple.png").default;
        imageUrl = `<img src="${imageSrc}" alt="Newly built" />`;
    }

    return { condition: actualCondition, color, imageSrc, imageUrl }
};

export const getConditionRatingForNewComp = function (currentRating) {
    let imageSrc = null;
    if (currentRating >= CONDITION.UNSALVAGE_MIN && currentRating <= CONDITION.UNSALVAGE_MAX) {
        imageSrc = require("../../assets/img/conditions/unsalvageable-grey-1.png").default;
    } else if (currentRating >= CONDITION.VERY_POOR_MIN && currentRating <= CONDITION.VERY_POOR_MAX) {
        imageSrc = require("../../assets/img/conditions/very-poor-2.png").default;
    } else if (currentRating >= CONDITION.POOR_MIN && currentRating <= CONDITION.POOR_MAX) {
        imageSrc = require("../../assets/img/conditions/poor-3.png").default;
    } else if (currentRating >= CONDITION.MODERATE_MIN && currentRating <= CONDITION.MODERATE_MAX) {
        imageSrc = require("../../assets/img/conditions/moderate-4.png").default;
    } else if (currentRating >= CONDITION.MAINTAINED_MIN && currentRating <= CONDITION.MAINTAINED_MAX) {
        imageSrc = require("../../assets/img/conditions/maintained-5.png").default;
    } else if (currentRating >= CONDITION.PARTIAL_REMODEL_MIN && currentRating <= CONDITION.PARTIAL_REMODEL_MAX) {
        imageSrc = require("../../assets/img/conditions/partial-remodel-6.png").default;
    } else if (currentRating >= CONDITION.FULLY_REMODEL_MIN && currentRating <= CONDITION.FULLY_REMODEL_MAX) {
        imageSrc = require("../../assets/img/conditions/full-remodel-7.png").default;
    } else if (currentRating >= CONDITION.NEW_BUILT_MIN && currentRating <= CONDITION.NEW_BUILT_MAX) {
        imageSrc = require("../../assets/img/conditions/new-build-purple-8.png").default;
    }
    return { imageSrc }
};

export const getConditionRatingName = function (currentRating, ratingRecords, circle = true) {
    let circleElement = null;
    if (currentRating) {
        currentRating = roundToTenth(currentRating);
        const conditionResult = getConditionRatingColor(currentRating);
        const whiteFontColors = ["#A63322", "#3D3DDF", "#575757", "#AB40D7"];
        const fontColor = whiteFontColors.includes(`${conditionResult.color}`) ? "#fff" : "#000";

        var item = ratingRecords.find(item => { return item.rate === parseInt(conditionResult.condition); });
        if (circle) {
            circleElement = `<div class="circle" style="background-color:${conditionResult.color}; color: ${fontColor};">${currentRating}</div>`;
            return circleElement;
        } else {
            return `${item.name} - ${currentRating}`;
        }
    } else {
        return circleElement;
    }
};

export const removeDuplicatesByFields = function (arr, fields) {
    const uniqueMap = {};
    const uniqueArray = arr.reduce((result, current) => {
        const key = fields.map(field => current[field]).join('-'); // Create a unique key
        if (!uniqueMap[key]) {
            uniqueMap[key] = true;
            result.push(current);
        }
        return result;
    }, []);
    return uniqueArray;
}

export const hasDuplicate = function (array, newItem, propertiesToCheck, indexToSkip) {
    return array
        .filter((_, index) => index !== indexToSkip) // Exclude the item at the specified index
        .some(existingItem => {
            return propertiesToCheck.every(property => existingItem[property] === newItem[property]);
        });
}

export const createLabelFromKey = function (string) {
    let label = string.replace("_", " ");
    label = label.replace("_", " ");
    label = label.replace(/\b\w/g, x => x.toUpperCase())
    return label;
};

export const roundToTenth = function (decimalNumber) {
    const roundedNumber = Math.floor(decimalNumber * 10) / 10;
    return roundedNumber.toFixed(2);
}

export const OpenURLInNewTab = async (address, companyName) => {
    try {
        const splittedAddress = address.split(",");
        let preparePayload, found;
        if (splittedAddress.length === 3 || splittedAddress.length === 4) {
            preparePayload = {
                address: splittedAddress[0].trim(),
                city: splittedAddress[1].trim(),
                state: splittedAddress[2].trim().split(" ")[0],
                postal_code: splittedAddress[2].trim().split(" ")[1]
            };
        } else {
            preparePayload = {
                address: splittedAddress[0].trim(),
                city: splittedAddress[1].trim(),
                state: splittedAddress[2].trim(),
                postal_code: splittedAddress[3].trim()
            };
        }
        const url = process.env.REACT_APP_API_ENDPOINT + "public/search-property-on-google";
        const response = await axios.post(url, preparePayload);
        let openTabURL = '';
        if (companyName === 'redfin') {
            openTabURL = response.data?.data?.redfin;
        } else {
            openTabURL = response.data?.data?.realtor;
        }
        if (openTabURL === "https://www.realtor.com/" || openTabURL === "https://www.redfin.com/") {
            found = false
        } else found = true
        return { status: 200, found, url: openTabURL, message: `Opening ${companyName}.` }
    } catch (error) {
        console.error("Error occurred while searching property on the internet:", error);
        throw error;
    }
};

export const formatSentenceCae = function (value) {
    // Replace underscores with spaces
    let formattedCase = value.replace(/_/g, ' ');

    // Capitalize the first letter of each word
    formattedCase = formattedCase.replace(/\b\w/g, match => match.toUpperCase());

    return formattedCase;
}

export const convertLotSize = function (lotSize) {
    if (lotSize > 0) {
        lotSize = lotSize / 43560;
        lotSize = parseFloat(lotSize, 2);
    }
    return lotSize;
}

export const formatPropertyAddress = function (property) {

    let property_address = property.address ? property.address : (property.property_address ? property.property_address : "");

    if (property.address_line_2) {
        property_address += ', ' + property.address_line_2;
    }

    if (property.unit_number) {
        property_address += ', ' + property.unit_number;
    }

    property_address += ', ' + property.city + ", " + property.state + " " + property.postal_code;

    return property_address;
}

export const ConditionRatingsFormate = function (value) {
    value = Number(value);
    if (value > 0) {
        const formattedValue = value % 1 === 0 ? value.toFixed(0) : value.toFixed(1);
        return formattedValue
    } else {
        return 0;
    }
}
export const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
        return null
    }
    phoneNumber = phoneNumber.replace(/\D/g, '');

    if (!phoneNumber.startsWith('1')) {
        phoneNumber = '1' + phoneNumber;
    }

    const formatted = '+' + phoneNumber.charAt(0) + ' (' + phoneNumber.substr(1, 3) + ') ' +
        phoneNumber.substr(4, 3) + '-' + phoneNumber.substr(7);

    return formatted;
}

export const createFilesArrayWtFaClasses = async function (files) {

    let uploadedFilesTemp = [];
    let uploadedPhotosTemp = [];

    files?.forEach(fileRow => {
        fileRow = { ...fileRow }
        let splittedArr = fileRow.location.split(".");
        let ext = splittedArr[splittedArr.length - 1];
        fileRow.ext = ext;

        if (['jpg', 'png', 'jpeg'].includes(fileRow.ext)) {
            uploadedPhotosTemp.push({
                title: fileRow.property_file_type.file_type_name + " - " + moment(fileRow.uploaded).format("MMM DD, YYYY"),
                url: fileRow.location,
                id: fileRow.id,
            });
        } else if (['xls', 'xlsx'].includes(fileRow.ext)) {
            fileRow.iClassName = 'fa fa-file-excel-o text-success'
            uploadedFilesTemp.push(fileRow);
        } else if (['mov', 'MOV', 'mkv', 'mp4', 'avi', 'wma', 'wmk', 'mpeg'].includes(fileRow.ext)) {
            fileRow.iClassName = 'fa fa-file-video-o text-success'
            uploadedFilesTemp.push(fileRow);
        } else if (['pdf'].includes(fileRow.ext)) {
            fileRow.iClassName = 'fa fa-file-pdf-o text-danger'
            uploadedFilesTemp.push(fileRow);
        } else {
            fileRow.iClassName = 'fa fa-file'
            uploadedFilesTemp.push(fileRow);
        }
    });

    return { uploadedFilesTemp, uploadedPhotosTemp }
}

export const sleep = (seconds) => new Promise(resolve => setTimeout(resolve, seconds * 1000));

export const openInNewTab = (url) => { window.open(url, '_blank', 'noopener,noreferrer'); };

export const isValidDate = function (value, format = "MM-DD-YY") {
    const isValidDate = moment(value, format, true).isValid();
    if (!isValidDate) return false
    return true
}

export const googleMapCustomMarker = (label, color = '22caab') => {
    label = String(label)
    let textX = "90"
    if (label?.length >= 2) textX = "60"

    const svgMarkup = `
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 -50 256 256" width="35px" height="55px">
                <rect width="180" height="100" x="30" y="-88" rx="20" ry="20" fill="rgba(0, 0, 0, 0.65)" stroke-width="1" stroke="white" />
            <g fill="#${color}" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
                <g transform="scale(5.33333,5.33333)">
                <path d="M35.76,26.36h0.01c0,0 -3.77,5.53 -6.94,9.64c-2.74,3.55 -3.54,6.59 -3.77,8.06c-0.09,0.54 -0.53,0.94 -1.06,0.94c-0.53,0 -0.97,-0.4 -1.06,-0.94c-0.23,-1.47 -1.03,-4.51 -3.77,-8.06c-0.42,-0.55 -0.85,-1.12 -1.28,-1.7l10.35,-12.3l8.33,-9.88c0.92,1.93 1.43,4.09 1.43,6.38c0,2.9 -0.83,5.59 -2.24,7.86z"></path>
                <path d="M28.24,22l-10.35,12.3c-2.82,-3.78 -5.66,-7.94 -5.66,-7.94h0.01c-0.3,-0.48 -0.57,-0.97 -0.8,-1.48l8.32,-9.88c-0.79,0.95 -1.26,2.17 -1.26,3.5c0,3.04 2.46,5.5 5.5,5.5c1.71,0 3.24,-0.78 4.24,-2z"></path>
                <path d="M28.4,4.74l-8.57,10.18l-6.56,-5.72c2.56,-3.18 6.42,-5.2 10.73,-5.2c1.54,0 3.02,0.26 4.4,0.74z"></path>
                <path d="M19.83,14.92l-0.07,0.08l-8.32,9.88c-0.92,-1.93 -1.44,-4.09 -1.44,-6.38c0,-3.54 1.23,-6.79 3.27,-9.3z"></path>
                <path d="M28.24,22c0.79,-0.95 1.26,-2.17 1.26,-3.5c0,-3.04 -2.46,-5.5 -5.5,-5.5c-1.71,0 -3.24,0.78 -4.24,2l8.64,-10.26c3.59,1.22 6.53,3.91 8.17,7.38z"></path>
                </g>
            </g>
            <text fill="#ffffff" font-size="100" font-family="Roboto" x="${textX}" y="0">${label}</text>
            </svg>
                `;

    const dataUrl = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svgMarkup)}`;

    return dataUrl;
};

export const getBuildingApexQualityGrades = (value) => {
    value = Number(value)
    switch (value) {
        case 1:
            return A_PLUS;
        case 2:
            return A;
        case 3:
            return A_MINUS;
        case 4:
            return B_PLUS;
        case 5:
            return B;
        case 6:
            return B_MINUS;
        case 7:
            return C_PLUS;
        case 8:
            return C_OR_C_MINUS;
        case 9:
            return C_OR_C_MINUS;
        case 10:
            return D;
        case 11:
            return D;
        case 12:
            return E;
        case 13:
            return E;
        case 14:
            return E;
        case 15:
            return E;
        case 16:
            return E;

        default: return E;
    }
}


export const getBuildingApexQualityRating = (value) => {
    value = 9 - value
    console.log('*getBuildingApexQualityRating', value);
    if (value >= 1 && value <= 8) {
        return parseFloat(value).toFixed(1);
    } else {
        return value;
    }
};

export const isPdf = (value) => {
    if (!value) {
        return false;
    }
    const extension = value.split('.').pop().toLowerCase();
    return extension === 'pdf';
};

export const loginUserAvatarUrl = (name) => {
    return `https://ui-avatars.com/api/?name=${name}&size=32&background=fff&color=0D8ABC&rounded=true&bold=true`;
};

//Prepare color pair for Other score 
export const commonScoreColorPair = (score) => {
    var bgColorCode = "";
    var fontColor = "";
    var innerFontColor = "";
    if (score < 0) {
        bgColorCode = "#c00000";
        fontColor = "#000000";
        innerFontColor = "#000000";
    } else if (score < 60) {
        bgColorCode = "#c00000";
        fontColor = "#c00000";
        innerFontColor = "#fff";
    } else if (score < 70) {
        bgColorCode = "#ffc700";
        fontColor = "#ffc700";
        innerFontColor = "#fff";
    } else if (score < 75) {
        bgColorCode = "#fbee66";
        fontColor = '#000000';
        innerFontColor = '#000000';
    } else if (score < 80) {
        bgColorCode = "#b0f0ff";
        fontColor = "#b0f0ff";
        innerFontColor = "#000000";
    } else if (score < 85) {
        bgColorCode = "#b7f3ca";
        fontColor = '#000000';
        innerFontColor = '#000000';
    } else if (score < 90) {
        bgColorCode = "#5ee487";
        fontColor = '#ffffff';
        innerFontColor = '#ffffff';
    } else if (score >= 90) {
        bgColorCode = "#00b050";
        fontColor = '#ffffff';
        innerFontColor = '#ffffff';
    }
    return { bgColorCode, fontColor, innerFontColor }
};

// Prepare color pair for Data Quality
export const dataQualityScoreColorPair = (score) => {

    var bgColorCode = "";
    var fontColor = "";

    if (score < 0) {
        bgColorCode = "#c00000";
        fontColor = "#000000";
    } else if (score < 50) {
        bgColorCode = "#c00000";
        fontColor = "#c00000";
    } else if (score < 60) {
        bgColorCode = "#ffc700";
        fontColor = "#ffc700";
    } else if (score < 65) {
        bgColorCode = "#fbee66";
        fontColor = '#000000';
    } else if (score < 70) {
        bgColorCode = "#b0f0ff";
        fontColor = '#000000';
    } else if (score < 75) {
        bgColorCode = "#b7f3ca";
        fontColor = '#000000';
    } else if (score < 80) {
        bgColorCode = "#5ee487";
        fontColor = "#5ee487";
    } else if (score >= 80) {
        bgColorCode = "#00b050";
        fontColor = '#ffffff';
    }
    return { bgColorCode, fontColor }
};

// Prepare color pair for Applicability Score
export const applicabilityScoreColorPair = (score) => {
    var bgColorCode = "";
    var fontColor = "";
    if (score < 5.50) {
        bgColorCode = "#c00000";
        fontColor = "#c00000";
    } else if (score < 6) {
        bgColorCode = "#ffc700";
        fontColor = "#ffc700";
    } else if (score < 6.75) {
        bgColorCode = "#fbee66";
        fontColor = '#000000';
    } else if (score < 7.25) {
        bgColorCode = "#b0f0ff";
        fontColor = '#000000';
    } else if (score < 7.75) {
        bgColorCode = "#b7f3ca";
        fontColor = '#000000';
    } else if (score < 8.25) {
        bgColorCode = "#5ee487";
        fontColor = '#000000';
    } else if (score >= 8.25) {
        bgColorCode = "#00b050";
        fontColor = '#ffffff';
    }
    return { bgColorCode, fontColor }
};

// Prepare color pair for Applicability Score
export const compScoreApplicabilityScoreColorPair = (score) => {
    var bgColorCode = "";
    var fontColor = "";
    if (score < 5.50) {
        bgColorCode = "#c00000";
        fontColor = "#fff";
    } else if (score < 6) {
        bgColorCode = "#ffc700";
        fontColor = "#000000";
    } else if (score < 6.75) {
        bgColorCode = "#fbee66";
        fontColor = '#000000';
    } else if (score < 7.5) {
        bgColorCode = "#b0f0ff";
        fontColor = '#000000';
    } else if (score < 8.25) {
        bgColorCode = "#b7f3ca";
        fontColor = '#000000';
    } else if (score < 9) {
        bgColorCode = "#5ee487";
        fontColor = '#000000';
    } else if (score >= 9) {
        bgColorCode = "#00b050";
        fontColor = '#fff';
    }
    return { bgColorCode, fontColor }
};

// Prepare color pair for Applicability Score
export const propertyComparisonColorPair = (score) => {
    var bgColorCode = "";
    var fontColor = "";
    if (score <= 5) {
        bgColorCode = "#C00000";
        fontColor = "#000000";
    } else if (score < 10) {
        bgColorCode = "#DC44A5";
        fontColor = "#000000";
    } else if (score < 20) {
        bgColorCode = "#F3BFE0";
        fontColor = "#000000";
    } else if (score < 30) {
        bgColorCode = "#FEB96E";
        fontColor = "#000000";
    } else if (score < 40) {
        bgColorCode = "#FBEE66";
        fontColor = "#000000";
    } else if (score < 50) {
        bgColorCode = "#B0F0FF";
        fontColor = "#000000";
    } else if (score < 70) {
        bgColorCode = "#B7F3CA";
        fontColor = "#000000";
    } else if (score < 80) {
        bgColorCode = "#00B050";
        fontColor = "#000000";
    } else if (score < 90) {
        bgColorCode = "#5EE487";
        fontColor = "#000000";
    } else if (score < 95) {
        bgColorCode = "#B7F3CA";
        fontColor = "#000000";
    } else if (score >= 95) {
        bgColorCode = "#FEB96E";
        fontColor = "#000000";
    }

    return { bgColorCode, fontColor }
};

export const getOrdinal = (number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = number % 100;
    return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
};

export const formatSubjectPropertyStats = (property) => {
    const formatBathroomTotal = (bathroomsTotal) => parseFloat(bathroomsTotal).toFixed(2);

    const numberFormat = (number, decimals = 0) => parseFloat(number).toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    const livingAreaSqFt = parseFloat(property.above_sqft) + parseFloat(property.below_sqft);
    const formattedLivingArea = numberFormat(livingAreaSqFt) + ' sqft';

    const formattedBedrooms = numberFormat(property.beds) + ' + ';
    const formattedBathrooms = formatBathroomTotal(property.baths) + ';';

    const yearBuilt = property.year_built;
    const propertyType = property.property_type_normalized;

    const lotSizeInAcres = parseFloat(property.lot_size) / 43560;
    const formattedLotSize = isNaN(lotSizeInAcres) ? '' : ' on ' + numberFormat(lotSizeInAcres, 2) + ' acres';

    return `${formattedLivingArea} ${formattedBedrooms} ${formattedBathrooms} ${yearBuilt} ${propertyType}${formattedLotSize}`;
}

export const onlyUnique = (array) => {
    return array.filter((value, index, array) => array.indexOf(value) === index);
}

export const calculateDecimalHoursAndMinutes = async (startDate, endDate) => {
    const start = moment(startDate);
    const end = moment(endDate);

    const difference = moment.duration(end.diff(start));
    //const totalMinutes = difference.asMinutes();
    const totalHours = difference.asHours();
    return totalHours;
}

// Array SUM
export const arraySum = async (arrayData) => {
    let sum = 0;
    if (arrayData.length) {
        arrayData.forEach(item => {
            sum += parseFloat(item);
        });
    }
    return sum;
}

export const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};


export const updateLoginLogoutLocalStorage = (isLogin) => {
    // Set a value in localStorage when the user logs in
    localStorage.setItem('isLoggedIn', isLogin ? 'true' : 'false');

    // Trigger storage event for manual tab detection (optional in some cases)
    localStorage.setItem('refreshTabs', Date.now().toString());
};