import React from "react";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import { showSpinner } from './../../../../ShowHideSpinner.reducer'

import { PutData as OrderIntakePut } from "services/OrderIntakeData";
import { sendExceptionEmail } from "components/Common/Helpers";
import RenderActionButton from "./RenderActionButton";

const ReactivateInspectionOrderIntakeButton = ({ propRowData, propButtonOptions }) => {

    const dispatch = useDispatch();

    let handleActionClick = (e, row) => {
        e.preventDefault();
        confirmAlert({
            title: '',
            message: 'Are you sure you want to reactivate this order?',
            closeOnClickOutside: false,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        await handleReactivateInspectionIntakeProcess();
                    },
                    className: "btn btn-primary btn-sm"
                }, {
                    label: 'Close',
                    onClick: () => { },
                    className: "btn btn-secondary btn-sm"
                }
            ]
        });
    };

    let handleReactivateInspectionIntakeProcess = async () => {
        try {
            dispatch(showSpinner(true));
            const response = await OrderIntakePut("inspection/" + propRowData.intake_token + "/reactivate", {}, propRowData.company_id)
            toast["success"](response.message);
            dispatch(showSpinner(false));
        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error);
            dispatch(showSpinner(false));
        }
    };

    return (
        <>
            <RenderActionButton propButtonTitle="Reactivate" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />
        </>
    );
};

export default ReactivateInspectionOrderIntakeButton;