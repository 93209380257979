import React from "react";

const Ul = ({ propLiContent = [] }) => {
    return (
        <>
            <ul>
                {propLiContent.length > 0 && propLiContent.map((li, i) => (
                    <li key={i} >{li}</li>
                ))
                }
            </ul>
        </>
    );
}
export default Ul;
