import React, { useState, useEffect } from "react";
import { Modal, Row, Col, CardBody } from "reactstrap";
import "./style.scss";
import { AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";

const ResumeAnalysisReview = ({ handleResumeAnalysisReview, onHandleResponse }) => {

    const [isResumeModal, setOpenResumeModal] = useState(false);

    useEffect(() => {

        if (handleResumeAnalysisReview.current) {
            setOpenResumeModal(true);
        }
    }, [handleResumeAnalysisReview.current, onHandleResponse]);// eslint-disable-line react-hooks/exhaustive-deps

    let handleToggleModal = (state) => {
        state = !state;
        setOpenResumeModal(state);
        handleResumeAnalysisReview.current = false;
    };

    return (
        <>
            <Modal className="modal-dialog-centered resume-select-option-modal" style={{ maxWidth: "500px" }} isOpen={handleResumeAnalysisReview.current} toggle={() => handleToggleModal(isResumeModal)}>
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title text-center w-100">
                        Select a Option
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isResumeModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <AvForm >
                    <CardBody className="pb-0">
                        <Row className="mb-2">
                            <Col className="">
                                <div className="custom-av-radio-btn inline">
                                    <AvRadioGroup className="radio-group" name="select_type">
                                        <AvRadio onChange={e => onHandleResponse(e.target.value)} label="Resume Analysis" value="resume" />
                                        <AvRadio onChange={e => onHandleResponse(e.target.value)} label="Relinquish My Assignment" value="continue" />
                                    </AvRadioGroup>
                                </div>
                            </Col>
                        </Row>

                    </CardBody>
                </AvForm>
            </Modal>
        </>
    );
};

export default ResumeAnalysisReview;