import React, { useState, useEffect } from "react";
import "./style.scss";
import { UncontrolledTooltip } from "reactstrap";
import * as moment from "moment";
import { useLocation, useHistory } from 'react-router-dom'
import ConversationModal from "./ConversationModal";
import ChatModal from "./ChatModal";
import MessageWithConversationModal from "./MessageWithConversationModal";
import RenderActionButton from "../Common/ActionsButtons/RenderActionButton";

const ThreadColumn = ({ rowData, onUpdateData, onUpdateMessage, showButton = false, propButtonOptions }) => {

    const location = useLocation();
    const history = useHistory();
    const search = location.search
    let searchParams = new URLSearchParams(search)
    let mainTab = '';

    let [update, setUpdate] = useState(moment());
    let isOpenConversationModal = React.useRef(false);
    let [conversationRow, setConversationRow] = useState([]);
    let isOpenMessageWithConversationModal = React.useRef(false);
    let [conversationDataRow, setConversationDataRow] = useState([]);
    let isOpenChatModal = React.useRef(false);

    useEffect(() => { }, [update]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (searchParams.get('mainTab')) {
            mainTab = searchParams.get('mainTab').split("__"); // eslint-disable-line react-hooks/exhaustive-deps
        }

        if (searchParams.get('mainTab') && rowData.id === mainTab[1]) {
            handleConversation();
        }
        searchParams.delete('mainTab')
        // Update the location with the modified search params
        const newLocation = {
            ...location,
            search: searchParams.toString(),
        };
        // Replace the current location with the updated one
        if (mainTab)
            history.replace(newLocation);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /*
    | Handle Conversation
    */
    let handleConversation = () => {
        if (rowData.notifications !== null) {
            isOpenConversationModal.current = true;
            setConversationDataRow(rowData.id)
        } else {
            isOpenMessageWithConversationModal.current = true;
            setConversationDataRow(rowData.id)
        }
        setUpdate(moment());
    };

    let handleOpenThread = (row, fields) => {
        onUpdateData();
        isOpenChatModal.current = true;
        conversationRow = {
            id: row.id,
            property_address: row.property_address,
            issue_resolved: "0",
            topic_subtopic: (row.topic_id) ? fields.selected_topic + ':' + fields.selected_subtopic : "General",
            created_by: row.created_by
        }
        if (row.report_order_id) {
            conversationRow.report_order_id = row.report_order_id;
        } else {
            conversationRow.inspection_order_id = row.inspection_order_id;
        }
        setConversationRow(conversationRow);
    };
    return (
        <>
            {!showButton ?
                <>
                    {rowData.notifications === null &&
                        <>
                            <i id={"message_" + rowData.id} className="fa fa-2x fa-comment-dots cursor-pointer" style={{ color: '#999997' }} aria-hidden="true" onClick={e => handleConversation()}></i>
                            <UncontrolledTooltip delay={0} placement="auto" target={"message_" + rowData.id} >Chat</UncontrolledTooltip>
                        </>
                    }
                    {rowData.notifications === 0 &&
                        <>
                            <i id={"message_" + rowData.id} className="fa fa-2x fa-comment-dots cursor-pointer" style={{ color: '#ADD8E6' }} aria-hidden="true" onClick={e => handleConversation()}></i>
                            <UncontrolledTooltip delay={0} placement="auto" target={"message_" + rowData.id} >Chat</UncontrolledTooltip>
                        </>
                    }
                    {(rowData.notifications > 0) &&
                        <>
                            <span className="notification_span">
                                <i id={"message_" + rowData.id} className="fa fa-2x fa-comment-dots cursor-pointer" style={{ color: '#338BA8' }} aria-hidden="true" onClick={e => handleConversation()}></i>
                                <div className="notification">{rowData.notifications}</div>
                            </span>
                        </>
                    }
                </> :
                <>
                    <RenderActionButton propButtonTitle="Chat" propButtonOptions={propButtonOptions} propOnClickEvent={handleConversation} />
                    {rowData.notifications ? <span className="notification-badge">{rowData.notifications}</span> : null}
                </>
            }
            {isOpenConversationModal.current &&
                <ConversationModal standaloneType={rowData.standalone} propertyId={rowData.propertyId} propData={conversationDataRow} onOpenConversationModal={isOpenConversationModal} onUpdateConversation={onUpdateData} onUpdateMessage={onUpdateMessage} />
            }
            {isOpenMessageWithConversationModal.current &&
                <MessageWithConversationModal propHandleModal={isOpenMessageWithConversationModal} reportInspectionId={conversationDataRow} standaloneType={rowData.standalone}
                    currentRow={[]}
                    onUpdateConversation={handleOpenThread} />
            }
            {isOpenChatModal.current &&
                <ChatModal standaloneType={rowData.standalone} propertyId={rowData.propertyId} conversationData={conversationRow} propHandleModal={isOpenChatModal} onUpdateMessage={onUpdateMessage} />
            }
        </>
    );
};

export default ThreadColumn;