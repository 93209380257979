import React from "react";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import { showSpinner } from '../../../../../ShowHideSpinner.reducer'

import eventBus from "../../EventBus";
import { PostData } from "services/Api.service";
import { sendExceptionEmail } from "components/Common/Helpers";
import RenderActionButton from "../RenderActionButton";

const ReportOrderRetryPropertyUploadButton = ({ propRowData, propButtonOptions }) => {

    const dispatch = useDispatch();

    let handleActionClick = (e) => {
        e.preventDefault();
        confirmAlert({
            title: '',
            message: 'You are trying to upload property again. Are you sure ?',
            closeOnClickOutside: false,
            buttons: [
                {
                    label: 'Yes, Retrigger',
                    onClick: async () => {
                        await handleProcess();
                    },
                    className: "btn btn-primary btn-sm"
                }, {
                    label: 'Close',
                    onClick: () => { },
                    className: "btn btn-outline-danger btn-sm"
                }
            ]
        });
    };

    let handleProcess = async () => {

        try {
            dispatch(showSpinner(true));
            const response = await PostData("orders/retry-property-upload", { report_order_id: propRowData.report_order_id });
            toast["success"](response.message);
            dispatch(showSpinner(false));
            eventBus.dispatch("report_order_status_updated", { client_id: propRowData.company_id });
        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            eventBus.dispatch("report_order_status_updated", { client_id: propRowData.company_id });
            await sendExceptionEmail(error);
            dispatch(showSpinner(false));
        }
    };

    return (
        <>
            <RenderActionButton propButtonTitle="Retry Property Upload" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />
        </>
    );
};

export default ReportOrderRetryPropertyUploadButton;